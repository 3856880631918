import 'cropperjs/dist/cropper.css';
import React from 'react';
import { default as Cropper, default as ReactCropper } from 'react-cropper';
import dataURItoBlob, { resizeImage } from '../../../functionLib/img';
import ButtonComponent from '../ButtonComponent/ButtonComponent';
import { ResizeImageData } from '../types/imageData';

export type CroppProps = {
    src: string;
    close: (data: ResizeImageData) => void;
    cb: (data: ResizeImageData) => void;
    aspectRatio?: number;
};
const CroppComponent: React.FC<CroppProps> = ({ src, close, cb, aspectRatio = 3 / 3 }) => {
    const cropper = React.createRef<ReactCropper>();

    const onCrop = async () => {
        if (!cropper.current) {
            return;
        }
        const dataUrl = cropper.current.getCroppedCanvas().toDataURL();
        const dataOriginal = {
            image: dataUrl,
            file: dataURItoBlob(dataUrl),
        };

        const resize: string = await resizeImage(dataUrl, dataOriginal.file.type, 100, 100);
        const dataResized = {
            image: resize,
            file: dataURItoBlob(resize),
        };
        const data: ResizeImageData = {
            original: dataOriginal,
            resized: dataResized,
        };
        cb(data);
        close(data);
    };

    return (
        <div className="crop_main">
            <div className="cropper_container">
                <Cropper
                    ref={cropper}
                    src={src}
                    style={{ height: 300, width: 400 }}
                    aspectRatio={aspectRatio}
                    guides
                    viewMode={1}
                />
            </div>
            <div className="buttons_cropper">
                <ButtonComponent onClick={onCrop} acuikit>
                    Crop
                </ButtonComponent>
            </div>
        </div>
    );
};

export default CroppComponent;
