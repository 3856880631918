import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { emptyUsersFilter, UsersFilterValue } from './users.types';

export type UsersState = {
    filter: UsersFilterValue;
    filterCompletion: {
        tags: string[];
        attributes: string[];
    };
};

const initialUsersState: UsersState = {
    filter: emptyUsersFilter,
    filterCompletion: {
        tags: [],
        attributes: [],
    },
};

const usersSlice = createSlice({
    name: 'users',
    initialState: initialUsersState,
    reducers: {
        setUsersFilter: (state, action: PayloadAction<UsersFilterValue>) => ({
            ...state,
            filter: action.payload,
        }),
        setFilterCompletionTags: (state, action: PayloadAction<string[]>) => ({
            ...state,
            filterCompletion: { ...state.filterCompletion, tags: action.payload },
        }),
        setFilterCompletionAttributes: (state, action: PayloadAction<string[]>) => ({
            ...state,
            filterCompletion: { ...state.filterCompletion, attributes: action.payload },
        }),
    },
});

export const usersActions = usersSlice.actions;
export const usersReducer = usersSlice.reducer;
