export default class StrokeCap
{
	static get Butt()
	{
		return 0;
	}

	static get Round()
	{
		return 1;
	}

	static get Square()
	{
		return 2;
	}
}