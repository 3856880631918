import React, { useEffect, useRef, useState } from 'react';
import svgLib from '../../../functionLib/svglib';
import './InformComponent.css';

export type InformComponentProps = {
    display?: string;
    title: string;
    description: string | React.ReactElement;
};
export const InformComponent: React.FC<InformComponentProps> = ({ display, title, description }) => {
    const contentWrapper = useRef<HTMLDivElement>(null);

    const [isShow, setIsShow] = useState(false);
    const [height, setHeight] = useState('0px');
    const [width, setWidth] = useState('0px');
    useEffect(() => {
        if (contentWrapper.current && contentWrapper.current.getBoundingClientRect().height !== 0) {
            setHeight(`${contentWrapper.current.getBoundingClientRect().height + 55}px`);
            setWidth(`${contentWrapper.current.getBoundingClientRect().width + 30}px`);
        } else {
            setHeight('auto');
            setWidth('auto');
        }
    }, []);

    let style;
    switch (display) {
        case 'top':
            style = {
                overflow: 'hidden',
                position: 'absolute',
                height: isShow ? height : '0px',
                width: isShow ? width : '0px',
                transition: 'width 0.3s, height 0.3s',
                left: '50%',
                transform: 'translateX(-50%)',
                bottom: '7px',
            };
            break;

        default:
            style = {
                overflow: 'hidden',
                position: 'absolute',
                height: isShow ? height : '0px',
                width: isShow ? width : '0px',
                transition: 'width 0.3s, height 0.3s',
                left: '50%',
                transform: 'translateX(-50%)',
                top: '-9px',
            };
            break;
    }

    return (
        <div className="InformComponent_main">
            <div
                className="InformComponent_main_container"
                onMouseEnter={() => setIsShow(true)}
                onMouseLeave={() => setIsShow(false)}
            >
                <div className="InformComponent_main_svg">{svgLib.information('rgba(0, 0, 0, 0.38)')}</div>
                <div id="style" style={{ ...style }}>
                    <div
                        className="InformComponent_main_message_container"
                        // style={{ display: isShow ? 'block' : 'none' }}
                        ref={contentWrapper}
                    >
                        <div className="InformComponent_main_message_content">
                            <div
                                className="InformComponent_main_message_sqwer_wrapper"
                                style={
                                    display === 'top'
                                        ? {
                                              bottom: '-10px',
                                              top: 'auto',
                                          }
                                        : {
                                              top: '-10px ',
                                              bottom: 'auto',
                                          }
                                }
                            >
                                <div className="InformComponent_main_message_sqwer" />
                                <div
                                    className="InformComponent_main_message_hide"
                                    style={
                                        display === 'top'
                                            ? {
                                                  top: '-13px',
                                              }
                                            : {
                                                  top: '10px',
                                              }
                                    }
                                />
                            </div>
                            <div className="InformComponent_main_message_header">
                                <div className="InformComponent_main_message_header_td">
                                    <div className="InformComponent_main_message_header_title">{title}</div>
                                </div>
                            </div>
                            <div className="InformComponent_main_message_contant_wrapper">{description}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InformComponent;
