import { Api } from '../api';
import { customerActions } from '../redux/actions';
import { AppDispatch } from '../redux/store';

const getRolesAndUpdateState = async (api: Api, appDispatch: AppDispatch) => {
    try {
        const customerId = await api.customers.get({ _id: true });
        const customerRoles = await api.roles.readCustomerRoles(customerId._id);
        appDispatch(customerActions.setCustomerRoles(customerRoles.roles));
    } catch (error) {
        console.log(`${getRolesAndUpdateState.name}:`, error);
    }
};

export default getRolesAndUpdateState;
