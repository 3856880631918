import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAppBot } from '../../../../redux/bot/bot.selectors';
import { useAppCustomer } from '../../../../redux/customer/customer.selectors';
import AppHeaderComponent from '../../../UIComponents/AppHeaderComponent/AppHeaderComponent';
import LoadingComponent from '../../../UIComponents/LoadingComponent/LoadingComponent';
import { useLoadAgents } from '../AllUsersComponent/hooks/useLoadAgents';
import './BotComponent.css';
import { BotDashboard } from './BotDashboard';
import DataNavigation from './DataNavigation';
import { useLastNewSessionId } from './hooks/useLastNewSessionId';
import { useLastQueuedSessionId } from './hooks/useLastQueuedSessionId';
import { useRoot } from './hooks/useRoot';
import { useGlobalQueuedSessionsList, useGlobalSessionsList } from './hooks/useSessionsList';
import { useSetLivechatData } from './hooks/useSetLivechatData';
import { useSetSelectedBotId } from './hooks/useSetSelectedBotId';
import { useUnreadMessagesCount } from './hooks/useUnreadMessagesCount';
import './SelectedBotComponent.css';

type SelectedBotProps = {
    setShowSidebar: (val: boolean) => void;
    showSidebar: boolean;
};
const SelectedBotComponent: React.FC<SelectedBotProps> = (props) => {
    const { botAgent: selectedBot } = useAppBot();
    const { customer } = useAppCustomer();

    const { sessionsList } = useGlobalSessionsList();
    const { unreadMessagesCount } = useUnreadMessagesCount(sessionsList);
    const { lastNewSessionId } = useLastNewSessionId(sessionsList);

    const { queuedSessionsList } = useGlobalQueuedSessionsList();
    const {} = useLastQueuedSessionId(queuedSessionsList);

    const { root, subRoot, permissions } = useRoot();
    useSetSelectedBotId();
    useLoadAgents();
    useSetLivechatData();

    return (
        <div className="BotComponent_main">
            {(permissions.length > 0 || customer?.isAdmin) && (
                <BotDashboard
                    unreadMsgCount={unreadMessagesCount}
                    lastSessionId={lastNewSessionId}
                    setShowSidebar={props.setShowSidebar}
                    showSidebar={props.showSidebar}
                    navigation={props}
                    permissions={permissions}
                />
            )}
            <div className="BotComponent_content">
                <AppHeaderComponent
                    setShowSidebar={props.setShowSidebar}
                    root={root}
                    subRoot={subRoot}
                    text={
                        <div className="rootView">
                            {subRoot ? (
                                <div className="rootView_container">
                                    <div className="rootView_container_main">{root}/</div>
                                    <div className="rootView_container_one">{subRoot}</div>
                                </div>
                            ) : (
                                <div className="rootView_container_one">
                                    <div className="rootView_container_main">
                                        {selectedBot?.title ? `${selectedBot.title} /` : ''}
                                    </div>
                                    <div className="rootView_container_one">{root}</div>
                                </div>
                            )}
                        </div>
                    }
                />
                <div className="BotComponent_content_container">
                    {selectedBot && Object.keys(selectedBot).length ? (
                        <Switch>
                            <Suspense fallback={<LoadingComponent />}>
                                {DataNavigation(customer, permissions).map((item) => (
                                    <Route
                                        key={item.id.concat('unic')}
                                        path={`/bots/bot/:id/${item.linkTo}`}
                                        render={() => item.component}
                                    />
                                ))}
                            </Suspense>
                        </Switch>
                    ) : (
                        <LoadingComponent />
                    )}
                </div>
            </div>
        </div>
    );
};

export default SelectedBotComponent;
