
const broadcasts = (options = {}) => {
    const { GATEWAY_URL, DEFAULT_API_ERROR } = options

    /**
     * Gets broadcasts list for the specified agent
     * @param {String} agentID - Id of the agent to get broadcasts list for
     * @returns {Promise<[]>} broadcasts list for the specified agent
     */
    const get = async (agentID) => {
        if (!agentID) throw new Error('Please specify id of the agent to get broadcasts list for')

        const url = new URL(`${GATEWAY_URL}/api/getAgentBroadcasts`)

        url.searchParams.append('agentID', agentID)

        const options = {
            mode: 'cors',
            credentials: 'include',
            method: 'GET'
        }

        try {
            const response = await fetch(url, options),
                statusCode = response.status,
                body = await response.json()

if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }

            if (!response.ok) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body })
            if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

            return body
        } catch (error) {
            throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
        }
    }

    /**
     * Creates specified broadcast
     * @param {Object} broadcast - An object with broadcast data to create
     * @returns {Promise} an error if occurred while creating broadcast
     */
    const create = async (broadcast = {}) => {
        if (!Object.keys(broadcast).length) throw new Error('Please specify correct broadcast data to create')

        const url = new URL(`${GATEWAY_URL}/api/createBroadcast`)

        const options = {
            headers: {
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ broadcast })
        }

        try {
            const response = await fetch(url, options),
                statusCode = response.status,
                body = await response.json()

if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }

            if (!response.ok) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body })
            if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

            return body
        } catch (error) {
            throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
        }
    }

    /**
     * Updates already existing broadcast with data specified
     * @param {String} id Id of the broadcast to update
     * @param {Object} broadcast Broadcast object data to update specified by id broadcast
     */
    const update = async (id, broadcast = {}) => {
        if (!id) throw new Error('Please specify id of the broadcast to update')
        if (!Object.keys(broadcast).length) throw new Error('Please specify correct broadcast data to update')

        const url = new URL(`${GATEWAY_URL}/api/updateBroadcast/${id}`)

        const options = {
            headers: {
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            credentials: 'include',
            method: 'PUT',
            body: JSON.stringify({ broadcast })
        }

        try {
            const response = await fetch(url, options),
                statusCode = response.status,
                body = await response.json()

if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }

            if (!response.ok) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body })
            if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

            return body
        } catch (error) {
            throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
        }
    }

    return ({
        get,
        create,
        update
    })
}

export default broadcasts;