const shopify = (options = {}) => {
    const { GATEWAY_URL, DEFAULT_API_ERROR } = options

    /**
     * Diconnects Shopify integration from the specified agent
     * @param {String} agentID - Id of the agent to disconnect Shopify for
     * @param {String} store - An address of the Shopify store to disconnect
     * @param {String} access_token - An access token used for current Shopify integration
     * @returns {Promise} an error if occurred while disconnecting Shopify from the agent
     */
    const disconnect = async (agentID, store, access_token) => {
        if (!agentID) throw new Error('Please specify the id of the agent to disconnect Shopify for')
        if (!store) throw new Error('Please specify an address of the Shopify store to diconnect')
        if (!access_token) throw new Error('Please specify an access token used for current Shopify integration')

        const url = new URL(`${GATEWAY_URL}/api/shopifyApp`)

        const options = {
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            method: 'DELETE',
            body: JSON.stringify({ agentID, store, access_token })
        }

        try {
            const response = await fetch(url, options),
                statusCode = response.status,
                body = await response.json()

if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }

            if (!response.ok) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body })
            if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

            return body
        } catch (error) {
            throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
        }
    }

    return ({
        disconnect
    })
}

export default shopify;