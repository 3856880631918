import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './UiKit.css';
import TableComponent from '../../UIComponents/TableComponet/TableComponent';
import ButtonComponent from '../../UIComponents/ButtonComponent/ButtonComponent';
import CheckboxComponent from '../../UIComponents/CheckboxComponent/CheckboxComponent';
import Select from '../../UIComponents/Select/Select';
import ToggleComponent from '../../UIComponents/ToggleComponent/ToggleComponent';
import Dropdown from '../../UIComponents/Dropdown/Dropdown';
import InformComponent from '../../UIComponents/InformComponent/InformComponent';
import IconButton from '../../UIComponents/IconButton/IconButton';
import InputComponent from '../../UIComponents/InputComponent/InputComponent';
import { InputPopupComponent } from '../../UIComponents/InputComponent/InputPopupComponent';
import svgLib from '../../../functionLib/svglib';
import { copyTextToClipboard } from '../../../functionLib/copyFunc';

const UiKit = () => {
    const [stateCheckbox, setStateCheckbox] = useState(false);
    const [stateToggle, setStateToggle] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [message, setMessage] = useState({ data: 'Message', name: 'ui', borderColor: '#FF6F61' });

    const onSetShow = () => {
        setMessage({
            ...message,
            name: '',
        });
    };

    const createMessage = (messageText, name) => {
        setMessage({
            ...message,
            data: messageText,
            name,
        });
    };

    const data = [
        {
            name: 'Default button',
            code: (
                <CodeSnippet className="codeMark">
                    {'<ButtonComponent acuikit>Action button</ButtonComponent>'}
                </CodeSnippet>
            ),
            component: <ButtonComponent acuikit>Action button</ButtonComponent>,
        },
        {
            name: 'Default button whith loader',
            code: (
                <CodeSnippet className="codeMark">
                    {'<ButtonComponent colorLoader="white" loading acuikit>Action button</ButtonComponent>'}
                </CodeSnippet>
            ),
            component: (
                <ButtonComponent colorLoader="white" loading acuikit>
                    Action button
                </ButtonComponent>
            ),
        },
        {
            name: 'outline button',
            code: (
                <CodeSnippet className="codeMark">
                    {'<ButtonComponent  outline acuikit>Action button</ButtonComponent>'}
                </CodeSnippet>
            ),
            component: (
                <ButtonComponent outline acuikit>
                    Action button
                </ButtonComponent>
            ),
        },
        {
            name: 'red button',
            code: (
                <CodeSnippet className="codeMark">
                    {'<ButtonComponent  red acuikit>Action button</ButtonComponent>'}
                </CodeSnippet>
            ),
            component: (
                <ButtonComponent red acuikit>
                    Action button
                </ButtonComponent>
            ),
        },
        {
            name: 'Locked button',
            code: (
                <CodeSnippet className="codeMark">
                    {'<ButtonComponent locked acuikit>Action button</ButtonComponent>'}
                </CodeSnippet>
            ),
            component: (
                <ButtonComponent locked acuikit>
                    Action button
                </ButtonComponent>
            ),
        },
        {
            name: 'Locked button',
            code: (
                <CodeSnippet className="codeMark">
                    {'<ButtonComponent outline locked acuikit>Action button</ButtonComponent>'}
                </CodeSnippet>
            ),
            component: (
                <ButtonComponent outline locked acuikit>
                    Action button
                </ButtonComponent>
            ),
        },
        {
            name: 'Checkbox',
            code: (
                <CodeSnippet className="codeMark">
                    {'<CheckboxComponent checked={stateCheckbox} onChange={() => setStateCheckbox(!stateCheckbox)} />'}
                </CodeSnippet>
            ),
            component: <CheckboxComponent checked={stateCheckbox} onChange={() => setStateCheckbox(!stateCheckbox)} />,
        },
        {
            name: 'ToggleComponent',
            code: (
                <CodeSnippet className="codeMark">
                    {'<ToggleComponent checked={stateToggle} onChange={() => setStateToggle(!stateToggle)} />'}
                </CodeSnippet>
            ),
            component: <ToggleComponent checked={stateToggle} onChange={() => setStateToggle(!stateToggle)} />,
        },
        {
            name: 'ToggleComponent',
            code: (
                <CodeSnippet className="codeMark">
                    {'<ToggleComponent disabled checked={stateToggle} onChange={() => setStateToggle(!stateToggle)} />'}
                </CodeSnippet>
            ),
            component: <ToggleComponent checked={stateToggle} disabled onChange={() => setStateToggle(!stateToggle)} />,
        },
        {
            name: 'IconButton',
            code: (
                <CodeSnippet className="codeMark">
                    {`<IconButton
        svg={svgLib.settings('#006CFF')}
        onClick={() => alert('Hi')}
    />`}
                </CodeSnippet>
            ),
            component: <IconButton svg={svgLib.settings()} onClick={() => alert('Hi')} />,
        },

        {
            name: 'IconButton',
            code: (
                <CodeSnippet className="codeMark">
                    {`<IconButton
                outline
        svg={svgLib.settings('#006CFF')}
        onClick={() => alert('Hi')}
    />`}
                </CodeSnippet>
            ),
            component: <IconButton outline svg={svgLib.settings()} onClick={() => alert('Hi')} />,
        },
    ];

    const columns = [
        {
            Header: 'Name',
            field: 'name',
            className: 'centerClass',
        },
        {
            Header: 'Code',
            field: 'code',
            className: 'centerClass',
        },
        {
            Header: 'Component',
            field: 'component',
            className: 'centerClass',
        },
    ];

    const dataDropDown = [
        {
            name: 'Select',
            code: (
                <CodeSnippet className="codeMark">
                    {`<Select
                        zIndex={23}
                        acuikit
                        name="Actions"
                        onChange={value => alert(value)}
                        options={[
                            {
                                content: <div className="group_bot_select_option">Move to</div>,
                                value: 'moveTo',
                                name: 'Move to',
                            },
                            {
                                content: <div className="group_bot_select_option">Stop bots</div>,
                                value: 'stopBots',
                                name: 'Stop bots',
    
                            },
                            {
                                content: <div className="group_bot_select_option">Delete</div>,
                                value: 'delete',
                                name: 'Delete',
    
                            },
                        ]}
                />`}
                </CodeSnippet>
            ),
            component: (
                <Select
                    zIndex={24}
                    acuikit
                    name="Actions"
                    onChange={(value) => alert(value)}
                    options={[
                        {
                            content: <div className="group_bot_select_option">Move to</div>,
                            value: 'moveTo',
                            name: 'Move to',
                        },
                        {
                            content: <div className="group_bot_select_option">Stop bots</div>,
                            value: 'stopBots',
                            name: 'Stop bots',
                        },
                        {
                            content: <div className="group_bot_select_option">Delete</div>,
                            value: 'delete',
                            name: 'Delete',
                        },
                    ]}
                />
            ),
        },

        {
            name: 'Select without name',
            code: (
                <CodeSnippet className="codeMark">
                    {`<Select
                        acuikit
                        onChange={value => alert(value)}
                        options={[
                            {
                                content: <div className="group_bot_select_option">Move to</div>,
                                value: 'moveTo',
                                name: 'Move to',
                            },
                            {
                                content: <div className="group_bot_select_option">Stop bots</div>,
                                value: 'stopBots',
                                name: 'Stop bots',
    
                            },
                            {
                                content: <div className="group_bot_select_option">Delete</div>,
                                value: 'delete',
                                name: 'Delete',
    
                            },
                        ]}
                />`}
                </CodeSnippet>
            ),
            component: (
                <Select
                    acuikit
                    zIndex={23}
                    onChange={(value) => alert(value)}
                    options={[
                        {
                            content: <div className="group_bot_select_option">Move to</div>,
                            value: 'moveTo',
                            name: 'Move to',
                        },
                        {
                            content: <div className="group_bot_select_option">Stop bots</div>,
                            value: 'stopBots',
                            name: 'Stop bots',
                        },
                        {
                            content: <div className="group_bot_select_option">Delete</div>,
                            value: 'delete',
                            name: 'Delete',
                        },
                    ]}
                />
            ),
        },
        {
            name: 'Drop down',
            code: (
                <CodeSnippet className="codeMark">
                    {`<Dropdown
                name="Actions"
                acuikit
                onChange={value => alert(value)}
                options={[
                    {
                        content: <div className="group_bot_select_option">Move to</div>,
                        value: 'moveTo',
                        name: 'Move to',
                    },
                    {
                        content: <div className="group_bot_select_option">Stop bots</div>,
                        value: 'stopBots',
                        name: 'Stop bots',

                    },
                    {
                        content: <div className="group_bot_select_option">Delete</div>,
                        value: 'delete',
                        name: 'Delete',

                    },
                ]}
            />`}
                </CodeSnippet>
            ),
            component: (
                <Dropdown
                    name="Actions"
                    acuikit
                    onChange={(value) => alert(value)}
                    options={[
                        {
                            content: <div className="group_bot_select_option">Move to</div>,
                            value: 'moveTo',
                            name: 'Move to',
                        },
                        {
                            content: <div className="group_bot_select_option">Stop bots</div>,
                            value: 'stopBots',
                            name: 'Stop bots',
                        },
                        {
                            content: <div className="group_bot_select_option">Delete</div>,
                            value: 'delete',
                            name: 'Delete',
                        },
                    ]}
                />
            ),
        },
    ];

    const dataFields = [
        {
            name: 'InformComponent',
            code: (
                <CodeSnippet className="codeMark">
                    {`<InformComponent
        title="How it works?"
            description="Default reply is sent when your user types in something your bot doesn’t understand"
    />`}
                </CodeSnippet>
            ),
            component: (
                <InformComponent
                    title="How it works?"
                    description="Default reply is sent when your user types in something your bot doesn’t understand"
                />
            ),
        },
        {
            name: 'InformComponent top',
            code: (
                <CodeSnippet className="codeMark">
                    {`<InformComponent
                 display="top"
        title="How it works?"
            description="Default reply is sent when your user types in something your bot doesn’t understand"
    />`}
                </CodeSnippet>
            ),
            component: (
                <InformComponent
                    display="top"
                    title="How it works?"
                    description="Default reply is sent when your user types in something your bot doesn’t understand"
                />
            ),
        },
        {
            name: 'Input ',
            code: <CodeSnippet className="codeMark">{'<InputComponent />'}</CodeSnippet>,
            component: (
                <InputComponent
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="placeholder"
                />
            ),
        },
        {
            name: 'Input read only',
            code: (
                <CodeSnippet className="codeMark">
                    {`<InputComponent
            value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                placeholder="placeholder"
                readonly
            />`}
                </CodeSnippet>
            ),
            component: (
                <InputComponent
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="placeholder"
                    readonly
                />
            ),
        },
        {
            name: 'InputPopupComponent',
            code: (
                <CodeSnippet className="codeMark">
                    {`<InputPopupComponent
            value={inputValue}
                onChange={(e) => { setInputValue(e.target.value); createMessage(e.target.value, 'ui'); }}
                placeholder="placeholder"
            notification={{ ...message }}
                name="ui"
            setShow={onSetShow}
                />`}
                </CodeSnippet>
            ),
            component: (
                <InputPopupComponent
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        createMessage(e.target.value, 'ui');
                    }}
                    placeholder="placeholder"
                    notification={{ ...message }}
                    name="ui"
                    setShow={onSetShow}
                />
            ),
        },
    ];

    return (
        <div className="iu_wrapper" style={{ paddingTop: '50px' }}>
            <Link
                to="/svglib"
                style={{
                    position: 'fixed',
                    width: '100%',
                    padding: '20px',
                    background: 'linear-gradient(180deg, #006CFF 0%, #00BFFF 100%)',
                    top: 0,
                    zIndex: '43654645634',
                    textAlign: 'center',
                    textDecoration: 'none',
                }}
            >
                <span style={{ color: 'white' }}> go to svg </span>
            </Link>
            <h1>Buttons</h1>
            <TableComponent data={data} columns={columns} />
            <h1>Dropdowns</h1>
            <TableComponent data={dataDropDown} columns={columns} minHeight="300px" />
            <h1>Fields</h1>
            <TableComponent data={dataFields} columns={columns} minHeight="300px" disableOverflow />
        </div>
    );
};

// eslint-disable-next-line react/prop-types
const CodeSnippet = ({ children }) => {
    const [showCode, setShowCode] = useState(false);

    return (
        <div style={{ position: 'relative' }}>
            <div className="codeheader">
                <button type="button" onClick={() => copyTextToClipboard(children)}>
                    Copy
                </button>
                <button type="button" onClick={() => setShowCode(!showCode)}>
                    {showCode ? 'hide' : 'show'}
                </button>
            </div>
            <mark style={{ height: showCode ? 'auto' : '40px' }} className="codeMark">
                {children}
            </mark>
        </div>
    );
};

export default UiKit;
