import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import svgLib from '../../../../functionLib/svglib';
import { useAppBot } from '../../../../redux/bot/bot.selectors';
import { useUnsafeCustomer } from '../../../../redux/customer/customer.selectors';
import { useLivechatData } from '../../../../redux/livechat/livechat.selectors';
import { BotComponentDashButtonComponent } from './BotComponentDashButtonComponent';
import DataNavigation from './DataNavigation';
import arrowDown from './img/arrowDown.png';
import search from './img/search.png';
import { PopupFolderComponent } from './PopupFolder';

type BotDashboardProps = {
    setShowSidebar: (val: boolean) => void;
    showSidebar: boolean;
    navigation: any;
    permissions: any[];
    lastSessionId: string | undefined;
    unreadMsgCount: number;
};
export const BotDashboard: React.FC<BotDashboardProps> = ({
    setShowSidebar,
    showSidebar,
    navigation,
    permissions,
    lastSessionId,
    unreadMsgCount,
}) => {
    const { botAgent: selectedBot, botList } = useAppBot();
    const { customer } = useUnsafeCustomer();
    const livechatData = useLivechatData();

    const [dashWidth, setDashWidth] = useState('0px');
    const [showContent] = useState(true);

    const [isHiddenDashboard, setIsHiddenDashboard] = useState(false);
    const [openBotPopup, setOpentBotPopup] = useState(false);
    const [botSearch, setBotSearch] = useState('');

    const openTogle = (stateDashbord?: 'open') => {
        if (stateDashbord === 'open') {
            setDashWidth('220px');
            setIsHiddenDashboard(false);
        } else {
            setDashWidth('80px');
            setIsHiddenDashboard(true);
        }
    };

    useEffect(() => {
        openTogle();
    }, []);

    const showPopup = () => {
        setOpentBotPopup(true);
        setTimeout(() => document.getElementById('serachBot')?.focus(), 300);
    };

    if (!showSidebar) {
        return null;
    }
    return (
        <div className="BotComponent_dashboard_" style={{ width: dashWidth }}>
            <div
                className="BotComponent_dashboard_wrapper"
                style={{
                    opacity: `${showContent ? 1 : 0}`,
                }}
            >
                <div
                    className="BotComponent_dashboard_header"
                    onMouseEnter={() => openTogle('open')}
                    onMouseLeave={() => openTogle()}
                >
                    <div className="BotComponent_dashboard_header_bot" onClick={showPopup}>
                        <div
                            className="BotComponent_dashboard_header_bot_avatar_wrapper"
                            style={{ margin: isHiddenDashboard ? '0 20px' : '0 10px 0 20px' }}
                        >
                            <div className="BotComponent_dashboard_header_bot_image_wrapper">
                                <img src={selectedBot?.image} alt="" />
                            </div>
                            {livechatData?.liveChat && (
                                <div
                                    style={{
                                        background: `${
                                            lastSessionId ? 'red' : unreadMsgCount > 0 ? 'yellow' : 'green'
                                        }`,
                                    }}
                                    className="BotComponent_dashboard_header_bot_avatar_notification"
                                />
                            )}
                        </div>
                        <div className="BotComponent_dashboard_header_bot_name">
                            <span>{selectedBot?.title}</span>
                        </div>
                        <div className="BotComponent_dashboard_header_arrow">
                            <img src={arrowDown} alt="" />
                        </div>
                    </div>
                    <div onClick={() => setShowSidebar(false)} className="BotComponent_dashboard_header_cross">
                        {svgLib.cross('#161616', { width: '17px', height: '17px' })}
                    </div>
                </div>
                <div
                    className="BotComponent_dashboard_content"
                    onMouseEnter={() => openTogle('open')}
                    onMouseLeave={() => openTogle()}
                    onClick={() => openTogle('open')}
                >
                    {DataNavigation(customer, permissions).map((item) => (
                        <BotComponentDashButtonComponent
                            linkTo={`/bots/bot/${navigation.match.params.agentID}/${item.linkTo}`}
                            name={item.name}
                            img={item.img}
                            key={item.id}
                            isOpen={!isHiddenDashboard}
                            subRoutes={item.subRoutes}
                            navigation={navigation}
                        />
                    ))}
                </div>
            </div>

            <div
                className="BotComponent_bot_popup"
                style={{ display: openBotPopup ? 'flex' : 'none' }}
                onMouseLeave={() => {
                    openBotPopup && setOpentBotPopup(false);
                }}
            >
                <div className="BotComponent_bot_popup_td_name">
                    <div className="BotComponent_bot_popup_bot_name" onClick={() => setOpentBotPopup(false)}>
                        <div className="BotComponent_bot_popup_header_bot_avatar_wrapper">
                            <img src={selectedBot?.image} alt="" />
                        </div>
                        <div className="BotComponent_bot_popup_header_bot_name">
                            <span>{selectedBot?.title}</span>
                        </div>
                        <div className="BotComponent_bot_popup_header_arrow">
                            <img src={arrowDown} alt="" />
                        </div>
                    </div>
                    <div className="BotComponent_bot_popup_search">
                        <label htmlFor="serachBot" className="BotComponent_bot_popup_search_input_wrapper">
                            <div className="BotComponent_bot_popup_search_icon">
                                <img src={search} alt="" />
                            </div>
                            <div className="BotComponent_bot_popup_search_input">
                                <input
                                    onChange={(e) => setBotSearch(e.target.value)}
                                    value={botSearch}
                                    id="serachBot"
                                    placeholder="Search bot"
                                />
                            </div>
                        </label>
                    </div>
                </div>
                <div className="BotComponent_bot_popup_td_midle">
                    <PopupFolderComponent
                        filter={botSearch}
                        name="My bots"
                        bots={botList}
                        match={navigation.match}
                        close={() => setOpentBotPopup(false)}
                        location={navigation.location.pathname}
                        botSearch={botSearch}
                    />
                </div>
                <div className="BotComponent_bot_popup_td">
                    <Link
                        to="/bots/newbot/all"
                        style={{ textDecoration: 'none' }}
                        className="BotComponent_bot_popup_Create_new_bot"
                    >
                        <span>Create new bot</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};
