import { BotSchemaBlockType } from '../../types/botSchema';
import { Reducer } from '../types';

export type AppState = {
    blocksList: Record<string, BotSchemaBlockType>;
    currentSkill: string;
};

export const initialAppState: AppState = {
    blocksList: {},
    currentSkill: 'start',
};

export const appReducer: Reducer<AppState, AppAction> = (state, action) => {
    switch (action.type) {
        case 'setBlocksList':
            return { ...state, blocksList: action.value };
        case 'setCurrentSKill':
            return { ...state, currentSkill: action.value };

        default:
            return state;
    }
};

export type AppAction =
    | { type: 'setBlocksList'; value: Record<string, BotSchemaBlockType> }
    | { type: 'setCurrentSKill'; value: string };

export const setBlocksList = (value: Record<string, BotSchemaBlockType>): AppAction => ({
    type: 'setBlocksList',
    value,
});
