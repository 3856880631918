const googleSheets = (options = {}) => {
    const { GATEWAY_URL, DEFAULT_API_ERROR } = options

    /**
     * Diconnects Google Sheets integration from the specified agent
     * @param {String} agentID - Id of the agent to disconnect Google Sheets for
     * @returns {Promise} an error if occurred while disconnecting Google Sheets from the agent
     */
    const disconnect = async (agentID) => {
        if (!agentID) throw new Error('Please specify the id of the agent to disconnect Google Sheets for')

        const url = new URL(`${GATEWAY_URL}/api/disconnectGoogleSheetsAccount`)

        const options = {
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ agentID })
        }

        try {
            const response = await fetch(url, options),
                statusCode = response.status,
                body = await response.json()

if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }

            if (!response.ok) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body })
            if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

            return body.data
        } catch (error) {
            throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
        }
    }

    /**
     * Gets Google Sheets list by specified agent id
     * @param {String} agentID Id of the agent to get Google Sheets list for
     * @returns {Promise<[Object]>} array of tables taken from Google Sheets 
     */
    const list = async (agentID) => {
        if (!agentID) throw new Error('Please specify the id of the agent to get Google Sheets list')

        const url = new URL(`${GATEWAY_URL}/api/getGoogleSheetsList`)

        const options = {
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ agentID })
        }

        try {
            const response = await fetch(url, options),
                statusCode = response.status,
                body = await response.json()

if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }

            if (!response.ok) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body })
            if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

            return body.sheets
        } catch (error) {
            throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
        }
    }

    return ({
        disconnect,
        list
    })
}

export default googleSheets;