import React from 'react';
import './Waiting.css';

const Waiting = ({
    text = '',
    className = '',
    description = '',
}) => (

    <div className={`waiting_conponent ${className}`}>
        {text && (
            <div className="waiting_text">
                <span>{text}</span>
            </div>
        )}
        {description && (
            <div className="waiting_description">
                <span>{description}</span>
            </div>
        )}
        <div className="waiting_snippet" data-title=".dot-pulse">
            <div className="waiting_stage">
                <div className="dot-pulse" />
            </div>
        </div>
    </div>
);

export default Waiting;
