import React, { createContext, useContext, useReducer } from 'react';
import { GlobalAction, GlobalState, initGlobalState } from './globalState';

export type GlobalStateContextType = [GlobalState, React.Dispatch<GlobalAction>];
export const GlobalStateContext = createContext<GlobalStateContextType>([initGlobalState, () => {}]);

export type GlobalStateProviderProps = {
    initialState: GlobalState;
    reducer: React.Reducer<GlobalState, GlobalAction>;
};
export const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({ reducer, initialState, children }) => {
    const value = useReducer(reducer, initialState);
    return <GlobalStateContext.Provider value={value}>{children}</GlobalStateContext.Provider>;
};

// TODO(m-nny): return non flat object
export function useGlobalState() {
    const [{ app, ui }, dispatch] = useContext(GlobalStateContext);
    return [{ ...app, ...ui }, dispatch] as const;
}

export const useAppState = useGlobalState;