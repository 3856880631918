import React, { useEffect, useState } from "react";
import { useApi } from '../../../../../../functionLib/hooks';
import ButtonComponent from "../../../../../UIComponents/ButtonComponent/ButtonComponent";
import { InputPopupComponent } from "../../../../../UIComponents/InputComponent/InputPopupComponent";
import EnterNewPassword from "./EnterNewPassword";
import "./index.css";
import PasswordChanged from "./PasswordChanged";
import UI from "./styled";


const ChangePasswordContent = () => {
  const { customers } = useApi();
  const [match, setMatch] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [buttonState, setButtonState] = useState({
    loading: false,
    locked: true
  });
  const [message, setMessage] = useState({
    data: "",
    name: "",
    borderColor: ""
  });

  useEffect(() => {
    setMatch(false);
    setSuccess(false);
  }, []);

  const onSetShow = () => {
    setMessage({
      ...message,
      name: ""
    });
  };

  const createMessage = (messageText, name) => {
    setMessage({
      ...message,
      data: messageText,
      name
    });
  };

  const switchButtonState = e => {
    if (e.target.value.length)
      setButtonState({ ...buttonState, locked: false });
    else setButtonState({ ...buttonState, locked: true });
  };

  const requestForChangingPassword = async () => {
    try {
      setButtonState({ ...buttonState, loading: true });
      await customers.checkPassword(currentPassword);
      setMatch(true);
    } catch (err) {
      setButtonState({ ...buttonState, loading: false });
      createMessage(err.message, "name-currentPassword");
    }
  };

  if (success) {
    return <PasswordChanged />;
  }

  if (match) {
    return <EnterNewPassword setSuccess={setSuccess} />;
  }

  return (
    <UI.CurrentPassword>
      <UI.CurrentPasswordText>Enter current password</UI.CurrentPasswordText>

      <InputPopupComponent
        value={currentPassword}
        type="password"
        notification={{ ...message }}
        className="currentPasswordInput"
        placeholder="Your current password"
        name="name-currentPassword"
        setShow={onSetShow}
        onChange={e => {
          switchButtonState(e);
          setCurrentPassword(e.target.value);
        }}
      />

      <ButtonComponent
        onClick={requestForChangingPassword}
        locked={buttonState.locked}
        loading={buttonState.loading}
        colorLoader="white"
        acuikit
      >
        Continue
      </ButtonComponent>
    </UI.CurrentPassword>
  );
};

export default ChangePasswordContent;
