import { CustomerRole } from '../../../../types/customerRole';
import DataNavigation from '../SelectedBotComponent/DataNavigation';

export function getBotLink(customerRoles: CustomerRole[], botId: string, customer): any {
  const customerRole = customerRoles.find((role) => role.botId === botId);
  if (!customerRole) {
    return '';
  }
  const dataNavigation = DataNavigation(customer, customerRole.permissions).find((root) => root.subRoutes.length > 0);
  if (!dataNavigation) {
    return '';
  }
  return `/bots/bot/${botId}/${dataNavigation.linkTo}/${dataNavigation.subRoutes[0].linkTo}`;
}
