import React from 'react';
import FlareComponent from 'flare-react';
import anim from './404anim.flr';
import './NotfoundComponent.css';
// import { browserHistory } from 'react-router';

const NotfoundComponent = ({ history }) => (
    <div className="NotfoundComponent_main">
        <div className="NotfoundComponent_container">
            <div className="NotfoundComponent_anim">
                <FlareComponent width={500} transparent height={500} animationName="404" file={anim} />

            </div>
            <div className="NotfoundComponent_text_Container">
                <h1>
                    404 Page not found
                </h1>
                <p className="errorText">
                    Uh oh, we can't seem to find the page you're looking for.
                    <br />
                    Try going
                    {' '}
                    <span
                        onClick={() => { history.goBack(); }}
                        style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                    >
                        back
                    </span>
                    {' '}
                    to the previous page.
                </p>
            </div>
        </div>
    </div>
);

export default NotfoundComponent;
