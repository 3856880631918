import React, { useContext } from 'react';
import { TableEngineState } from './TableEngine';

export type TableContext<T = any> = TableEngineState<T>;

const initialTableContext: TableContext = {} as any;

const GlobalStateContext = React.createContext(initialTableContext);

export const useTableContent = <T = any>(): TableContext<T> => useContext(GlobalStateContext);

export const TableContentProvider = GlobalStateContext.Provider;
