/* eslint-disable indent */
import styled from 'styled-components';

const NameEditor = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NameInput = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 40px;
  font-family: var(--main-font);
  font-size: 12px;
  font-weight: 200;
  padding: 12px 10px
  outline: none;
`;

const InputLabel = styled.label`
  position: relative;
  align: right;
  color: #000000;
  margin: 20px 15px 10px 0px;
  font-family: SFUI;
  font-size: 12px;
  line-height: 16px;
`;

export default {
  NameEditor,
  NameInput,
  InputLabel,
};
