import React, { useEffect } from 'react';
import CheckboxComponent from '../CheckboxComponent/CheckboxComponent';
import { useTableContent } from './GlobalStateContext';

type ContentTableProps = {
    maxHeight?: string;
    minHeight?: string;
    disableOverflow?: boolean;
    loading?: boolean;
    onScroll?: React.UIEventHandler<HTMLDivElement>;
    onSort?: (value) => void;
    rowMinHeight?;
    style?: React.CSSProperties;
};

const ContentTable: React.FC<ContentTableProps> = ({
    maxHeight,
    minHeight,
    disableOverflow,
    loading,
    onScroll,
    onSort,
    rowMinHeight,
    style,
}) => {
    const context = useTableContent();
    const { data, sort, keyExtractor } = context;
    const dataTr = data;

    if (!onSort) {
        dataTr.sort((a, b) => {
            if (a[context.sort.value.by] > b[context.sort.value.by]) {
                return context.sort.value.direction ? 1 : -1;
            }
            if (a[context.sort.value.by] < b[context.sort.value.by]) {
                return context.sort.value.direction ? -1 : 1;
            }
            return 0;
        });
    }
    useEffect(() => {
        if (onSort && context.sort.value.by) {
            onSort(context.sort.value);
        }
    }, [sort.value.direction, sort.value.by]);

    const checkRow = (dataCheck) => {
        if (context.selectedRow.value.some((element) => element === dataCheck)) {
            const selectedArray = [...context.selectedRow.value];
            const indexElement = selectedArray.indexOf(dataCheck);
            selectedArray.splice(indexElement, 1);
            context.selectedRow.set(selectedArray);
            context.selectedAll.set(false);
        } else {
            const selectedArray = [...context.selectedRow.value];
            selectedArray.push(dataCheck);
            context.selectedRow.set(selectedArray);
            context.selectedAll.set(false);
        }
    };
    return (
        <div
            style={{
                ...style,
                maxHeight: maxHeight || 'none',
                minHeight: minHeight || 'none',
                overflow: disableOverflow ? 'visible' : 'auto',
                overflowY: disableOverflow ? ('visible' as any) : 'overlay',
            }}
            className={` ContentTableStyle style_table_scroll ${loading ? 'ContentTableStyle_blur' : null}`}
            onScroll={onScroll}
        >
            {dataTr.map((itemTr, index) => (
                <div
                    className="ContentTableTr"
                    key={keyExtractor?.(itemTr) || index}
                    style={{ minHeight: rowMinHeight || '60px' }}
                >
                    {context.showCheckbox ? (
                        <div className="table_checkbox_wrapper">
                            <CheckboxComponent
                                className="tablecheckbox"
                                onChange={() => checkRow(itemTr)}
                                checked={context.selectedRow.value.some((element) => element === itemTr)}
                            />
                        </div>
                    ) : null}
                    {context.columns.map((itemTd, Index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div
                            id="contentItem"
                            key={Index}
                            className={` ContentTableTd ${itemTd.fieldClassName ? itemTd.fieldClassName : ''} ${
                                itemTd.className ? itemTd.className : ''
                            }`}
                        >
                            {itemTd.CustomField
                                ? itemTd.CustomField({
                                      tr: itemTr,
                                      td: itemTd,
                                  })
                                : itemTr[itemTd.field]}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default ContentTable;
