const _parseFieldsObjectToString = (fields: Record<string, any> = {}): string => {
    let resultString = '';

    if (typeof fields !== 'object') return resultString;

    fields = _flattenObject(fields);

    resultString = Object.keys(fields)
        .map((field) => `${fields[field] ? '' : '-'}${field}`)
        .join(' ');

    return resultString;
};
const _flattenObject = (obj: Record<string, any>, delimiter: string = '.'): Record<string, any> => {
    const toReturn = {};

    for (const propName in obj) {
        if (!obj.hasOwnProperty(propName)) continue;

        if (typeof obj[propName] === 'object' && obj[propName] !== null) {
            const flatProp = _flattenObject(obj[propName]);
            for (const x in flatProp) {
                if (!flatProp.hasOwnProperty(x)) continue;
                toReturn[propName + delimiter + x] = flatProp[x];
            }
        } else {
            toReturn[propName] = obj[propName];
        }
    }
    return toReturn;
};


type typeArrayDates = {date:string}
const  _ArrayOfOneDate = (myDate : number) =>{
    const D = new Date(myDate);
    const Till = new Date();
    const result: typeArrayDates[] = [];

    function pad(s) {
        return ('00' + s).slice(-2);
    }

    while (D.getTime() < Till.getTime()) {
        const onPush = {date: `${pad(D.getDate())}/${pad(D.getMonth() + 1)}`, Bot:0, User:0, Agent:0};
        result.push(onPush);
        D.setDate(D.getDate() + 1);
    }
    return result;
}

function _ArrayOfTwoDate(startDate, endDate) {
    const date = new Date(new Date(startDate).getTime());

    const dates: typeArrayDates[] = [];

    while (date <= endDate) {
        dates.push({
            date: `${('0' + new Date(date).getDate()).slice(-2)}/${('0' + (new Date(date).getMonth() + 1)).slice(-2)}`,
        });
        date.setDate(date.getDate() + 1);
    }

    return dates;
}

export default { _parseFieldsObjectToString, _flattenObject, _ArrayOfOneDate, _ArrayOfTwoDate };
