const qrcodes = (options = {}) => {
  const { GATEWAY_URL, DEFAULT_API_ERROR } = options

  /**
   * @param {String} id - id of selected bot
   * @returns {Promise<Array>} - collection of qrCodes 
   */
  const fetchCodes = async (selectedBotId) => {
    try {
      const options = {
        mode: 'cors',
        credentials: 'include',
        method: 'GET',
      }

      const response = await fetch(`${GATEWAY_URL}/api/qrcodes/?agentId=${selectedBotId}`, options)
      const statusCode = response.status
      const body = await response.json()

      if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }
      if (!response.ok) {
        throw ({
            statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body,
        })
      }
      if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

      return body
    } catch (error) {
      throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
    }
  }

  /**
   * @param {String} id 
   */
  const removeQrCode = async (id) => {
    try {
      const  options = {
        mode: 'cors',
        credentials: 'include',
        method: 'DELETE'
      }

      const response = await fetch(`${GATEWAY_URL}/api/qrcodes?codeId=${id}`, options)
      const statusCode = response.status
      const body = await response.json()

      if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }
      if (!response.ok) {
        throw ({
            statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body,
        })
      }
      if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

      window.notification.info('QR Code removed', 1)
    } catch (error) {
      throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
    }
  }

  /**
   * @returns {Object} - response
   */
  const saveQrCode = async (qrConfiguration) => {
    try {
      const options = {
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(qrConfiguration)
      }

      const response = await fetch(`${GATEWAY_URL}/api/qrcodes`, options)
      const statusCode = response.status
      const body = await response.json()

      if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }
      if (!response.ok) {
        throw ({
            statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body,
        })
      }
      if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

      return body
    } catch(error) {
      throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
    }
  }

  return {
    fetchCodes,
    removeQrCode,
    saveQrCode
  }
}

export default qrcodes