import { useAppSelector } from '../hooks';

export const useAppBot = () => useAppSelector((state) => state.bot);

// NOTE(m-nny): botAgent actually could be undefined, if page did not load
export const useUnsafeAppBotAgent = () => useAppSelector((state) => state.bot.botAgent!);

export const useUnsafeSelectedBot = () =>
    useAppSelector((state) => ({ selectedBotId: state.bot.botId!, selectedBot: state.bot.botAgent! }));
export const useUnsafeSelectedBotId = () => useAppSelector((state) => state.bot.botId!);
