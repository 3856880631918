import styled from 'styled-components';

export const AppNavBarComponentMain = styled.div`
    width: 80px;
    height: 100%;
    display: flex;
    background-color: #0d1d33;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    position: relative;
`;
export const AppNavBarButtonsWrapComponent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
`;
export const AppNavBarButtonTrComponent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 30px;
`;
