export const tagOperators = ['exists', 'not_exists'] as const;
export type TagOperator = typeof tagOperators[number];

export const attributeOperators = [
    'equals',
    'not_equals',
    'contains',
    'not_contains',
    'greater',
    'greater_equals',
    'less',
    'less_equals',
] as const;
export type AttributeOperator = typeof attributeOperators[number];

export const filterOperators = ['and', 'or'] as const;
export type FilterOperatorType = typeof filterOperators[number];

export type TagType = {
    id: number;
    value: string;
    operator: TagOperator;
};

export type AttributeType = {
    id: number;
    title: string;
    value: string;
    operator: AttributeOperator;
};

export type UsersFilterValue = {
    operator: FilterOperatorType;
    tags: TagType[];
    attributes: AttributeType[];
};

export const emptyUsersFilter: UsersFilterValue = { operator: 'and', tags: [], attributes: [] };
