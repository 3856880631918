import PropTypes from "prop-types";
import React, { useState } from "react";
import { useApi } from '../../../../../../../functionLib/hooks';
import ButtonComponent from "../../../../../../UIComponents/ButtonComponent/ButtonComponent";
import { InputPopupComponent } from "../../../../../../UIComponents/InputComponent/InputPopupComponent";
import "./index.css";
import UI from "./styled";


const EnterNewPassword = ({ setSuccess }) => {
  const { customers } = useApi();
  const [newPasswordValue, setNewPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [buttonState, setButtonState] = useState({
    locked: true,
    loading: false
  });
  const [message, setMessage] = useState({
    data: "",
    name: "",
    borderColor: ""
  });
  const [passwordPattern, setPasswordPattern] = useState({
    pswdRegEx: /(?=.*\d)(?=.*[a-z]).{6,}/
  });

  const onSetShow = () => {
    setMessage({
      ...message,
      name: ""
    });
  };

  const createMessage = (messageText, name) => {
    setMessage({
      ...message,
      data: messageText,
      name
    });
  };

  const requestForChangingPassword = async () => {
    const error = { message: "Invalid password type" };
    const error1 = { message: "Passwords do not match" };

    try {
      setButtonState({ ...buttonState, loading: true });

      if (newPasswordValue !== confirmPasswordValue) {
        createMessage("Passwords do not match", "name-newPassword");
        throw error1;
      }

      if (!passwordPattern.pswdRegEx.test(newPasswordValue)) {
        throw error;
      }

      await customers.setPassword(newPasswordValue);

      setSuccess(true);
    } catch (err) {
      setButtonState({ ...buttonState, loading: false });

      if (err.message === "Invalid password type") {
        createMessage(
          "Password must contain at least one digit and letter and be at least six characters long",
          "name-newPassword"
        );
      } else {
        createMessage("Passwords do not match", "name-newPassword");
      }
    }
  };

  const switchButtonState = e => {
    if (e.target.value.length || (newPasswordValue.length && confirmPasswordValue.length)) setButtonState({ ...buttonState, locked: false });
    else setButtonState({ ...buttonState, locked: true });
  };

  return (
    <UI.CurrentPassword>
      <UI.CurrentPasswordText>Set new password</UI.CurrentPasswordText>

      <InputPopupComponent
        value={newPasswordValue}
        type="password"
        notification={{ ...message }}
        className="enterNewPasswordInput"
        placeholder="Enter new password"
        name="name-newPassword"
        setShow={onSetShow}
        onChange={e => {
          switchButtonState(e);
          setNewPasswordValue(e.target.value);
        }}
      />

      <InputPopupComponent
        value={confirmPasswordValue}
        type="password"
        notification={{ ...message }}
        className="confirmPassword"
        placeholder="Confirm new password"
        name="name-confirmPassword"
        setShow={onSetShow}
        onChange={e => {
          switchButtonState(e);
          setConfirmPasswordValue(e.target.value);
        }}
      />

      <ButtonComponent
        onClick={requestForChangingPassword}
        locked={buttonState.locked}
        loading={buttonState.loading}
        colorLoader="white"
        acuikit
      >
        Continue
      </ButtonComponent>
    </UI.CurrentPassword>
  );
};

EnterNewPassword.propTypes = {
  setSuccess: PropTypes.func
};

EnterNewPassword.defaultProps = {
  setSuccess: () => {}
};

export default EnterNewPassword;
