import React, { useEffect, useRef, useState } from 'react';
import svgLib from '../../../functionLib/svglib';
import './GeneralTogleComponent.css';

type GeneralTogleProps = {
    img?: string;
    bot?: { name: string; img: string };
    name: string;
    customHeader?;
    children?;
    isOpened: boolean;
    className?: string;
    info?;
    svgLibPropery?;
};

const GeneralTogleComponent: React.FC<GeneralTogleProps> = ({
    bot,
    name,
    customHeader,
    children,
    isOpened,
    className,
    info,
    svgLibPropery,
}) => {
    const contentWrapper = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(isOpened);

    const overflowStyle = isOpen ? 'visible' : 'hidden';

    useEffect(() => {
        setIsOpen(isOpened);
    }, [isOpened]);

    return (
        <div style={{ overflow: overflowStyle }} className={`GeneralTogle_main ${className}`}>
            <div className="GeneralTogle_header">
                <div className="GeneralTogle_header_content">
                    {bot && Object.keys(bot).length ? (
                        <div className="GeneralTogle_header_content_own" onClick={() => setIsOpen(!isOpen)}>
                            {isOpen ? (
                                svgLib.folder('#000000', { height: '20px', width: '20px' })
                            ) : (
                                <div className="bot_avatar_wrapper">
                                    {svgLib.folder('#000000', { height: '20px', width: '20px' })}
                                </div>
                            )}
                            <span>{isOpen ? name : bot.name}</span>
                            <div className="GeneralTogle_header_arrow">
                                {svgLib.chevronDown('#000000', {
                                    height: '15px',
                                    width: '15px',
                                    transform: `rotate(${isOpen ? 0 : 180}deg)`,
                                })}
                            </div>
                        </div>
                    ) : (
                        <div className="GeneralTogle_header_content_own" onClick={() => setIsOpen(!isOpen)}>
                            {svgLibPropery
                                ? svgLib[svgLibPropery]('#000000', { width: '24px' })
                                : svgLib.folder('#000000', { height: '20px', width: '20px' })}
                            <span>{name}</span>

                            <div
                                style={{
                                    transform: `rotate(${isOpen ? 0 : 180}deg)`,
                                }}
                                className="GeneralTogle_header_arrow"
                            >
                                {svgLib.chevronDown('#000000', { height: '15px', width: '15px' })}
                            </div>
                            <div className="GeneralTogle_header_content_info">{isOpen && info && info}</div>
                        </div>
                    )}
                    {customHeader}
                </div>
            </div>
            <div
                className="GeneralTogle_content"
                style={{ height: `${isOpen ? 'auto' : '0px'}`, overflow: overflowStyle }}
            >
                <div className="GeneralTogle_content_height" ref={contentWrapper}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default GeneralTogleComponent;
