import React, { } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import './AppNavBarButtonComponent.css';

const AppNavBarButtonComponentMain = styled.div`
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;
        `;
const AppNavBarButtonImGWrapper = styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
        `;
const AppNavBarButtonNameWrapper = styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 12px;
        `;
const AppNavBarLogoImg = styled.img`
            width: 20px;
        `;
const AppNavBarName = styled.span`
            font-family: var(--main-font);
            font-size: 14px;
            color: #9e9e9e;
            font-weight: 600;
    `;
const AppNavBarButtonComponent = (props) => {
    const {
        linkTo, imgSrc, name, svg, className, onClick,
    } = props;

    return (
        <AppNavBarButtonComponentMain className={`${className}`} onClick={onClick}>
            {linkTo ? (
                <NavLink to={linkTo} activeClassName="navbatactivelink" className="navbatlink">
                    {imgSrc ? (
                        <AppNavBarButtonImGWrapper>
                            <AppNavBarLogoImg src={imgSrc} />

                        </AppNavBarButtonImGWrapper>
                    )
                        : null}

                    {svg ? (
                        <AppNavBarButtonImGWrapper>
                            {svg}
                        </AppNavBarButtonImGWrapper>
                    )
                        : null}

                    {name ? (
                        <AppNavBarButtonNameWrapper>
                            <AppNavBarName>
                                {name}
                            </AppNavBarName>
                        </AppNavBarButtonNameWrapper>
                    )
                        : null}

                </NavLink>
            )
                : (
                    <div>
                        {imgSrc ? (
                            <AppNavBarButtonImGWrapper>
                                <AppNavBarLogoImg src={imgSrc} />
                            </AppNavBarButtonImGWrapper>
                        )
                            : null}

                        {svg ? (
                            <AppNavBarButtonImGWrapper>
                                {svg}
                            </AppNavBarButtonImGWrapper>
                        )
                            : null}

                        {name ? (
                            <AppNavBarButtonNameWrapper>
                                <AppNavBarName>
                                    {name}
                                </AppNavBarName>
                            </AppNavBarButtonNameWrapper>
                        )
                            : null}
                    </div>
                )}
        </AppNavBarButtonComponentMain>
    );
};
AppNavBarButtonComponent.propTypes = {
    linkTo: PropTypes.string,
    imgSrc: PropTypes.string,
    name: PropTypes.string,
    svg: PropTypes.element,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

AppNavBarButtonComponent.defaultProps = {
    linkTo: '',
    imgSrc: '',
    name: '',
    svg: null,
    className: '',
    onClick: () => { },
};
export default AppNavBarButtonComponent;
