const billing = (options = {}) => {
    const { GATEWAY_URL, BILLING_SERVICE_URL, DEFAULT_API_ERROR } = options;

    /**
   * Create subscription
   * @param {String} botId - Id of an agent to subscribe
   * @param {Object} source - customers email, source id
   * @param {Object} settings - Settings to apply subscription by
   * @returns {Promise} result of the subscription
   */
    const createSubscription = async (botId, source, settings) => {
        const {
            interval, plan, seats, fp_uid, coupon,
        } = settings;
        if (!botId) throw new Error('Please specify agent id to subscribe');
        if (!source || !source.id || !source.email) throw new Error('Please specify agent id to subscribe', source);
        if (!interval || !plan || (seats !== 0 && !seats)) throw new Error('Please specify settings to subscribe', settings);

        // const url = new URL(`${BILLING_SERVICE_URL}/api/v1/subscriptions?botId=${botId}`);

        const body = {
            interval,
            plan,
            seats,
            source,
        };
        if (coupon && coupon.trim()) {
            body.coupon = coupon.trim();
        }
        if (fp_uid) {
            body.fp_uid = fp_uid;
        }
        const fetchOptions = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'POST',
            body: JSON.stringify(body),
        };


        try {
            const response = await fetch(`${BILLING_SERVICE_URL}/api/v1/subscriptions?botId=${botId}`, fetchOptions);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
   * Update subscription
   * @param {String} botId - Id of an agent to subscribe
   * @param {Object} source - customers email, source id. Optional
   * @param {Object} settings - Settings to apply subscription by
   * @returns {Promise} result of the subscription
   */
    const updateSubscription = async (botId, source, settings) => {
        const {
            interval, plan, seats,
        } = settings;

        if (!botId) throw new Error('Please specify agent id to subscribe');
        if (!interval || !plan || (seats !== 0 && !seats)) throw new Error('Please specify settings to subscribe');
        const url = new URL(`${BILLING_SERVICE_URL}/api/v1/subscriptions?botId=${botId}`);


        const fetchOptions = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'PUT',
            body: JSON.stringify({
                interval,
                plan,
                seats,
                source,
            }),
        };


        try {
            const response = await fetch(url, fetchOptions);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    const upgradeByCoupon = async (botId, couponId) => {
        if (!botId) throw new Error('Please specify agent id to subscribe');
        if (!couponId) throw new Error('Please specify coupon to subscribe');

        const url = new URL(`${BILLING_SERVICE_URL}/api/v1/coupons?botId=${botId}`);


        const fetchOptions = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ couponId }),
        };


        try {
            const response = await fetch(url, fetchOptions);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    /**
   * Update card
   * @param {String} botId - Id of an agent
   * @param {Object} source - customers email, source id. Optional
   * @returns {Promise} result of the subscription
   */
    const updateCard = async (botId, source) => {
        if (!botId) throw new Error('Please specify agent id to subscribe');
        if (!source) throw new Error('Please specify source to subscribe');
        const url = new URL(`${BILLING_SERVICE_URL}/api/v1/subscriptions/source?botId=${botId}`);


        const fetchOptions = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'PUT',
            body: JSON.stringify({
                source,
            }),
        };


        try {
            const response = await fetch(url, fetchOptions);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
   * Delete subscription
   * @param {String} botId - Id of an agent to subscribe
   * @returns {Promise} result of the subscription
   */
    const deleteSubscription = async (botId) => {
        if (!botId) throw new Error('Please specify agent id to subscribe');

        const url = new URL(`${BILLING_SERVICE_URL}/api/v1/subscriptions?botId=${botId}`);

        const fetchOptions = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'DELETE',
        };


        try {
            const response = await fetch(url, fetchOptions);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
   * Get subscription data
   * @param {String} botId - Id of an agent to subscribe
   * @param {Boolean} invocesList - add to response list of invoices. Optional
   * @returns {Promise} result of the subscription
   */
    const getSubscriptionData = async (botId, invocesList) => {
        if (!botId) throw new Error('Please specify agent id to subscribe');

        const url = new URL(`${BILLING_SERVICE_URL}/api/v1/subscriptions?botId=${botId}&invocesList=${invocesList}`);

        const fetchOptions = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'GET',
        };


        try {
            const response = await fetch(url, fetchOptions);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    return {
        createSubscription,
        updateSubscription,
        deleteSubscription,
        getSubscriptionData,
        updateCard,
        upgradeByCoupon,
    };
};

export default billing;
