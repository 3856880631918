import React, { useState } from 'react';
import { TableColumn } from './types';

type SortType = {
    by: string;
    direction: boolean;
};

type ValueSet<T> = { value: T; set: React.Dispatch<React.SetStateAction<T>> };

type UseTableEngineArgs<T = any> = {
    newColumns: TableColumn[];
    data: T[];
    showCheckbox: boolean;
    keyExtractor?: (item: T) => string;
};
export type TableEngineState<T> = {
    columns: TableColumn[];
    data: T[];
    showCheckbox: boolean;
    sort: ValueSet<SortType>;
    selectedRow: ValueSet<any>;
    selectedAll: ValueSet<boolean>;
    keyExtractor: UseTableEngineArgs<T>['keyExtractor'];
};

const useTableEngine = <T = any>(args: UseTableEngineArgs<T>): TableEngineState<T> => {
    const [sort, setSort] = useState({
        by: '',
        direction: true,
    });
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [selectedAll, setSelectedAll] = useState(false);

    return {
        columns: args.newColumns,
        data: args.data,
        showCheckbox: args.showCheckbox,
        sort: { value: sort, set: setSort },
        selectedRow: { value: selectedRow, set: setSelectedRow },
        selectedAll: { value: selectedAll, set: setSelectedAll },
        keyExtractor: args.keyExtractor,
    };
};
export default useTableEngine;
