import React, { useEffect, useState } from 'react';
import validator from 'validator';
import { useAppState } from '../../../../../engine/globalStateContext';
import { useApi } from '../../../../../functionLib/hooks';
import LoadingComponent from '../../../../UIComponents/LoadingComponent/LoadingComponent';
import { ResizeImageData } from '../../../../UIComponents/types/imageData';
import AboutMeEditor from './AboutMeEditor';
import AvatarEditor from './AvatarEditor';
import ChangePasswordContent from './ChangePasswordContent';
import EmailEditor from './EmailEditor';
import LogOut from './LogOut';
import NameEditor from './NameEditor';
import SaveOrChangePassword from './SaveOrChangePassword';
import UI from './styled';

export type InputValueState = {
    imageFile: ResizeImageData;
    picture: any;
    name: '';
    email: '';
    about: '';
    languageToggle: boolean;
};

export type DataState = {
    _id: string;
    picture: any;
    name: string;
    email: string;
    about: string;
    language: string;
};

const AccountEditorComponent: React.FC = () => {
    const api = useApi();
    const [{ popup }] = useAppState();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any>({});
    const [popupError, setPopUpError] = useState(false);
    const [passwordPopupError, setPasswordPopUpError] = useState(false);
    const [inputValue, setInputValue] = useState<InputValueState>({
        imageFile: {} as any,
        picture: {},
        name: '',
        email: '',
        about: '',
        languageToggle: false,
    });
    const [buttonsState, setButtonsState] = useState({
        locked: true,
        className: 'lockedButton',
        loading: false,
    });

    const sendResizedImageToGS = () =>
        api.uploadImageToGS(inputValue.imageFile.resized.file, '', '100x100', '', false, true);
    const sendOriginalImageToGS = () =>
        api.uploadImageToGS(inputValue.imageFile.original.file, '', '', '', false, true);

    const sendDataToApi = async () => {
        let action;
        let imageUrl;
        let resizedImageFromGS;

        if (!validator.isEmail(inputValue.email)) {
            setPopUpError(true);
            setButtonsState({ ...buttonsState, loading: false });
        } else {
            try {
                setButtonsState({ ...buttonsState, loading: true });

                if (inputValue.imageFile.resized.file && inputValue.imageFile.original.file) {
                    resizedImageFromGS = await sendResizedImageToGS();
                    await sendOriginalImageToGS();
                }

                resizedImageFromGS ? (imageUrl = resizedImageFromGS.url) : (imageUrl = inputValue.picture.picture);

                action = await api.customers.save({
                    _id: data._id,
                    'profile.picture': imageUrl,
                    'profile.name': inputValue.name,
                    'cred.email': inputValue.email,
                    about: inputValue.about,
                    user_language: inputValue.languageToggle ? 'Russian' : 'English',
                });

                if (action) {
                    setButtonsState({
                        ...buttonsState,
                        className: 'lockedButton',
                        locked: true,
                        loading: false,
                    });
                    window.notification.info('Data saved', 3);
                }
            } catch (err) {
                setButtonsState({ ...buttonsState, loading: false });
                window.notification.error(err.message);
            }
        }
    };

    const fetchUsers = async () => {
        let user;

        try {
            user = await api.customers.get();
            setData({
                _id: user._id,
                picture: user.profile.picture ? { picture: user.profile.picture } : inputValue.picture,
                name: user.profile.name,
                email: user.cred.email,
                about: user.about,
                language: user.user_language,
            });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            window.notification.error(err.message);
        }
    };

    useEffect(() => {
        setPopUpError(false);
        setPasswordPopUpError(false);
    }, [passwordPopupError, popupError]);

    useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (Object.keys(data).length) {
            setInputValue({
                imageFile: {
                    original: {
                        file: '' as any,
                        image: '',
                    },
                    resized: {
                        file: '' as any,
                        image: '',
                    },
                },
                picture: data.picture,
                name: data.name,
                email: data.email,
                about: data.about,
                languageToggle: data.language === 'English' ? false : true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Object.keys(data).length]);

    const checkNewData = () => {
        if (buttonsState.locked) {
            setButtonsState({ ...buttonsState, className: '', locked: false });
        }
    };

    if (loading) {
        return (
            <UI.Loading>
                <LoadingComponent />
            </UI.Loading>
        );
    }

    return (
        <UI.AccountEditor>
            <AvatarEditor
                setInputValue={setInputValue}
                inputValue={inputValue}
                checkNewData={checkNewData}
                picture={inputValue.picture}
                id={data._id}
            />

            <NameEditor
                onChange={(e) => {
                    setInputValue({ ...inputValue, name: e.target.value });
                    checkNewData();
                }}
                value={inputValue.name}
            />

            <EmailEditor
                setInputValue={setInputValue}
                inputValue={inputValue}
                checkNewData={checkNewData}
                popupError={popupError}
            />

            <AboutMeEditor
                onChange={(e) => {
                    setInputValue({ ...inputValue, about: e.target.value });
                    checkNewData();
                }}
                value={inputValue.about}
            />

            <SaveOrChangePassword
                onClick={() => popup?.show({ content: <ChangePasswordContent /> })}
                onClickSave={sendDataToApi}
                className={buttonsState.className}
                locked={buttonsState.locked}
                loading={buttonsState.loading}
            />

            <LogOut />
        </UI.AccountEditor>
    );
};

export default AccountEditorComponent;
