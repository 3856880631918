import { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { useApi } from '../../../../../functionLib/hooks';
import { useNotificationsActions } from '../../../../../hooks/notifications/actions';
import { botActions } from '../../../../../redux/actions';
import { useAppDispatch } from '../../../../../redux/hooks';

export const useSetSelectedBotId = () => {
    const dispatch = useAppDispatch();
    const api = useApi();
    const match = useRouteMatch<{ agentID: string }>();
    const n11s = useNotificationsActions();

    useEffect(() => {
        dispatch(botActions.setBotId(match.params.agentID));
        api.agents
            .getById(match.params.agentID)
            .then((botAgent) => dispatch(botActions.setBotAgent(botAgent)))
            .catch((error) => {
                console.error('Error while taking agent', error.message);
                n11s.error(`Error while taking agent: ${error.message}`, 5);
            });
    }, [match.params.agentID]);
};
