import { ApiConfig } from '../config';
import {
    GetAgentIdByRoleIdResult,
    GetAgentsListResult,
    GetListOfTagsResult,
    SendMessageContent,
} from './livechat.types';

const livechat = (options: ApiConfig) => {
    const { ARCHITECT_URL, DEFAULT_API_ERROR, LIVECHAT_SERVICE_URL, USER_SERVICE_URL } = options;

    const getAgentsList = async (botId: string): Promise<GetAgentsListResult> => {
        if (!botId) throw new Error('Please specify agent id');
        const url = new URL(`${LIVECHAT_SERVICE_URL}/api/v1/agents?botId=${botId}&attach=customerMeta`);
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            // credentials: 'include',
            method: 'GET',
        };
        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (statusCode === 404) return { agents: [] };

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body || {};
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const updateAgent = async (botId: string, agentId: string, newAgentData?: any) => {
        if (!botId) throw new Error('Please specify bot id');
        if (!agentId) throw new Error('Please specify agent id');
        const url = new URL(`${LIVECHAT_SERVICE_URL}/api/v1/agents/${agentId}/?botId=${botId}`);
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'PUT',
            body: JSON.stringify(newAgentData),
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body || {};
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const getAgentIdByRoleId = async (roleId: string): Promise<GetAgentIdByRoleIdResult> => {
        if (!roleId) throw new Error('Please specify role id');
        const url = new URL(`${LIVECHAT_SERVICE_URL}/api/v1/agents/role/${roleId}`);
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body || {};
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const getAgentById = async (agentId: string) => {
        if (!agentId) throw new Error('Please specify id');
        const url = new URL(`${LIVECHAT_SERVICE_URL}/api/v1/agents/${agentId}?attach=customerMeta`);
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body || {};
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    const openSession = async (botId: string, agentId: string, userId?: string) => {
        if (!botId) throw new Error('Please specify bot id');
        if (!agentId) throw new Error('Please specify agent id');
        const url = new URL(`${LIVECHAT_SERVICE_URL}/api/v1/agents/${agentId}/livechat/open?botId=${botId}`);
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ userId }),
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();
            // console.log(response, body);

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body || {};
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const closeSession = async (botId: string, agentId: string, userId: string, skillName?: string) => {
        if (!botId) throw new Error('Please specify bot id');
        if (!agentId) throw new Error('Please specify agent id');
        if (!userId) throw new Error('Please specify userId');
        // if (!event) throw new Error('Please specify event');

        const url = new URL(`${LIVECHAT_SERVICE_URL}/api/v1/agents/${agentId}/livechat/close?botId=${botId}`);
        console.log('closeSession');
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ userId, event: skillName }),
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;

            if (statusCode === 200) return { status: 200 };
            throw { statusCode, message: DEFAULT_API_ERROR };
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const sendMessage = async (botId: string, agentId: string, userId: string, content: SendMessageContent) => {
        if (!botId) throw new Error('Please specify bot id');
        if (!agentId) throw new Error('Please specify agent id');
        if (!userId) throw new Error('Please specify userId');
        if (!content) throw new Error('Please specify content');

        const url = new URL(`${LIVECHAT_SERVICE_URL}/api/v1/agents/${agentId}/livechat/send?botId=${botId}`);
        console.log('sendMessage');
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ userId, content }),
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;

            if (statusCode === 200) return { status: 200 };
            throw { statusCode, message: DEFAULT_API_ERROR };
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const sessionsDetails = async (botId: string, agentIds: string[], start?: number | null) => {
        if (!botId) throw new Error('Please specify bot id');
        if (!agentIds) throw new Error('Please specify agents ids');

        const url = new URL(
            `${USER_SERVICE_URL}/api/v1/sessions/agent/details?ids=${agentIds.join(',')}&botId=${botId}${
                start ? `&start=${start}` : ''
            }`
        );
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();
            if (statusCode === 404) return { status: 404 };

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body || {};
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const getAiHints = async (botId: string, userId: string) => {
        if (!botId) throw new Error('Please specify the id of the agent');
        if (!userId) throw new Error('Please specify the id of the user');

        const url = new URL(`${ARCHITECT_URL}/v1/openai/completions?botId=${botId}&userId=${userId}`);

        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };
            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };
            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    const getMetaDialogAiHints = async (botId: string, userId: string) => {
        if (!botId) throw new Error('Please specify the id of the agent');
        if (!userId) throw new Error('Please specify the id of the user');

        const url = new URL(`${USER_SERVICE_URL}/api/v1/fine_tuning/query`);

        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ botId, userId }),
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };
            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };
            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    const getListOfTags = async (botId: string): Promise<GetListOfTagsResult> => {
        const url = new URL(`${LIVECHAT_SERVICE_URL}/api/v1/agents/tags?botId=${botId}`);

        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };
            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };
            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const transferQueuedSession = async (botId: string, value: string, userId: string, tag = false) => {
        const url = tag
            ? new URL(`${LIVECHAT_SERVICE_URL}/api/v1/agents/tags:${value}/livechat/transfer-queued?botId=${botId}`)
            : new URL(`${LIVECHAT_SERVICE_URL}/api/v1/agents/${value}/livechat/transfer-queued?botId=${botId}`);
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            // credentials: 'include',
            body: JSON.stringify({ userId }),
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;

            if (statusCode === 200) return { status: 200 };
            throw { statusCode, message: DEFAULT_API_ERROR };
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    return {
        getAgentsList,
        updateAgent,
        getAgentIdByRoleId,
        openSession,
        closeSession,
        sendMessage,
        getAgentById,
        sessionsDetails,
        getAiHints,
        getListOfTags,
        transferQueuedSession,
        getMetaDialogAiHints,
    };
};

export default livechat;
