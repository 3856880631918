/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import "./ResetPswdPage.css";
import { InformPopup } from "../../UIComponents/InputComponent/InformPopup";
import ButtonComponent from "../../UIComponents/ButtonComponent/ButtonComponent";
import ShowPassword from "./images/ShowPassword1.svg";
import { useApi } from '../../../functionLib/hooks';


const ResetPswdPage = () => {
  const api = useApi();
  const [customerID, setCustomerID] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [confirmStyle, setConfirmStyle] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [validation, setValidation] = useState({
    lowercase: false,
    uppercase: false,
    numeric: false,
    special: false,
    passwordLength: false
  });
  const [message, setMessage] = useState({
    data: null,
    name: ""
  });
  const [userData, setUserData] = useState({
    password,
    confirmPassword
  });
  const lowercase = /(?=.*[a-z])/;
  const uppercase = /(?=.*[A-Z])/;
  const numeric = /(?=.*[0-9])/;
  const special = /(?=.*[!@#\$%\^&\*])/;
  const passwordLength = /(?=.{8,})/;

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");

    setCustomerID(pathArray[pathArray.length - 1]);
  }, []);

  useEffect(() => {
    passwordValidation();
  }, [password]);

  useEffect(() => {
    setUserData({
      password,
      confirmPassword
    });
  }, [password, confirmPassword]);
  const passwordValidation = () => {
    let obj = {
      lowercase: false,
      uppercase: false,
      numeric: false,
      special: false,
      symbolsLength: false
    };

    uppercase.test(password)
      ? (obj = { ...obj, uppercase: true })
      : (obj = { ...obj, uppercase: false });
    lowercase.test(password)
      ? (obj = { ...obj, lowercase: true })
      : (obj = { ...obj, lowercase: false });
    numeric.test(password)
      ? (obj = { ...obj, numeric: true })
      : (obj = { ...obj, numeric: false });
    special.test(password)
      ? (obj = { ...obj, special: true })
      : (obj = { ...obj, special: false });
    special.test(password)
      ? (obj = { ...obj, special: true })
      : (obj = { ...obj, special: false });
    passwordLength.test(password)
      ? (obj = { ...obj, passwordLength: true })
      : (obj = { ...obj, passwordLength: false });

    setValidation({ ...obj });

    Object.keys(obj).map(element =>
      !element ? setPasswordValid(false) : setPasswordValid(true)
    );
  };
  let onSetShow = value => {
    setMessage({
      ...message,
      name: ""
    });
  };
  let createMessage = (message, name, color) => {
    setMessage({
      data: message,
      name: name
    });

    color && setPopupColor(color);
  };
  const ResetPassword = async (e, dataAccount) => {
    console.log("ResetPassword");
    e.preventDefault();

    if (!String(dataAccount.password).trim().length)
      return createMessage("Please fill out this field", "pass");

    if (dataAccount.password !== dataAccount.confirmPassword) {
      console.log(
        "password does not match",
        dataAccount.password,
        dataAccount.confirmPassword,
        dataAccount.password === dataAccount.confirmPassword
      );
      createMessage("Passwords do not match", "confirmPass");
      setConfirmStyle({ borderColor: "#e68f8f" });
      return;
    }

    setConfirmStyle({});

    const result = await api.authorize.setNewPassword(customerID, password);
    if (result.status === "success") {
      window.notification.info(`Password changed successfully!`, 2);
      setTimeout(() => {
        setRedirect(true);
      }, 2000);
    } else {
      window.notification.error(result.message, 5);
    }
  };
  return redirect ? (
    <Redirect to='/login' />
  ) : (
    <div className='reset_password_page'>
      <div className='reset_password_page_title'>
        <span>Reset password</span>
      </div>
      {
        <InformPopup
          className={"signup_quiz_v2"}
          offZIndex={true}
          customTop={[50, 13]}
          view={
            <div className='CreateNewAccount_Password'>
              <div className='CreateNewAccount_CreatePassword'>
                <div className='CreateNewAccount_CreatePasswordLabel'>
                  Create Password
                </div>
                <input
                  style={confirmStyle}
                  type={showPassword ? "text" : "password"}
                  onChange={e => {
                    setPassword(e.target.value);
                  }}
                  placeholder='password'
                />
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className='CreateNewAccount_ShowPassword'
                >
                  <img src={ShowPassword} />
                </div>
              </div>
            </div>
          }
          show={message.name === "pass"}
          setShow={onSetShow}
          description={
            <div className='informPOpup_errorMessage'>{message.data}</div>
          }
        />
      }
      {/* <div className='CreateNewAccount_Password'>
          <div className='CreateNewAccount_CreatePassword'>
            <div className='CreateNewAccount_CreatePasswordLabel'>
              Create Password
            </div>
            <input
              type={showPassword ? "text" : "password"}
              onChange={e => {
                setPassword(e.target.value);
              }}
              placeholder='password'
            />
            <div
              onClick={() => setShowPassword(!showPassword)}
              className='CreateNewAccount_ShowPassword'
            >
              <img src={ShowPassword} />
            </div>
          </div>
        </div> */}
      <div className='CreateNewAccount_PasswordValidation'>
        <div className='Validation_Container'>
          <div
            className={
              validation.lowercase
                ? "Validation_Checkbox_Confirmed"
                : "Validation_Checkbox"
            }
          ></div>
          One lowercase character
        </div>

        <div className='Validation_Container'>
          <div
            className={
              validation.uppercase
                ? "Validation_Checkbox_Confirmed"
                : "Validation_Checkbox"
            }
          ></div>
          One uppercase character
        </div>

        <div className='Validation_Container'>
          <div
            className={
              validation.numeric
                ? "Validation_Checkbox_Confirmed"
                : "Validation_Checkbox"
            }
          ></div>
          One number
        </div>

        <div className='Validation_Container'>
          <div
            className={
              validation.special
                ? "Validation_Checkbox_Confirmed"
                : "Validation_Checkbox"
            }
          ></div>
          One special character
        </div>

        <div className='Validation_Container'>
          <div
            className={
              validation.passwordLength
                ? "Validation_Checkbox_Confirmed"
                : "Validation_Checkbox"
            }
          ></div>
          8 characters minimum
        </div>
      </div>
      {
        <InformPopup
          className={"signup_quiz_v2"}
          offZIndex={true}
          customTop={[50, 13]}
          view={
            <div className='CreateNewAccount_ConfirmPassword'>
              <div className='CreateNewAccount_ConfirmPasswordLabel'>
                Confirm password
              </div>
              <input
                style={confirmStyle}
                onChange={e => setConfirmPassword(e.target.value)}
                type='password'
                placeholder='confirm password'
              />
            </div>
          }
          show={message.name === "confirmPass"}
          setShow={onSetShow}
          description={
            <div className='informPOpup_errorMessage'>{message.data}</div>
          }
        />
      }
      {/* <div className='CreateNewAccount_ConfirmPassword'>
          <div className='CreateNewAccount_ConfirmPasswordLabel'>
            Confirm password
          </div>
          <input
            onChange={e => setConfirmPassword(e.target.value)}
            type='password'
            placeholder='confirm password'
          />
        </div> */}
      <ButtonComponent acuikit onClick={e => ResetPassword(e, userData)}>
        Reset password
      </ButtonComponent>
    </div>
  );
};

export default ResetPswdPage;
