import React, { ChangeEventHandler, FocusEventHandler } from 'react';
import './InputComponent.css';

type InputComponentProps = {
    placeholder?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    value?: string;
    name?: string;
    className?: string;
    readonly?: boolean;
    borderColor?: string;
    type?: string;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    pattern?: string;
    maxLength?: number;
};
export const InputComponent: React.FC<InputComponentProps> = ({
    placeholder,
    onChange,
    value,
    name,
    className,
    readonly = false,
    borderColor = 'rgba(0, 0, 0, 0.1)',
    type,
    onBlur,
    pattern,
    maxLength,
}) => (
    <div className="InputComponent_main ">
        <input
            pattern={pattern}
            className={`InputComponent_mainInput ${className || ''}`}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            name={name}
            readOnly={readonly}
            style={{ borderColor }}
            type={type}
            onBlur={onBlur}
            maxLength={maxLength}
        />
    </div>
);

export default InputComponent;
