/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React from 'react';

import UI from './styled';
import LockImage from '../../images/LockImage.svg';
import ButtonComponent from '../../../../../../UIComponents/ButtonComponent/ButtonComponent';
import { useAppState } from '../../../../../../../engine/globalStateContext';

const PasswordChanged = () => {
  const [{ popup }] = useAppState();

  return (
    <UI.PasswordChanged>
      <UI.PasswordChangedImage src={LockImage} />

      <UI.PasswordChangedNotification>
        Your password succesfully changed!
      </UI.PasswordChangedNotification>

      <ButtonComponent onClick={() => { popup.close(); window.notification.info('Success', 2); }} acuikit>Got it</ButtonComponent>
    </UI.PasswordChanged>
  );
};

export default PasswordChanged;
