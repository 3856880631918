import { ApiConfig } from '../config';
import { CreateFolderResult, CreateIntentResult, GetFoldersResult, GetIntentsResult } from './intents.types';

const intents = (options: ApiConfig) => {
    const { DEFAULT_API_ERROR, ARCHITECT_URL } = options;

    const createFolder = async (botId: string, parentId: string, name: string): Promise<CreateFolderResult> => {
        if (!botId || !name) throw new Error('Incorrect params');

        const url = new URL(`${ARCHITECT_URL}/v1/intents-manager/folders?botId=${botId}`);

        const fetchOptions: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                name,
                parentId,
            }),
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();
            console.log(response, statusCode, body);
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const getFolders = async (botId: string): Promise<GetFoldersResult> => {
        if (!botId) throw new Error('Incorrect params');

        const url = new URL(`${ARCHITECT_URL}/v1/intents-manager/folders?botId=${botId}`);

        const fetchOptions: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();
            if (statusCode === 404) return { folders: [] };

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const updateFolder = async (botId: string, folderId: string, parentId: string, name: string | null) => {
        console.log(botId, folderId, parentId, name);
        if (!botId || !folderId) throw new Error('Incorrect params');

        const url = new URL(`${ARCHITECT_URL}/v1/intents-manager/folders/${folderId}?botId=${botId}`);
        const bodyPh = { parentId };
        if (name) {
            (bodyPh as any).name = name;
        }
        if (parentId) {
            bodyPh.parentId = parentId;
        }
        console.log('bodyPh', bodyPh);
        const fetchOptions: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'PUT',
            body: JSON.stringify(bodyPh),
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const deleteFolder = async (botId, folderId) => {
        if (!botId || !folderId) throw new Error('Incorrect params');

        const url = new URL(`${ARCHITECT_URL}/v1/intents-manager/folders/${folderId}?botId=${botId}`);

        const fetchOptions: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'DELETE',
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const createIntent = async (botId: string, parentId: string, name: string): Promise<CreateIntentResult> => {
        if (!botId || !parentId || !name) throw new Error('Incorrect params');

        const url = new URL(`${ARCHITECT_URL}/v1/intents-manager/intents?botId=${botId}`);

        const fetchOptions: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ name, parentId }),
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const getIntents = async (botId: string): Promise<GetIntentsResult> => {
        if (!botId) throw new Error('Incorrect params');

        const url = new URL(`${ARCHITECT_URL}/v1/intents-manager/intents?botId=${botId}`);

        const fetchOptions: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();
            if (statusCode === 404) return { intents: [] };

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const updateIntent = async (botId, intentId, parentId, name, action) => {
        if (!botId || !intentId || !parentId) throw new Error('Incorrect params');

        const url = new URL(`${ARCHITECT_URL}/v1/intents-manager/intents/${intentId}?botId=${botId}`);
        const bodyPh = { parentId };
        name && ((bodyPh as any).name = name);
        action && ((bodyPh as any).action = action);
        const fetchOptions: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'PUT',
            body: JSON.stringify(bodyPh),
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();
            console.log(response);
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const deleteIntent = async (botId, intentId) => {
        if (!botId || !intentId) throw new Error('Incorrect params');

        const url = new URL(`${ARCHITECT_URL}/v1/intents-manager/intents/${intentId}?botId=${botId}`);

        const fetchOptions: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'DELETE',
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const addUtterances = async (botId, intentId, textList) => {
        if (!botId || !intentId || !textList) throw new Error('Incorrect params');

        const url = new URL(`${ARCHITECT_URL}/v1/intents-manager/intents/${intentId}/utterances?botId=${botId}`);

        const fetchOptions: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'POST',
            body: JSON.stringify(textList),
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    const getUtterances = async (botId, intentId) => {
        if (!botId) throw new Error('Incorrect params');

        const url = new URL(`${ARCHITECT_URL}/v1/intents-manager/intents/${intentId}/utterances?botId=${botId}`);

        const fetchOptions: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();
            if (statusCode === 404) return { utterances: [] };

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const updateUtterances = async (botId, intentId, utteranceId, text) => {
        if (!botId || !intentId || !utteranceId || !text) throw new Error('Incorrect params');

        const url = new URL(
            `${ARCHITECT_URL}/v1/intents-manager/intents/${intentId}/utterances/${utteranceId}?botId=${botId}`
        );

        const fetchOptions: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'PUT',
            body: JSON.stringify({
                text,
            }),
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const deleteUtterances = async (botId, intentId, utteranceId) => {
        if (!botId || !intentId) throw new Error('Incorrect params');

        const url = new URL(
            `${ARCHITECT_URL}/v1/intents-manager/intents/${intentId}/utterances/${utteranceId}?botId=${botId}`
        );

        const fetchOptions: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'DELETE',
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    const getTrainingStatus = async (botId) => {
        if (!botId) throw new Error('Incorrect params');

        const url = new URL(`${ARCHITECT_URL}/v1/intents-manager/training?botId=${botId}`);

        const fetchOptions: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            // credentials: 'include',
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    return {
        createFolder,
        getFolders,
        updateFolder,
        deleteFolder,
        createIntent,
        getIntents,
        updateIntent,
        deleteIntent,
        addUtterances,
        getUtterances,
        updateUtterances,
        deleteUtterances,
        getTrainingStatus,
    };
};

export default intents;
