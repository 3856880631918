import type React from 'react';
import { dummpyPopUp, dummpySidebar, PopUp, Sidebar } from './ui.types';

export type UIState = {
    sidebar: Sidebar;
    popup: PopUp;
};

export const initialUIState: UIState = {
    popup: dummpyPopUp,
    sidebar: dummpySidebar,
};

export type UIAction =
    | { type: 'setSidebar'; value: { sidebar: Sidebar } }
    | { type: 'setPopUp'; value: { popup: PopUp } };

export const uiReducer: React.Reducer<UIState, UIAction> = (state, action) => {
    switch (action.type) {
        case 'setSidebar':
            return { ...state, sidebar: action.value.sidebar };
        case 'setPopUp':
            return { ...state, popup: action.value.popup };
        default:
            return state;
    }
};

export function setSidebar(sidebar: Sidebar): UIAction {
    return { type: 'setSidebar', value: { sidebar } };
}
export function setPopup(popup: any): UIAction {
    return { type: 'setPopUp', value: { popup } };
}
