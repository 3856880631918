import React, { ChangeEventHandler, useState } from 'react';
import { useAppState } from '../../../../../../engine/globalStateContext';
import { useApi } from '../../../../../../functionLib/hooks';
import CropImage from '../images/CroppImage.svg';
import RemoveImage from '../images/RemoveImage.svg';
import UploadImage from '../images/UploadImage.svg';
import UI from './styled';

export type AvatarEditorProps = {
    id: string;
    picture: any;
    inputValue: any;
    checkNewData: () => void;
    setInputValue: (value: any) => void;
};

// AvatarEditor.defaultProps = {
//     id: '',
//     picture: {},
//     inputValue: {},
//     checkNewData: () => {},
//     setInputValue: () => {},
// };

const AvatarEditor: React.FC<AvatarEditorProps> = ({ picture, setInputValue, inputValue, checkNewData, id }) => {
    const api = useApi();
    const [{ popup }] = useAppState();
    const [image, setImage] = useState({
        image: picture.picture,
    });

    const onLoadImg: ChangeEventHandler<HTMLInputElement> = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files?.[0];

        if (!file) {
            return;
        }
        reader.readAsDataURL(file);

        reader.onloadend = () => {
            popup.crop(reader.result as string, (data) => {
                setImage(data.resized);
                setInputValue({ ...inputValue, imageFile: data });
                checkNewData();
            });
        };
    };

    const CroppImg = () => {
        popup.crop(image.image, (data) => {
            setImage(data.resized);
            setInputValue({ ...inputValue, imageFile: data });
            checkNewData();
        });
    };

    const removeImage = async () => {
        try {
            setImage({ image: '' });
            await api.customers.save({
                _id: id,
                'profile.picture': '',
            });
        } catch (err) {
            console.error(err);
        }
    };

    if (image.image) {
        return (
            <UI.AvatarEditor>
                <UI.AvatarEditorInputBox>
                    <UI.AvatarName>Avatar</UI.AvatarName>

                    <UI.AvatarEditorLabel htmlFor="image-upload">
                        <UI.Box id="image-upload" border={image.image ? '0px' : '1px'}>
                            <UI.UploadedImage
                                width={!image.image ? '20px' : ''}
                                src={image.image ? image.image : UploadImage}
                            />
                            <UI.AvatarImageInput onChange={onLoadImg} type="file" id="image-upload" />
                        </UI.Box>
                    </UI.AvatarEditorLabel>
                </UI.AvatarEditorInputBox>

                <UI.CropOrRemoveContainer>
                    <UI.CropImageContainer onClick={CroppImg} htmlFor="crop-image">
                        <UI.CropImage id="crop-image" src={CropImage} />

                        <UI.AvatarEditorText>Crop</UI.AvatarEditorText>
                    </UI.CropImageContainer>

                    <UI.RemoveImageContainer onClick={removeImage}>
                        <UI.CropImage src={RemoveImage} />

                        <UI.AvatarEditorText>Remove</UI.AvatarEditorText>
                    </UI.RemoveImageContainer>
                </UI.CropOrRemoveContainer>
            </UI.AvatarEditor>
        );
    }
    return (
        <UI.AvatarEditor>
            <UI.AvatarEditorInputBox>
                <UI.AvatarName>Avatar</UI.AvatarName>

                <UI.AvatarEditorLabel htmlFor="image-upload">
                    <UI.Box border={image.image ? '0px' : '1px'}>
                        <UI.UploadedImage
                            width={!image.image ? '20px' : ''}
                            src={image.image ? image.image : UploadImage}
                        />
                        <UI.AvatarImageInput onChange={onLoadImg} type="file" id="image-upload" />
                    </UI.Box>

                    <UI.AvatarEditorText>Click to upload image</UI.AvatarEditorText>
                </UI.AvatarEditorLabel>
            </UI.AvatarEditorInputBox>
        </UI.AvatarEditor>
    );
};

export default AvatarEditor;
