import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import svgLib from '../../../functionLib/svglib';
import TableComponent from '../../UIComponents/TableComponet/TableComponent';
import UI from './styled';
import './svgLib.css';

const columns = [
    {
        Header: 'Name',
        field: 'name',
        className: 'centerClass',
    },
    {
        Header: 'Function',
        field: 'function',
        className: 'centerClass',

        // CustomField: () => <span>Hello</span>,
    },
    {
        Header: 'SVG',
        field: 'svg',
        fieldClassName: 'svgClass',
        className: 'centerClass',
        // CustomField: () => <span>Hello</span>,
    },
];

const SvgLibrary = () => {
    const [value, setValue] = useState('');
    const [updatedData, setUpdatedData] = useState([]);
    const arraySvg = Object.keys(svgLib).map((item) => ({
        name: item,
        function: `svgLib.${item}('#c5a48e', { height: '20px', width: '20px' }) =>`,
        svg: svgLib[item]('#c5a48e', { height: '20px', width: '20px' }),
    }));

    const data = [...arraySvg];

    useEffect(() => {
        setUpdatedData(data);
    }, []);

    useEffect(() => {
        if (!value.length) setUpdatedData(data);
    }, [value]);

    const filterByName = (e) => {
        setValue(e.target.value);
        setUpdatedData(data.filter((element) => element.name.includes(value)));
    };

    return (
        <UI.SVGLIB>
            <UI.NavBar>
                <UI.Input value={value} placeholder="Search..." type="text" onChange={filterByName} />

                <Link style={{ textDecoration: 'none' }} to="/uikit">
                    <span style={{ color: 'white', textDecoration: 'none' }}> go to ui </span>
                </Link>
            </UI.NavBar>
            <TableComponent data={updatedData} columns={columns} />
        </UI.SVGLIB>
    );
};

export default SvgLibrary;
