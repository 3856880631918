/* eslint-disable indent */
import styled, { keyframes } from 'styled-components';

const LogOutContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 26px 0px 0px 0px;
`;

const LogOutButton = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 40px;
  min-width: 120px;
  justify-content: center;
  background: #ff6f61;
  border-radius: 4px;
  color: #ffffff;
  font-family: var(--main-font);
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  :hover {
    background: #ff5747;
  }
`;

export default {
  LogOutContainer,
  LogOutButton,
};
