import React from 'react';
import PropTypes from 'prop-types';
import SomeWrongComponent from '../../SomeWrongComponent/SomeWrongComponent';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Оновлюємо стан, щоб наступний рендер показав запасний UI.
        return { hasError: true };
    }

    componentDidCatch(error) {
        // Ви також можете передати помилку в службу звітування про помилки
        console.error('ErrorBoundary', error, window.location.href);
        console.log(error);
        console.log(window.location.href);
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        if (hasError) {
            // Ви можете відрендерити будь-який власний запасний UI
            return <SomeWrongComponent />;
        }

        return children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.element.isRequired,
};


export default ErrorBoundary;
