import PropTypes from 'prop-types';
import React from 'react';
import { InformPopup } from './InformPopup';
import { InputComponent } from './InputComponent';

export const InputPopupComponent = ({
    placeholder,
    onChange,
    value,
    name,
    className,
    notification,
    readonly,
    setShow,
    type,
}) => (
    <InformPopup
        view={
            <InputComponent
                onChange={onChange}
                placeholder={placeholder}
                value={value}
                name={name}
                readOnly={readonly}
                borderColor={notification.borderColor || 'rgba(0, 0, 0, 0.1)'}
                type={type}
            />
        }
        className={className}
        offZIndex
        description={<div className="InputPopupComponent_description">{notification.data}</div>}
        show={name === notification.name}
        setShow={setShow}
    />
);

InputPopupComponent.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    readonly: PropTypes.bool,
    notification: PropTypes.objectOf(PropTypes.any),
    setShow: PropTypes.func,
    type: PropTypes.string,
};

InputPopupComponent.defaultProps = {
    placeholder: '',
    onChange: () => {},
    value: '',
    name: '',
    className: '',
    readonly: false,
    notification: {},
    type: '',
    setShow: () => {},
};
