import React, { } from 'react';
import styled from 'styled-components';

const MarketComponentMain = styled.div`
        width: 100%;
        height: 100%;
        display: flex;
    `;
const MarketComponent = () => (
    <MarketComponentMain>
        MarketComponentMain
    </MarketComponentMain>
);

export default MarketComponent;
