import React, { useState } from 'react';
import Waiting from '../../../UIComponents/WaitingComponent/Waiting';
import { Body } from './Body';
import { Header } from './Header';
import './NewBotComponent_v2.css';

const NewBotComponent: React.FC = () => {
    const [step, setStep] = useState(0);
    const [copyBot, setCopyBot] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newBotId, setNewBotId] = useState<string>();

    return (
        <div className="new_bot_component">
            <Header currentStep={step} steps={[0, 1, 2]} />
            {loading ? (
                <Waiting />
            ) : (
                <Body
                    currentStep={step}
                    copyBot={copyBot}
                    setCopyBot={setCopyBot}
                    setStep={setStep}
                    setLoading={setLoading}
                    newBotId={newBotId}
                    setNewBotId={setNewBotId}
                />
            )}
        </div>
    );
};

export default NewBotComponent;
