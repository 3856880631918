/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React from 'react';
import PropTypes from 'prop-types';


import TitledInput from '../../../../../UIComponents/TitledInputTextComponent/TitledInputTextComponent'
import './styled.css'
import UI from './styled';

const NameEditor = ({ onChange, value }) => (
  <UI.NameEditor>
    {/* <UI.InputLabel htmlFor="name-input">
      Name
    </UI.InputLabel> */}
    
    {/* <UI.NameInput
      placeholder="Your name"
      onChange={onChange}
      value={value}
      name="name-input"
      type="text"
      maxLength="40"
    /> */}
    <TitledInput
      title='Name'
      placeholder="Your name"
      className='account-name-input'
      onChange={onChange}
      value={value}
      name="name-input"
      type="text"
    />
  </UI.NameEditor>
);

NameEditor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

NameEditor.defaultProps = {
  onChange: () => {},
  value: '',
};

export default NameEditor;
