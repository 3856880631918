import styled from 'styled-components';

const PasswordChanged = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justifyContent: center;
`;

const PasswordChangedImage = styled.img``;

const PasswordChangedNotification = styled.div`
  display: flex;
  align-items: center;
  font-family: var(--main-font)
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  margin: 13px 10px 20px 10px;
  text-align: center;
  width: 210px;
  height: 36px;
`;

const GotItButton = styled.div`
  display: flex;
  align-items: center;
`;

export default {
  GotItButton,
  PasswordChanged,
  PasswordChangedImage,
  PasswordChangedNotification,
};