/* eslint-disable indent */
import styled from 'styled-components';

const SaveOrChangePassword = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 26px 0px 0px 0px;
`;

const ChangePasswordButton = styled.div`
  display: flex;
  align-items: center
  font-size: 12px;
  font-family: var(--main-font);
  color: rgba(0, 0, 0, 0.38);
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 0px 0px 0px 5px;
`;

export default {
  SaveOrChangePassword,
  ChangePasswordButton,
};
