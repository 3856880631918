import { ApiConfig, useConfig } from './config';
import additional from './models/additional';
import agents from './models/agents';
import authorize from './models/authorize';
import billing from './models/billing';
import broadcasts from './models/broadcasts';
import facebook from './models/channels/facebook';
import twillio from './models/channels/twillio';
import whatsapp from './models/channels/whatsapp';
import customers from './models/customers';
import qrCodes from './models/growTools/qrcodes';
import insights from './models/insights';
import integrations from './models/integrations';
import chatbase from './models/integrations/chatbase';
import dialogFlow from './models/integrations/dialogFlow';
import googleCalendar from './models/integrations/googleCalendar';
import googleSheets from './models/integrations/googleSheets';
import openAi from './models/integrations/openAi';
import shopify from './models/integrations/shopify';
import woocommerce from './models/integrations/woocommerce';
import intents from './models/intents';
import livechat from './models/livechat';
import nlp from './models/nlp';
import roles from './models/roles';
import storeData from './models/storeData';
import templates from './models/templates';
import users from './models/users';

const connect = (configOverride: Partial<ApiConfig> = {}) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const config = useConfig(configOverride);

    return {
        agents: agents(config),
        livechat: livechat(config),
        users: users(config),
        authorize: authorize(config),
        templates: templates(config),
        customers: customers(config),
        billing: billing(config),
        broadcasts: broadcasts(config),
        storeData: storeData(config),
        nlp: nlp(config),
        growTools: {
            qrcodes: qrCodes(config),
        },
        channels: {
            twillio: twillio(config),
            facebook: facebook(config),
            whatsapp: whatsapp(config),
        },
        integrations: {
            woocommerce: woocommerce(config),
            shopify: shopify(config),
            googleSheets: googleSheets(config),
            googleCalendar: googleCalendar(config),
            dialogFlow: dialogFlow(config),
            chatbase: chatbase(config),
            openAi: openAi(config),
            ...integrations(config),
        },
        roles: roles(config),
        intents: intents(config),
        insights: insights(config),
        ...additional(config),
    };
};

export type Api = ReturnType<typeof connect>;

export default connect;
