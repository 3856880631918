import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useApi } from '../../../../functionLib/hooks';
import svgLib from '../../../../functionLib/svglib';
import { useNotificationsActions } from '../../../../hooks/notifications/actions';
import ButtonComponent from '../../../UIComponents/ButtonComponent/ButtonComponent';

type PopupFolderProps = {
    name: any;
    bots: any;
    match: any;
    location: any;
    botSearch: any;
    close: any;
    filter: any;
};
export const PopupFolderComponent: React.FC<PopupFolderProps> = ({ name, match, location, close, filter }) => {
    const api = useApi();
    const [botsArra, setBotsArray] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const secondLink = location.split(match.url)[1];
    const firsLink = match.path.substr(0, match.path.indexOf(':agentID'));
    const contentWrapper = React.createRef<any>();
    const n11s = useNotificationsActions();
    useEffect(() => {
        setLoading(true);
        api.agents
            .get({ title: true, image: true, _id: true, limit: 100 }, { title: true, image: true, _id: true })
            .then((agents) => {
                setBotsArray((agents as any).agents);
                /**
                 * TODO:  Here should be called reducer to set botsList = agents
                 */
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error while taking bots list for the customer: ', error.message);
                n11s.error(`Error while save advanced settings: ${error.message}`, 5);
            });
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState('0px');
    useEffect(() => {
        if (contentWrapper.current.getBoundingClientRect().height !== 0) {
            setHeight(`${contentWrapper.current.getBoundingClientRect().height}px`);
        } else {
            setHeight('auto');
        }
    }, []);

    useEffect(() => {
        if (filter && !isOpen) {
            setIsOpen(true);
        }
    }, [filter, isOpen]);

    let filterBots = botsArra;
    filterBots = filterBots.filter((item) =>
        item.title && typeof item.title === 'string' ? item.title.toLowerCase().includes(filter.toLowerCase()) : ''
    );

    return (
        <div className="PopupFolderComponent_main">
            <div
                className={`PopupFolderComponent_folder_name_wrapper ${
                    isOpen ? 'PopupFolderComponent_folder_name_wrapperr_open' : ''
                }`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="BPopupFolderComponent_folder_arrow ">{svgLib.chevronDown('#9e9e9e')}</div>
                <div className="PopupFolderComponent_folder_name">
                    {loading ? (
                        <ButtonComponent
                            className="PopupFolderComponent_folder_loading"
                            loading
                            colorLoader="#9e9e9e"
                            acuikit
                            outline
                        >
                            {' '}
                        </ButtonComponent>
                    ) : (
                        <span>{`${name}(${botsArra.length})`}</span>
                    )}
                </div>
            </div>
            <div className="GeneralTogle_content" style={{ height: `${isOpen ? height : '0px'}` }}>
                <div
                    style={{ paddingTop: '10px' }}
                    className="GeneralTogle_content_height_popup ac_scroll"
                    ref={contentWrapper}
                >
                    {filterBots.map((item) => (
                        <Link
                            key={item._id}
                            to={firsLink + item._id + secondLink}
                            className="PopupFolderComponent_bot_popup_bot_name_link"
                            onClick={close}
                        >
                            <div className="PopupFolderComponent_bot_popup_bot_name">
                                <div className="PopupFolderComponent_bot_popup_header_bot_avatar_wrapper">
                                    <img src={item.image} alt="" />
                                </div>
                                <div className="PopupFolderComponent_bot_popup_header_bot_name">
                                    <span>{item.title}</span>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

PopupFolderComponent.propTypes = {
    name: PropTypes.string.isRequired,
};
