/* eslint-disable no-empty-pattern */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { uiActions } from '../../../engine/actions';
import { useAppState } from '../../../engine/globalStateContext';
import { PopUp, PopUpSettings } from '../../../engine/modules/ui.types';
import svgLib from '../../../functionLib/svglib';
import CroppComponent from '../CroppComponent/CroppComponent';
import './index.css';
import UI from './styled';

const PopUpComponent = () => {
    const [, dispatch] = useAppState();
    const [isShow, setIsShow] = useState(false);

    const [settings, setSettings] = useState<PopUpSettings>({
        content: <div>Hi</div>,
    });

    const show = (settingsPopUp: PopUpSettings) => {
        setIsShow(true);
        setSettings(settingsPopUp || settings);
    };

    const close = () => {
        setIsShow(false);
    };

    const crop: PopUp['crop'] = (src, cb, ratio) => {
        setIsShow(true);
        setSettings({
            content: <CroppComponent src={src as any} close={close} cb={cb} aspectRatio={ratio} />,
        });
    };

    useEffect(() => {
        dispatch(
            uiActions.setPopup({
                show,
                close,
                crop,
            })
        );
    }, []);

    if (!isShow) {
        return null;
    }
    return (
        <UI.Background onClick={(e) => (e.target === e.currentTarget ? close() : '')}>
            <UI.PopUp>
                <UI.PopUpHeader>
                    <UI.ClosePopUp onClick={close}>
                        {svgLib.cross('#9e9e9e', { height: '9px', width: '9px' })}
                    </UI.ClosePopUp>
                </UI.PopUpHeader>
                <UI.Container>{settings.content}</UI.Container>
            </UI.PopUp>
        </UI.Background>
    );
};

PopUpComponent.propTypes = {
    display: PropTypes.string,
};

PopUpComponent.defaultProps = {
    display: 'flex',
};

export default PopUpComponent;
