import { useEffect } from 'react';
import { useApi } from '../../../../../functionLib/hooks';
import { agentsActions } from '../../../../../redux/agents/agents.slice';
import { useAppBot } from '../../../../../redux/bot/bot.selectors';
import { useAppDispatch } from '../../../../../redux/hooks';

export const useLoadAgents = () => {
    const api = useApi();
    const { botId } = useAppBot();
    const appDispatch = useAppDispatch();
    const loadAgentsWithMetadata = async (botId: string) => {
        const result = await api.livechat.getAgentsList(botId);
        appDispatch(agentsActions.setAgentsWithMetadata(result.agents));
    };
    useEffect(() => {
        if (botId) loadAgentsWithMetadata(botId);
    }, [botId]);
};
