import { useEffect, useMemo } from 'react';
import { useSounds } from '../../../../../hooks/useSounds';
import { UserSessionWithAgentSession } from '../../../../../types/userSession';

export const useLastNewSessionId = (sessions: UserSessionWithAgentSession[]) => {
    const sounds = useSounds();
    const lastNewSessionId = useMemo(() => findLastNewSessionId(sessions), [sessions]);
    useEffect(() => {
        if (lastNewSessionId) {
            sounds.playNewChat();
        }
    }, [lastNewSessionId]);
    return { lastNewSessionId };
};

const findLastNewSessionId = (sessions: UserSessionWithAgentSession[]): string | undefined => {
    const lastSessionId = sessions
        .filter((item) => item.session.status === 'new')
        .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))[sessions.length - 1]?._id;
    return lastSessionId;
};
