import React, { Component, useState } from 'react';
import svgLib from '../../../../../functionLib/svglib';
import Arrow from './icons8_down3.png';
import './PricingRangeComponent.css';

class PricingRangeComponent extends Component {
    constructor(props) {
        super(props);
        this.PRICING_TIERS = window.PRICING_TIERS || [
            {
                flat_amount: 900,
                up_to: 500,
            },
            {
                flat_amount: 1900,
                up_to: 1000,
            },
            {
                flat_amount: 2900,
                up_to: 3000,
            },
            {
                flat_amount: 3900,
                up_to: 5000,
            },
            {
                flat_amount: 6900,
                up_to: 10000,
            },
            {
                flat_amount: 8900,
                up_to: 15000,
            },
            {
                flat_amount: 10900,
                up_to: 20000,
            },
            {
                flat_amount: 12900,
                up_to: 25000,
            },
            {
                flat_amount: 14900,
                up_to: 30000,
            },
            {
                flat_amount: 17900,
                up_to: 40000,
            },
            {
                flat_amount: 19900,
                up_to: 50000,
            },
            {
                flat_amount: 29900,
                up_to: 100000,
            },
            {
                flat_amount: 39900,
                up_to: 200000,
            },
            {
                flat_amount: 49900,
                up_to: 300000,
            },
            {
                flat_amount: 69900,
                up_to: 500000,
            },
            {
                flat_amount: 99900,
                up_to: 'inf',
            },
        ];
        this.PRICING_TIERS[0].flat_amount !== 'free' &&
            this.PRICING_TIERS.unshift({
                flat_amount: 'free',
                up_to: 100,
            });
        this.state = {
            rangeValue: 0,
            selectedPlan: this.PRICING_TIERS[0] || {
                flat_amount: 'free',
                up_to: 100,
            },
            selectedTab: 'monthly',
            inputWidth: 630,
            billingPeriod: 1,
        };
    }

    /**
     * Method to find a tier by usage from the current PRICING_TIERS
     * @param usage - Pricing usage <Number>
     * @returns Tier
     */
    _findCurrentTierByUsage = (usage = 0) => {
        usage = Number(usage);
        const currentTier = this.PRICING_TIERS.reduce((selectedTier, nextTier) => {
            let newTier = selectedTier;
            if (nextTier.up_to >= usage || nextTier.up_to === 'inf') {
                if (!selectedTier || nextTier.up_to < selectedTier.up_to) {
                    newTier = nextTier;
                }
            }
            return newTier;
        }, undefined);

        return currentTier;
    };

    hideWidget() {
        if (
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.widgetShow === 'hide' &&
            document.querySelector('#jsd-widget')
        )
            return (document.querySelector('#jsd-widget').style.display = 'none');
    }

    componentDidMount() {
        this.setState({
            inputWidth: document.querySelector('.pricingRangeComponent-range-slider-input').getBoundingClientRect()
                .width,
            rangePoints: this.props.rangePointsData,
            // selectedPlan: this.props.selectedPlan
        });

        setTimeout(() => this.hideWidget(), 0);
        // console.log(document.querySelector('#jsd-widget'))
    }

    scale = (num, in_min, in_max, out_min, out_max) =>
        ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;

    render() {
        const { agent = {} } = this.props;

        const { plan = 'Starter' } = agent;
        const currentPlanUpTo =
            this.PRICING_TIERS.find((item) => item.flat_amount === this.props.currentPlan * 100) &&
            this.PRICING_TIERS.find((item) => item.flat_amount === this.props.currentPlan * 100).up_to;
        return (
            <div
                style={!this.props.nonIframe ? { fontFamily: 'Montserrat' } : { fontFamily: 'var(--main-font)' }}
                className="pricingRangeComponent-container"
            >
                {
                    //     <section className={"pricingRangeComponent-tabs-section"}>
                    //     <span className={this.state.selectedTab === "monthly" ? "pricingRangeComponent-tabs-section-singe-tab-selected" : "pricingRangeComponent-tabs-section-singe-tab"} onClick={() => this.setState({
                    //         selectedTab: "monthly",
                    //         billingPeriod: 1
                    //     })}>Monthly</span>
                    //     <span className={this.state.selectedTab === "annually" ? "pricingRangeComponent-tabs-section-singe-tab-selected" : "pricingRangeComponent-tabs-section-singe-tab"} onClick={() => this.setState({
                    //         selectedTab: "annually",
                    //         billingPeriod: 12
                    //     })}>Annually</span>
                    // </section>
                }
                {/* <section className="pricingRangeComponent-info">{plan === 'Starter' ? 'START FREE with unlimited features and pay as you grow' : 'Estimate your next bill'}</section> */}
                <div
                    style={{
                        width: this.props.width || '100%',
                        margin: 'auto',
                    }}
                >
                    <CustomRange
                        style={{
                            width: '100%',
                        }}
                        data={this.PRICING_TIERS}
                        // billingPeriod= {this.state.billingPeriod}
                        onChange={(e) => {
                            this.setState(
                                {
                                    rangeValue: e.target.value,
                                    selectedPlan: this.PRICING_TIERS[e.target.value],
                                },
                                () => {
                                    if (this.props.changePrice) this.props.changePrice(this.state.selectedPlan.up_to);
                                }
                            );
                        }}
                        value={this.state.rangeValue}
                        currentTier={currentPlanUpTo || 1}
                    />
                </div>
                {/* <section className="pricingRangeComponent-summary-section">
                    {this.state.selectedPlan.flat_amount === 'free' ? (
                        <span className="pricingRangeComponent-summary-simple">
                            Engage up to
                            <span className="pricingRangeComponent-summary-highlighted">
                                {' '}
                                {moment(agent.createdAt).isBefore(moment(1563310800000)) ? '500' : '100'}
                                {' '}
                            </span>
                            subscribed users for
                            <span className="pricingRangeComponent-summary-highlighted"> FREE</span>
                        </span>
                    )
                        : (
                            <span className="pricingRangeComponent-summary-simple">
                                <span className="pricingRangeComponent-summary-simple">Engage up to </span>
                                <span className="pricingRangeComponent-summary-highlighted">{this.state.selectedPlan && this.state.selectedPlan.up_to === 'inf' ? '1M' : (this.state.selectedPlan.up_to >= 1000 ? `${this.state.selectedPlan.up_to / 1000}k` : this.state.selectedPlan.up_to)}</span>
                                <span className="pricingRangeComponent-summary-simple"> subscribed users for </span>
                                <span className="pricingRangeComponent-summary-highlighted">{!(this.state.selectedPlan.flat_amount === 'free') ? `$${this.state.selectedPlan.flat_amount / 100 * this.state.billingPeriod}` : 'FREE'}</span>
                                <span className="pricingRangeComponent-summary-simple"> per month</span>
                            </span>
                        )}
                </section> */}
                {/* {this.props.nonIframe ? (plan === 'Starter' ? (
                    <div className="pricingRangeComponent-button-conteiner">
                        <span
                            className="pricingRangeComponent-button"
                            onClick={() => {
                                this.props.subscribe && this.props.subscribe();
                            }}
                        >
                        Upgrade plan

                        </span>
                    </div>
                ) : <div />)
                    : (
                        <div className="pricingRangeComponent-button-conteiner pricingRangeComponent-button-conteiner-iframe">
                            <span
                                style={{
                                    fontFamily: 'Montserrat',
                                }}
                                onClick={() => {
                                    window.open('https://app.activechat.ai/signup');
                                }}
                            >
                            Start free

                            </span>
                        </div>
                    )


                } */}
                {
                    // <iframe src="http://localhost:3000/pricingRange" />
                }
                {
                    // plan === 'Starter'
                    //     && (
                    //         <div className="pricingRangeComponent-trial-info">
                    //             <span style={!this.props.nonIframe ? { fontFamily: 'Montserrat' } : {}} className="pricingRangeComponent-trial-info-span">
                    //                 <span className="pricingRangeComponent-trial-info-check">✔</span>
                    //                 {' '}
                    //             Start 14-day free trial with no user limits
                    //             </span>
                    //             {/* <span style={!this.props.nonIframe ? { fontFamily: 'Montserrat' } : {}} className="pricingRangeComponent-trial-info-span">
                    //                 <span className="pricingRangeComponent-trial-info-check">✔</span>
                    //                 {' '}
                    //             No credit card required
                    //             </span> */}
                    //             {/* <span style={!this.props.nonIframe ? { fontFamily: 'Montserrat' } : {}} className="pricingRangeComponent-trial-info-span">
                    //                 <span className="pricingRangeComponent-trial-info-check">✔</span>
                    //                 {' '}
                    //             Free forever up to 100 bot users per month
                    //             </span> */}
                    //         </div>
                    //     )
                }
            </div>
        );
    }
}

const CurrentPlanPointer = (props) => {
    const reversedVal = 50000;
    const arrowImage = (
        <img
            src={Arrow}
            className={
                props.currentTier && props.currentTier < reversedVal
                    ? 'currentPlanPointer-arrow'
                    : 'currentPlanPointer-arrow-reversed'
            }
        />
    );
    const PointerText = (
        <span
            className={
                props.currentTier && props.currentTier < reversedVal
                    ? 'currentPlanPointer-text'
                    : 'currentPlanPointer-reversed'
            }
        >
            Your current usage
        </span>
    );
    // console.log(props)
    return (
        <div
            style={{
                position: 'absolute',
                left: `${props.position.left}`,
                top: '-25px',
            }}
        >
            {props.currentTier < reversedVal ? (
                <div>
                    {arrowImage}
                    {PointerText}
                </div>
            ) : (
                <div>
                    {PointerText}
                    {arrowImage}
                </div>
            )}
        </div>
    );
};
const CustomRange = (props) => {
    const { data } = props;
    const pricongListValues = data.map((item, i) => (
        <span key={i} className="scale-d" style={{ left: `${(i * 100) / (data.length - 1)}%` }} />
    ));
    const pricongList = data.map((item, i) => (
        <span key={i} className="scale-d2" style={{ left: `${(i * 100) / (data.length - 1)}%` }}>
            {item.up_to !== 'inf' ? (item.up_to >= 1000 ? `${item.up_to / 1000}k` : item.up_to) : '1M'}
        </span>
    ));

    const [rangeValue, setRangeValue] = useState(0);

    return (
        <div className="CustomRange" style={props.styles}>
            <div style={{ width: '100%', padding: '0 10px' }}>
                <div
                    style={{
                        position: 'relative',
                        visibility: props.currentTier && props.currentTier === 1 ? 'hidden' : 'visible',
                    }}
                >
                    {props.currentTier && (
                        <CurrentPlanPointer
                            position={{
                                left: `${
                                    (data.findIndex((item) => item.up_to === props.currentTier) * 100) /
                                    (data.length - 1)
                                }%`,
                            }}
                            currentTier={props.currentTier}
                        />
                    )}
                </div>
            </div>

            <input
                className="pricingRangeComponent-range-slider-input "
                style={{ background: 'transparent' }}
                type="range"
                min="0"
                max={data.length - 1}
                value={props.value}
                onChange={props.onChange}
            />

            <div className="myRange">
                <div className="myRange-slider">
                    <div className="myRange-slider-thumb-background" />
                    <div
                        className="myRange-slider-thumb"
                        style={{ width: `${(props.value * 100) / (data.length - 1)}%` }}
                    >
                        <div className="img-tumb">
                            {/* <img src={Thumb} /> */}
                            {/* <img src={test} alt='' /> */}

                            {svgLib.pause(
                                '#ffffff',
                                { height: '26px', width: '50px', position: 'absolute' },
                                'linear-gradient(180deg, #006CFF 0%, #00BFFF 100%)'
                            )}
                            {/* <div className='img-thumb-pause1'></div>
                            <div className='img-thumb-pause2'></div> */}
                        </div>
                    </div>
                </div>
                <div className="myRange-scale">
                    <div className="myRange-scale-wrap">{pricongListValues}</div>
                </div>
                <div className="myRange-scale">
                    <div className="myRange-scale-wrap">{pricongList}</div>
                </div>
            </div>
        </div>
    );
};
const PricingRangeProvider = (props) => {
    // console.log(props);
    return <PricingRangeComponent {...props} />;
};
export default PricingRangeProvider;
