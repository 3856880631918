import styled from 'styled-components';

const CurrentPassword = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CurrentPasswordText = styled.div`
  font-family: var(--main-font);
  font-size: 14px;
  padding: 0px 0px 20px 0px;
`;

export default {
  CurrentPassword,
  CurrentPasswordText,
};