/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { ChangeEventHandler } from 'react';
import './CheckboxComponent.css';

type CheckboxComponentProps = {
    checked?: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
    name?: string;
    className?: string;
};

const CheckboxComponent: React.FC<CheckboxComponentProps> = ({ checked, onChange, name, className }) => (
    <label className={`CreateAccount_Checkbox_container ${className || ''}`}>
        <input
            className="CreateAccount_item_checkbox"
            checked={checked}
            type="checkbox"
            name={name}
            onChange={onChange}
        />
        <span className="CreateAccount_Checkbox_checkmark">
            <i className="fas fa-check CreateAccount_Checkbox_check" />
        </span>
    </label>
);

export default CheckboxComponent;
