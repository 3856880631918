const storeData = (options = {}) => {
    const { GATEWAY_URL, DEFAULT_API_ERROR } = options

    /**
     * Gets store data for an agent specified
     * @param {String} agentID - Id of the agent ot get store data for
     * @returns {Promise} store data taken by specified agent id
     */
    const get = async (agentID) => {
        if (!agentID) throw new Error('Please specify id of the agent to get store data for')
        
        const url = new URL(`${GATEWAY_URL}/api/retrieveStoreDataByAgentID`)
        
        url.searchParams.append('agentID', agentID)
        
        const options = {
            mode: 'cors',
            credentials: 'include',
            method: 'GET'
        }
        
        try {
            const response = await fetch(url, options),
                statusCode = response.status,
                body = await response.json()

if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }

            if (!response.ok) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body })
            if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

            return body.storeData || {}
        } catch (error) {
            throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
        }
    }

    /**
     * Removes store data document by id
     * @param {String} storeDataID - Id of the store data to remove one
     * @returns {Promise} an error if occurred while removing store data
     */
    const remove = async (storeDataID) => {
        if (!storeDataID) throw new Error('Please specify the id of the store data to remove')

        const url = new URL(`${GATEWAY_URL}/api/removeStoreData`)

        url.searchParams.append('storeDataID', storeDataID)

        const options = {
            mode: 'cors',
            credentials: 'include',
            method: 'GET'
        }

        try {
            const response = await fetch(url, options),
                statusCode = response.status,
                body = await response.json()

if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }

            if (!response.ok) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body })
            if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

            return body
        } catch (error) {
            throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
        }
    }

    const load = async (agentID, integration) => {
        if (!agentID) throw new Error('Please specify id of the agent to fetch store data for')
        if (!integration) throw new Error('Please specify an integration to fetch store data for')

        const url = new URL(`${GATEWAY_URL}/api/getStoreData`)

        url.searchParams.append('agentID', agentID)
        url.searchParams.append('integration', integration)

        const options = {
            mode: 'cors',
            credentials: 'include',
            method: 'GET'
        }

        try {
            const response = await fetch(url, options),
                statusCode = response.status,
                body = await response.json()

if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }

            if (!response.ok) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body })
            if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

            return body.data || {}
        } catch (error) {
            throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
        }
    }

    return ({
        get,
        remove,
        load
    })
}

export default storeData;