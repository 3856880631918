import React from 'react';
import { NavLink } from 'react-router-dom';
export type BotComponentDashButtonProps = {
    linkTo: string;
    img: any;
    name: string;
    isOpen: boolean;
    subRoutes?: any[];
    navigation: any;
};

export const BotComponentDashButtonComponent: React.FC<BotComponentDashButtonProps> = ({
    linkTo,
    img,
    name,
    isOpen,
    subRoutes = [],
    navigation,
}) => {
    let showSubLink = navigation.location.pathname.indexOf(linkTo) !== -1;
    return (
        <div className={showSubLink ? 'active_nav_tab nav_tab' : 'nav_tab'}>
            <NavLink
                className={`BotComponentDashButtonComponent ${
                    subRoutes.length && showSubLink ? 'BotComponentDashButtonComponent_sub_t' : ''
                }`}
                activeClassName={
                    subRoutes.length
                        ? 'BotComponentDashButtonComponentActive_sub'
                        : 'BotComponentDashButtonComponentActive'
                }
                to={`${linkTo}/${subRoutes.length ? subRoutes[0].linkTo : ''}`}
            >
                <div className="BotComponentDashButtonComponent_mainrout">
                    <div
                        className="BotComponentDashButtonComponent_logo_wrapper"
                        style={{ margin: isOpen ? '0 20px 0 28px' : '0 30px' }}
                    >
                        {img}
                    </div>
                    <span>{name}</span>
                </div>
            </NavLink>
            {subRoutes.length && isOpen && (
                <div className="BotComponentDashButtonComponent_subrout">
                    {subRoutes.map((item) => (
                        <NavLink
                            key={item.linkTo}
                            activeClassName="BotComponentDashButtonComponent_subrout_link_active"
                            className="BotComponentDashButtonComponent_subrout_link"
                            to={`${linkTo}/${item.linkTo}`}
                        >
                            <span>{item.name}</span>
                        </NavLink>
                    ))}
                </div>
            )}
        </div>
    );
};
