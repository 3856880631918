import React from 'react';
import FlareComponent from 'flare-react';
import smile from './smile.flr';
import './SomeWrongComponent.css';

const SomeWrongComponent = ({ history }) => (
    <div className="NotfoundComponent_main">
        <div className="NotfoundComponent_container">
            <div className="NotfoundComponent_anim">
                <FlareComponent width={500} transparent height={500} animationName="smile" file={smile} />

            </div>
            <div className="NotfoundComponent_text_Container">
                <h1>
                    Oops, something went wrong.
                </h1>
                <p className="errorText">
                    Don't worry the problem has already been submitted to the developers.
                    <br />
                    Try going
                    {' '}
                    <span
                        onClick={() => { history.goBack(); }}
                        style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                    >
                        back
                    </span>
                    {' '}
                    to the previous page.
                </p>
            </div>
        </div>
    </div>
);

export default SomeWrongComponent;
