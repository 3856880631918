import { useEffect } from 'react';
import getLiveChatData from '../../../../../functionLib/getLiveChatData';
import { useApi } from '../../../../../functionLib/hooks';
import { livechatActions } from '../../../../../redux/actions';
import { useAppBot } from '../../../../../redux/bot/bot.selectors';
import { useUnsafeCustomer } from '../../../../../redux/customer/customer.selectors';
import { useAppDispatch } from '../../../../../redux/hooks';

export const useSetLivechatData = () => {
    const { botId: selectedBotId } = useAppBot();
    const { customerRoles } = useUnsafeCustomer();
    const appDispatch = useAppDispatch();
    const api = useApi();
    useEffect(() => {
        if (selectedBotId && customerRoles.length) {
            getLiveChatData(api, selectedBotId, customerRoles).then(
                (livechat) => livechat && appDispatch(livechatActions.setLivechatData(livechat))
            );
        }
    }, [customerRoles, selectedBotId]);
};
