import { ApiConfig } from '../../config';
import { ImportUserItem } from './twillio.types';

const twillio = (options: ApiConfig) => {
    const { GATEWAY_URL, DEFAULT_API_ERROR } = options;

    /**
     * Connects an agent to the Twillio channel
     * @param {String} agentID - Id of the agent to connect Twillio to
     * @param {String} accountSid - Id of the Twillio account session
     * @param {String} authToken - Auth token
     * @returns {Promise} twillio data object
     */
    const connect = async (agentID, accountSid, authToken) => {
        if (!agentID) throw new Error('Please specify the id of the agent to connect Twillio to');

        const url = new URL(`${GATEWAY_URL}/api/twilioConnect`);

        const options: RequestInit = {
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ agentID, accountSid, authToken }),
        };

        try {
            const response = await fetch(url.toString(), options),
                statusCode = response.status,
                body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body };
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body.data;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Diconnects Twillio channel from the specified agent
     * @param {String} agentID - Id of the agent to disconnect Twillio for
     * @returns {Promise} an error if occurred while disconnecting Twillio from the agent
     */
    const disconnect = async (agentID) => {
        if (!agentID) throw new Error('Please specify the id of the agent to disconnect Twillio for');

        const url = new URL(`${GATEWAY_URL}/api/twilioDisconnect`);

        const options: RequestInit = {
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ agentID }),
        };

        try {
            const response = await fetch(url.toString(), options),
                statusCode = response.status,
                body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body };
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Imports users to the Twillio
     * @param {String} agentID - Id of the agent to import users for
     * @param {String} serviceSid - Id of the Twillio account service
     * @param {Array<{phone:number, first_name:string, last_name:string, tags:string}>} users - Array of users to import
     * @returns {Promise} an error if occurred while importing users
     */
    const importUsers = async (
        agentID: string,
        serviceSid: string | undefined,
        users: ImportUserItem[]
    ): Promise<any> => {
        if (!agentID) throw new Error('Please specify the id of the agent to import users for');

        const url = new URL(`${GATEWAY_URL}/api/twilioUsersImport`);

        const options: RequestInit = {
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ agentID, serviceSid, users }),
        };

        try {
            const response = await fetch(url.toString(), options),
                statusCode = response.status,
                body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body };
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body.data;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    return {
        connect,
        disconnect,
        importUsers,
    };
};

export default twillio;
