/* eslint-disable no-prototype-builtins */
import * as PIXI from 'pixi.js';
import React, { useEffect } from 'react';
import { appActions } from '../../../engine/actions';
import { useAppState } from '../../../engine/globalStateContext';
import './MainCanvasConteiner.css';
import RawBlockDataModifier from './rawBlockDataModifier';

// TODO(m-nny): remove from app, if is not used
const MainCanvasConteiner: React.FC = () => {
    const [{ blocksList, currentSkill }, dispatch] = useAppState();
    const app = new PIXI.Application({
        width: 800,
        height: 600,
        backgroundColor: 0xffffff,
        resolution: window.devicePixelRatio || 1,
        forceCanvas: true,
        autoResize: true,
    } as any);
    useEffect(() => {
        const mainCanvasConteiner = document.querySelector<HTMLDivElement>('.main-canvas-conteiner');
        if (!mainCanvasConteiner) {
            return;
        }
        mainCanvasConteiner.appendChild(app.view);
        app.renderer.resize(
            mainCanvasConteiner.getBoundingClientRect().width,
            mainCanvasConteiner.getBoundingClientRect().height
        );
    }, [app.renderer, app.view]);

    const onWindowResize = () => {
        const mainCanvasConteiner = document.querySelector<HTMLDivElement>('.main-canvas-conteiner');
        if (!mainCanvasConteiner) {
            return;
        }
        app.renderer.resize(
            mainCanvasConteiner.getBoundingClientRect().width + 300,
            mainCanvasConteiner.getBoundingClientRect().height
        );
    };
    useEffect(() => {
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    });

    const drop = (e) => {
        try {
            const blockJSON = JSON.parse(e.dataTransfer.getData('text'));
            if (Object.entries(blockJSON).length) {
                const modifiedBlockData = RawBlockDataModifier({
                    rawJSON: blockJSON,
                    blocksList,
                    mouseCords: {
                        x: e.clientX - e.currentTarget.offsetLeft,
                        y: e.clientY - e.currentTarget.offsetTop,
                    },
                    skill: currentSkill,
                });
                const blocksListPH = {
                    ...blocksList,
                    [modifiedBlockData.id]: modifiedBlockData,
                };

                dispatch(appActions.setBlocksList(blocksListPH));
            }
        } catch (error) {}
    };
    return <div className="main-canvas-conteiner" onDrop={drop} onDragOver={(e) => e.preventDefault()} />;
};

export default MainCanvasConteiner;
