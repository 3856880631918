const nlp = (options = {}) => {
  const { GATEWAY_URL, DEFAULT_API_ERROR, ARCHITECT_URL } = options; 

  const connect = async (lang, botId) => {
    console.log(botId, lang);

    const url = new URL(`${ARCHITECT_URL}/v1/intents-manager?botId=${botId}`);

    const fetchOptions = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        // credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
          lang
        }),
    };

    try {
        const response = await fetch(url, fetchOptions);
        const statusCode = response.status;
        const body = await response.json();
        console.log(response)
        if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

        if (!response.ok) {
            throw ({
                statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body,
            });
        }
        if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error });

        return body;
    } catch (error) {
        throw (error.statusCode ? error : { message: DEFAULT_API_ERROR });
    }
  };

  const disconnect = async (botId) => {
    console.log(botId);

    const url = new URL(`${ARCHITECT_URL}/v1/intents-manager?botId=${botId}`);

    const fetchOptions = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        // credentials: 'include',
        method: 'DELETE',
    };

    try {
        const response = await fetch(url, fetchOptions);
        const statusCode = response.status;
        const body = await response.json();
        if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

        if (!response.ok) {
            throw ({
                statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body,
            });
        }
        if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error });

        return body;
    } catch (error) {
        throw (error.statusCode ? error : { message: DEFAULT_API_ERROR });
    }
  };

  return {
    connect,
    disconnect
  }
}

export default nlp;