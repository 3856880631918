/* eslint-disable indent */
import styled from 'styled-components';

const AboutMeEditor = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 5px 0px 0px 0px;
  margin: 10px 0px 0px 0px;
`;

const AboutMeInput = styled.textarea`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 100%;
  height: 98px;
  font-family: var(--main-font);
  font-size: 12px;
  font-weight: 200;
  resize: none;
  padding: 12px;
  outline: none;
`;

const InputLabel = styled.div`
  position: absolute;
  align: right;
  background: #ffffff;
  bottom: 88px;
  left: 10px;
  color: rgba(0, 0, 0, 0.45);
  padding: 1px;
  font-family: var(--main-font);
  font-size: 14px;

`;

export default {
  AboutMeEditor,
  AboutMeInput,
  InputLabel,
};
