import { useEffect, useRef } from 'react';
import { notificationActions } from '../../redux/actions';
import { useAppDispatch } from '../../redux/hooks';
import { FeedBackPopUp, NotificationEngine, NotificationType } from '../../redux/notification/notification.types';

export const useNotificationsActions = (): NotificationEngine => {
    const timer = useRef<NodeJS.Timeout>();
    const dispatch = useAppDispatch();
    const showNotificationFactory = (type: NotificationType) => (message: string, time?: number) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        dispatch(notificationActions.showNotification({ type, message, showCloseButton: time === undefined }));
        if (time) {
            timer.current = setTimeout(() => dispatch(notificationActions.closeNotification()), time * 1000);
        }
    };
    return {
        info: showNotificationFactory('info'),
        warning: showNotificationFactory('warning'),
        error: showNotificationFactory('error'),
        close: () => dispatch(notificationActions.closeNotification()),
    };
};
export const useN11s = useNotificationsActions;

export const useFeedBackPopUp = (): FeedBackPopUp => {
    const notifications = useNotificationsActions();
    return (mode, messsage, time) => {
        switch (mode) {
            case 'r':
                return notifications.error(messsage, time);
            case 'y':
                return notifications.warning(messsage, time);
            case 'g':
                return notifications.info(messsage, time);
            default:
                break;
        }
    };
};

export const injectNotificationsIntoWindows = () => {
    const n11sEngine = useNotificationsActions();
    const n11sFeedBackPopUp = useFeedBackPopUp();
    useEffect(() => {
        window.notification = n11sEngine;
        window.feedBackPopUp = n11sFeedBackPopUp;
    }, []);
};
