import styled from 'styled-components';

const SVGLIB = styled.div`
  position: relative;
  display: flex;
  jusyify-content: center;
  padding: 50px 0px 0px 0px;
`;

const NavBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  padding: 20px;
  opacity: 100%;
  background: linear-gradient(180deg, #006CFF 0%, #00BFFF 100%);
  top: 0;
  z-index: 1000;
  textAlign: center;
  textDecoration: none;
`;

const Input = styled.input`
  z-index: 10;
`;

export default {
  Input,
  NavBar,
  SVGLIB,
}