/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React from 'react';
import PropTypes from 'prop-types';

import UI from './styled';
import './styled.css'

const AboutMeEditor = ({ onChange, value }) => (
  <UI.AboutMeEditor>
    <UI.InputLabel htmlFor="name-input">
      About me
    </UI.InputLabel>
    <UI.AboutMeInput
      maxLength="200"
      onChange={onChange}
      value={value}
      placeholder="You can leave some text about you here"
      name="name-input"
      type="text"
    />
  </UI.AboutMeEditor>
);

AboutMeEditor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

AboutMeEditor.defaultProps = {
  onChange: () => {},
  value: '',
};

export default AboutMeEditor;
