import React, { MouseEventHandler } from 'react';
import './IconButton.css';

export type IconButtonProps = {
    src?: string;
    className?: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
    svg?: React.ReactElement;
    outline?: boolean;
    disabled?: boolean;
    styles?: any;
};

const IconButton = ({
    src = '',
    onClick = () => {},
    svg = {},
    outline = false,
    disabled = false,
    styles = {},
    className = '',
}) => (
    <div
        style={styles}
        className={`IconButton_main ${outline ? 'IconButton_main_outline' : ''} ${
            disabled ? 'IconButton_main_disabled' : ''
        } ${className}`}
        onClick={onClick}
    >
        {Object.keys(svg).length ? <div className="IconButton_main_svg">{svg}</div> : <img src={src} alt="" />}
    </div>
);

export default IconButton;
