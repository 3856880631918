import React, { MouseEventHandler } from 'react';
import './ButtonComponent.css';

export type ButtonProps = {
    id?: string;
    loading?: boolean;
    className?: string;
    colorLoader?: string;
    acuikit?: boolean;
    outline?: boolean;
    red?: boolean;
    locked?: boolean;
    bold?: boolean;
    style?: React.CSSProperties;
    onClick?: MouseEventHandler<HTMLDivElement>;
    onDisableClick?: MouseEventHandler<HTMLDivElement>;
    onTitleClick?: MouseEventHandler<HTMLDivElement>;
};
const ButtonComponent: React.FC<ButtonProps> = ({
    id = 'customButton',
    children,
    loading,
    onClick,
    className,
    colorLoader = 'grey',
    acuikit,
    outline,
    red,
    locked,
    onDisableClick,
    bold,
    onTitleClick,
    style,
}) => {
    let onPress: MouseEventHandler<HTMLDivElement> | undefined;

    if (loading && !locked) {
        onPress = undefined;
    } else if (locked) {
        onPress = onDisableClick;
    } else {
        onPress = onClick;
    }

    return (
        <div
            id={id}
            style={{ ...style, '--color-loader': colorLoader } as any}
            className={`bt-component ${className} ${acuikit ? 'button_acuikit' : ''} ${
                outline ? 'button_acuikit_outline' : ''
            }  ${red ? 'button_acuikit_red' : ''} ${locked ? 'button_acuikit_locked' : ''} ${
                locked && outline ? 'button_acuikit_locked_outline' : ''
            } `}
            onClick={onPress}
        >
            {loading ? (
                <div className="loader-container">{<div className="loader" />}</div>
            ) : (
                <div className="bt-content">
                    <span
                        onClick={onTitleClick}
                        className="bt-span"
                        style={bold ? { fontWeight: 'bold', fontSize: '16px' } : { fontWeight: 'normal' }}
                    >
                        {children}
                    </span>
                </div>
            )}
        </div>
    );
};

export default ButtonComponent;
