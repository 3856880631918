
var CanvasKitInit = (function() {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  return (
function(CanvasKitInit) {
  CanvasKitInit = CanvasKitInit || {};

var f;f||(f=typeof CanvasKitInit !== 'undefined' ? CanvasKitInit : {});
(function(a){a.yt=a.yt||[];a.yt.push(function(){a.MakeSWCanvasSurface=function(b){var c=b;if("CANVAS"!==c.tagName&&(c=document.getElementById(b),!c))throw"Canvas with id "+b+" was not found";if(b=a.MakeSurface(c.width,c.height))b.hu=c;return b};a.MakeCanvasSurface||(a.MakeCanvasSurface=a.MakeSWCanvasSurface);a.MakeSurface=function(b,c){var d={width:b,height:c,colorType:a.ColorType.RGBA_8888,alphaType:a.AlphaType.Unpremul},e=b*c*4,k=a._malloc(e);if(d=this._getRasterDirectSurface(d,k,4*b))d.hu=null,
d.Uu=b,d.Su=c,d.Tu=e,d.iu=k,d.getCanvas().clear(a.TRANSPARENT);return d};a.SkSurface.prototype.flush=function(){this._flush();if(this.hu){var b=new Uint8ClampedArray(a.buffer,this.iu,this.Tu);b=new ImageData(b,this.Uu,this.Su);this.hu.getContext("2d").putImageData(b,0,0)}};a.SkSurface.prototype.dispose=function(){this.iu&&a._free(this.iu);this.delete()};a.currentContext=a.currentContext||function(){};a.setCurrentContext=a.setCurrentContext||function(){}})})(f);
(function(a){a.yt=a.yt||[];a.yt.push(function(){function b(a,b,e){return a&&a.hasOwnProperty(b)?a[b]:e}a.GetWebGLContext=function(a,d){d={alpha:b(d,"alpha",1),depth:b(d,"depth",1),stencil:b(d,"stencil",0),antialias:b(d,"antialias",1),premultipliedAlpha:b(d,"premultipliedAlpha",1),preserveDrawingBuffer:b(d,"preserveDrawingBuffer",0),preferLowPowerToHighPerformance:b(d,"preferLowPowerToHighPerformance",0),failIfMajorPerformanceCaveat:b(d,"failIfMajorPerformanceCaveat",0),majorVersion:b(d,"majorVersion",
1),minorVersion:b(d,"minorVersion",0),Gx:b(d,"enableExtensionsByDefault",1),Ix:b(d,"explicitSwapControl",0),Nx:b(d,"renderViaOffscreenBackBuffer",0)};return!a||d.explicitSwapControl?0:aa(a,d)};a.MakeWebGLCanvasSurface=function(b,d,e){var c=b;if("CANVAS"!==c.tagName&&(c=document.getElementById(b),!c))throw"Canvas with id "+b+" was not found";b=this.GetWebGLContext(c);if(!b||0>b)throw"failed to create webgl context: err "+b;if(!(c||d&&e))throw"height and width must be provided with context";var l=this.MakeGrContext(b);
l.setResourceCacheLimitBytes(268435456);d=this.MakeOnScreenGLSurface(l,d||c.width,e||c.height);if(!d)return d=c.cloneNode(!0),c.parentNode.replaceChild(d,c),d.classList.add("ck-replaced"),a.MakeSWCanvasSurface(d);d.Eu=b;d.grContext=l;return d};a.MakeCanvasSurface=a.MakeWebGLCanvasSurface})})(f);
(function(a){function b(a){return Math.round(Math.max(0,Math.min(a||0,255)))}function c(b,c,d){if(!b||!b.length)return 0;d||(d=a._malloc(b.length*c.BYTES_PER_ELEMENT));c.set(b,d/c.BYTES_PER_ELEMENT);return d}function d(b,c,d){if(b&&b.length){d||(d=a._malloc(b.length*b[0].length*c.BYTES_PER_ELEMENT));var e=0;d/=c.BYTES_PER_ELEMENT;for(var m=0;m<b.length;m++)for(var k=0;k<b[0].length;k++)c[d+e]=b[m][k],e++}}function e(a,b){if(k)require("fs").writeFile(b,new Buffer(a),function(a){if(a)throw a;});else{url=
window.URL.createObjectURL(new Blob([a],{type:"application/octet-stream"}));var c=document.createElement("a");document.body.appendChild(c);c.href=url;c.download=b;c.click();setTimeout(function(){URL.revokeObjectURL(url);c.remove()},50)}}a.Color=function(a,c,d,e){void 0===e&&(e=1);return(b(255*e)<<24|b(a)<<16|b(c)<<8|b(d)<<0)>>>0};a.getColorComponents=function(a){return[a>>16&255,a>>8&255,a>>0&255,(a>>24&255)/255]};a.multiplyByAlpha=function(a,c){return 1===c?a:(b((a>>24&255)*c)<<24|a&16777215)>>>
0};var k=!(new Function("try {return this===window;}catch(e){ return false;}"))(),l={};a.Et=function(){this.vt=[];this.bt=null;Object.defineProperty(this,"length",{enumerable:!0,get:function(){return this.vt.length/4}})};a.Et.prototype.push=function(a,b,c,d){this.bt||this.vt.push(a,b,c,d)};a.Et.prototype.set=function(b,c,d,e,k){0>b||b>=this.vt.length/4||(b*=4,this.bt?(b=this.bt/4+b,a.HEAPF32[b]=c,a.HEAPF32[b+1]=d,a.HEAPF32[b+2]=e,a.HEAPF32[b+3]=k):(this.vt[b]=c,this.vt[b+1]=d,this.vt[b+2]=e,this.vt[b+
3]=k))};a.Et.prototype.build=function(){return this.bt?this.bt:this.bt=c(this.vt,a.HEAPF32)};a.Et.prototype.delete=function(){this.bt&&(a._free(this.bt),this.bt=null)};a.Kt=function(){this.Lt=[];this.bt=null;Object.defineProperty(this,"length",{enumerable:!0,get:function(){return this.Lt.length}})};a.Kt.prototype.push=function(a){this.bt||this.Lt.push(a)};a.Kt.prototype.set=function(b,c){0>b||b>=this.Lt.length||(b*=4,this.bt?a.HEAPU32[this.bt/4+b]=c:this.Lt[b]=c)};a.Kt.prototype.build=function(){return this.bt?
this.bt:this.bt=c(this.Lt,a.HEAPU32)};a.Kt.prototype.delete=function(){this.bt&&(a._free(this.bt),this.bt=null)};a.SkRectBuilder=a.Et;a.RSXFormBuilder=a.Et;a.SkColorBuilder=a.Kt;a.onRuntimeInitialized=function(){a.SkMatrix={};a.SkMatrix.identity=function(){return[1,0,0,0,1,0,0,0,1]};a.SkMatrix.invert=function(b){var c=b[0]*b[4]*b[8]+b[1]*b[5]*b[6]+b[2]*b[3]*b[7]-b[2]*b[4]*b[6]-b[1]*b[3]*b[8]-b[0]*b[5]*b[7];return c?[(b[4]*b[8]-b[5]*b[7])/c,(b[2]*b[7]-b[1]*b[8])/c,(b[1]*b[5]-b[2]*b[4])/c,(b[5]*b[6]-
b[3]*b[8])/c,(b[0]*b[8]-b[2]*b[6])/c,(b[2]*b[3]-b[0]*b[5])/c,(b[3]*b[7]-b[4]*b[6])/c,(b[1]*b[6]-b[0]*b[7])/c,(b[0]*b[4]-b[1]*b[3])/c]:a.SkMatrix.identity()};a.SkMatrix.mapPoints=function(a,b){if(b.length%2)throw"mapPoints requires an even length arr";for(var c=0;c<b.length;c+=2){var d=b[c],e=b[c+1],m=a[6]*d+a[7]*e+a[8],k=a[3]*d+a[4]*e+a[5];b[c]=(a[0]*d+a[1]*e+a[2])/m;b[c+1]=k/m}return b};a.SkMatrix.multiply=function(a,b){for(var c=[0,0,0,0,0,0,0,0,0],d=0;3>d;d++)for(var e=0;3>e;e++)c[3*d+e]=a[3*d+
0]*b[0+e]+a[3*d+1]*b[3+e]+(a[3*d+2]||0)*(b[6+e]||0);return c};a.SkMatrix.rotated=function(a,b,c){b=b||0;c=c||0;var d=Math.sin(a);a=Math.cos(a);return[a,-d,d*c+(1-a)*b+0,d,a,-d*b+(1-a)*c+0,0,0,1]};a.SkMatrix.scaled=function(a,b,c,d){c=c||0;d=d||0;return[a,0,c-a*c,0,b,d-b*d,0,0,1]};a.SkMatrix.skewed=function(a,b,c,d){return[1,a,-a*(c||0),b,1,-b*(d||0),0,0,1]};a.SkMatrix.translated=function(a,b){return[1,0,a,0,1,b,0,0,1]};a.SkPath.prototype.addArc=function(a,b,c){this._addArc(a,b,c);return this};a.SkPath.prototype.addPath=
function(){var a=Array.prototype.slice.call(arguments),b=a[0],c=!1;"boolean"===typeof a[a.length-1]&&(c=a.pop());if(1===a.length)this._addPath(b,1,0,0,0,1,0,0,0,1,c);else if(2===a.length)a=a[1],this._addPath(b,a[0],a[1],a[2],a[3],a[4],a[5],a[6]||0,a[7]||0,a[8]||1,c);else if(7===a.length||10===a.length)this._addPath(b,a[1],a[2],a[3],a[4],a[5],a[6],a[7]||0,a[8]||0,a[9]||1,c);else return null;return this};a.SkPath.prototype.addRect=function(){if(1===arguments.length||2===arguments.length){var a=arguments[0];
this._addRect(a.fLeft,a.fTop,a.fRight,a.fBottom,arguments[1]||!1)}else if(4===arguments.length||5===arguments.length)a=arguments,this._addRect(a[0],a[1],a[2],a[3],a[4]||!1);else return null;return this};a.SkPath.prototype.addRoundRect=function(){var b=arguments;if(3===b.length||6===b.length)var d=b[b.length-2];else if(6===b.length||7===b.length){d=b[b.length-3];var e=b[b.length-2];d=[d,e,d,e,d,e,d,e]}else return null;if(8!==d.length)return null;d=c(d,a.HEAPF32);if(3===b.length||4===b.length){e=b[0];
var k=b[b.length-1];this._addRoundRect(e.fLeft,e.fTop,e.fRight,e.fBottom,d,k)}else 6!==b.length&&7!==b.length||this._addRoundRect(b[0],b[1],b[2],b[3],d,k);a._free(d);return this};a.SkPath.prototype.arc=function(b,c,d,e,k,l){b=a.LTRBRect(b-d,c-d,b+d,c+d);k=(k-e)/Math.PI*180-360*!!l;l=new a.SkPath;l.addArc(b,e/Math.PI*180,k);this.addPath(l,!0);l.delete();return this};a.SkPath.prototype.arcTo=function(){var b=arguments;if(5===b.length)this._arcTo(b[0],b[1],b[2],b[3],b[4]);else if(4===b.length)this._arcTo(b[0],
b[1],b[2],b[3]);else if(7===b.length)this._arcTo(a.LTRBRect(b[0],b[1],b[2],b[3]),b[4],b[5],b[6]);else throw"Invalid args for arcTo. Expected 4, 5, or 7, got "+b.length;return this};a.SkPath.prototype.close=function(){this._close();return this};a.SkPath.prototype.conicTo=function(a,b,c,d,e){this._conicTo(a,b,c,d,e);return this};a.SkPath.prototype.cubicTo=function(a,b,c,d,e,k){this._cubicTo(a,b,c,d,e,k);return this};a.SkPath.prototype.dash=function(a,b,c){return this._dash(a,b,c)?this:null};a.SkPath.prototype.lineTo=
function(a,b){this._lineTo(a,b);return this};a.SkPath.prototype.moveTo=function(a,b){this._moveTo(a,b);return this};a.SkPath.prototype.op=function(a,b){return this._op(a,b)?this:null};a.SkPath.prototype.quadTo=function(a,b,c,d){this._quadTo(a,b,c,d);return this};a.SkPath.prototype.simplify=function(){return this._simplify()?this:null};a.SkPath.prototype.stroke=function(b){b=b||{};b.width=b.width||1;b.miter_limit=b.miter_limit||4;b.cap=b.cap||a.StrokeCap.Butt;b.join=b.join||a.StrokeJoin.Miter;b.precision=
b.precision||1;return this._stroke(b)?this:null};a.SkPath.prototype.transform=function(){if(1===arguments.length){var a=arguments[0];this._transform(a[0],a[1],a[2],a[3],a[4],a[5],a[6]||0,a[7]||0,a[8]||1)}else if(6===arguments.length||9===arguments.length)a=arguments,this._transform(a[0],a[1],a[2],a[3],a[4],a[5],a[6]||0,a[7]||0,a[8]||1);else throw"transform expected to take 1 or 9 arguments. Got "+arguments.length;return this};a.SkPath.prototype.trim=function(a,b,c){return this._trim(a,b,!!c)?this:
null};a.SkVertices.prototype.applyBones=function(b){var c=a.HEAPF32;var d;if(b&&b.length&&b[0].length){d||(d=a._malloc(b.length*b[0].length*b[0][0].length*c.BYTES_PER_ELEMENT));for(var e=0,k=d/c.BYTES_PER_ELEMENT,m=0;m<b.length;m++)for(var l=0;l<b[0].length;l++)for(var J=0;J<b[0][0].length;J++)c[k+e]=b[m][l][J],e++;c=d}else c=0;b=this._applyBones(c,b.length);a._free(c);return b};a.SkImage.prototype.encodeToData=function(){if(!arguments.length)return this._encodeToData();if(2===arguments.length){var a=
arguments;return this._encodeToDataWithFormat(a[0],a[1])}throw"encodeToData expected to take 0 or 2 arguments. Got "+arguments.length;};a.SkImage.prototype.makeShader=function(a,b,c){return c?(6===c.length&&c.push(0,0,1),this._makeShader(a,b,c)):this._makeShader(a,b)};a.SkImage.prototype.readPixels=function(b,c,d){switch(b.$u){case a.ColorType.RGBA_8888:var e=4*b.width;break;case a.ColorType.RGBA_F32:e=16*b.width;break;default:return}var k=e*b.height,m=a._malloc(k);if(!this._readPixels(b,m,e,c,d))return null;
c=null;switch(b.$u){case a.ColorType.RGBA_8888:c=(new Uint8Array(a.buffer,m,k)).slice();break;case a.ColorType.RGBA_F32:c=(new Float32Array(a.buffer,m,k)).slice()}a._free(m);return c};a.SkCanvas.prototype.drawAtlas=function(b,d,e,k,l,A){if(b&&k&&d&&e){l||(l=a.BlendMode.SrcOver);var m;d.build?m=d.build():m=c(d,a.HEAPF32);var p;e.build?p=e.build():p=c(e,a.HEAPF32);var r=0;A&&(A.build?r=A.build():r=c(A,a.HEAPU32));this._drawAtlas(b,p,m,r,e.length,l,k);m&&!d.build&&a._free(m);p&&!e.build&&a._free(p);
r&&!A.build&&a._free(r)}};a.SkCanvas.prototype.drawText=function(b,c,d,e,k){if("string"===typeof b){var m=ba(b)+1,l=a._malloc(m);g(b,h,l,m);this._drawSimpleText(l,m,c,d,k,e)}else this._drawShapedText(b,c,d,e)};a.SkCanvas.prototype.readPixels=function(b,c,d,e,k,l,B){k=k||a.AlphaType.Unpremul;l=l||a.ColorType.RGBA_8888;B=B||4*d;var m=e*B,p=a._malloc(m);if(!this._readPixels({width:d,height:e,colorType:l,alphaType:k},p,B,b,c))return a._free(p),null;b=(new Uint8Array(a.HEAPU8.buffer,p,m)).slice();a._free(p);
return b};a.SkCanvas.prototype.writePixels=function(b,c,d,e,k,l,B){if(b.byteLength%(c*d))throw"pixels length must be a multiple of the srcWidth * srcHeight";var m=b.byteLength/(c*d);l=l||a.AlphaType.Unpremul;B=B||a.ColorType.RGBA_8888;var p=m*c;m=a._malloc(b.byteLength);a.HEAPU8.set(b,m);b=this._writePixels({width:c,height:d,colorType:B,alphaType:l},m,p,e,k);a._free(m);return b};a.SkFont.prototype.getWidths=function(b){var c=b.length+1,d=ba(b)+1,e=a._malloc(d);g(b,h,e,d);b=a._malloc(4*c);if(!this._getWidths(e,
d,c,b))return a._free(e),a._free(b),null;c=new Float32Array(a.buffer,b,c);c=Array.from(c);a._free(e);a._free(b);return c};a.SkFontMgr.prototype.MakeTypefaceFromData=function(b){b=new Uint8Array(b);var c=a._malloc(b.byteLength);a.HEAPU8.set(b,c);return(b=this._makeTypefaceFromData(c,b.byteLength))?b:null};a.SkPicture.prototype.DEBUGONLY_saveAsFile=function(b){var c=this.DEBUGONLY_serialize();if(c){var d=a.getSkDataBytes(c);e(d,b);c.delete()}};a.SkSurface.prototype.captureFrameAsSkPicture=function(b){var c=
new a.SkPictureRecorder,d=c.beginRecording(a.LTRBRect(0,0,this.width(),this.height()));b(d);b=c.finishRecordingAsPicture();c.delete();return b};a.SkSurface.prototype.requestAnimationFrame=function(b){this.Du||(this.Du=this.getCanvas());window.requestAnimationFrame(function(){void 0!==this.Eu&&a.setCurrentContext(this.Eu);b(this.Du);this.flush()}.bind(this))};a.SkTextBlob.MakeOnPath=function(b,c,d,e){if(b&&b.length&&c&&c.countPoints()){if(1===c.countPoints())return this.MakeFromText(b,d);e||(e=0);
var k=d.getWidths(b),m=new a.RSXFormBuilder;c=new a.SkPathMeasure(c,!1,1);for(var l=0;l<b.length;l++){var p=k[l];e+=p/2;if(e>c.getLength()){if(!c.nextContour()){b=b.substring(0,l);break}e=p/2}var r=c.getPosTan(e),v=r[2],dd=r[3];m.push(v,dd,r[0]-p/2*v,r[1]-p/2*dd);e+=p/2}b=this.MakeFromRSXform(b,m,d);m.delete();c.delete();return b}};a.SkTextBlob.MakeFromRSXform=function(b,c,d){var e=ba(b)+1,k=a._malloc(e);g(b,h,k,e);b=c.build();d=a.SkTextBlob._MakeFromRSXform(k,e-1,b,d,a.TextEncoding.UTF8);if(!d)return null;
var m=d.delete.bind(d);d.delete=function(){a._free(k);m()};return d};a.SkTextBlob.MakeFromText=function(b,c){var d=ba(b)+1,e=a._malloc(d);g(b,h,e,d);b=a.SkTextBlob._MakeFromText(e,d-1,c,a.TextEncoding.UTF8);if(!b)return null;var k=b.delete.bind(b);b.delete=function(){a._free(e);k()};return b};a.yt&&a.yt.forEach(function(a){a()})};a.LTRBRect=function(a,b,c,d){return{fLeft:a,fTop:b,fRight:c,fBottom:d}};a.XYWHRect=function(a,b,c,d){return{fLeft:a,fTop:b,fRight:a+c,fBottom:b+d}};a.MakePathFromCmds=function(b){for(var d=
0,e=0;e<b.length;e++)d+=b[e].length;if(l[d])var k=l[d];else k=new Float32Array(d),l[d]=k;var m=0;for(e=0;e<b.length;e++)for(var A=0;A<b[e].length;A++)k[m]=b[e][A],m++;b=[c(k,a.HEAPF32),d];d=a._MakePathFromCmds(b[0],b[1]);a._free(b[0]);return d};a.MakeSkDashPathEffect=function(b,d){d||(d=0);if(!b.length||1===b.length%2)throw"Intervals array must have even length";var e=c(b,a.HEAPF32);b=a._MakeSkDashPathEffect(e,b.length,d);a._free(e);return b};a.MakeImageFromEncoded=function(b){b=new Uint8Array(b);
var c=a._malloc(b.byteLength);a.HEAPU8.set(b,c);return(b=a._decodeImage(c,b.byteLength))?b:null};a.MakeImage=function(b,c,d,e,k){var l=b.byteLength/(c*d);d={width:c,height:d,alphaType:e,colorType:k};e=a._malloc(b.byteLength);a.HEAPU8.set(b,e);return a._MakeImage(d,e,b.byteLength,c*l)};a.MakeLinearGradientShader=function(b,d,e,k,l,A,B){var m=c(e,a.HEAPU32);k=c(k,a.HEAPF32);B=B||0;A?(6===A.length&&A.push(0,0,1),b=a._MakeLinearGradientShader(b,d,m,k,e.length,l,B,A)):b=a._MakeLinearGradientShader(b,d,
m,k,e.length,l,B);a._free(m);a._free(k);return b};a.MakeRadialGradientShader=function(b,d,e,k,l,A,B){var m=c(e,a.HEAPU32);k=c(k,a.HEAPF32);B=B||0;A?(6===A.length&&A.push(0,0,1),b=a._MakeRadialGradientShader(b,d,m,k,e.length,l,B,A)):b=a._MakeRadialGradientShader(b,d,m,k,e.length,l,B);a._free(m);a._free(k);return b};a.MakeTwoPointConicalGradientShader=function(b,d,e,k,l,A,B,J,Fa){var m=c(l,a.HEAPU32);A=c(A,a.HEAPF32);Fa=Fa||0;J?(6===J.length&&J.push(0,0,1),b=a._MakeTwoPointConicalGradientShader(b,d,
e,k,m,A,l.length,B,Fa,J)):b=a._MakeTwoPointConicalGradientShader(b,d,e,k,m,A,l.length,B,Fa);a._free(m);a._free(A);return b};a.MakeSkVertices=function(b,e,k,l,C,A,B,J){var m=B&&B.length||0,p=0;k&&k.length&&(p|=1);l&&l.length&&(p|=2);C&&C.length&&(p|=4);void 0===J||J||(p|=8);b=new a._SkVerticesBuilder(b,e.length,m,p);d(e,a.HEAPF32,b.positions());b.texCoords()&&d(k,a.HEAPF32,b.texCoords());b.colors()&&c(l,a.HEAPU32,b.colors());b.boneIndices()&&d(C,a.HEAP32,b.boneIndices());b.boneWeights()&&d(A,a.HEAPF32,
b.boneWeights());b.indices()&&c(B,a.HEAPU16,b.indices());return b.detach()}})(f);var ca={},da;for(da in f)f.hasOwnProperty(da)&&(ca[da]=f[da]);f.arguments=[];f.thisProgram="./this.program";f.quit=function(a,b){throw b;};f.preRun=[];f.postRun=[];var ea=!1,fa=!1,ha=!1,ia=!1;ea="object"===typeof window;fa="function"===typeof importScripts;ha="object"===typeof process&&"function"===typeof require&&!ea&&!fa;ia=!ea&&!ha&&!fa;var ja="";function ka(a){return f.locateFile?f.locateFile(a,ja):ja+a}
if(ha){ja=__dirname+"/";var la,ma;f.read=function(a,b){la||(la=require("fs"));ma||(ma=require("path"));a=ma.normalize(a);a=la.readFileSync(a);return b?a:a.toString()};f.readBinary=function(a){a=f.read(a,!0);a.buffer||(a=new Uint8Array(a));assert(a.buffer);return a};1<process.argv.length&&(f.thisProgram=process.argv[1].replace(/\\/g,"/"));f.arguments=process.argv.slice(2);process.on("uncaughtException",function(a){if(!(a instanceof na))throw a;});process.on("unhandledRejection",n);f.quit=function(a){process.exit(a)};
f.inspect=function(){return"[Emscripten Module object]"}}else if(ia)"undefined"!=typeof read&&(f.read=function(a){return read(a)}),f.readBinary=function(a){if("function"===typeof readbuffer)return new Uint8Array(readbuffer(a));a=read(a,"binary");assert("object"===typeof a);return a},"undefined"!=typeof scriptArgs?f.arguments=scriptArgs:"undefined"!=typeof arguments&&(f.arguments=arguments),"function"===typeof quit&&(f.quit=function(a){quit(a)});else if(ea||fa)fa?ja=self.location.href:document.currentScript&&
(ja=document.currentScript.src),_scriptDir&&(ja=_scriptDir),0!==ja.indexOf("blob:")?ja=ja.substr(0,ja.lastIndexOf("/")+1):ja="",f.read=function(a){var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},fa&&(f.readBinary=function(a){var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),f.readAsync=function(a,b,c){var d=new XMLHttpRequest;d.open("GET",a,!0);d.responseType="arraybuffer";d.onload=function(){200==
d.status||0==d.status&&d.response?b(d.response):c()};d.onerror=c;d.send(null)},f.setWindowTitle=function(a){document.title=a};var oa=f.print||("undefined"!==typeof console?console.log.bind(console):"undefined"!==typeof print?print:null),q=f.printErr||("undefined"!==typeof printErr?printErr:"undefined"!==typeof console&&console.warn.bind(console)||oa);for(da in ca)ca.hasOwnProperty(da)&&(f[da]=ca[da]);ca=void 0;function pa(a){var b=qa;qa=qa+a+15&-16;return b}
function ra(a){var b=t[sa>>2];a=b+a+15&-16;t[sa>>2]=a;return a>=u&&!ta()?(t[sa>>2]=b,0):b}function ua(a){var b;b||(b=16);return Math.ceil(a/b)*b}function va(a){wa||(wa={});wa[a]||(wa[a]=1,q(a))}var wa,xa={"f64-rem":function(a,b){return a%b},"debugger":function(){debugger}},ya=[],tempRet0=0,za=!1;function assert(a,b){a||n("Assertion failed: "+b)}
function w(a){if("number"===typeof a){var b=!0;var c=a}else b=!1,c=a.length;var d=("function"===typeof Aa?Aa:pa)(Math.max(c,1));if(b){a=d;assert(0==(d&3));for(b=d+(c&-4);a<b;a+=4)t[a>>2]=0;for(b=d+c;a<b;)x[a++>>0]=0;return d}a.subarray||a.slice?h.set(a,d):h.set(new Uint8Array(a),d);return d}
function y(a,b){if(0===b||!a)return"";for(var c=0,d,e=0;;){d=h[a+e>>0];c|=d;if(0==d&&!b)break;e++;if(b&&e==b)break}b||(b=e);d="";if(128>c){for(;0<b;)c=String.fromCharCode.apply(String,h.subarray(a,a+Math.min(b,1024))),d=d?d+c:c,a+=1024,b-=1024;return d}return Ba(h,a)}var Ca="undefined"!==typeof TextDecoder?new TextDecoder("utf8"):void 0;
function Ba(a,b){for(var c=b;a[c];)++c;if(16<c-b&&a.subarray&&Ca)return Ca.decode(a.subarray(b,c));for(c="";;){var d=a[b++];if(!d)return c;if(d&128){var e=a[b++]&63;if(192==(d&224))c+=String.fromCharCode((d&31)<<6|e);else{var k=a[b++]&63;if(224==(d&240))d=(d&15)<<12|e<<6|k;else{var l=a[b++]&63;if(240==(d&248))d=(d&7)<<18|e<<12|k<<6|l;else{var m=a[b++]&63;if(248==(d&252))d=(d&3)<<24|e<<18|k<<12|l<<6|m;else{var p=a[b++]&63;d=(d&1)<<30|e<<24|k<<18|l<<12|m<<6|p}}}65536>d?c+=String.fromCharCode(d):(d-=
65536,c+=String.fromCharCode(55296|d>>10,56320|d&1023))}}else c+=String.fromCharCode(d)}}
function g(a,b,c,d){if(!(0<d))return 0;var e=c;d=c+d-1;for(var k=0;k<a.length;++k){var l=a.charCodeAt(k);if(55296<=l&&57343>=l){var m=a.charCodeAt(++k);l=65536+((l&1023)<<10)|m&1023}if(127>=l){if(c>=d)break;b[c++]=l}else{if(2047>=l){if(c+1>=d)break;b[c++]=192|l>>6}else{if(65535>=l){if(c+2>=d)break;b[c++]=224|l>>12}else{if(2097151>=l){if(c+3>=d)break;b[c++]=240|l>>18}else{if(67108863>=l){if(c+4>=d)break;b[c++]=248|l>>24}else{if(c+5>=d)break;b[c++]=252|l>>30;b[c++]=128|l>>24&63}b[c++]=128|l>>18&63}b[c++]=
128|l>>12&63}b[c++]=128|l>>6&63}b[c++]=128|l&63}}b[c]=0;return c-e}function ba(a){for(var b=0,c=0;c<a.length;++c){var d=a.charCodeAt(c);55296<=d&&57343>=d&&(d=65536+((d&1023)<<10)|a.charCodeAt(++c)&1023);127>=d?++b:b=2047>=d?b+2:65535>=d?b+3:2097151>=d?b+4:67108863>=d?b+5:b+6}return b}"undefined"!==typeof TextDecoder&&new TextDecoder("utf-16le");var Da=65536,Ea=16777216,Ga=16777216;function Ha(a,b){0<a%b&&(a+=b-a%b);return a}var buffer,x,h,Ia,Ja,t,z,D,Ka;
function La(){f.HEAP8=x=new Int8Array(buffer);f.HEAP16=Ia=new Int16Array(buffer);f.HEAP32=t=new Int32Array(buffer);f.HEAPU8=h=new Uint8Array(buffer);f.HEAPU16=Ja=new Uint16Array(buffer);f.HEAPU32=z=new Uint32Array(buffer);f.HEAPF32=D=new Float32Array(buffer);f.HEAPF64=Ka=new Float64Array(buffer)}var Ma,qa,Na,Oa,Pa,Qa,Ra,sa;Ma=qa=Oa=Pa=Qa=Ra=sa=0;Na=!1;f.reallocBuffer||(f.reallocBuffer=function(a){try{var b=x;var c=new ArrayBuffer(a);(new Int8Array(c)).set(b)}catch(d){return!1}return Sa(c)?c:!1});
function ta(){var a=f.usingWasm?Da:Ea,b=2147483648-a;if(t[sa>>2]>b)return!1;var c=u;for(u=Math.max(u,Ga);u<t[sa>>2];)536870912>=u?u=Ha(2*u,a):u=Math.min(Ha((3*u+2147483648)/4,a),b);a=f.reallocBuffer(u);if(!a||a.byteLength!=u)return u=c,!1;f.buffer=buffer=a;La();return!0}var Ta;try{Ta=Function.prototype.call.bind(Object.getOwnPropertyDescriptor(ArrayBuffer.prototype,"byteLength").get),Ta(new ArrayBuffer(4))}catch(a){Ta=function(b){return b.byteLength}}
var Ua=f.TOTAL_STACK||5242880,u=f.TOTAL_MEMORY||134217728;u<Ua&&q("TOTAL_MEMORY should be larger than TOTAL_STACK, was "+u+"! (TOTAL_STACK="+Ua+")");f.buffer?buffer=f.buffer:("object"===typeof WebAssembly&&"function"===typeof WebAssembly.Memory?(f.wasmMemory=new WebAssembly.Memory({initial:u/Da}),buffer=f.wasmMemory.buffer):buffer=new ArrayBuffer(u),f.buffer=buffer);La();
function Va(a){for(;0<a.length;){var b=a.shift();if("function"==typeof b)b();else{var c=b.Wt;"number"===typeof c?void 0===b.Vt?f.dynCall_v(c):f.dynCall_vi(c,b.Vt):c(void 0===b.Vt?null:b.Vt)}}}var Wa=[],Xa=[],Ya=[],Za=[],$a=[],ab=!1;function bb(){var a=f.preRun.shift();Wa.unshift(a)}var cb=Math.abs,db=Math.ceil,eb=Math.floor,fb=Math.min,gb=Math.trunc,hb=0,ib=null,jb=null;f.preloadedImages={};f.preloadedAudios={};
function kb(a){return String.prototype.startsWith?a.startsWith("data:application/octet-stream;base64,"):0===a.indexOf("data:application/octet-stream;base64,")}
(function(){function a(){try{if(f.wasmBinary)return new Uint8Array(f.wasmBinary);if(f.readBinary)return f.readBinary(e);throw"both async and sync fetching of the wasm failed";}catch(v){n(v)}}function b(){return f.wasmBinary||!ea&&!fa||"function"!==typeof fetch?new Promise(function(b){b(a())}):fetch(e,{credentials:"same-origin"}).then(function(a){if(!a.ok)throw"failed to load wasm binary file at '"+e+"'";return a.arrayBuffer()}).catch(function(){return a()})}function c(a){function c(a){m=a.exports;
if(m.memory){a=m.memory;var b=f.buffer;a.byteLength<b.byteLength&&q("the new buffer in mergeMemory is smaller than the previous one. in native wasm, we should grow memory here");b=new Int8Array(b);(new Int8Array(a)).set(b);f.buffer=buffer=a;La()}f.asm=m;f.usingWasm=!0;hb--;f.monitorRunDependencies&&f.monitorRunDependencies(hb);0==hb&&(null!==ib&&(clearInterval(ib),ib=null),jb&&(a=jb,jb=null,a()))}function d(a){c(a.instance)}function k(a){b().then(function(a){return WebAssembly.instantiate(a,l)}).then(a,
function(a){q("failed to asynchronously prepare wasm: "+a);n(a)})}if("object"!==typeof WebAssembly)return q("no native wasm support detected"),!1;if(!(f.wasmMemory instanceof WebAssembly.Memory))return q("no native wasm Memory in use"),!1;a.memory=f.wasmMemory;l.global={NaN:NaN,Infinity:Infinity};l["global.Math"]=Math;l.env=a;hb++;f.monitorRunDependencies&&f.monitorRunDependencies(hb);if(f.instantiateWasm)try{return f.instantiateWasm(l,c)}catch(J){return q("Module.instantiateWasm callback failed with error: "+
J),!1}f.wasmBinary||"function"!==typeof WebAssembly.instantiateStreaming||kb(e)||"function"!==typeof fetch?k(d):WebAssembly.instantiateStreaming(fetch(e,{credentials:"same-origin"}),l).then(d,function(a){q("wasm streaming compile failed: "+a);q("falling back to ArrayBuffer instantiation");k(d)});return{}}var d="canvaskit.wast",e="canvaskit.wasm",k="canvaskit.temp.asm.js";kb(d)||(d=ka(d));kb(e)||(e=ka(e));kb(k)||(k=ka(k));var l={global:null,env:null,asm2wasm:xa,parent:f},m=null;f.asmPreload=f.asm;
var p=f.reallocBuffer;f.reallocBuffer=function(a){if("asmjs"===r)var b=p(a);else a:{a=Ha(a,f.usingWasm?Da:Ea);var c=f.buffer.byteLength;if(f.usingWasm)try{b=-1!==f.wasmMemory.grow((a-c)/65536)?f.buffer=f.wasmMemory.buffer:null;break a}catch(B){b=null;break a}b=void 0}return b};var r="";f.asm=function(a,b){if(!b.table){a=f.wasmTableSize;void 0===a&&(a=1024);var d=f.wasmMaxTableSize;b.table="object"===typeof WebAssembly&&"function"===typeof WebAssembly.Table?void 0!==d?new WebAssembly.Table({initial:a,
maximum:d,element:"anyfunc"}):new WebAssembly.Table({initial:a,element:"anyfunc"}):Array(a);f.wasmTable=b.table}b.__memory_base||(b.__memory_base=f.STATIC_BASE);b.__table_base||(b.__table_base=0);b=c(b);assert(b,"no binaryen method succeeded.");return b}})();Ma=1024;qa=Ma+546816;Xa.push({Wt:function(){lb()}},{Wt:function(){mb()}},{Wt:function(){nb()}});f.STATIC_BASE=Ma;f.STATIC_BUMP=546816;qa+=16;var E={};
function ob(a){if(ob.Yu){var b=t[a>>2];var c=t[b>>2]}else ob.Yu=!0,E.USER=E.LOGNAME="web_user",E.PATH="/",E.PWD="/",E.HOME="/home/web_user",E.LANG="C.UTF-8",E._=f.thisProgram,c=Na?ab?Aa(1024):ra(1024):pa(1024),b=Na?ab?Aa(256):ra(256):pa(256),t[b>>2]=c,t[a>>2]=b;a=[];var d=0,e;for(e in E)if("string"===typeof E[e]){var k=e+"="+E[e];a.push(k);d+=k.length}if(1024<d)throw Error("Environment size exceeded TOTAL_ENV_SIZE!");for(e=0;e<a.length;e++){d=k=a[e];for(var l=c,m=0;m<d.length;++m)x[l++>>0]=d.charCodeAt(m);
x[l>>0]=0;t[b+4*e>>2]=c;c+=k.length+1}t[b+4*a.length>>2]=0}
var F={Dt:1,tt:2,ox:3,jw:4,Jt:5,Cu:6,Dv:7,Iw:8,st:9,Rv:10,xu:11,zx:11,Au:12,Rt:13,cw:14,Uw:15,yu:16,zu:17,Ax:18,Tt:19,gu:20,St:21,dt:22,Dw:23,Ru:24,xt:25,vx:26,dw:27,Qw:28,Ut:29,lx:30,vw:31,ex:32,$v:33,ix:34,Mw:42,gw:43,Sv:44,mw:45,nw:46,ow:47,uw:48,xx:49,Gw:50,lw:51,Xv:35,Jw:37,Jv:52,Mv:53,Bx:54,Ew:55,Nv:56,Ov:57,Yv:35,Pv:59,Sw:60,Hw:61,sx:62,Rw:63,Nw:64,Ow:65,kx:66,Kw:67,Gv:68,px:69,Tv:70,fx:71,yw:72,aw:73,Lv:74,Zw:76,Kv:77,jx:78,pw:79,qw:80,tw:81,sw:82,rw:83,Tw:38,Bu:39,zw:36,fu:40,$w:95,dx:96,
Wv:104,Fw:105,Hv:97,hx:91,Xw:88,Pw:92,mx:108,Vv:111,Ev:98,Uv:103,Cw:101,Aw:100,tx:110,ew:112,fw:113,iw:115,Iv:114,Zv:89,ww:90,gx:93,nx:94,Fv:99,Bw:102,kw:106,Vw:107,ux:109,yx:87,bw:122,qx:116,Yw:95,Lw:123,hw:84,bx:75,Qv:125,Ww:131,cx:130,rx:86};function pb(a){f.___errno_location&&(t[f.___errno_location()>>2]=a);return a}
var qb={0:"Success",1:"Not super-user",2:"No such file or directory",3:"No such process",4:"Interrupted system call",5:"I/O error",6:"No such device or address",7:"Arg list too long",8:"Exec format error",9:"Bad file number",10:"No children",11:"No more processes",12:"Not enough core",13:"Permission denied",14:"Bad address",15:"Block device required",16:"Mount device busy",17:"File exists",18:"Cross-device link",19:"No such device",20:"Not a directory",21:"Is a directory",22:"Invalid argument",23:"Too many open files in system",
24:"Too many open files",25:"Not a typewriter",26:"Text file busy",27:"File too large",28:"No space left on device",29:"Illegal seek",30:"Read only file system",31:"Too many links",32:"Broken pipe",33:"Math arg out of domain of func",34:"Math result not representable",35:"File locking deadlock error",36:"File or path name too long",37:"No record locks available",38:"Function not implemented",39:"Directory not empty",40:"Too many symbolic links",42:"No message of desired type",43:"Identifier removed",
44:"Channel number out of range",45:"Level 2 not synchronized",46:"Level 3 halted",47:"Level 3 reset",48:"Link number out of range",49:"Protocol driver not attached",50:"No CSI structure available",51:"Level 2 halted",52:"Invalid exchange",53:"Invalid request descriptor",54:"Exchange full",55:"No anode",56:"Invalid request code",57:"Invalid slot",59:"Bad font file fmt",60:"Device not a stream",61:"No data (for no delay io)",62:"Timer expired",63:"Out of streams resources",64:"Machine is not on the network",
65:"Package not installed",66:"The object is remote",67:"The link has been severed",68:"Advertise error",69:"Srmount error",70:"Communication error on send",71:"Protocol error",72:"Multihop attempted",73:"Cross mount point (not really error)",74:"Trying to read unreadable message",75:"Value too large for defined data type",76:"Given log. name not unique",77:"f.d. invalid for this operation",78:"Remote address changed",79:"Can   access a needed shared lib",80:"Accessing a corrupted shared lib",81:".lib section in a.out corrupted",
82:"Attempting to link in too many libs",83:"Attempting to exec a shared library",84:"Illegal byte sequence",86:"Streams pipe error",87:"Too many users",88:"Socket operation on non-socket",89:"Destination address required",90:"Message too long",91:"Protocol wrong type for socket",92:"Protocol not available",93:"Unknown protocol",94:"Socket type not supported",95:"Not supported",96:"Protocol family not supported",97:"Address family not supported by protocol family",98:"Address already in use",99:"Address not available",
100:"Network interface is not configured",101:"Network is unreachable",102:"Connection reset by network",103:"Connection aborted",104:"Connection reset by peer",105:"No buffer space available",106:"Socket is already connected",107:"Socket is not connected",108:"Can't send after socket shutdown",109:"Too many references",110:"Connection timed out",111:"Connection refused",112:"Host is down",113:"Host is unreachable",114:"Socket already connected",115:"Connection already in progress",116:"Stale file handle",
122:"Quota exceeded",123:"No medium (in tape drive)",125:"Operation canceled",130:"Previous owner died",131:"State not recoverable"};function rb(a,b){for(var c=0,d=a.length-1;0<=d;d--){var e=a[d];"."===e?a.splice(d,1):".."===e?(a.splice(d,1),c++):c&&(a.splice(d,1),c--)}if(b)for(;c;c--)a.unshift("..");return a}function sb(a){var b="/"===a.charAt(0),c="/"===a.substr(-1);(a=rb(a.split("/").filter(function(a){return!!a}),!b).join("/"))||b||(a=".");a&&c&&(a+="/");return(b?"/":"")+a}
function tb(a){var b=/^(\/?|)([\s\S]*?)((?:\.{1,2}|[^\/]+?|)(\.[^.\/]*|))(?:[\/]*)$/.exec(a).slice(1);a=b[0];b=b[1];if(!a&&!b)return".";b&&(b=b.substr(0,b.length-1));return a+b}function ub(a){if("/"===a)return"/";var b=a.lastIndexOf("/");return-1===b?a:a.substr(b+1)}
function vb(){for(var a="",b=!1,c=arguments.length-1;-1<=c&&!b;c--){b=0<=c?arguments[c]:"/";if("string"!==typeof b)throw new TypeError("Arguments to path.resolve must be strings");if(!b)return"";a=b+"/"+a;b="/"===b.charAt(0)}a=rb(a.split("/").filter(function(a){return!!a}),!b).join("/");return(b?"/":"")+a||"."}var wb=[];function xb(a,b){wb[a]={input:[],output:[],Bt:b};yb(a,zb)}
var zb={open:function(a){var b=wb[a.node.rdev];if(!b)throw new G(F.Tt);a.tty=b;a.seekable=!1},close:function(a){a.tty.Bt.flush(a.tty)},flush:function(a){a.tty.Bt.flush(a.tty)},read:function(a,b,c,d){if(!a.tty||!a.tty.Bt.Lu)throw new G(F.Cu);for(var e=0,k=0;k<d;k++){try{var l=a.tty.Bt.Lu(a.tty)}catch(m){throw new G(F.Jt);}if(void 0===l&&0===e)throw new G(F.xu);if(null===l||void 0===l)break;e++;b[c+k]=l}e&&(a.node.timestamp=Date.now());return e},write:function(a,b,c,d){if(!a.tty||!a.tty.Bt.ru)throw new G(F.Cu);
var e=0;try{if(0===c&&0===d)a.tty.Bt.flush(a.tty);else for(;e<d;)a.tty.Bt.ru(a.tty,b[c+e]),e++}catch(k){throw new G(F.Jt);}d&&(a.node.timestamp=Date.now());return e}},Ab={Lu:function(a){if(!a.input.length){var b=null;if(ha){var c=new Buffer(256),d=0,e=process.stdin.fd;if("win32"!=process.platform){var k=!1;try{e=fs.openSync("/dev/stdin","r"),k=!0}catch(l){}}try{d=fs.readSync(e,c,0,256,null)}catch(l){if(-1!=l.toString().indexOf("EOF"))d=0;else throw l;}k&&fs.closeSync(e);0<d?b=c.slice(0,d).toString("utf-8"):
b=null}else"undefined"!=typeof window&&"function"==typeof window.prompt?(b=window.prompt("Input: "),null!==b&&(b+="\n")):"function"==typeof readline&&(b=readline(),null!==b&&(b+="\n"));if(!b)return null;a.input=H(b,!0)}return a.input.shift()},ru:function(a,b){null===b||10===b?(oa(Ba(a.output,0)),a.output=[]):0!=b&&a.output.push(b)},flush:function(a){a.output&&0<a.output.length&&(oa(Ba(a.output,0)),a.output=[])}},Bb={ru:function(a,b){null===b||10===b?(q(Ba(a.output,0)),a.output=[]):0!=b&&a.output.push(b)},
flush:function(a){a.output&&0<a.output.length&&(q(Ba(a.output,0)),a.output=[])}},I={qt:null,ut:function(){return I.createNode(null,"/",16895,0)},createNode:function(a,b,c,d){if(24576===(c&61440)||4096===(c&61440))throw new G(F.Dt);I.qt||(I.qt={dir:{node:{nt:I.Ys.nt,rt:I.Ys.rt,lookup:I.Ys.lookup,$t:I.Ys.$t,rename:I.Ys.rename,unlink:I.Ys.unlink,rmdir:I.Ys.rmdir,readdir:I.Ys.readdir,symlink:I.Ys.symlink},stream:{At:I.Xs.At}},file:{node:{nt:I.Ys.nt,rt:I.Ys.rt},stream:{At:I.Xs.At,read:I.Xs.read,write:I.Xs.write,
Fu:I.Xs.Fu,au:I.Xs.au,cu:I.Xs.cu}},link:{node:{nt:I.Ys.nt,rt:I.Ys.rt,readlink:I.Ys.readlink},stream:{}},Gu:{node:{nt:I.Ys.nt,rt:I.Ys.rt},stream:Cb}});c=Db(a,b,c,d);16384===(c.mode&61440)?(c.Ys=I.qt.dir.node,c.Xs=I.qt.dir.stream,c.Ws={}):32768===(c.mode&61440)?(c.Ys=I.qt.file.node,c.Xs=I.qt.file.stream,c.at=0,c.Ws=null):40960===(c.mode&61440)?(c.Ys=I.qt.link.node,c.Xs=I.qt.link.stream):8192===(c.mode&61440)&&(c.Ys=I.qt.Gu.node,c.Xs=I.qt.Gu.stream);c.timestamp=Date.now();a&&(a.Ws[b]=c);return c},fv:function(a){if(a.Ws&&
a.Ws.subarray){for(var b=[],c=0;c<a.at;++c)b.push(a.Ws[c]);return b}return a.Ws},Jx:function(a){return a.Ws?a.Ws.subarray?a.Ws.subarray(0,a.at):new Uint8Array(a.Ws):new Uint8Array},Iu:function(a,b){a.Ws&&a.Ws.subarray&&b>a.Ws.length&&(a.Ws=I.fv(a),a.at=a.Ws.length);if(!a.Ws||a.Ws.subarray){var c=a.Ws?a.Ws.length:0;c>=b||(b=Math.max(b,c*(1048576>c?2:1.125)|0),0!=c&&(b=Math.max(b,256)),c=a.Ws,a.Ws=new Uint8Array(b),0<a.at&&a.Ws.set(c.subarray(0,a.at),0))}else for(!a.Ws&&0<b&&(a.Ws=[]);a.Ws.length<b;)a.Ws.push(0)},
uv:function(a,b){if(a.at!=b)if(0==b)a.Ws=null,a.at=0;else{if(!a.Ws||a.Ws.subarray){var c=a.Ws;a.Ws=new Uint8Array(new ArrayBuffer(b));c&&a.Ws.set(c.subarray(0,Math.min(b,a.at)))}else if(a.Ws||(a.Ws=[]),a.Ws.length>b)a.Ws.length=b;else for(;a.Ws.length<b;)a.Ws.push(0);a.at=b}},Ys:{nt:function(a){var b={};b.dev=8192===(a.mode&61440)?a.id:1;b.ino=a.id;b.mode=a.mode;b.nlink=1;b.uid=0;b.gid=0;b.rdev=a.rdev;16384===(a.mode&61440)?b.size=4096:32768===(a.mode&61440)?b.size=a.at:40960===(a.mode&61440)?b.size=
a.link.length:b.size=0;b.atime=new Date(a.timestamp);b.mtime=new Date(a.timestamp);b.ctime=new Date(a.timestamp);b.Xu=4096;b.blocks=Math.ceil(b.size/b.Xu);return b},rt:function(a,b){void 0!==b.mode&&(a.mode=b.mode);void 0!==b.timestamp&&(a.timestamp=b.timestamp);void 0!==b.size&&I.uv(a,b.size)},lookup:function(){throw Eb[F.tt];},$t:function(a,b,c,d){return I.createNode(a,b,c,d)},rename:function(a,b,c){if(16384===(a.mode&61440)){try{var d=Fb(b,c)}catch(k){}if(d)for(var e in d.Ws)throw new G(F.Bu);
}delete a.parent.Ws[a.name];a.name=c;b.Ws[c]=a;a.parent=b},unlink:function(a,b){delete a.Ws[b]},rmdir:function(a,b){var c=Fb(a,b),d;for(d in c.Ws)throw new G(F.Bu);delete a.Ws[b]},readdir:function(a){var b=[".",".."],c;for(c in a.Ws)a.Ws.hasOwnProperty(c)&&b.push(c);return b},symlink:function(a,b,c){a=I.createNode(a,b,41471,0);a.link=c;return a},readlink:function(a){if(40960!==(a.mode&61440))throw new G(F.dt);return a.link}},Xs:{read:function(a,b,c,d,e){var k=a.node.Ws;if(e>=a.node.at)return 0;a=
Math.min(a.node.at-e,d);assert(0<=a);if(8<a&&k.subarray)b.set(k.subarray(e,e+a),c);else for(d=0;d<a;d++)b[c+d]=k[e+d];return a},write:function(a,b,c,d,e,k){k=!1;if(!d)return 0;a=a.node;a.timestamp=Date.now();if(b.subarray&&(!a.Ws||a.Ws.subarray)){if(k)return a.Ws=b.subarray(c,c+d),a.at=d;if(0===a.at&&0===e)return a.Ws=new Uint8Array(b.subarray(c,c+d)),a.at=d;if(e+d<=a.at)return a.Ws.set(b.subarray(c,c+d),e),d}I.Iu(a,e+d);if(a.Ws.subarray&&b.subarray)a.Ws.set(b.subarray(c,c+d),e);else for(k=0;k<d;k++)a.Ws[e+
k]=b[c+k];a.at=Math.max(a.at,e+d);return d},At:function(a,b,c){1===c?b+=a.position:2===c&&32768===(a.node.mode&61440)&&(b+=a.node.at);if(0>b)throw new G(F.dt);return b},Fu:function(a,b,c){I.Iu(a.node,b+c);a.node.at=Math.max(a.node.at,b+c)},au:function(a,b,c,d,e,k,l){if(32768!==(a.node.mode&61440))throw new G(F.Tt);c=a.node.Ws;if(l&2||c.buffer!==b&&c.buffer!==b.buffer){if(0<e||e+d<a.node.at)c.subarray?c=c.subarray(e,e+d):c=Array.prototype.slice.call(c,e,e+d);a=!0;d=Aa(d);if(!d)throw new G(F.Au);b.set(c,
d)}else a=!1,d=c.byteOffset;return{$s:d,ju:a}},cu:function(a,b,c,d,e){if(32768!==(a.node.mode&61440))throw new G(F.Tt);if(e&2)return 0;I.Xs.write(a,b,0,d,c,!1);return 0}}};qa+=16;qa+=16;qa+=16;var Gb=null,Hb={},Ib=[],Jb=1,Kb=null,Lb=!0,Mb={},G=null,Eb={};
function Nb(a,b){a=vb("/",a);b=b||{};if(!a)return{path:"",node:null};var c={Ku:!0,su:0},d;for(d in c)void 0===b[d]&&(b[d]=c[d]);if(8<b.su)throw new G(F.fu);a=rb(a.split("/").filter(function(a){return!!a}),!1);var e=Gb;c="/";for(d=0;d<a.length;d++){var k=d===a.length-1;if(k&&b.parent)break;e=Fb(e,a[d]);c=sb(c+"/"+a[d]);e.bu&&(!k||k&&b.Ku)&&(e=e.bu.root);if(!k||b.Nt)for(k=0;40960===(e.mode&61440);)if(e=Ob(c),c=vb(tb(c),e),e=Nb(c,{su:b.su}).node,40<k++)throw new G(F.fu);}return{path:c,node:e}}
function Pb(a){for(var b;;){if(a===a.parent)return a=a.ut.Mu,b?"/"!==a[a.length-1]?a+"/"+b:a+b:a;b=b?a.name+"/"+b:a.name;a=a.parent}}function Qb(a,b){for(var c=0,d=0;d<b.length;d++)c=(c<<5)-c+b.charCodeAt(d)|0;return(a+c>>>0)%Kb.length}function Rb(a){var b=Qb(a.parent.id,a.name);a.pv=Kb[b];Kb[b]=a}function Fb(a,b){var c;if(c=(c=Sb(a,"x"))?c:a.Ys.lookup?0:F.Rt)throw new G(c,a);for(c=Kb[Qb(a.id,b)];c;c=c.pv){var d=c.name;if(c.parent.id===a.id&&d===b)return c}return a.Ys.lookup(a,b)}
function Db(a,b,c,d){Tb||(Tb=function(a,b,c,d){a||(a=this);this.parent=a;this.ut=a.ut;this.bu=null;this.id=Jb++;this.name=b;this.mode=c;this.Ys={};this.Xs={};this.rdev=d},Tb.prototype={},Object.defineProperties(Tb.prototype,{read:{get:function(){return 365===(this.mode&365)},set:function(a){a?this.mode|=365:this.mode&=-366}},write:{get:function(){return 146===(this.mode&146)},set:function(a){a?this.mode|=146:this.mode&=-147}},pu:{get:function(){return 16384===(this.mode&61440)}},ou:{get:function(){return 8192===
(this.mode&61440)}}}));a=new Tb(a,b,c,d);Rb(a);return a}var Ub={r:0,rs:1052672,"r+":2,w:577,wx:705,xw:705,"w+":578,"wx+":706,"xw+":706,a:1089,ax:1217,xa:1217,"a+":1090,"ax+":1218,"xa+":1218};function Vb(a){var b=["r","w","rw"][a&3];a&512&&(b+="w");return b}function Sb(a,b){if(Lb)return 0;if(-1===b.indexOf("r")||a.mode&292){if(-1!==b.indexOf("w")&&!(a.mode&146)||-1!==b.indexOf("x")&&!(a.mode&73))return F.Rt}else return F.Rt;return 0}
function Wb(a,b){try{return Fb(a,b),F.zu}catch(c){}return Sb(a,"wx")}function Xb(a){var b=4096;for(a=a||0;a<=b;a++)if(!Ib[a])return a;throw new G(F.Ru);}function Yb(a,b){Zb||(Zb=function(){},Zb.prototype={},Object.defineProperties(Zb.prototype,{object:{get:function(){return this.node},set:function(a){this.node=a}}}));var c=new Zb,d;for(d in a)c[d]=a[d];a=c;b=Xb(b);a.fd=b;return Ib[b]=a}var Cb={open:function(a){a.Xs=Hb[a.node.rdev].Xs;a.Xs.open&&a.Xs.open(a)},At:function(){throw new G(F.Ut);}};
function yb(a,b){Hb[a]={Xs:b}}function $b(a,b){var c="/"===b,d=!b;if(c&&Gb)throw new G(F.yu);if(!c&&!d){var e=Nb(b,{Ku:!1});b=e.path;e=e.node;if(e.bu)throw new G(F.yu);if(16384!==(e.mode&61440))throw new G(F.gu);}b={type:a,Mx:{},Mu:b,ov:[]};a=a.ut(b);a.ut=b;b.root=a;c?Gb=a:e&&(e.bu=b,e.ut&&e.ut.ov.push(b))}function ac(a,b,c){var d=Nb(a,{parent:!0}).node;a=ub(a);if(!a||"."===a||".."===a)throw new G(F.dt);var e=Wb(d,a);if(e)throw new G(e);if(!d.Ys.$t)throw new G(F.Dt);return d.Ys.$t(d,a,b,c)}
function bc(a){ac(a,16895,0)}function cc(a,b,c){"undefined"===typeof c&&(c=b,b=438);ac(a,b|8192,c)}function dc(a,b){if(!vb(a))throw new G(F.tt);var c=Nb(b,{parent:!0}).node;if(!c)throw new G(F.tt);b=ub(b);var d=Wb(c,b);if(d)throw new G(d);if(!c.Ys.symlink)throw new G(F.Dt);c.Ys.symlink(c,b,a)}function Ob(a){a=Nb(a).node;if(!a)throw new G(F.tt);if(!a.Ys.readlink)throw new G(F.dt);return vb(Pb(a.parent),a.Ys.readlink(a))}
function ec(a){a=Nb(a,{Nt:!0}).node;if(!a)throw new G(F.tt);if(!a.Ys.nt)throw new G(F.Dt);return a.Ys.nt(a)}
function fc(a,b,c,d){if(""===a)throw new G(F.tt);if("string"===typeof b){var e=Ub[b];if("undefined"===typeof e)throw Error("Unknown file open mode: "+b);b=e}c=b&64?("undefined"===typeof c?438:c)&4095|32768:0;if("object"===typeof a)var k=a;else{a=sb(a);try{k=Nb(a,{Nt:!(b&131072)}).node}catch(m){}}e=!1;if(b&64)if(k){if(b&128)throw new G(F.zu);}else k=ac(a,c,0),e=!0;if(!k)throw new G(F.tt);8192===(k.mode&61440)&&(b&=-513);if(b&65536&&16384!==(k.mode&61440))throw new G(F.gu);if(!e&&(c=k?40960===(k.mode&
61440)?F.fu:16384===(k.mode&61440)&&("r"!==Vb(b)||b&512)?F.St:Sb(k,Vb(b)):F.tt))throw new G(c);if(b&512){c=k;var l;"string"===typeof c?l=Nb(c,{Nt:!0}).node:l=c;if(!l.Ys.rt)throw new G(F.Dt);if(16384===(l.mode&61440))throw new G(F.St);if(32768!==(l.mode&61440))throw new G(F.dt);if(c=Sb(l,"w"))throw new G(c);l.Ys.rt(l,{size:0,timestamp:Date.now()})}b&=-641;d=Yb({node:k,path:Pb(k),flags:b,seekable:!0,position:0,Xs:k.Xs,Bv:[],error:!1},d);d.Xs.open&&d.Xs.open(d);!f.logReadFiles||b&1||(hc||(hc={}),a in
hc||(hc[a]=1,console.log("FS.trackingDelegate error on read file: "+a)));try{Mb.onOpenFile&&(k=0,1!==(b&2097155)&&(k|=1),0!==(b&2097155)&&(k|=2),Mb.onOpenFile(a,k))}catch(m){console.log("FS.trackingDelegate['onOpenFile']('"+a+"', flags) threw an exception: "+m.message)}return d}function ic(a){if(null===a.fd)throw new G(F.st);a.ku&&(a.ku=null);try{a.Xs.close&&a.Xs.close(a)}catch(b){throw b;}finally{Ib[a.fd]=null}a.fd=null}
function jc(a,b,c){if(null===a.fd)throw new G(F.st);if(!a.seekable||!a.Xs.At)throw new G(F.Ut);a.position=a.Xs.At(a,b,c);a.Bv=[]}function kc(a,b,c,d,e){if(0>d||0>e)throw new G(F.dt);if(null===a.fd)throw new G(F.st);if(1===(a.flags&2097155))throw new G(F.st);if(16384===(a.node.mode&61440))throw new G(F.St);if(!a.Xs.read)throw new G(F.dt);var k="undefined"!==typeof e;if(!k)e=a.position;else if(!a.seekable)throw new G(F.Ut);b=a.Xs.read(a,b,c,d,e);k||(a.position+=b);return b}
function lc(a){var b={encoding:"binary"};b=b||{};b.flags=b.flags||"r";b.encoding=b.encoding||"binary";if("utf8"!==b.encoding&&"binary"!==b.encoding)throw Error('Invalid encoding type "'+b.encoding+'"');var c,d=fc(a,b.flags);a=ec(a).size;var e=new Uint8Array(a);kc(d,e,0,a,0);"utf8"===b.encoding?c=Ba(e,0):"binary"===b.encoding&&(c=e);ic(d);return c}
function mc(){G||(G=function(a,b){this.node=b;this.vv=function(a){this.ht=a;for(var b in F)if(F[b]===a){this.code=b;break}};this.vv(a);this.message=qb[a];this.stack&&Object.defineProperty(this,"stack",{value:Error().stack,writable:!0})},G.prototype=Error(),G.prototype.constructor=G,[F.tt].forEach(function(a){Eb[a]=new G(a);Eb[a].stack="<generic error, no stack>"}))}var nc;function oc(a,b){var c=0;a&&(c|=365);b&&(c|=146);return c}
function pc(a){try{var b=Nb(a,{Nt:!0});a=b.path}catch(k){}var c=!1,d=0,e=null;try{b=Nb(a,{parent:!0}),b=Nb(a,{Nt:!0}),c=!0,e=b.node}catch(k){d=k.ht}if(c)return e;pb(d);return null}
function qc(a,b,c){a=sb("/dev/"+a);var d=oc(!!b,!!c);rc||(rc=64);var e=rc++<<8|0;yb(e,{open:function(a){a.seekable=!1},close:function(){c&&c.buffer&&c.buffer.length&&c(10)},read:function(a,c,d,e){for(var k=0,l=0;l<e;l++){try{var m=b()}catch(A){throw new G(F.Jt);}if(void 0===m&&0===k)throw new G(F.xu);if(null===m||void 0===m)break;k++;c[d+l]=m}k&&(a.node.timestamp=Date.now());return k},write:function(a,b,d,e){for(var k=0;k<e;k++)try{c(b[d+k])}catch(v){throw new G(F.Jt);}e&&(a.node.timestamp=Date.now());
return k}});cc(a,d,e)}var rc;function sc(a){if(!(a.ou||a.pu||a.link||a.Ws)){var b=!0;if("undefined"!==typeof XMLHttpRequest)throw Error("Lazy loading should have been performed (contents set) in createLazyFile, but it was not. Lazy loading only works in web workers. Use --embed-file or --preload-file in emcc on the main thread.");if(f.read)try{a.Ws=H(f.read(a.url),!0),a.at=a.Ws.length}catch(c){b=!1}else throw Error("Cannot load without read() or XMLHttpRequest.");b||pb(F.Jt)}}
var FS={},Tb,Zb,hc,tc={};function uc(a,b){try{var c=ec(a)}catch(d){if(d&&d.node&&sb(a)!==sb(Pb(d.node)))return-F.gu;throw d;}t[b>>2]=c.dev;t[b+4>>2]=0;t[b+8>>2]=c.ino;t[b+12>>2]=c.mode;t[b+16>>2]=c.nlink;t[b+20>>2]=c.uid;t[b+24>>2]=c.gid;t[b+28>>2]=c.rdev;t[b+32>>2]=0;t[b+36>>2]=c.size;t[b+40>>2]=4096;t[b+44>>2]=c.blocks;t[b+48>>2]=c.atime.getTime()/1E3|0;t[b+52>>2]=0;t[b+56>>2]=c.mtime.getTime()/1E3|0;t[b+60>>2]=0;t[b+64>>2]=c.ctime.getTime()/1E3|0;t[b+68>>2]=0;t[b+72>>2]=c.ino;return 0}var K=0;
function L(){K+=4;return t[K-4>>2]}function vc(){var a=Ib[L()];if(!a)throw new G(F.st);return a}var wc={};function xc(a){for(;a.length;){var b=a.pop();a.pop()(b)}}function yc(a){return this.fromWireType(z[a>>2])}var zc={},Ac={},Bc={};function Cc(a){if(void 0===a)return"_unknown";a=a.replace(/[^a-zA-Z0-9_]/g,"$");var b=a.charCodeAt(0);return 48<=b&&57>=b?"_"+a:a}
function Dc(a,b){a=Cc(a);return(new Function("body","return function "+a+'() {\n    "use strict";    return body.apply(this, arguments);\n};\n'))(b)}function Ec(a){var b=Error,c=Dc(a,function(b){this.name=a;this.message=b;b=Error(b).stack;void 0!==b&&(this.stack=this.toString()+"\n"+b.replace(/^Error(:[^\n]*)?\n/,""))});c.prototype=Object.create(b.prototype);c.prototype.constructor=c;c.prototype.toString=function(){return void 0===this.message?this.name:this.name+": "+this.message};return c}
var Fc=void 0;function Gc(a){throw new Fc(a);}function Hc(a,b,c){function d(b){b=c(b);b.length!==a.length&&Gc("Mismatched type converter count");for(var d=0;d<a.length;++d)Ic(a[d],b[d])}a.forEach(function(a){Bc[a]=b});var e=Array(b.length),k=[],l=0;b.forEach(function(a,b){Ac.hasOwnProperty(a)?e[b]=Ac[a]:(k.push(a),zc.hasOwnProperty(a)||(zc[a]=[]),zc[a].push(function(){e[b]=Ac[a];++l;l===k.length&&d(e)}))});0===k.length&&d(e)}var Jc={};
function Kc(a){switch(a){case 1:return 0;case 2:return 1;case 4:return 2;case 8:return 3;default:throw new TypeError("Unknown type size: "+a);}}var Lc=void 0;function M(a){for(var b="";h[a];)b+=Lc[h[a++]];return b}var Mc=void 0;function N(a){throw new Mc(a);}
function Ic(a,b,c){c=c||{};if(!("argPackAdvance"in b))throw new TypeError("registerType registeredInstance requires argPackAdvance");var d=b.name;a||N('type "'+d+'" must have a positive integer typeid pointer');if(Ac.hasOwnProperty(a)){if(c.iv)return;N("Cannot register type '"+d+"' twice")}Ac[a]=b;delete Bc[a];zc.hasOwnProperty(a)&&(b=zc[a],delete zc[a],b.forEach(function(a){a()}))}function Nc(a){N(a.Vs.ct.Zs.name+" instance already deleted")}var Oc=void 0,Pc=[];
function Qc(){for(;Pc.length;){var a=Pc.pop();a.Vs.Gt=!1;a["delete"]()}}function Rc(){}var Sc={};function Tc(a,b,c){if(void 0===a[b].et){var d=a[b];a[b]=function(){a[b].et.hasOwnProperty(arguments.length)||N("Function '"+c+"' called with an invalid number of arguments ("+arguments.length+") - expects one of ("+a[b].et+")!");return a[b].et[arguments.length].apply(this,arguments)};a[b].et=[];a[b].et[d.Ft]=d}}
function Uc(a,b,c){f.hasOwnProperty(a)?((void 0===c||void 0!==f[a].et&&void 0!==f[a].et[c])&&N("Cannot register public name '"+a+"' twice"),Tc(f,a,a),f.hasOwnProperty(c)&&N("Cannot register multiple overloads of a function with the same number of arguments ("+c+")!"),f[a].et[c]=b):(f[a]=b,void 0!==c&&(f[a].Lx=c))}function Vc(a,b,c,d,e,k,l,m){this.name=a;this.constructor=b;this.Ht=c;this.kt=d;this.mt=e;this.ev=k;this.Qt=l;this.bv=m;this.rv=[]}
function Wc(a,b,c){for(;b!==c;)b.Qt||N("Expected null or instance of "+c.name+", got an instance of "+b.name),a=b.Qt(a),b=b.mt;return a}function Xc(a,b){if(null===b)return this.qu&&N("null is not a valid "+this.name),0;b.Vs||N('Cannot pass "'+Yc(b)+'" as a '+this.name);b.Vs.$s||N("Cannot pass deleted object as a pointer of type "+this.name);return Wc(b.Vs.$s,b.Vs.ct.Zs,this.Zs)}
function Zc(a,b){if(null===b){this.qu&&N("null is not a valid "+this.name);if(this.Yt){var c=this.Pt();null!==a&&a.push(this.kt,c);return c}return 0}b.Vs||N('Cannot pass "'+Yc(b)+'" as a '+this.name);b.Vs.$s||N("Cannot pass deleted object as a pointer of type "+this.name);!this.Xt&&b.Vs.ct.Xt&&N("Cannot convert argument of type "+(b.Vs.lt?b.Vs.lt.name:b.Vs.ct.name)+" to parameter type "+this.name);c=Wc(b.Vs.$s,b.Vs.ct.Zs,this.Zs);if(this.Yt)switch(void 0===b.Vs.jt&&N("Passing raw pointer to smart pointer is illegal"),
this.wv){case 0:b.Vs.lt===this?c=b.Vs.jt:N("Cannot convert argument of type "+(b.Vs.lt?b.Vs.lt.name:b.Vs.ct.name)+" to parameter type "+this.name);break;case 1:c=b.Vs.jt;break;case 2:if(b.Vs.lt===this)c=b.Vs.jt;else{var d=b.clone();c=this.tv(c,$c(function(){d["delete"]()}));null!==a&&a.push(this.kt,c)}break;default:N("Unsupporting sharing policy")}return c}
function ad(a,b){if(null===b)return this.qu&&N("null is not a valid "+this.name),0;b.Vs||N('Cannot pass "'+Yc(b)+'" as a '+this.name);b.Vs.$s||N("Cannot pass deleted object as a pointer of type "+this.name);b.Vs.ct.Xt&&N("Cannot convert argument of type "+b.Vs.ct.name+" to parameter type "+this.name);return Wc(b.Vs.$s,b.Vs.ct.Zs,this.Zs)}function bd(a,b,c){if(b===c)return a;if(void 0===c.mt)return null;a=bd(a,b,c.mt);return null===a?null:c.bv(a)}var cd={};
function ed(a,b){for(void 0===b&&N("ptr should not be undefined");a.mt;)b=a.Qt(b),a=a.mt;return cd[b]}function fd(a,b){b.ct&&b.$s||Gc("makeClassHandle requires ptr and ptrType");!!b.lt!==!!b.jt&&Gc("Both smartPtrType and smartPtr must be specified");b.count={value:1};return Object.create(a,{Vs:{value:b}})}
function gd(a,b,c,d,e,k,l,m,p,r,v){this.name=a;this.Zs=b;this.qu=c;this.Xt=d;this.Yt=e;this.qv=k;this.wv=l;this.Ou=m;this.Pt=p;this.tv=r;this.kt=v;e||void 0!==b.mt?this.toWireType=Zc:(this.toWireType=d?Xc:ad,this.it=null)}function hd(a,b,c){f.hasOwnProperty(a)||Gc("Replacing nonexistant public symbol");void 0!==f[a].et&&void 0!==c?f[a].et[c]=b:(f[a]=b,f[a].Ft=c)}
function O(a,b){a=M(a);if(void 0!==f["FUNCTION_TABLE_"+a])var c=f["FUNCTION_TABLE_"+a][b];else if("undefined"!==typeof FUNCTION_TABLE)c=FUNCTION_TABLE[b];else{c=f["dynCall_"+a];void 0===c&&(c=f["dynCall_"+a.replace(/f/g,"d")],void 0===c&&N("No dynCall invoker for signature: "+a));for(var d=[],e=1;e<a.length;++e)d.push("a"+e);e="return function "+("dynCall_"+a+"_"+b)+"("+d.join(", ")+") {\n";e+="    return dynCall(rawFunction"+(d.length?", ":"")+d.join(", ")+");\n";c=(new Function("dynCall","rawFunction",
e+"};\n"))(c,b)}"function"!==typeof c&&N("unknown function pointer with signature "+a+": "+b);return c}var id=void 0;function jd(a){a=kd(a);var b=M(a);ld(a);return b}function md(a,b){function c(a){e[a]||Ac[a]||(Bc[a]?Bc[a].forEach(c):(d.push(a),e[a]=!0))}var d=[],e={};b.forEach(c);throw new id(a+": "+d.map(jd).join([", "]));}
function nd(a){var b=Function;if(!(b instanceof Function))throw new TypeError("new_ called with constructor type "+typeof b+" which is not a function");var c=Dc(b.name||"unknownFunctionName",function(){});c.prototype=b.prototype;c=new c;a=b.apply(c,a);return a instanceof Object?a:c}
function od(a,b,c,d,e){var k=b.length;2>k&&N("argTypes array size mismatch! Must at least get return value and 'this' types!");var l=null!==b[1]&&null!==c,m=!1;for(c=1;c<b.length;++c)if(null!==b[c]&&void 0===b[c].it){m=!0;break}var p="void"!==b[0].name,r="",v="";for(c=0;c<k-2;++c)r+=(0!==c?", ":"")+"arg"+c,v+=(0!==c?", ":"")+"arg"+c+"Wired";a="return function "+Cc(a)+"("+r+") {\nif (arguments.length !== "+(k-2)+") {\nthrowBindingError('function "+a+" called with ' + arguments.length + ' arguments, expected "+
(k-2)+" args!');\n}\n";m&&(a+="var destructors = [];\n");var C=m?"destructors":"null";r="throwBindingError invoker fn runDestructors retType classParam".split(" ");d=[N,d,e,xc,b[0],b[1]];l&&(a+="var thisWired = classParam.toWireType("+C+", this);\n");for(c=0;c<k-2;++c)a+="var arg"+c+"Wired = argType"+c+".toWireType("+C+", arg"+c+"); // "+b[c+2].name+"\n",r.push("argType"+c),d.push(b[c+2]);l&&(v="thisWired"+(0<v.length?", ":"")+v);a+=(p?"var rv = ":"")+"invoker(fn"+(0<v.length?", ":"")+v+");\n";if(m)a+=
"runDestructors(destructors);\n";else for(c=l?1:2;c<b.length;++c)k=1===c?"thisWired":"arg"+(c-2)+"Wired",null!==b[c].it&&(a+=k+"_dtor("+k+"); // "+b[c].name+"\n",r.push(k+"_dtor"),d.push(b[c].it));p&&(a+="var ret = retType.fromWireType(rv);\nreturn ret;\n");r.push(a+"}\n");return nd(r).apply(null,d)}function pd(a,b){for(var c=[],d=0;d<a;d++)c.push(t[(b>>2)+d]);return c}var qd=[],P=[{},{value:void 0},{value:null},{value:!0},{value:!1}];function rd(a){4<a&&0===--P[a].It&&(P[a]=void 0,qd.push(a))}
function $c(a){switch(a){case void 0:return 1;case null:return 2;case !0:return 3;case !1:return 4;default:var b=qd.length?qd.pop():P.length;P[b]={It:1,value:a};return b}}function sd(a,b,c){switch(b){case 0:return function(a){return this.fromWireType((c?x:h)[a])};case 1:return function(a){return this.fromWireType((c?Ia:Ja)[a>>1])};case 2:return function(a){return this.fromWireType((c?t:z)[a>>2])};default:throw new TypeError("Unknown integer type: "+a);}}
function td(a,b){var c=Ac[a];void 0===c&&N(b+" has unknown type "+jd(a));return c}function Yc(a){if(null===a)return"null";var b=typeof a;return"object"===b||"array"===b||"function"===b?a.toString():""+a}function ud(a,b){switch(b){case 2:return function(a){return this.fromWireType(D[a>>2])};case 3:return function(a){return this.fromWireType(Ka[a>>3])};default:throw new TypeError("Unknown float type: "+a);}}
function vd(a,b,c){switch(b){case 0:return c?function(a){return x[a]}:function(a){return h[a]};case 1:return c?function(a){return Ia[a>>1]}:function(a){return Ja[a>>1]};case 2:return c?function(a){return t[a>>2]}:function(a){return z[a>>2]};default:throw new TypeError("Unknown integer type: "+a);}}var wd={};function xd(a){var b=wd[a];return void 0===b?M(a):b}var yd=[];function zd(a){var b=yd.length;yd.push(a);return b}
function Ad(a,b){for(var c=Array(a),d=0;d<a;++d)c[d]=td(t[(b>>2)+d],"parameter "+d);return c}function Bd(){n()}var Cd=1,Dd={},Ed={};
function Fd(a,b){Gd=a;Hd=b;if(Id)if(0==a)Jd=function(){var a=Math.max(0,Kd+b-Bd())|0;setTimeout(Ld,a)},Md="timeout";else if(1==a)Jd=function(){Nd(Ld)},Md="rAF";else if(2==a){if("undefined"===typeof setImmediate){var c=[];addEventListener("message",function(a){if("setimmediate"===a.data||"setimmediate"===a.data.target)a.stopPropagation(),c.shift()()},!0);setImmediate=function(a){c.push(a);fa?(void 0===f.setImmediates&&(f.setImmediates=[]),f.setImmediates.push(a),postMessage({target:"setimmediate"})):
postMessage("setimmediate","*")}}Jd=function(){setImmediate(Ld)};Md="immediate"}}
function Od(a){var b=Pd;f.noExitRuntime=!0;assert(!Id,"emscripten_set_main_loop: there can only be one main loop function at once: call emscripten_cancel_main_loop to cancel the previous one before setting a new one with different parameters.");Id=a;Pd=b;var c="undefined"!==typeof b?function(){f.dynCall_vi(a,b)}:function(){f.dynCall_v(a)};var d=Qd;Ld=function(){if(!za)if(0<Rd.length){var a=Date.now(),b=Rd.shift();b.Wt(b.Vt);if(Sd){var l=Sd,m=0==l%1?l-1:Math.floor(l);Sd=b.Ex?m:(8*l+(m+.5))/9}console.log('main loop blocker "'+
b.name+'" took '+(Date.now()-a)+" ms");f.setStatus&&(a=f.statusMessage||"Please wait...",b=Sd,l=Td.Hx,b?b<l?f.setStatus(a+" ("+(l-b)+"/"+l+")"):f.setStatus(a):f.setStatus(""));d<Qd||setTimeout(Ld,0)}else if(!(d<Qd))if(Ud=Ud+1|0,1==Gd&&1<Hd&&0!=Ud%Hd)Jd();else{0==Gd&&(Kd=Bd());"timeout"===Md&&f.Mt&&(q("Looks like you are rendering without using requestAnimationFrame for the main loop. You should use 0 for the frame rate in emscripten_set_main_loop in order to use requestAnimationFrame, as that can greatly improve your frame rates!"),
Md="");a:if(!(za||f.preMainLoop&&!1===f.preMainLoop())){try{c()}catch(p){if(p instanceof na)break a;p&&"object"===typeof p&&p.stack&&q("exception thrown: "+[p,p.stack]);throw p;}f.postMainLoop&&f.postMainLoop()}d<Qd||("object"===typeof SDL&&SDL.audio&&SDL.audio.sv&&SDL.audio.sv(),Jd())}}}var Jd=null,Md="",Qd=0,Id=null,Pd=0,Gd=0,Hd=0,Ud=0,Rd=[],Td={},Kd,Ld,Sd,Vd=!1,Wd=!1,Xd=[];
function Yd(){function a(){Wd=document.pointerLockElement===f.canvas||document.mozPointerLockElement===f.canvas||document.webkitPointerLockElement===f.canvas||document.msPointerLockElement===f.canvas}f.preloadPlugins||(f.preloadPlugins=[]);if(!Zd){Zd=!0;try{$d=!0}catch(c){$d=!1,console.log("warning: no blob constructor, cannot create blobs with mimetypes")}ae="undefined"!=typeof MozBlobBuilder?MozBlobBuilder:"undefined"!=typeof WebKitBlobBuilder?WebKitBlobBuilder:$d?null:console.log("warning: no BlobBuilder");
be="undefined"!=typeof window?window.URL?window.URL:window.webkitURL:void 0;f.Nu||"undefined"!==typeof be||(console.log("warning: Browser does not support creating object URLs. Built-in browser image decoding will not be available."),f.Nu=!0);f.preloadPlugins.push({canHandle:function(a){return!f.Nu&&/\.(jpg|jpeg|png|bmp)$/i.test(a)},handle:function(a,b,e,k){var c=null;if($d)try{c=new Blob([a],{type:ce(b)}),c.size!==a.length&&(c=new Blob([(new Uint8Array(a)).buffer],{type:ce(b)}))}catch(r){va("Blob constructor present but fails: "+
r+"; falling back to blob builder")}c||(c=new ae,c.append((new Uint8Array(a)).buffer),c=c.getBlob());var d=be.createObjectURL(c),p=new Image;p.onload=function(){assert(p.complete,"Image "+b+" could not be decoded");var c=document.createElement("canvas");c.width=p.width;c.height=p.height;c.getContext("2d").drawImage(p,0,0);f.preloadedImages[b]=c;be.revokeObjectURL(d);e&&e(a)};p.onerror=function(){console.log("Image "+d+" could not be decoded");k&&k()};p.src=d}});f.preloadPlugins.push({canHandle:function(a){return!f.Kx&&
a.substr(-4)in{".ogg":1,".wav":1,".mp3":1}},handle:function(a,b,e,k){function c(c){p||(p=!0,f.preloadedAudios[b]=c,e&&e(a))}function d(){p||(p=!0,f.preloadedAudios[b]=new Audio,k&&k())}var p=!1;if($d){try{var r=new Blob([a],{type:ce(b)})}catch(C){return d()}r=be.createObjectURL(r);var v=new Audio;v.addEventListener("canplaythrough",function(){c(v)},!1);v.onerror=function(){if(!p){console.log("warning: browser could not fully decode audio "+b+", trying slower base64 approach");for(var d="",e=0,k=0,
l=0;l<a.length;l++)for(e=e<<8|a[l],k+=8;6<=k;){var m=e>>k-6&63;k-=6;d+="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/"[m]}2==k?(d+="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/"[(e&3)<<4],d+="=="):4==k&&(d+="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/"[(e&15)<<2],d+="=");v.src="data:audio/x-"+b.substr(-3)+";base64,"+d;c(v)}};v.src=r;de(function(){c(v)})}else return d()}});var b=f.canvas;b&&(b.requestPointerLock=b.requestPointerLock||b.mozRequestPointerLock||
b.webkitRequestPointerLock||b.msRequestPointerLock||function(){},b.exitPointerLock=document.exitPointerLock||document.mozExitPointerLock||document.webkitExitPointerLock||document.msExitPointerLock||function(){},b.exitPointerLock=b.exitPointerLock.bind(document),document.addEventListener("pointerlockchange",a,!1),document.addEventListener("mozpointerlockchange",a,!1),document.addEventListener("webkitpointerlockchange",a,!1),document.addEventListener("mspointerlockchange",a,!1),f.elementPointerLock&&
b.addEventListener("click",function(a){!Wd&&f.canvas.requestPointerLock&&(f.canvas.requestPointerLock(),a.preventDefault())},!1))}}
function ee(a,b,c,d){if(b&&f.Mt&&a==f.canvas)return f.Mt;if(b){var e={antialias:!1,alpha:!1};if(d)for(var k in d)e[k]=d[k];if(e=aa(a,e))var l=fe[e].GLctx}else l=a.getContext("2d");if(!l)return null;c&&(b||assert("undefined"===typeof GLctx,"cannot set in module if GLctx is used, but we are a non-GL context that would replace it"),f.Mt=l,b&&ge(e),f.Px=b,Xd.forEach(function(a){a()}),Yd());return l}var he=!1,ie=void 0,je=void 0;
function ke(a,b,c){function d(){Vd=!1;var a=e.parentNode;(document.fullscreenElement||document.mozFullScreenElement||document.msFullscreenElement||document.webkitFullscreenElement||document.webkitCurrentFullScreenElement)===a?(e.exitFullscreen=document.exitFullscreen||document.cancelFullScreen||document.mozCancelFullScreen||document.msExitFullscreen||document.webkitCancelFullScreen||function(){},e.exitFullscreen=e.exitFullscreen.bind(document),ie&&e.requestPointerLock(),Vd=!0,je?("undefined"!=typeof SDL&&
(t[SDL.screen>>2]=z[SDL.screen>>2]|8388608),le(f.canvas),me()):le(e)):(a.parentNode.insertBefore(e,a),a.parentNode.removeChild(a),je?("undefined"!=typeof SDL&&(t[SDL.screen>>2]=z[SDL.screen>>2]&-8388609),le(f.canvas),me()):le(e));if(f.onFullScreen)f.onFullScreen(Vd);if(f.onFullscreen)f.onFullscreen(Vd)}ie=a;je=b;ne=c;"undefined"===typeof ie&&(ie=!0);"undefined"===typeof je&&(je=!1);"undefined"===typeof ne&&(ne=null);var e=f.canvas;he||(he=!0,document.addEventListener("fullscreenchange",d,!1),document.addEventListener("mozfullscreenchange",
d,!1),document.addEventListener("webkitfullscreenchange",d,!1),document.addEventListener("MSFullscreenChange",d,!1));var k=document.createElement("div");e.parentNode.insertBefore(k,e);k.appendChild(e);k.requestFullscreen=k.requestFullscreen||k.mozRequestFullScreen||k.msRequestFullscreen||(k.webkitRequestFullscreen?function(){k.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)}:null)||(k.webkitRequestFullScreen?function(){k.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)}:null);c?k.requestFullscreen({Qx:c}):
k.requestFullscreen()}function oe(a,b,c){q("Browser.requestFullScreen() is deprecated. Please call Browser.requestFullscreen instead.");oe=function(a,b,c){return ke(a,b,c)};return ke(a,b,c)}var pe=0;function qe(a){var b=Date.now();if(0===pe)pe=b+1E3/60;else for(;b+2>=pe;)pe+=1E3/60;setTimeout(a,Math.max(pe-b,0))}
function Nd(a){"undefined"===typeof window?qe(a):(window.requestAnimationFrame||(window.requestAnimationFrame=window.requestAnimationFrame||window.mozRequestAnimationFrame||window.webkitRequestAnimationFrame||window.msRequestAnimationFrame||window.oRequestAnimationFrame||qe),window.requestAnimationFrame(a))}function de(a){f.noExitRuntime=!0;setTimeout(function(){za||a()},1E4)}
function ce(a){return{jpg:"image/jpeg",jpeg:"image/jpeg",png:"image/png",bmp:"image/bmp",ogg:"audio/ogg",wav:"audio/wav",mp3:"audio/mpeg"}[a.substr(a.lastIndexOf(".")+1)]}var re=[];function me(){var a=f.canvas;re.forEach(function(b){b(a.width,a.height)})}
function le(a,b,c){b&&c?(a.Cv=b,a.hv=c):(b=a.Cv,c=a.hv);var d=b,e=c;f.forcedAspectRatio&&0<f.forcedAspectRatio&&(d/e<f.forcedAspectRatio?d=Math.round(e*f.forcedAspectRatio):e=Math.round(d/f.forcedAspectRatio));if((document.fullscreenElement||document.mozFullScreenElement||document.msFullscreenElement||document.webkitFullscreenElement||document.webkitCurrentFullScreenElement)===a.parentNode&&"undefined"!=typeof screen){var k=Math.min(screen.width/d,screen.height/e);d=Math.round(d*k);e=Math.round(e*
k)}je?(a.width!=d&&(a.width=d),a.height!=e&&(a.height=e),"undefined"!=typeof a.style&&(a.style.removeProperty("width"),a.style.removeProperty("height"))):(a.width!=b&&(a.width=b),a.height!=c&&(a.height=c),"undefined"!=typeof a.style&&(d!=b||e!=c?(a.style.setProperty("width",d+"px","important"),a.style.setProperty("height",e+"px","important")):(a.style.removeProperty("width"),a.style.removeProperty("height"))))}
var Zd,$d,ae,be,ne,se={},te=1,ue=0,ve=[],Q=[],we=[],R=[],S=[],T=[],U=[],xe=[],fe={},ye=null,ze={},Ae={},Be=[],Ce=4;function V(a){ue||(ue=a)}function W(a){for(var b=te++,c=a.length;c<b;c++)a[c]=null;return b}var De=null,X=[0];function Ee(a,b,c){for(var d="",e=0;e<a;++e){if(c){var k=t[c+4*e>>2];k=0>k?y(t[b+4*e>>2]):y(t[b+4*e>>2],k)}else k=y(t[b+4*e>>2]);d+=k}return d}
function aa(a,b){function c(){}"undefined"===typeof b.majorVersion&&"undefined"===typeof b.minorVersion&&(b.majorVersion=1,b.minorVersion=0);try{a.addEventListener("webglcontextcreationerror",c,!1);try{if(1==b.majorVersion&&0==b.minorVersion)var d=a.getContext("webgl",b)||a.getContext("experimental-webgl",b);else if(2==b.majorVersion&&0==b.minorVersion)d=a.getContext("webgl2",b);else throw"Unsupported WebGL context version "+majorVersion+"."+minorVersion+"!";}finally{a.removeEventListener("webglcontextcreationerror",
c,!1)}if(!d)throw":(";}catch(e){return 0}return d?Fe(d,b):0}function Fe(a,b){var c=Aa(8);t[c>>2]=b.explicitSwapControl;var d={handle:c,attributes:b,version:b.majorVersion,GLctx:a};a.canvas&&(a.canvas.Cx=d);fe[c]=d;("undefined"===typeof b.enableExtensionsByDefault||b.enableExtensionsByDefault)&&Ge(d);return c}function ge(a){if(!a)return GLctx=f.Mt=ye=null,!0;a=fe[a];if(!a)return!1;GLctx=f.Mt=a.GLctx;ye=a;return!0}
function Ge(a){a||(a=ye);if(!a.jv){a.jv=!0;var b=a.GLctx;if(2>a.version){var c=b.getExtension("ANGLE_instanced_arrays");c&&(b.vertexAttribDivisor=function(a,b){c.vertexAttribDivisorANGLE(a,b)},b.drawArraysInstanced=function(a,b,d,e){c.drawArraysInstancedANGLE(a,b,d,e)},b.drawElementsInstanced=function(a,b,d,e,k){c.drawElementsInstancedANGLE(a,b,d,e,k)});var d=b.getExtension("OES_vertex_array_object");d&&(b.createVertexArray=function(){return d.createVertexArrayOES()},b.deleteVertexArray=function(a){d.deleteVertexArrayOES(a)},
b.bindVertexArray=function(a){d.bindVertexArrayOES(a)},b.isVertexArray=function(a){return d.isVertexArrayOES(a)});var e=b.getExtension("WEBGL_draw_buffers");e&&(b.drawBuffers=function(a,b){e.drawBuffersWEBGL(a,b)})}b.Fx=b.getExtension("EXT_disjoint_timer_query");var k="OES_texture_float OES_texture_half_float OES_standard_derivatives OES_vertex_array_object WEBGL_compressed_texture_s3tc WEBGL_depth_texture OES_element_index_uint EXT_texture_filter_anisotropic EXT_frag_depth WEBGL_draw_buffers ANGLE_instanced_arrays OES_texture_float_linear OES_texture_half_float_linear EXT_blend_minmax EXT_shader_texture_lod WEBGL_compressed_texture_pvrtc EXT_color_buffer_half_float WEBGL_color_buffer_float EXT_sRGB WEBGL_compressed_texture_etc1 EXT_disjoint_timer_query WEBGL_compressed_texture_etc WEBGL_compressed_texture_astc EXT_color_buffer_float WEBGL_compressed_texture_s3tc_srgb EXT_disjoint_timer_query_webgl2".split(" ");
(a=b.getSupportedExtensions())&&0<a.length&&b.getSupportedExtensions().forEach(function(a){-1!=k.indexOf(a)&&b.getExtension(a)})}}
function He(a){var b=Q[a];ze[a]={wu:{},Zt:0,ot:-1,pt:-1};a=ze[a];for(var c=a.wu,d=GLctx.getProgramParameter(b,GLctx.ACTIVE_UNIFORMS),e=0;e<d;++e){var k=GLctx.getActiveUniform(b,e),l=k.name;a.Zt=Math.max(a.Zt,l.length+1);-1!==l.indexOf("]",l.length-1)&&(l=l.slice(0,l.lastIndexOf("[")));var m=GLctx.getUniformLocation(b,l);if(null!=m){var p=W(T);c[l]=[k.size,p];T[p]=m;for(var r=1;r<k.size;++r)m=GLctx.getUniformLocation(b,l+"["+r+"]"),p=W(T),T[p]=m}}}var Ie,Je;
function _emscripten_glActiveTexture(a){GLctx.activeTexture(a)}function _emscripten_glAttachShader(a,b){GLctx.attachShader(Q[a],U[b])}function _emscripten_glBindAttribLocation(a,b,c){c=y(c);GLctx.bindAttribLocation(Q[a],b,c)}function _emscripten_glBindBuffer(a,b){GLctx.bindBuffer(a,b?ve[b]:null)}function _emscripten_glCompileShader(a){GLctx.compileShader(U[a])}function _emscripten_glCreateShader(a){var b=W(U);U[b]=GLctx.createShader(a);return b}
function _emscripten_glDeleteProgram(a){if(a){var b=Q[a];b?(GLctx.deleteProgram(b),b.name=0,Q[a]=null,ze[a]=null):V(1281)}}function _emscripten_glDetachShader(a,b){GLctx.detachShader(Q[a],U[b])}function _emscripten_glDisable(a){GLctx.disable(a)}function _emscripten_glDisableVertexAttribArray(a){GLctx.disableVertexAttribArray(a)}function _emscripten_glDrawArrays(a,b,c){GLctx.drawArrays(a,b,c)}function _emscripten_glDrawElements(a,b,c,d){GLctx.drawElements(a,b,c,d)}
function _emscripten_glEnable(a){GLctx.enable(a)}function _emscripten_glEnableVertexAttribArray(a){GLctx.enableVertexAttribArray(a)}
function Ke(a,b,c){if(b){var d=void 0;switch(a){case 36346:d=1;break;case 36344:"Integer"!==c&&"Integer64"!==c&&V(1280);return;case 36345:d=0;break;case 34466:var e=GLctx.getParameter(34467);d=e?e.length:0}if(void 0===d)switch(e=GLctx.getParameter(a),typeof e){case "number":d=e;break;case "boolean":d=e?1:0;break;case "string":V(1280);return;case "object":if(null===e)switch(a){case 34964:case 35725:case 34965:case 36006:case 36007:case 32873:case 34068:d=0;break;default:V(1280);return}else{if(e instanceof
Float32Array||e instanceof Uint32Array||e instanceof Int32Array||e instanceof Array){for(a=0;a<e.length;++a)switch(c){case "Integer":t[b+4*a>>2]=e[a];break;case "Float":D[b+4*a>>2]=e[a];break;case "Boolean":x[b+a>>0]=e[a]?1:0;break;default:throw"internal glGet error, bad type: "+c;}return}try{d=e.name|0}catch(k){V(1280);q("GL_INVALID_ENUM in glGet"+c+"v: Unknown object returned from WebGL getParameter("+a+")! (error: "+k+")");return}}break;default:V(1280);return}switch(c){case "Integer64":tempI64=
[d>>>0,(tempDouble=d,1<=+cb(tempDouble)?0<tempDouble?(fb(+eb(tempDouble/4294967296),4294967295)|0)>>>0:~~+db((tempDouble-+(~~tempDouble>>>0))/4294967296)>>>0:0)];t[b>>2]=tempI64[0];t[b+4>>2]=tempI64[1];break;case "Integer":t[b>>2]=d;break;case "Float":D[b>>2]=d;break;case "Boolean":x[b>>0]=d?1:0;break;default:throw"internal glGet error, bad type: "+c;}}else V(1281)}function _emscripten_glGetBooleanv(a,b){Ke(a,b,"Boolean")}function _emscripten_glGetFloatv(a,b){Ke(a,b,"Float")}
function _emscripten_glGetIntegerv(a,b){Ke(a,b,"Integer")}
function _emscripten_glGetString(a){if(Ae[a])return Ae[a];switch(a){case 7939:var b=GLctx.getSupportedExtensions();for(var c=[],d=0;d<b.length;++d)c.push(b[d]),c.push("GL_"+b[d]);b=w(H(c.join(" ")));break;case 7936:case 7937:case 37445:case 37446:(b=GLctx.getParameter(a))||V(1280);b=w(H(b));break;case 7938:b=w(H("OpenGL ES 2.0 ("+GLctx.getParameter(GLctx.VERSION)+")"));break;case 35724:b=GLctx.getParameter(GLctx.SHADING_LANGUAGE_VERSION);c=b.match(/^WebGL GLSL ES ([0-9]\.[0-9][0-9]?)(?:$| .*)/);null!==
c&&(3==c[1].length&&(c[1]+="0"),b="OpenGL ES GLSL ES "+c[1]+" ("+b+")");b=w(H(b));break;default:return V(1280),0}return Ae[a]=b}
function Le(a,b,c,d){if(c)if(a=GLctx.getUniform(Q[a],T[b]),"number"==typeof a||"boolean"==typeof a)switch(d){case "Integer":t[c>>2]=a;break;case "Float":D[c>>2]=a;break;default:throw"internal emscriptenWebGLGetUniform() error, bad type: "+d;}else for(b=0;b<a.length;b++)switch(d){case "Integer":t[c+4*b>>2]=a[b];break;case "Float":D[c+4*b>>2]=a[b];break;default:throw"internal emscriptenWebGLGetUniform() error, bad type: "+d;}else V(1281)}
function Me(a,b,c,d){if(c)if(a=GLctx.getVertexAttrib(a,b),34975==b)t[c>>2]=a.name;else if("number"==typeof a||"boolean"==typeof a)switch(d){case "Integer":t[c>>2]=a;break;case "Float":D[c>>2]=a;break;case "FloatToInteger":t[c>>2]=Math.fround(a);break;default:throw"internal emscriptenWebGLGetVertexAttrib() error, bad type: "+d;}else for(b=0;b<a.length;b++)switch(d){case "Integer":t[c+4*b>>2]=a[b];break;case "Float":D[c+4*b>>2]=a[b];break;case "FloatToInteger":t[c+4*b>>2]=Math.fround(a[b]);break;default:throw"internal emscriptenWebGLGetVertexAttrib() error, bad type: "+
d;}else V(1281)}function _emscripten_glHint(a,b){GLctx.hint(a,b)}function _emscripten_glIsEnabled(a){return GLctx.isEnabled(a)}function _emscripten_glLinkProgram(a){GLctx.linkProgram(Q[a]);ze[a]=null;He(a)}
function Ne(a,b,c,d,e){switch(b){case 6406:case 6409:case 6402:b=1;break;case 6410:b=2;break;case 6407:case 35904:b=3;break;case 6408:case 35906:b=4;break;default:return V(1280),null}switch(a){case 5121:var k=1*b;break;case 5123:case 36193:k=2*b;break;case 5125:case 5126:k=4*b;break;case 34042:k=4;break;case 33635:case 32819:case 32820:k=2;break;default:return V(1280),null}b=Ce;c*=k;b*=Math.floor((c+b-1)/b);d=0>=d?0:(d-1)*b+c;switch(a){case 5121:return h.subarray(e,e+d);case 5126:return D.subarray(e>>
2,e+d>>2);case 5125:case 34042:return z.subarray(e>>2,e+d>>2);case 5123:case 33635:case 32819:case 32820:case 36193:return Ja.subarray(e>>1,e+d>>1);default:return V(1280),null}}function _emscripten_glShaderSource(a,b,c,d){b=Ee(b,c,d);GLctx.shaderSource(U[a],b)}function _emscripten_glTexEnvf(){q("missing function: emscripten_glTexEnvf");n(-1)}function _emscripten_glTexEnvfv(){q("missing function: emscripten_glTexEnvfv");n(-1)}
function _emscripten_glTexEnvi(){q("missing function: emscripten_glTexEnvi");n(-1)}function _emscripten_glUseProgram(a){GLctx.useProgram(a?Q[a]:null)}function _emscripten_glVertexAttribPointer(a,b,c,d,e,k){GLctx.vertexAttribPointer(a,b,c,!!d,e,k)}function Oe(a,b){f.setThrew(a,b||1);throw"longjmp";}var Pe=0;function Qe(){++Pe}function Re(){--Pe}function Se(){return ye?ye.handle:0}
function Te(a){if(0===a)return 0;a=y(a);if(!E.hasOwnProperty(a))return 0;Te.gt&&ld(Te.gt);a=E[a];var b=ba(a)+1,c=Aa(b);c&&g(a,x,c,b);Te.gt=c;return Te.gt}function _glDrawArrays(a,b,c){GLctx.drawArrays(a,b,c)}function _glDrawElements(a,b,c,d){GLctx.drawElements(a,b,c,d)}function Ue(){Ue.gt||(Ue.gt=[]);Ue.gt.push(Y());return Ue.gt.length-1}var Ve={},We=1;function Xe(a,b){Xe.gt||(Xe.gt={});a in Xe.gt||(f.dynCall_v(b),Xe.gt[a]=1)}function Ye(a){return 0===a%4&&(0!==a%100||0===a%400)}
function Ze(a,b){for(var c=0,d=0;d<=b;c+=a[d++]);return c}var $e=[31,29,31,30,31,30,31,31,30,31,30,31],af=[31,28,31,30,31,30,31,31,30,31,30,31];function bf(a,b){for(a=new Date(a.getTime());0<b;){var c=a.getMonth(),d=(Ye(a.getFullYear())?$e:af)[c];if(b>d-a.getDate())b-=d-a.getDate()+1,a.setDate(1),11>c?a.setMonth(c+1):(a.setMonth(0),a.setFullYear(a.getFullYear()+1));else{a.setDate(a.getDate()+b);break}}return a}
function cf(a,b,c,d){function e(a,b,c){for(a="number"===typeof a?a.toString():a||"";a.length<b;)a=c[0]+a;return a}function k(a,b){return e(a,b,"0")}function l(a,b){function c(a){return 0>a?-1:0<a?1:0}var d;0===(d=c(a.getFullYear()-b.getFullYear()))&&0===(d=c(a.getMonth()-b.getMonth()))&&(d=c(a.getDate()-b.getDate()));return d}function m(a){switch(a.getDay()){case 0:return new Date(a.getFullYear()-1,11,29);case 1:return a;case 2:return new Date(a.getFullYear(),0,3);case 3:return new Date(a.getFullYear(),
0,2);case 4:return new Date(a.getFullYear(),0,1);case 5:return new Date(a.getFullYear()-1,11,31);case 6:return new Date(a.getFullYear()-1,11,30)}}function p(a){a=bf(new Date(a.ft+1900,0,1),a.eu);var b=m(new Date(a.getFullYear()+1,0,4));return 0>=l(m(new Date(a.getFullYear(),0,4)),a)?0>=l(b,a)?a.getFullYear()+1:a.getFullYear():a.getFullYear()-1}var r=t[d+40>>2];d={zv:t[d>>2],yv:t[d+4>>2],du:t[d+8>>2],Ct:t[d+12>>2],wt:t[d+16>>2],ft:t[d+20>>2],Pu:t[d+24>>2],eu:t[d+28>>2],Ox:t[d+32>>2],xv:t[d+36>>2],
Av:r?y(r):""};c=y(c);r={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S"};for(var v in r)c=c.replace(new RegExp(v,"g"),r[v]);var C="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),A="January February March April May June July August September October November December".split(" ");r={"%a":function(a){return C[a.Pu].substring(0,3)},"%A":function(a){return C[a.Pu]},"%b":function(a){return A[a.wt].substring(0,
3)},"%B":function(a){return A[a.wt]},"%C":function(a){return k((a.ft+1900)/100|0,2)},"%d":function(a){return k(a.Ct,2)},"%e":function(a){return e(a.Ct,2," ")},"%g":function(a){return p(a).toString().substring(2)},"%G":function(a){return p(a)},"%H":function(a){return k(a.du,2)},"%I":function(a){a=a.du;0==a?a=12:12<a&&(a-=12);return k(a,2)},"%j":function(a){return k(a.Ct+Ze(Ye(a.ft+1900)?$e:af,a.wt-1),3)},"%m":function(a){return k(a.wt+1,2)},"%M":function(a){return k(a.yv,2)},"%n":function(){return"\n"},
"%p":function(a){return 0<=a.du&&12>a.du?"AM":"PM"},"%S":function(a){return k(a.zv,2)},"%t":function(){return"\t"},"%u":function(a){return(new Date(a.ft+1900,a.wt+1,a.Ct,0,0,0,0)).getDay()||7},"%U":function(a){var b=new Date(a.ft+1900,0,1),c=0===b.getDay()?b:bf(b,7-b.getDay());a=new Date(a.ft+1900,a.wt,a.Ct);return 0>l(c,a)?k(Math.ceil((31-c.getDate()+(Ze(Ye(a.getFullYear())?$e:af,a.getMonth()-1)-31)+a.getDate())/7),2):0===l(c,b)?"01":"00"},"%V":function(a){var b=m(new Date(a.ft+1900,0,4)),c=m(new Date(a.ft+
1901,0,4)),d=bf(new Date(a.ft+1900,0,1),a.eu);return 0>l(d,b)?"53":0>=l(c,d)?"01":k(Math.ceil((b.getFullYear()<a.ft+1900?a.eu+32-b.getDate():a.eu+1-b.getDate())/7),2)},"%w":function(a){return(new Date(a.ft+1900,a.wt+1,a.Ct,0,0,0,0)).getDay()},"%W":function(a){var b=new Date(a.ft,0,1),c=1===b.getDay()?b:bf(b,0===b.getDay()?1:7-b.getDay()+1);a=new Date(a.ft+1900,a.wt,a.Ct);return 0>l(c,a)?k(Math.ceil((31-c.getDate()+(Ze(Ye(a.getFullYear())?$e:af,a.getMonth()-1)-31)+a.getDate())/7),2):0===l(c,b)?"01":
"00"},"%y":function(a){return(a.ft+1900).toString().substring(2)},"%Y":function(a){return a.ft+1900},"%z":function(a){a=a.xv;var b=0<=a;a=Math.abs(a)/60;return(b?"+":"-")+String("0000"+(a/60*100+a%60)).slice(-4)},"%Z":function(a){return a.Av},"%%":function(){return"%"}};for(v in r)0<=c.indexOf(v)&&(c=c.replace(new RegExp(v,"g"),r[v](d)));v=H(c,!1);if(v.length>b)return 0;x.set(v,a);return v.length-1}mc();Kb=Array(4096);$b(I,"/");bc("/tmp");bc("/home");bc("/home/web_user");
(function(){bc("/dev");yb(259,{read:function(){return 0},write:function(a,b,e,k){return k}});cc("/dev/null",259);xb(1280,Ab);xb(1536,Bb);cc("/dev/tty",1280);cc("/dev/tty1",1536);if("undefined"!==typeof crypto){var a=new Uint8Array(1);var b=function(){crypto.getRandomValues(a);return a[0]}}else ha?b=function(){return require("crypto").randomBytes(1)[0]}:b=function(){n("random_device")};qc("random",b);qc("urandom",b);bc("/dev/shm");bc("/dev/shm/tmp")})();bc("/proc");bc("/proc/self");bc("/proc/self/fd");
$b({ut:function(){var a=Db("/proc/self","fd",16895,73);a.Ys={lookup:function(a,c){var b=Ib[+c];if(!b)throw new G(F.st);a={parent:null,ut:{Mu:"fake"},Ys:{readlink:function(){return b.path}}};return a.parent=a}};return a}},"/proc/self/fd");
Xa.unshift(function(){if(!f.noFSInit&&!nc){assert(!nc,"FS.init was previously called. If you want to initialize later with custom parameters, remove any earlier calls (note that one is automatically added to the generated code)");nc=!0;mc();f.stdin=f.stdin;f.stdout=f.stdout;f.stderr=f.stderr;f.stdin?qc("stdin",f.stdin):dc("/dev/tty","/dev/stdin");f.stdout?qc("stdout",null,f.stdout):dc("/dev/tty","/dev/stdout");f.stderr?qc("stderr",null,f.stderr):dc("/dev/tty1","/dev/stderr");var a=fc("/dev/stdin",
"r");assert(0===a.fd,"invalid handle for stdin ("+a.fd+")");a=fc("/dev/stdout","w");assert(1===a.fd,"invalid handle for stdout ("+a.fd+")");a=fc("/dev/stderr","w");assert(2===a.fd,"invalid handle for stderr ("+a.fd+")")}});Ya.push(function(){Lb=!1});Za.push(function(){nc=!1;var a=f._fflush;a&&a(0);for(a=0;a<Ib.length;a++){var b=Ib[a];b&&ic(b)}});Xa.unshift(function(){});Za.push(function(){});Fc=f.InternalError=Ec("InternalError");for(var df=Array(256),ef=0;256>ef;++ef)df[ef]=String.fromCharCode(ef);
Lc=df;Mc=f.BindingError=Ec("BindingError");Rc.prototype.isAliasOf=function(a){if(!(this instanceof Rc&&a instanceof Rc))return!1;var b=this.Vs.ct.Zs,c=this.Vs.$s,d=a.Vs.ct.Zs;for(a=a.Vs.$s;b.mt;)c=b.Qt(c),b=b.mt;for(;d.mt;)a=d.Qt(a),d=d.mt;return b===d&&c===a};
Rc.prototype.clone=function(){this.Vs.$s||Nc(this);if(this.Vs.Ot)return this.Vs.count.value+=1,this;var a=this.Vs;a=Object.create(Object.getPrototypeOf(this),{Vs:{value:{count:a.count,Gt:a.Gt,Ot:a.Ot,$s:a.$s,ct:a.ct,jt:a.jt,lt:a.lt}}});a.Vs.count.value+=1;a.Vs.Gt=!1;return a};
Rc.prototype["delete"]=function(){this.Vs.$s||Nc(this);this.Vs.Gt&&!this.Vs.Ot&&N("Object already scheduled for deletion");--this.Vs.count.value;if(0===this.Vs.count.value){var a=this.Vs;a.jt?a.lt.kt(a.jt):a.ct.Zs.kt(a.$s)}this.Vs.Ot||(this.Vs.jt=void 0,this.Vs.$s=void 0)};Rc.prototype.isDeleted=function(){return!this.Vs.$s};
Rc.prototype.deleteLater=function(){this.Vs.$s||Nc(this);this.Vs.Gt&&!this.Vs.Ot&&N("Object already scheduled for deletion");Pc.push(this);1===Pc.length&&Oc&&Oc(Qc);this.Vs.Gt=!0;return this};gd.prototype.gv=function(a){this.Ou&&(a=this.Ou(a));return a};gd.prototype.Hu=function(a){this.kt&&this.kt(a)};gd.prototype.argPackAdvance=8;gd.prototype.readValueFromPointer=yc;gd.prototype.deleteObject=function(a){if(null!==a)a["delete"]()};
gd.prototype.fromWireType=function(a){function b(){return this.Yt?fd(this.Zs.Ht,{ct:this.qv,$s:c,lt:this,jt:a}):fd(this.Zs.Ht,{ct:this,$s:a})}var c=this.gv(a);if(!c)return this.Hu(a),null;var d=ed(this.Zs,c);if(void 0!==d){if(0===d.Vs.count.value)return d.Vs.$s=c,d.Vs.jt=a,d.clone();d=d.clone();this.Hu(a);return d}d=this.Zs.ev(c);d=Sc[d];if(!d)return b.call(this);d=this.Xt?d.av:d.pointerType;var e=bd(c,this.Zs,d.Zs);return null===e?b.call(this):this.Yt?fd(d.Zs.Ht,{ct:d,$s:e,lt:this,jt:a}):fd(d.Zs.Ht,
{ct:d,$s:e})};f.getInheritedInstanceCount=function(){return Object.keys(cd).length};f.getLiveInheritedInstances=function(){var a=[],b;for(b in cd)cd.hasOwnProperty(b)&&a.push(cd[b]);return a};f.flushPendingDeletes=Qc;f.setDelayFunction=function(a){Oc=a;Pc.length&&Oc&&Oc(Qc)};id=f.UnboundTypeError=Ec("UnboundTypeError");f.count_emval_handles=function(){for(var a=0,b=5;b<P.length;++b)void 0!==P[b]&&++a;return a};f.get_first_emval=function(){for(var a=5;a<P.length;++a)if(void 0!==P[a])return P[a];return null};
ha?Bd=function(){var a=process.hrtime();return 1E3*a[0]+a[1]/1E6}:"undefined"!==typeof dateNow?Bd=dateNow:"object"===typeof self&&self.performance&&"function"===typeof self.performance.now?Bd=function(){return self.performance.now()}:"object"===typeof performance&&"function"===typeof performance.now?Bd=function(){return performance.now()}:Bd=Date.now;
f.requestFullScreen=function(a,b,c){q("Module.requestFullScreen is deprecated. Please call Module.requestFullscreen instead.");f.requestFullScreen=f.requestFullscreen;oe(a,b,c)};f.requestFullscreen=function(a,b,c){ke(a,b,c)};f.requestAnimationFrame=function(a){Nd(a)};f.setCanvasSize=function(a,b,c){le(f.canvas,a,b);c||me()};f.pauseMainLoop=function(){Jd=null;Qd++};f.resumeMainLoop=function(){Qd++;var a=Gd,b=Hd,c=Id;Id=null;Od(c);Fd(a,b);Jd()};
f.getUserMedia=function(){window.getUserMedia||(window.getUserMedia=navigator.getUserMedia||navigator.mozGetUserMedia);window.getUserMedia(void 0)};f.createContext=function(a,b,c,d){return ee(a,b,c,d)};var GLctx;De=new Float32Array(256);for(var ff=0;256>ff;ff++)X[ff]=De.subarray(0,ff+1);for(ff=0;32>ff;ff++)Be.push(Array(ff));
if("undefined"!==typeof window){window.addEventListener("gamepadconnected",Qe);window.addEventListener("gamepaddisconnected",Re);var gf=navigator.getGamepads?navigator.getGamepads():navigator.webkitGetGamepads?navigator.webkitGetGamepads():null;gf&&(Pe=gf.length)}sa=pa(4);Oa=Pa=ua(qa);Qa=Oa+Ua;Ra=ua(Qa);t[sa>>2]=Ra;Na=!0;function H(a,b){var c=Array(ba(a)+1);a=g(a,c,0,c.length);b&&(c.length=a);return c}f.wasmTableSize=8959;f.wasmMaxTableSize=8959;f.Vu={};
f.Wu={h:n,dq:ta,fo:function(){return u},f:function(a){tempRet0=a},d:function(){return tempRet0},ni:function(){n("Cannot enlarge memory arrays. Either (1) compile with  -s TOTAL_MEMORY=X  with X higher than the current value "+u+", (2) compile with  -s ALLOW_MEMORY_GROWTH=1  which allows increasing the size at runtime, or (3) if you want malloc to return NULL (0) instead of this abort, compile with  -s ABORTING_MALLOC=0 ")},tg:function(a){var b=Y();try{return f.dynCall_i(a)}catch(c){Z(b);if("number"!==
typeof c&&"longjmp"!==c)throw c;f.setThrew(1,0)}},m:function(a,b){var c=Y();try{return f.dynCall_ii(a,b)}catch(d){Z(c);if("number"!==typeof d&&"longjmp"!==d)throw d;f.setThrew(1,0)}},x:function(a,b,c){var d=Y();try{return f.dynCall_iii(a,b,c)}catch(e){Z(d);if("number"!==typeof e&&"longjmp"!==e)throw e;f.setThrew(1,0)}},p:function(a,b,c,d){var e=Y();try{return f.dynCall_iiii(a,b,c,d)}catch(k){Z(e);if("number"!==typeof k&&"longjmp"!==k)throw k;f.setThrew(1,0)}},U:function(a,b,c,d,e){var k=Y();try{return f.dynCall_iiiii(a,
b,c,d,e)}catch(l){Z(k);if("number"!==typeof l&&"longjmp"!==l)throw l;f.setThrew(1,0)}},Up:function(a,b,c,d,e,k,l){var m=Y();try{return f.dynCall_iiiiiii(a,b,c,d,e,k,l)}catch(p){Z(m);if("number"!==typeof p&&"longjmp"!==p)throw p;f.setThrew(1,0)}},Jp:function(a){var b=Y();try{f.dynCall_v(a)}catch(c){Z(b);if("number"!==typeof c&&"longjmp"!==c)throw c;f.setThrew(1,0)}},k:function(a,b){var c=Y();try{f.dynCall_vi(a,b)}catch(d){Z(c);if("number"!==typeof d&&"longjmp"!==d)throw d;f.setThrew(1,0)}},j:function(a,
b,c){var d=Y();try{f.dynCall_vii(a,b,c)}catch(e){Z(d);if("number"!==typeof e&&"longjmp"!==e)throw e;f.setThrew(1,0)}},y:function(a,b,c,d){var e=Y();try{f.dynCall_viii(a,b,c,d)}catch(k){Z(e);if("number"!==typeof k&&"longjmp"!==k)throw k;f.setThrew(1,0)}},s:function(a,b,c,d,e){var k=Y();try{f.dynCall_viiii(a,b,c,d,e)}catch(l){Z(k);if("number"!==typeof l&&"longjmp"!==l)throw l;f.setThrew(1,0)}},Mo:function(a,b,c,d,e,k){var l=Y();try{f.dynCall_viiiii(a,b,c,d,e,k)}catch(m){Z(l);if("number"!==typeof m&&
"longjmp"!==m)throw m;f.setThrew(1,0)}},Bo:function(a,b,c,d,e,k,l,m,p,r){var v=Y();try{f.dynCall_viiiiiiiii(a,b,c,d,e,k,l,m,p,r)}catch(C){Z(v);if("number"!==typeof C&&"longjmp"!==C)throw C;f.setThrew(1,0)}},qo:ob,eo:function(){za=!0;throw"Pure virtual function called!";},Un:function(){return!!hf.Dx},O:function(){},zn:function(){pb(F.Dt);return-1},ia:pb,dn:function(a,b){K=b;try{var c=vc();L();var d=L(),e=L(),k=L();jc(c,d,k);t[e>>2]=c.position;c.ku&&0===d&&0===k&&(c.ku=null);return 0}catch(l){return"undefined"!==
typeof FS&&l instanceof G||n(l),-l.ht}},Um:function(a,b){K=b;try{var c=vc(),d=L();a:{var e=L();for(b=a=0;b<e;b++){var k=t[d+(8*b+4)>>2],l=kc(c,x,t[d+8*b>>2],k,void 0);if(0>l){var m=-1;break a}a+=l;if(l<k)break}m=a}return m}catch(p){return"undefined"!==typeof FS&&p instanceof G||n(p),-p.ht}},ha:function(a,b){K=b;try{var c=vc(),d=L();a:{var e=L();for(b=a=0;b<e;b++){var k=c,l=t[d+8*b>>2],m=t[d+(8*b+4)>>2],p=void 0,r=x;if(0>m||0>p)throw new G(F.dt);if(null===k.fd)throw new G(F.st);if(0===(k.flags&2097155))throw new G(F.st);
if(16384===(k.node.mode&61440))throw new G(F.St);if(!k.Xs.write)throw new G(F.dt);k.flags&1024&&jc(k,0,2);var v="undefined"!==typeof p;if(!v)p=k.position;else if(!k.seekable)throw new G(F.Ut);var C=k.Xs.write(k,r,l,m,p,void 0);v||(k.position+=C);try{if(k.path&&Mb.onWriteToFile)Mb.onWriteToFile(k.path)}catch(J){console.log("FS.trackingDelegate['onWriteToFile']('"+path+"') threw an exception: "+J.message)}var A=C;if(0>A){var B=-1;break a}a+=A}B=a}return B}catch(J){return"undefined"!==typeof FS&&J instanceof
G||n(J),-J.ht}},zm:function(a,b){K=b;try{var c=vc(),d=L(),e=L();assert(0===L());var k=L(),l=L();0<=k?assert(0===l):assert(-1===l);return kc(c,x,d,e,k)}catch(m){return"undefined"!==typeof FS&&m instanceof G||n(m),-m.ht}},om:function(a,b){K=b;try{var c=L(),d=L(),e=L(),k=L(),l=L(),m=L();m<<=12;a=!1;if(-1===l){var p=jf(16384,d);if(!p)return-F.Au;kf(p,0,d);a=!0}else{var r=Ib[l];if(!r)return-F.st;b=h;if(1===(r.flags&2097155))throw new G(F.Rt);if(!r.Xs.au)throw new G(F.Tt);var v=r.Xs.au(r,b,c,d,m,e,k);p=
v.$s;a=v.ju}tc[p]={nv:p,mv:d,ju:a,fd:l,flags:k};return p}catch(C){return"undefined"!==typeof FS&&C instanceof G||n(C),-C.ht}},dm:function(a,b){K=b;try{var c=y(L()),d=L();return uc(c,d)}catch(e){return"undefined"!==typeof FS&&e instanceof G||n(e),-e.ht}},Ul:function(a,b){K=b;try{var c=vc(),d=L();return uc(c.path,d)}catch(e){return"undefined"!==typeof FS&&e instanceof G||n(e),-e.ht}},K:function(a,b){K=b;try{var c=vc();switch(L()){case 0:var d=L();return 0>d?-F.dt:fc(c.path,c.flags,0,d).fd;case 1:case 2:return 0;
case 3:return c.flags;case 4:return d=L(),c.flags|=d,0;case 12:case 12:return d=L(),Ia[d+0>>1]=2,0;case 13:case 14:case 13:case 14:return 0;case 16:case 8:return-F.dt;case 9:return pb(F.dt),-1;default:return-F.dt}}catch(e){return"undefined"!==typeof FS&&e instanceof G||n(e),-e.ht}},zl:function(a,b){K=b;try{var c=y(L()),d=L(),e=L();return fc(c,d,e).fd}catch(k){return"undefined"!==typeof FS&&k instanceof G||n(k),-k.ht}},ga:function(a,b){K=b;try{var c=vc(),d=L();switch(d){case 21509:case 21505:return c.tty?
0:-F.xt;case 21510:case 21511:case 21512:case 21506:case 21507:case 21508:return c.tty?0:-F.xt;case 21519:if(!c.tty)return-F.xt;var e=L();return t[e>>2]=0;case 21520:return c.tty?-F.dt:-F.xt;case 21531:a=e=L();if(!c.Xs.kv)throw new G(F.xt);return c.Xs.kv(c,d,a);case 21523:return c.tty?0:-F.xt;case 21524:return c.tty?0:-F.xt;default:n("bad ioctl syscall "+d)}}catch(k){return"undefined"!==typeof FS&&k instanceof G||n(k),-k.ht}},fa:function(a,b){K=b;try{var c=vc();ic(c);return 0}catch(d){return"undefined"!==
typeof FS&&d instanceof G||n(d),-d.ht}},Wk:function(a,b){K=b;try{var c=L(),d=L(),e=tc[c];if(!e)return 0;if(d===e.mv){var k=Ib[e.fd],l=e.flags,m=new Uint8Array(h.subarray(c,c+d));k&&k.Xs.cu&&k.Xs.cu(k,m,0,d,l);tc[c]=null;e.ju&&ld(e.nv)}return 0}catch(p){return"undefined"!==typeof FS&&p instanceof G||n(p),-p.ht}},J:function(){},N:function(a){var b=wc[a];delete wc[a];var c=b.elements,d=c.length,e=c.map(function(a){return a.nu}).concat(c.map(function(a){return a.uu})),k=b.Pt,l=b.kt;Hc([a],e,function(a){c.forEach(function(b,
c){var e=a[c],k=b.lu,l=b.mu,m=a[c+d],p=b.tu,r=b.vu;b.read=function(a){return e.fromWireType(k(l,a))};b.write=function(a,b){var c=[];p(r,a,m.toWireType(c,b));xc(c)}});return[{name:b.name,fromWireType:function(a){for(var b=Array(d),e=0;e<d;++e)b[e]=c[e].read(a);l(a);return b},toWireType:function(a,e){if(d!==e.length)throw new TypeError("Incorrect number of tuple elements for "+b.name+": expected="+d+", actual="+e.length);for(var m=k(),p=0;p<d;++p)c[p].write(m,e[p]);null!==a&&a.push(l,m);return m},argPackAdvance:8,
readValueFromPointer:yc,it:l}]})},E:function(a){var b=Jc[a];delete Jc[a];var c=b.Pt,d=b.kt,e=b.Ju,k=e.map(function(a){return a.nu}).concat(e.map(function(a){return a.uu}));Hc([a],k,function(a){var k={};e.forEach(function(b,c){var d=a[c],l=b.lu,m=b.mu,p=a[c+e.length],r=b.tu,Fa=b.vu;k[b.cv]={read:function(a){return d.fromWireType(l(m,a))},write:function(a,b){var c=[];r(Fa,a,p.toWireType(c,b));xc(c)}}});return[{name:b.name,fromWireType:function(a){var b={},c;for(c in k)b[c]=k[c].read(a);d(a);return b},
toWireType:function(a,b){for(var e in k)if(!(e in b))throw new TypeError("Missing field");var l=c();for(e in k)k[e].write(l,b[e]);null!==a&&a.push(d,l);return l},argPackAdvance:8,readValueFromPointer:yc,it:d}]})},hk:function(a,b,c,d,e){var k=Kc(c);b=M(b);Ic(a,{name:b,fromWireType:function(a){return!!a},toWireType:function(a,b){return b?d:e},argPackAdvance:8,readValueFromPointer:function(a){if(1===c)var d=x;else if(2===c)d=Ia;else if(4===c)d=t;else throw new TypeError("Unknown boolean type size: "+
b);return this.fromWireType(d[a>>k])},it:null})},o:function(a,b,c,d,e,k,l,m,p,r,v,C,A){v=M(v);k=O(e,k);m&&(m=O(l,m));r&&(r=O(p,r));A=O(C,A);var B=Cc(v);Uc(B,function(){md("Cannot construct "+v+" due to unbound types",[d])});Hc([a,b,c],d?[d]:[],function(b){b=b[0];if(d){var c=b.Zs;var e=c.Ht}else e=Rc.prototype;b=Dc(B,function(){if(Object.getPrototypeOf(this)!==l)throw new Mc("Use 'new' to construct "+v);if(void 0===p.zt)throw new Mc(v+" has no accessible constructor");var a=p.zt[arguments.length];
if(void 0===a)throw new Mc("Tried to invoke ctor of "+v+" with invalid number of parameters ("+arguments.length+") - expected ("+Object.keys(p.zt).toString()+") parameters instead!");return a.apply(this,arguments)});var l=Object.create(e,{constructor:{value:b}});b.prototype=l;var p=new Vc(v,b,l,A,c,k,m,r);c=new gd(v,p,!0,!1,!1);e=new gd(v+"*",p,!1,!1,!1);var C=new gd(v+" const*",p,!1,!0,!1);Sc[a]={pointerType:e,av:C};hd(B,b);return[c,e,C]})},T:function(a,b,c,d,e,k,l){var m=pd(c,d);b=M(b);k=O(e,k);
Hc([],[a],function(a){function d(){md("Cannot call "+e+" due to unbound types",m)}a=a[0];var e=a.name+"."+b,p=a.Zs.constructor;void 0===p[b]?(d.Ft=c-1,p[b]=d):(Tc(p,b,e),p[b].et[c-1]=d);Hc([],m,function(a){a=[a[0],null].concat(a.slice(1));a=od(e,a,null,k,l);void 0===p[b].et?(a.Ft=c-1,p[b]=a):p[b].et[c-1]=a;return[]});return[]})},w:function(a,b,c,d,e,k){var l=pd(b,c);e=O(d,e);Hc([],[a],function(a){a=a[0];var c="constructor "+a.name;void 0===a.Zs.zt&&(a.Zs.zt=[]);if(void 0!==a.Zs.zt[b-1])throw new Mc("Cannot register multiple constructors with identical number of parameters ("+
(b-1)+") for class '"+a.name+"'! Overload resolution is currently only performed using the parameter count, not actual type info!");a.Zs.zt[b-1]=function(){md("Cannot construct "+a.name+" due to unbound types",l)};Hc([],l,function(d){a.Zs.zt[b-1]=function(){arguments.length!==b-1&&N(c+" called with "+arguments.length+" arguments, expected "+(b-1));var a=[],l=Array(b);l[0]=k;for(var m=1;m<b;++m)l[m]=d[m].toWireType(a,arguments[m-1]);l=e.apply(null,l);xc(a);return d[0].fromWireType(l)};return[]});return[]})},
g:function(a,b,c,d,e,k,l,m){var p=pd(c,d);b=M(b);k=O(e,k);Hc([],[a],function(a){function d(){md("Cannot call "+e+" due to unbound types",p)}a=a[0];var e=a.name+"."+b;m&&a.Zs.rv.push(b);var r=a.Zs.Ht,B=r[b];void 0===B||void 0===B.et&&B.className!==a.name&&B.Ft===c-2?(d.Ft=c-2,d.className=a.name,r[b]=d):(Tc(r,b,e),r[b].et[c-2]=d);Hc([],p,function(d){d=od(e,d,a,k,l);void 0===r[b].et?(d.Ft=c-2,r[b]=d):r[b].et[c-2]=d;return[]});return[]})},S:function(a,b,c){a=M(a);Hc([],[b],function(b){b=b[0];f[a]=b.fromWireType(c);
return[]})},aj:function(a,b){b=M(b);Ic(a,{name:b,fromWireType:function(a){var b=P[a].value;rd(a);return b},toWireType:function(a,b){return $c(b)},argPackAdvance:8,readValueFromPointer:yc,it:null})},r:function(a,b,c,d){function e(){}c=Kc(c);b=M(b);e.values={};Ic(a,{name:b,constructor:e,fromWireType:function(a){return this.constructor.values[a]},toWireType:function(a,b){return b.value},argPackAdvance:8,readValueFromPointer:sd(b,c,d),it:null});Uc(b,e)},q:function(a,b,c){var d=td(a,"enum");b=M(b);a=d.constructor;
d=Object.create(d.constructor.prototype,{value:{value:c},constructor:{value:Dc(d.name+"_"+b,function(){})}});a.values[c]=d;a[b]=d},ea:function(a,b,c){c=Kc(c);b=M(b);Ic(a,{name:b,fromWireType:function(a){return a},toWireType:function(a,b){if("number"!==typeof b&&"boolean"!==typeof b)throw new TypeError('Cannot convert "'+Yc(b)+'" to '+this.name);return b},argPackAdvance:8,readValueFromPointer:ud(b,c),it:null})},n:function(a,b,c,d,e,k){var l=pd(b,c);a=M(a);e=O(d,e);Uc(a,function(){md("Cannot call "+
a+" due to unbound types",l)},b-1);Hc([],l,function(c){c=[c[0],null].concat(c.slice(1));hd(a,od(a,c,null,e,k),b-1);return[]})},z:function(a,b,c,d,e){function k(a){return a}b=M(b);-1===e&&(e=4294967295);var l=Kc(c);if(0===d){var m=32-8*c;k=function(a){return a<<m>>>m}}var p=-1!=b.indexOf("unsigned");Ic(a,{name:b,fromWireType:k,toWireType:function(a,c){if("number"!==typeof c&&"boolean"!==typeof c)throw new TypeError('Cannot convert "'+Yc(c)+'" to '+this.name);if(c<d||c>e)throw new TypeError('Passing a number "'+
Yc(c)+'" from JS side to C/C++ side to an argument of type "'+b+'", which is outside the valid range ['+d+", "+e+"]!");return p?c>>>0:c|0},argPackAdvance:8,readValueFromPointer:vd(b,l,0!==d),it:null})},v:function(a,b,c){function d(a){a>>=2;var b=z;return new e(b.buffer,b[a+1],b[a])}var e=[Int8Array,Uint8Array,Int16Array,Uint16Array,Int32Array,Uint32Array,Float32Array,Float64Array][b];c=M(c);Ic(a,{name:c,fromWireType:d,argPackAdvance:8,readValueFromPointer:d},{iv:!0})},u:function(a,b,c,d,e,k,l,m,p,
r,v,C){c=M(c);k=O(e,k);m=O(l,m);r=O(p,r);C=O(v,C);Hc([a],[b],function(a){a=a[0];return[new gd(c,a.Zs,!1,!1,!0,a,d,k,m,r,C)]})},da:function(a,b){b=M(b);var c="std::string"===b;Ic(a,{name:b,fromWireType:function(a){var b=z[a>>2];if(c){var d=h[a+4+b],l=0;0!=d&&(l=d,h[a+4+b]=0);var m=a+4;for(d=0;d<=b;++d){var p=a+4+d;if(0==h[p]){m=Ba(h,m);if(void 0===r)var r=m;else r+=String.fromCharCode(0),r+=m;m=p+1}}0!=l&&(h[a+4+b]=l)}else{r=Array(b);for(d=0;d<b;++d)r[d]=String.fromCharCode(h[a+4+d]);r=r.join("")}ld(a);
return r},toWireType:function(a,b){b instanceof ArrayBuffer&&(b=new Uint8Array(b));var d="string"===typeof b;d||b instanceof Uint8Array||b instanceof Uint8ClampedArray||b instanceof Int8Array||N("Cannot pass non-string to std::string");var e=(c&&d?function(){return ba(b)}:function(){return b.length})(),m=Aa(4+e+1);z[m>>2]=e;if(c&&d)g(b,h,m+4,e+1);else if(d)for(d=0;d<e;++d){var p=b.charCodeAt(d);255<p&&(ld(m),N("String has UTF-16 code units that do not fit in 8 bits"));h[m+4+d]=p}else for(d=0;d<e;++d)h[m+
4+d]=b[d];null!==a&&a.push(ld,m);return m},argPackAdvance:8,readValueFromPointer:yc,it:function(a){ld(a)}})},qh:function(a,b,c){c=M(c);if(2===b){var d=function(){return Ja};var e=1}else 4===b&&(d=function(){return z},e=2);Ic(a,{name:c,fromWireType:function(a){for(var b=d(),c=z[a>>2],k=Array(c),r=a+4>>e,v=0;v<c;++v)k[v]=String.fromCharCode(b[r+v]);ld(a);return k.join("")},toWireType:function(a,c){var k=d(),l=c.length,r=Aa(4+l*b);z[r>>2]=l;for(var v=r+4>>e,C=0;C<l;++C)k[v+C]=c.charCodeAt(C);null!==
a&&a.push(ld,r);return r},argPackAdvance:8,readValueFromPointer:yc,it:function(a){ld(a)}})},M:function(a,b,c,d,e,k){wc[a]={name:M(b),Pt:O(c,d),kt:O(e,k),elements:[]}},L:function(a,b,c,d,e,k,l,m,p){wc[a].elements.push({nu:b,lu:O(c,d),mu:e,uu:k,tu:O(l,m),vu:p})},D:function(a,b,c,d,e,k){Jc[a]={name:M(b),Pt:O(c,d),kt:O(e,k),Ju:[]}},t:function(a,b,c,d,e,k,l,m,p,r){Jc[a].Ju.push({cv:M(b),nu:c,lu:O(d,e),mu:k,uu:l,tu:O(m,p),vu:r})},sg:function(a,b){b=M(b);Ic(a,{lv:!0,name:b,argPackAdvance:0,fromWireType:function(){},
toWireType:function(){}})},H:function(a,b,c,d){a=yd[a];b||N("Cannot use deleted val. handle = "+b);b=P[b].value;c=xd(c);a(b,c,null,d)},G:rd,F:function(a,b){b=Ad(a,b);for(var c=b[0],d=c.name+"_$"+b.slice(1).map(function(a){return a.name}).join("_")+"$",e=["retType"],k=[c],l="",m=0;m<a-1;++m)l+=(0!==m?", ":"")+"arg"+m,e.push("argType"+m),k.push(b[1+m]);d="return function "+Cc("methodCaller_"+d)+"(handle, name, destructors, args) {\n";var p=0;for(m=0;m<a-1;++m)d+="    var arg"+m+" = argType"+m+".readValueFromPointer(args"+
(p?"+"+p:"")+");\n",p+=b[m+1].argPackAdvance;d+="    var rv = handle[name]("+l+");\n";for(m=0;m<a-1;++m)b[m+1].deleteObject&&(d+="    argType"+m+".deleteObject(arg"+m+");\n");c.lv||(d+="    return retType.toWireType(destructors, rv);\n");e.push(d+"};\n");a=nd(e).apply(null,k);return zd(a)},Ff:function(a){4<a&&(P[a].It+=1)},uf:function(){return $c([])},jf:function(a){return $c(xd(a))},ca:function(a,b){a=td(a,"_emval_take_value");a=a.readValueFromPointer(b);return $c(a)},c:function(){f.abort()},Fe:function(a,
b){if(0===a)a=Date.now();else if(1===a&&(ha||"undefined"!==typeof dateNow||(ea||fa)&&self.performance&&self.performance.now))a=Bd();else return pb(F.dt),-1;t[b>>2]=a/1E3|0;t[b+4>>2]=a%1E3*1E6|0;return 0},R:function(a){if(Dd[a]){var b=Dd[a];0==--b.It&&(b.module.Zu&&b.module.Zu.forEach(function(a){a()}),delete Ed[b.name],delete Dd[a]);return 0}return 1},Q:function(a,b){n("To use dlopen, you need to use Emscripten's linking support, see https://github.com/kripken/emscripten/wiki/Linking");var c=[];if(0===
a)a="__self__";else{a=y(a);var d=function(a){return(a=pc(a))&&!a.pu&&!a.ou};if(!d(a)){E.LD_LIBRARY_PATH&&(c=E.LD_LIBRARY_PATH.split(":"));for(var e in c){var k=sb(c[e]+"/"+a);if(d(k)){a=k;break}}}}if(Ed[a]){var l=Ed[a];Dd[l].It++;return l}if("__self__"===a){l=-1;var m=f}else{if(void 0!==f.preloadedWasm&&void 0!==f.preloadedWasm[a])m=f.preloadedWasm[a];else{c=pc(a);if(!c||c.pu||c.ou)return 0;sc(c);try{l=lc(a),l instanceof Uint8Array||(l=new Uint8Array(l)),m=loadWebAssemblyModule(l)}catch(p){return 0}}l=
Cd++;if(b&256)for(e in m)m.hasOwnProperty(e)&&"_"==e[0]&&(f[e]=m[e])}Dd[l]={It:1,name:a,module:m};return Ed[a]=l},P:function(a,b){b=y(b);if(Dd[a]&&(a=Dd[a],b="_"+b,a.module.hasOwnProperty(b))){b=a.module[b];if("function"===typeof b)a:{for(a=0;0>a;a++)if(!ya[a]){ya[a]=b;b=1+a;break a}throw"Finished up all reserved function pointers. Use a higher value for RESERVED_FUNCTION_POINTERS.";}return b}return 0},Sd:function(){return 0},Hd:function(a){return lf(a)},wd:function(a,b){if(62E3!=a)return 0;if(se[b])return se[b];
switch(b){case 12371:a=w(H("Emscripten"));break;case 12372:a=w(H("1.4 Emscripten EGL"));break;case 12373:a=w(H(""));break;case 12429:a=w(H("OpenGL_ES"));break;default:return 0}return se[b]=a},ld:function(){q("missing function: emscripten_glAccum");n(-1)},ad:_emscripten_glActiveTexture,Rc:function(){q("missing function: emscripten_glAlphaFunc");n(-1)},Gc:function(){q("missing function: emscripten_glAreTexturesResident");n(-1)},yc:function(){q("missing function: emscripten_glArrayElement");n(-1)},qc:function(){q("missing function: emscripten_glAttachObjectARB");
n(-1)},fc:_emscripten_glAttachShader,Wb:function(){throw"Legacy GL function (glBegin) called. If you want legacy GL emulation, you need to compile with -s LEGACY_GL_EMULATION=1 to enable legacy GL emulation.";},Lb:function(){q("missing function: emscripten_glBeginConditionalRender");n(-1)},Bb:function(){q("missing function: emscripten_glBeginQuery");n(-1)},qb:function(){q("missing function: emscripten_glBeginTransformFeedback");n(-1)},fb:_emscripten_glBindAttribLocation,Wa:_emscripten_glBindBuffer,
La:function(){q("missing function: emscripten_glBindBufferBase");n(-1)},Ba:function(){q("missing function: emscripten_glBindBufferRange");n(-1)},wa:function(){q("missing function: emscripten_glBindFragDataLocation");n(-1)},qa:function(a,b){GLctx.bindFramebuffer(a,b?we[b]:null)},pa:function(){q("missing function: emscripten_glBindProgramARB");n(-1)},oa:function(a,b){GLctx.bindRenderbuffer(a,b?R[b]:null)},na:function(a,b){GLctx.bindTexture(a,b?S[b]:null)},ma:function(a){GLctx.bindVertexArray(xe[a])},
la:function(){q("missing function: emscripten_glBitmap");n(-1)},ka:function(a,b,c,d){GLctx.blendColor(a,b,c,d)},ja:function(a){GLctx.blendEquation(a)},cq:function(a,b){GLctx.blendEquationSeparate(a,b)},bq:function(a,b){GLctx.blendFunc(a,b)},aq:function(a,b,c,d){GLctx.blendFuncSeparate(a,b,c,d)},$p:function(){q("missing function: emscripten_glBlitFramebuffer");n(-1)},_p:function(a,b,c,d){c?GLctx.bufferData(a,h.subarray(c,c+b),d):GLctx.bufferData(a,b,d)},Zp:function(a,b,c,d){GLctx.bufferSubData(a,b,
h.subarray(d,d+c))},Yp:function(){q("missing function: emscripten_glCallList");n(-1)},Xp:function(){q("missing function: emscripten_glCallLists");n(-1)},Wp:function(a){return GLctx.checkFramebufferStatus(a)},Vp:function(){q("missing function: emscripten_glClampColor");n(-1)},Tp:function(a){GLctx.clear(a)},Sp:function(){q("missing function: emscripten_glClearAccum");n(-1)},Rp:function(){q("missing function: emscripten_glClearBufferfi");n(-1)},Qp:function(){q("missing function: emscripten_glClearBufferfv");
n(-1)},Pp:function(){q("missing function: emscripten_glClearBufferiv");n(-1)},Op:function(){q("missing function: emscripten_glClearBufferuiv");n(-1)},Np:function(a,b,c,d){GLctx.clearColor(a,b,c,d)},Mp:function(a){GLctx.clearDepth(a)},Lp:function(a){GLctx.clearDepth(a)},Kp:function(){q("missing function: emscripten_glClearIndex");n(-1)},Ip:function(a){GLctx.clearStencil(a)},Hp:function(){q("missing function: emscripten_glClientActiveTexture");n(-1)},Gp:function(){q("missing function: emscripten_glClipPlane");
n(-1)},Fp:function(){q("missing function: emscripten_glColor3b");n(-1)},Ep:function(){q("missing function: emscripten_glColor3bv");n(-1)},Dp:function(){q("missing function: emscripten_glColor3d");n(-1)},Cp:function(){q("missing function: emscripten_glColor3dv");n(-1)},Bp:function(){q("missing function: emscripten_glColor3f");n(-1)},Ap:function(){q("missing function: emscripten_glColor3fv");n(-1)},zp:function(){q("missing function: emscripten_glColor3i");n(-1)},yp:function(){q("missing function: emscripten_glColor3iv");
n(-1)},xp:function(){q("missing function: emscripten_glColor3s");n(-1)},wp:function(){q("missing function: emscripten_glColor3sv");n(-1)},vp:function(){q("missing function: emscripten_glColor3ub");n(-1)},up:function(){q("missing function: emscripten_glColor3ubv");n(-1)},tp:function(){q("missing function: emscripten_glColor3ui");n(-1)},sp:function(){q("missing function: emscripten_glColor3uiv");n(-1)},rp:function(){q("missing function: emscripten_glColor3us");n(-1)},qp:function(){q("missing function: emscripten_glColor3usv");
n(-1)},pp:function(){q("missing function: emscripten_glColor4b");n(-1)},op:function(){q("missing function: emscripten_glColor4bv");n(-1)},np:function(){q("missing function: emscripten_glColor4d");n(-1)},mp:function(){q("missing function: emscripten_glColor4dv");n(-1)},lp:function(){q("missing function: emscripten_glColor4f");n(-1)},kp:function(){q("missing function: emscripten_glColor4fv");n(-1)},jp:function(){q("missing function: emscripten_glColor4i");n(-1)},ip:function(){q("missing function: emscripten_glColor4iv");
n(-1)},hp:function(){q("missing function: emscripten_glColor4s");n(-1)},gp:function(){q("missing function: emscripten_glColor4sv");n(-1)},fp:function(){q("missing function: emscripten_glColor4ub");n(-1)},ep:function(){q("missing function: emscripten_glColor4ubv");n(-1)},dp:function(){q("missing function: emscripten_glColor4ui");n(-1)},cp:function(){q("missing function: emscripten_glColor4uiv");n(-1)},bp:function(){q("missing function: emscripten_glColor4us");n(-1)},ap:function(){q("missing function: emscripten_glColor4usv");
n(-1)},$o:function(a,b,c,d){GLctx.colorMask(!!a,!!b,!!c,!!d)},_o:function(){q("missing function: emscripten_glColorMaski");n(-1)},Zo:function(){q("missing function: emscripten_glColorMaterial");n(-1)},Yo:function(){q("missing function: emscripten_glColorPointer");n(-1)},Xo:function(){q("missing function: emscripten_glColorSubTable");n(-1)},Wo:function(){q("missing function: emscripten_glColorTable");n(-1)},Vo:function(){q("missing function: emscripten_glColorTableParameterfv");n(-1)},Uo:function(){q("missing function: emscripten_glColorTableParameteriv");
n(-1)},To:_emscripten_glCompileShader,So:function(){q("missing function: emscripten_glCompressedTexImage1D");n(-1)},Ro:function(a,b,c,d,e,k,l,m){GLctx.compressedTexImage2D(a,b,c,d,e,k,m?h.subarray(m,m+l):null)},Qo:function(){q("missing function: emscripten_glCompressedTexImage3D");n(-1)},Po:function(){q("missing function: emscripten_glCompressedTexSubImage1D");n(-1)},Oo:function(a,b,c,d,e,k,l,m,p){GLctx.compressedTexSubImage2D(a,b,c,d,e,k,l,p?h.subarray(p,p+m):null)},No:function(){q("missing function: emscripten_glCompressedTexSubImage3D");
n(-1)},Lo:function(){q("missing function: emscripten_glConvolutionFilter1D");n(-1)},Ko:function(){q("missing function: emscripten_glConvolutionFilter2D");n(-1)},Jo:function(){q("missing function: emscripten_glConvolutionParameterf");n(-1)},Io:function(){q("missing function: emscripten_glConvolutionParameterfv");n(-1)},Ho:function(){q("missing function: emscripten_glConvolutionParameteri");n(-1)},Go:function(){q("missing function: emscripten_glConvolutionParameteriv");n(-1)},Fo:function(){q("missing function: emscripten_glCopyColorSubTable");
n(-1)},Eo:function(){q("missing function: emscripten_glCopyColorTable");n(-1)},Do:function(){q("missing function: emscripten_glCopyConvolutionFilter1D");n(-1)},Co:function(){q("missing function: emscripten_glCopyConvolutionFilter2D");n(-1)},Ao:function(){q("missing function: emscripten_glCopyPixels");n(-1)},zo:function(){q("missing function: emscripten_glCopyTexImage1D");n(-1)},yo:function(a,b,c,d,e,k,l,m){GLctx.copyTexImage2D(a,b,c,d,e,k,l,m)},xo:function(){q("missing function: emscripten_glCopyTexSubImage1D");
n(-1)},wo:function(a,b,c,d,e,k,l,m){GLctx.copyTexSubImage2D(a,b,c,d,e,k,l,m)},vo:function(){q("missing function: emscripten_glCopyTexSubImage3D");n(-1)},uo:function(){var a=W(Q),b=GLctx.createProgram();b.name=a;Q[a]=b;return a},to:function(){q("missing function: emscripten_glCreateProgramObjectARB");n(-1)},so:_emscripten_glCreateShader,ro:function(){q("missing function: emscripten_glCreateShaderObjectARB");n(-1)},po:function(a){GLctx.cullFace(a)},oo:function(a,b){for(var c=0;c<a;c++){var d=t[b+4*
c>>2],e=ve[d];e&&(GLctx.deleteBuffer(e),e.name=0,ve[d]=null,d==Ie&&(Ie=0),d==Je&&(Je=0))}},no:function(a,b){for(var c=0;c<a;++c){var d=t[b+4*c>>2],e=we[d];e&&(GLctx.deleteFramebuffer(e),e.name=0,we[d]=null)}},mo:function(){q("missing function: emscripten_glDeleteLists");n(-1)},lo:function(){q("missing function: emscripten_glDeleteObjectARB");n(-1)},ko:_emscripten_glDeleteProgram,jo:function(){q("missing function: emscripten_glDeleteProgramsARB");n(-1)},io:function(){q("missing function: emscripten_glDeleteQueries");
n(-1)},ho:function(a,b){for(var c=0;c<a;c++){var d=t[b+4*c>>2],e=R[d];e&&(GLctx.deleteRenderbuffer(e),e.name=0,R[d]=null)}},go:function(a){if(a){var b=U[a];b?(GLctx.deleteShader(b),U[a]=null):V(1281)}},co:function(a,b){for(var c=0;c<a;c++){var d=t[b+4*c>>2],e=S[d];e&&(GLctx.deleteTexture(e),e.name=0,S[d]=null)}},bo:function(a,b){for(var c=0;c<a;c++){var d=t[b+4*c>>2];GLctx.deleteVertexArray(xe[d]);xe[d]=null}},ao:function(a){GLctx.depthFunc(a)},$n:function(a){GLctx.depthMask(!!a)},_n:function(a,b){GLctx.depthRange(a,
b)},Zn:function(a,b){GLctx.depthRange(a,b)},Yn:function(){q("missing function: emscripten_glDetachObjectARB");n(-1)},Xn:_emscripten_glDetachShader,Wn:_emscripten_glDisable,Vn:function(){q("missing function: emscripten_glDisableClientState");n(-1)},Tn:_emscripten_glDisableVertexAttribArray,Sn:function(){q("missing function: emscripten_glDisablei");n(-1)},Rn:_emscripten_glDrawArrays,Qn:function(a,b,c,d){GLctx.drawArraysInstanced(a,b,c,d)},Pn:function(){q("missing function: emscripten_glDrawBuffer");
n(-1)},On:function(a,b){for(var c=Be[a],d=0;d<a;d++)c[d]=t[b+4*d>>2];GLctx.drawBuffers(c)},Nn:_emscripten_glDrawElements,Mn:function(a,b,c,d,e){GLctx.drawElementsInstanced(a,b,c,d,e)},Ln:function(){q("missing function: emscripten_glDrawPixels");n(-1)},Kn:function(){q("missing function: emscripten_glDrawRangeElements");n(-1)},Jn:function(){q("missing function: emscripten_glEdgeFlag");n(-1)},In:function(){q("missing function: emscripten_glEdgeFlagPointer");n(-1)},Hn:function(){q("missing function: emscripten_glEdgeFlagv");
n(-1)},Gn:_emscripten_glEnable,Fn:function(){q("missing function: emscripten_glEnableClientState");n(-1)},En:_emscripten_glEnableVertexAttribArray,Dn:function(){q("missing function: emscripten_glEnablei");n(-1)},Cn:function(){q("missing function: emscripten_glEnd");n(-1)},Bn:function(){q("missing function: emscripten_glEndConditionalRender");n(-1)},An:function(){q("missing function: emscripten_glEndList");n(-1)},yn:function(){q("missing function: emscripten_glEndQuery");n(-1)},xn:function(){q("missing function: emscripten_glEndTransformFeedback");
n(-1)},wn:function(){q("missing function: emscripten_glEvalCoord1d");n(-1)},vn:function(){q("missing function: emscripten_glEvalCoord1dv");n(-1)},un:function(){q("missing function: emscripten_glEvalCoord1f");n(-1)},tn:function(){q("missing function: emscripten_glEvalCoord1fv");n(-1)},sn:function(){q("missing function: emscripten_glEvalCoord2d");n(-1)},rn:function(){q("missing function: emscripten_glEvalCoord2dv");n(-1)},qn:function(){q("missing function: emscripten_glEvalCoord2f");n(-1)},pn:function(){q("missing function: emscripten_glEvalCoord2fv");
n(-1)},on:function(){q("missing function: emscripten_glEvalMesh1");n(-1)},nn:function(){q("missing function: emscripten_glEvalMesh2");n(-1)},mn:function(){q("missing function: emscripten_glEvalPoint1");n(-1)},ln:function(){q("missing function: emscripten_glEvalPoint2");n(-1)},kn:function(){q("missing function: emscripten_glFeedbackBuffer");n(-1)},jn:function(){GLctx.finish()},hn:function(){GLctx.flush()},gn:function(){q("missing function: emscripten_glFogCoordPointer");n(-1)},fn:function(){q("missing function: emscripten_glFogCoordd");
n(-1)},en:function(){q("missing function: emscripten_glFogCoorddv");n(-1)},cn:function(){q("missing function: emscripten_glFogCoordf");n(-1)},bn:function(){q("missing function: emscripten_glFogCoordfv");n(-1)},an:function(){q("missing function: emscripten_glFogf");n(-1)},$m:function(){q("missing function: emscripten_glFogfv");n(-1)},_m:function(){q("missing function: emscripten_glFogi");n(-1)},Zm:function(){q("missing function: emscripten_glFogiv");n(-1)},Ym:function(a,b,c,d){GLctx.framebufferRenderbuffer(a,
b,c,R[d])},Xm:function(){q("missing function: emscripten_glFramebufferTexture1D");n(-1)},Wm:function(a,b,c,d,e){GLctx.framebufferTexture2D(a,b,c,S[d],e)},Vm:function(){q("missing function: emscripten_glFramebufferTexture3D");n(-1)},Tm:function(){q("missing function: emscripten_glFramebufferTextureLayer");n(-1)},Sm:function(a){GLctx.frontFace(a)},Rm:function(){q("missing function: emscripten_glFrustum");n(-1)},Qm:function(a,b){for(var c=0;c<a;c++){var d=GLctx.createBuffer();if(!d){for(V(1282);c<a;)t[b+
4*c++>>2]=0;break}var e=W(ve);d.name=e;ve[e]=d;t[b+4*c>>2]=e}},Pm:function(a,b){for(var c=0;c<a;++c){var d=GLctx.createFramebuffer();if(!d){for(V(1282);c<a;)t[b+4*c++>>2]=0;break}var e=W(we);d.name=e;we[e]=d;t[b+4*c>>2]=e}},Om:function(){q("missing function: emscripten_glGenLists");n(-1)},Nm:function(){q("missing function: emscripten_glGenProgramsARB");n(-1)},Mm:function(){q("missing function: emscripten_glGenQueries");n(-1)},Lm:function(a,b){for(var c=0;c<a;c++){var d=GLctx.createRenderbuffer();
if(!d){for(V(1282);c<a;)t[b+4*c++>>2]=0;break}var e=W(R);d.name=e;R[e]=d;t[b+4*c>>2]=e}},Km:function(a,b){for(var c=0;c<a;c++){var d=GLctx.createTexture();if(!d){for(V(1282);c<a;)t[b+4*c++>>2]=0;break}var e=W(S);d.name=e;S[e]=d;t[b+4*c>>2]=e}},Jm:function(a,b){for(var c=0;c<a;c++){var d=GLctx.createVertexArray();if(!d){for(V(1282);c<a;)t[b+4*c++>>2]=0;break}var e=W(xe);d.name=e;xe[e]=d;t[b+4*c>>2]=e}},Im:function(a){GLctx.generateMipmap(a)},Hm:function(a,b,c,d,e,k,l){a=Q[a];if(a=GLctx.getActiveAttrib(a,
b))0<c&&l?(c=g(a.name,h,l,c),d&&(t[d>>2]=c)):d&&(t[d>>2]=0),e&&(t[e>>2]=a.size),k&&(t[k>>2]=a.type)},Gm:function(a,b,c,d,e,k,l){a=Q[a];if(a=GLctx.getActiveUniform(a,b))0<c&&l?(c=g(a.name,h,l,c),d&&(t[d>>2]=c)):d&&(t[d>>2]=0),e&&(t[e>>2]=a.size),k&&(t[k>>2]=a.type)},Fm:function(){q("missing function: emscripten_glGetActiveUniformBlockName");n(-1)},Em:function(){q("missing function: emscripten_glGetActiveUniformBlockiv");n(-1)},Dm:function(){q("missing function: emscripten_glGetActiveUniformName");
n(-1)},Cm:function(){q("missing function: emscripten_glGetActiveUniformsiv");n(-1)},Bm:function(){q("missing function: emscripten_glGetAttachedObjectsARB");n(-1)},Am:function(a,b,c,d){a=GLctx.getAttachedShaders(Q[a]);var e=a.length;e>b&&(e=b);t[c>>2]=e;for(b=0;b<e;++b)t[d+4*b>>2]=U.indexOf(a[b])},ym:function(a,b){return GLctx.getAttribLocation(Q[a],y(b))},xm:function(){q("missing function: emscripten_glGetBooleani_v");n(-1)},wm:_emscripten_glGetBooleanv,vm:function(a,b,c){c?t[c>>2]=GLctx.getBufferParameter(a,
b):V(1281)},um:function(){q("missing function: emscripten_glGetBufferPointerv");n(-1)},tm:function(){q("missing function: emscripten_glGetBufferSubData");n(-1)},sm:function(){q("missing function: emscripten_glGetClipPlane");n(-1)},rm:function(){q("missing function: emscripten_glGetColorTable");n(-1)},qm:function(){q("missing function: emscripten_glGetColorTableParameterfv");n(-1)},pm:function(){q("missing function: emscripten_glGetColorTableParameteriv");n(-1)},nm:function(){q("missing function: emscripten_glGetCompressedTexImage");
n(-1)},mm:function(){q("missing function: emscripten_glGetConvolutionFilter");n(-1)},lm:function(){q("missing function: emscripten_glGetConvolutionParameterfv");n(-1)},km:function(){q("missing function: emscripten_glGetConvolutionParameteriv");n(-1)},jm:function(){q("missing function: emscripten_glGetDoublev");n(-1)},im:function(){if(ue){var a=ue;ue=0;return a}return GLctx.getError()},hm:_emscripten_glGetFloatv,gm:function(){q("missing function: emscripten_glGetFragDataLocation");n(-1)},fm:function(a,
b,c,d){a=GLctx.getFramebufferAttachmentParameter(a,b,c);if(a instanceof WebGLRenderbuffer||a instanceof WebGLTexture)a=a.name|0;t[d>>2]=a},em:function(){q("missing function: emscripten_glGetHandleARB");n(-1)},cm:function(){q("missing function: emscripten_glGetHistogram");n(-1)},bm:function(){q("missing function: emscripten_glGetHistogramParameterfv");n(-1)},am:function(){q("missing function: emscripten_glGetHistogramParameteriv");n(-1)},$l:function(){q("missing function: emscripten_glGetInfoLogARB");
n(-1)},_l:function(){q("missing function: emscripten_glGetIntegeri_v");n(-1)},Zl:_emscripten_glGetIntegerv,Yl:function(){q("missing function: emscripten_glGetLightfv");n(-1)},Xl:function(){q("missing function: emscripten_glGetLightiv");n(-1)},Wl:function(){q("missing function: emscripten_glGetMapdv");n(-1)},Vl:function(){q("missing function: emscripten_glGetMapfv");n(-1)},Tl:function(){q("missing function: emscripten_glGetMapiv");n(-1)},Sl:function(){q("missing function: emscripten_glGetMaterialfv");
n(-1)},Rl:function(){q("missing function: emscripten_glGetMaterialiv");n(-1)},Ql:function(){q("missing function: emscripten_glGetMinmax");n(-1)},Pl:function(){q("missing function: emscripten_glGetMinmaxParameterfv");n(-1)},Ol:function(){q("missing function: emscripten_glGetMinmaxParameteriv");n(-1)},Nl:function(){q("missing function: emscripten_glGetObjectParameterfvARB");n(-1)},Ml:function(){q("missing function: emscripten_glGetObjectParameterivARB");n(-1)},Ll:function(){q("missing function: emscripten_glGetPixelMapfv");
n(-1)},Kl:function(){q("missing function: emscripten_glGetPixelMapuiv");n(-1)},Jl:function(){q("missing function: emscripten_glGetPixelMapusv");n(-1)},Il:function(){q("missing function: emscripten_glGetPointerv");n(-1)},Hl:function(){q("missing function: emscripten_glGetPolygonStipple");n(-1)},Gl:function(){q("missing function: emscripten_glGetProgramEnvParameterdvARB");n(-1)},Fl:function(){q("missing function: emscripten_glGetProgramEnvParameterfvARB");n(-1)},El:function(a,b,c,d){a=GLctx.getProgramInfoLog(Q[a]);
null===a&&(a="(unknown error)");0<b&&d?(b=g(a,h,d,b),c&&(t[c>>2]=b)):c&&(t[c>>2]=0)},Dl:function(){q("missing function: emscripten_glGetProgramLocalParameterdvARB");n(-1)},Cl:function(){q("missing function: emscripten_glGetProgramLocalParameterfvARB");n(-1)},Bl:function(){q("missing function: emscripten_glGetProgramStringARB");n(-1)},Al:function(a,b,c){if(c)if(a>=te)V(1281);else{var d=ze[a];if(d)if(35716==b)a=GLctx.getProgramInfoLog(Q[a]),null===a&&(a="(unknown error)"),t[c>>2]=a.length+1;else if(35719==
b)t[c>>2]=d.Zt;else if(35722==b){if(-1==d.ot){a=Q[a];var e=GLctx.getProgramParameter(a,GLctx.ACTIVE_ATTRIBUTES);for(b=d.ot=0;b<e;++b)d.ot=Math.max(d.ot,GLctx.getActiveAttrib(a,b).name.length+1)}t[c>>2]=d.ot}else if(35381==b){if(-1==d.pt)for(a=Q[a],e=GLctx.getProgramParameter(a,GLctx.Qu),b=d.pt=0;b<e;++b){var k=GLctx.dv(a,b);d.pt=Math.max(d.pt,k.length+1)}t[c>>2]=d.pt}else t[c>>2]=GLctx.getProgramParameter(Q[a],b);else V(1282)}else V(1281)},yl:function(){q("missing function: emscripten_glGetQueryObjectiv");
n(-1)},xl:function(){q("missing function: emscripten_glGetQueryObjectuiv");n(-1)},wl:function(){q("missing function: emscripten_glGetQueryiv");n(-1)},vl:function(a,b,c){c?t[c>>2]=GLctx.getRenderbufferParameter(a,b):V(1281)},ul:function(){q("missing function: emscripten_glGetSeparableFilter");n(-1)},tl:function(a,b,c,d){a=GLctx.getShaderInfoLog(U[a]);null===a&&(a="(unknown error)");0<b&&d?(b=g(a,h,d,b),c&&(t[c>>2]=b)):c&&(t[c>>2]=0)},sl:function(a,b,c,d){a=GLctx.getShaderPrecisionFormat(a,b);t[c>>
2]=a.rangeMin;t[c+4>>2]=a.rangeMax;t[d>>2]=a.precision},rl:function(a,b,c,d){if(a=GLctx.getShaderSource(U[a]))0<b&&d?(b=g(a,h,d,b),c&&(t[c>>2]=b)):c&&(t[c>>2]=0)},ql:function(a,b,c){c?35716==b?(a=GLctx.getShaderInfoLog(U[a]),null===a&&(a="(unknown error)"),t[c>>2]=a.length+1):35720==b?(a=GLctx.getShaderSource(U[a]),t[c>>2]=null===a||0==a.length?0:a.length+1):t[c>>2]=GLctx.getShaderParameter(U[a],b):V(1281)},pl:_emscripten_glGetString,ol:function(){q("missing function: emscripten_glGetStringi");n(-1)},
nl:function(){q("missing function: emscripten_glGetTexEnvfv");n(-1)},ml:function(){q("missing function: emscripten_glGetTexEnviv");n(-1)},ll:function(){q("missing function: emscripten_glGetTexGendv");n(-1)},kl:function(){q("missing function: emscripten_glGetTexGenfv");n(-1)},jl:function(){q("missing function: emscripten_glGetTexGeniv");n(-1)},il:function(){q("missing function: emscripten_glGetTexImage");n(-1)},hl:function(){q("missing function: emscripten_glGetTexLevelParameterfv");n(-1)},gl:function(){q("missing function: emscripten_glGetTexLevelParameteriv");
n(-1)},fl:function(){q("missing function: emscripten_glGetTexParameterIiv");n(-1)},el:function(){q("missing function: emscripten_glGetTexParameterIuiv");n(-1)},dl:function(a,b,c){c?D[c>>2]=GLctx.getTexParameter(a,b):V(1281)},cl:function(a,b,c){c?t[c>>2]=GLctx.getTexParameter(a,b):V(1281)},bl:function(){q("missing function: emscripten_glGetTransformFeedbackVarying");n(-1)},al:function(){q("missing function: emscripten_glGetUniformBlockIndex");n(-1)},$k:function(){q("missing function: emscripten_glGetUniformIndices");
n(-1)},_k:function(a,b){b=y(b);var c=0;if(-1!==b.indexOf("]",b.length-1)){var d=b.lastIndexOf("["),e=b.slice(d+1,-1);if(0<e.length&&(c=parseInt(e),0>c))return-1;b=b.slice(0,d)}a=ze[a];return a?(b=a.wu[b])&&c<b[0]?b[1]+c:-1:-1},Zk:function(a,b,c){Le(a,b,c,"Float")},Yk:function(a,b,c){Le(a,b,c,"Integer")},Xk:function(){q("missing function: emscripten_glGetUniformuiv");n(-1)},Vk:function(){q("missing function: emscripten_glGetVertexAttribIiv");n(-1)},Uk:function(){q("missing function: emscripten_glGetVertexAttribIuiv");
n(-1)},Tk:function(a,b,c){c?t[c>>2]=GLctx.getVertexAttribOffset(a,b):V(1281)},Sk:function(){q("missing function: emscripten_glGetVertexAttribdv");n(-1)},Rk:function(a,b,c){Me(a,b,c,"Float")},Qk:function(a,b,c){Me(a,b,c,"FloatToInteger")},Pk:_emscripten_glHint,Ok:function(){q("missing function: emscripten_glHistogram");n(-1)},Nk:function(){q("missing function: emscripten_glIndexMask");n(-1)},Mk:function(){q("missing function: emscripten_glIndexPointer");n(-1)},Lk:function(){q("missing function: emscripten_glIndexd");
n(-1)},Kk:function(){q("missing function: emscripten_glIndexdv");n(-1)},Jk:function(){q("missing function: emscripten_glIndexf");n(-1)},Ik:function(){q("missing function: emscripten_glIndexfv");n(-1)},Hk:function(){q("missing function: emscripten_glIndexi");n(-1)},Gk:function(){q("missing function: emscripten_glIndexiv");n(-1)},Fk:function(){q("missing function: emscripten_glIndexs");n(-1)},Ek:function(){q("missing function: emscripten_glIndexsv");n(-1)},Dk:function(){q("missing function: emscripten_glIndexub");
n(-1)},Ck:function(){q("missing function: emscripten_glIndexubv");n(-1)},Bk:function(){q("missing function: emscripten_glInitNames");n(-1)},Ak:function(){q("missing function: emscripten_glInterleavedArrays");n(-1)},zk:function(a){return(a=ve[a])?GLctx.isBuffer(a):0},yk:_emscripten_glIsEnabled,xk:function(){q("missing function: emscripten_glIsEnabledi");n(-1)},wk:function(a){return(a=we[a])?GLctx.isFramebuffer(a):0},vk:function(){q("missing function: emscripten_glIsList");n(-1)},uk:function(a){return(a=
Q[a])?GLctx.isProgram(a):0},tk:function(){q("missing function: emscripten_glIsQuery");n(-1)},sk:function(a){return(a=R[a])?GLctx.isRenderbuffer(a):0},rk:function(a){return(a=U[a])?GLctx.isShader(a):0},qk:function(a){return(a=S[a])?GLctx.isTexture(a):0},pk:function(a){return(a=xe[a])?GLctx.isVertexArray(a):0},ok:function(){q("missing function: emscripten_glLightModelf");n(-1)},nk:function(){q("missing function: emscripten_glLightModelfv");n(-1)},mk:function(){q("missing function: emscripten_glLightModeli");
n(-1)},lk:function(){q("missing function: emscripten_glLightModeliv");n(-1)},kk:function(){q("missing function: emscripten_glLightf");n(-1)},jk:function(){q("missing function: emscripten_glLightfv");n(-1)},ik:function(){q("missing function: emscripten_glLighti");n(-1)},gk:function(){q("missing function: emscripten_glLightiv");n(-1)},fk:function(){q("missing function: emscripten_glLineStipple");n(-1)},ek:function(a){GLctx.lineWidth(a)},dk:_emscripten_glLinkProgram,ck:function(){q("missing function: emscripten_glListBase");
n(-1)},bk:function(){throw"Legacy GL function (glLoadIdentity) called. If you want legacy GL emulation, you need to compile with -s LEGACY_GL_EMULATION=1 to enable legacy GL emulation.";},ak:function(){q("missing function: emscripten_glLoadMatrixd");n(-1)},$j:function(){q("missing function: emscripten_glLoadMatrixf");n(-1)},_j:function(){q("missing function: emscripten_glLoadName");n(-1)},Zj:function(){q("missing function: emscripten_glLoadTransposeMatrixd");n(-1)},Yj:function(){q("missing function: emscripten_glLoadTransposeMatrixf");
n(-1)},Xj:function(){q("missing function: emscripten_glLogicOp");n(-1)},Wj:function(){q("missing function: emscripten_glMap1d");n(-1)},Vj:function(){q("missing function: emscripten_glMap1f");n(-1)},Uj:function(){q("missing function: emscripten_glMap2d");n(-1)},Tj:function(){q("missing function: emscripten_glMap2f");n(-1)},Sj:function(){q("missing function: emscripten_glMapBuffer");n(-1)},Rj:function(){q("missing function: emscripten_glMapGrid1d");n(-1)},Qj:function(){q("missing function: emscripten_glMapGrid1f");
n(-1)},Pj:function(){q("missing function: emscripten_glMapGrid2d");n(-1)},Oj:function(){q("missing function: emscripten_glMapGrid2f");n(-1)},Nj:function(){q("missing function: emscripten_glMaterialf");n(-1)},Mj:function(){q("missing function: emscripten_glMaterialfv");n(-1)},Lj:function(){q("missing function: emscripten_glMateriali");n(-1)},Kj:function(){q("missing function: emscripten_glMaterialiv");n(-1)},Jj:function(){throw"Legacy GL function (glMatrixMode) called. If you want legacy GL emulation, you need to compile with -s LEGACY_GL_EMULATION=1 to enable legacy GL emulation.";
},Ij:function(){q("missing function: emscripten_glMinmax");n(-1)},Hj:function(){q("missing function: emscripten_glMultMatrixd");n(-1)},Gj:function(){q("missing function: emscripten_glMultMatrixf");n(-1)},Fj:function(){q("missing function: emscripten_glMultTransposeMatrixd");n(-1)},Ej:function(){q("missing function: emscripten_glMultTransposeMatrixf");n(-1)},Dj:function(){q("missing function: emscripten_glMultiDrawArrays");n(-1)},Cj:function(){q("missing function: emscripten_glMultiDrawElements");
n(-1)},Bj:function(){q("missing function: emscripten_glMultiTexCoord1d");n(-1)},Aj:function(){q("missing function: emscripten_glMultiTexCoord1dv");n(-1)},zj:function(){q("missing function: emscripten_glMultiTexCoord1f");n(-1)},yj:function(){q("missing function: emscripten_glMultiTexCoord1fv");n(-1)},xj:function(){q("missing function: emscripten_glMultiTexCoord1i");n(-1)},wj:function(){q("missing function: emscripten_glMultiTexCoord1iv");n(-1)},vj:function(){q("missing function: emscripten_glMultiTexCoord1s");
n(-1)},uj:function(){q("missing function: emscripten_glMultiTexCoord1sv");n(-1)},tj:function(){q("missing function: emscripten_glMultiTexCoord2d");n(-1)},sj:function(){q("missing function: emscripten_glMultiTexCoord2dv");n(-1)},rj:function(){q("missing function: emscripten_glMultiTexCoord2f");n(-1)},qj:function(){q("missing function: emscripten_glMultiTexCoord2fv");n(-1)},pj:function(){q("missing function: emscripten_glMultiTexCoord2i");n(-1)},oj:function(){q("missing function: emscripten_glMultiTexCoord2iv");
n(-1)},nj:function(){q("missing function: emscripten_glMultiTexCoord2s");n(-1)},mj:function(){q("missing function: emscripten_glMultiTexCoord2sv");n(-1)},lj:function(){q("missing function: emscripten_glMultiTexCoord3d");n(-1)},kj:function(){q("missing function: emscripten_glMultiTexCoord3dv");n(-1)},jj:function(){q("missing function: emscripten_glMultiTexCoord3f");n(-1)},ij:function(){q("missing function: emscripten_glMultiTexCoord3fv");n(-1)},hj:function(){q("missing function: emscripten_glMultiTexCoord3i");
n(-1)},gj:function(){q("missing function: emscripten_glMultiTexCoord3iv");n(-1)},fj:function(){q("missing function: emscripten_glMultiTexCoord3s");n(-1)},ej:function(){q("missing function: emscripten_glMultiTexCoord3sv");n(-1)},dj:function(){q("missing function: emscripten_glMultiTexCoord4d");n(-1)},cj:function(){q("missing function: emscripten_glMultiTexCoord4dv");n(-1)},bj:function(){q("missing function: emscripten_glMultiTexCoord4f");n(-1)},$i:function(){q("missing function: emscripten_glMultiTexCoord4fv");
n(-1)},_i:function(){q("missing function: emscripten_glMultiTexCoord4i");n(-1)},Zi:function(){q("missing function: emscripten_glMultiTexCoord4iv");n(-1)},Yi:function(){q("missing function: emscripten_glMultiTexCoord4s");n(-1)},Xi:function(){q("missing function: emscripten_glMultiTexCoord4sv");n(-1)},Wi:function(){q("missing function: emscripten_glNewList");n(-1)},Vi:function(){q("missing function: emscripten_glNormal3b");n(-1)},Ui:function(){q("missing function: emscripten_glNormal3bv");n(-1)},Ti:function(){q("missing function: emscripten_glNormal3d");
n(-1)},Si:function(){q("missing function: emscripten_glNormal3dv");n(-1)},Ri:function(){q("missing function: emscripten_glNormal3f");n(-1)},Qi:function(){q("missing function: emscripten_glNormal3fv");n(-1)},Pi:function(){q("missing function: emscripten_glNormal3i");n(-1)},Oi:function(){q("missing function: emscripten_glNormal3iv");n(-1)},Ni:function(){q("missing function: emscripten_glNormal3s");n(-1)},Mi:function(){q("missing function: emscripten_glNormal3sv");n(-1)},Li:function(){q("missing function: emscripten_glNormalPointer");
n(-1)},Ki:function(){q("missing function: emscripten_glOrtho");n(-1)},Ji:function(){q("missing function: emscripten_glPassThrough");n(-1)},Ii:function(){q("missing function: emscripten_glPixelMapfv");n(-1)},Hi:function(){q("missing function: emscripten_glPixelMapuiv");n(-1)},Gi:function(){q("missing function: emscripten_glPixelMapusv");n(-1)},Fi:function(){q("missing function: emscripten_glPixelStoref");n(-1)},Ei:function(a,b){3333==a||3317==a&&(Ce=b);GLctx.pixelStorei(a,b)},Di:function(){q("missing function: emscripten_glPixelTransferf");
n(-1)},Ci:function(){q("missing function: emscripten_glPixelTransferi");n(-1)},Bi:function(){q("missing function: emscripten_glPixelZoom");n(-1)},Ai:function(){q("missing function: emscripten_glPointParameterf");n(-1)},zi:function(){q("missing function: emscripten_glPointParameterfv");n(-1)},yi:function(){q("missing function: emscripten_glPointParameteri");n(-1)},xi:function(){q("missing function: emscripten_glPointParameteriv");n(-1)},wi:function(){q("missing function: emscripten_glPointSize");n(-1)},
vi:function(){q("missing function: emscripten_glPolygonMode");n(-1)},ui:function(a,b){GLctx.polygonOffset(a,b)},ti:function(){q("missing function: emscripten_glPolygonStipple");n(-1)},si:function(){q("missing function: emscripten_glPopAttrib");n(-1)},ri:function(){q("missing function: emscripten_glPopClientAttrib");n(-1)},qi:function(){q("missing function: emscripten_glPopMatrix");n(-1)},pi:function(){q("missing function: emscripten_glPopName");n(-1)},oi:function(){q("missing function: emscripten_glPrimitiveRestartIndex");
n(-1)},mi:function(){q("missing function: emscripten_glPrioritizeTextures");n(-1)},li:function(){q("missing function: emscripten_glProgramEnvParameter4dARB");n(-1)},ki:function(){q("missing function: emscripten_glProgramEnvParameter4dvARB");n(-1)},ji:function(){q("missing function: emscripten_glProgramEnvParameter4fARB");n(-1)},ii:function(){q("missing function: emscripten_glProgramEnvParameter4fvARB");n(-1)},hi:function(){q("missing function: emscripten_glProgramLocalParameter4dARB");n(-1)},gi:function(){q("missing function: emscripten_glProgramLocalParameter4dvARB");
n(-1)},fi:function(){q("missing function: emscripten_glProgramLocalParameter4fARB");n(-1)},ei:function(){q("missing function: emscripten_glProgramLocalParameter4fvARB");n(-1)},di:function(){q("missing function: emscripten_glProgramStringARB");n(-1)},ci:function(){q("missing function: emscripten_glPushAttrib");n(-1)},bi:function(){q("missing function: emscripten_glPushClientAttrib");n(-1)},ai:function(){q("missing function: emscripten_glPushMatrix");n(-1)},$h:function(){q("missing function: emscripten_glPushName");
n(-1)},_h:function(){q("missing function: emscripten_glRasterPos2d");n(-1)},Zh:function(){q("missing function: emscripten_glRasterPos2dv");n(-1)},Yh:function(){q("missing function: emscripten_glRasterPos2f");n(-1)},Xh:function(){q("missing function: emscripten_glRasterPos2fv");n(-1)},Wh:function(){q("missing function: emscripten_glRasterPos2i");n(-1)},Vh:function(){q("missing function: emscripten_glRasterPos2iv");n(-1)},Uh:function(){q("missing function: emscripten_glRasterPos2s");n(-1)},Th:function(){q("missing function: emscripten_glRasterPos2sv");
n(-1)},Sh:function(){q("missing function: emscripten_glRasterPos3d");n(-1)},Rh:function(){q("missing function: emscripten_glRasterPos3dv");n(-1)},Qh:function(){q("missing function: emscripten_glRasterPos3f");n(-1)},Ph:function(){q("missing function: emscripten_glRasterPos3fv");n(-1)},Oh:function(){q("missing function: emscripten_glRasterPos3i");n(-1)},Nh:function(){q("missing function: emscripten_glRasterPos3iv");n(-1)},Mh:function(){q("missing function: emscripten_glRasterPos3s");n(-1)},Lh:function(){q("missing function: emscripten_glRasterPos3sv");
n(-1)},Kh:function(){q("missing function: emscripten_glRasterPos4d");n(-1)},Jh:function(){q("missing function: emscripten_glRasterPos4dv");n(-1)},Ih:function(){q("missing function: emscripten_glRasterPos4f");n(-1)},Hh:function(){q("missing function: emscripten_glRasterPos4fv");n(-1)},Gh:function(){q("missing function: emscripten_glRasterPos4i");n(-1)},Fh:function(){q("missing function: emscripten_glRasterPos4iv");n(-1)},Eh:function(){q("missing function: emscripten_glRasterPos4s");n(-1)},Dh:function(){q("missing function: emscripten_glRasterPos4sv");
n(-1)},Ch:function(){q("missing function: emscripten_glReadBuffer");n(-1)},Bh:function(a,b,c,d,e,k,l){(l=Ne(k,e,c,d,l))?GLctx.readPixels(a,b,c,d,e,k,l):V(1280)},Ah:function(){q("missing function: emscripten_glRectd");n(-1)},zh:function(){q("missing function: emscripten_glRectdv");n(-1)},yh:function(){q("missing function: emscripten_glRectf");n(-1)},xh:function(){q("missing function: emscripten_glRectfv");n(-1)},wh:function(){q("missing function: emscripten_glRecti");n(-1)},vh:function(){q("missing function: emscripten_glRectiv");
n(-1)},uh:function(){q("missing function: emscripten_glRects");n(-1)},th:function(){q("missing function: emscripten_glRectsv");n(-1)},sh:function(){},rh:function(){q("missing function: emscripten_glRenderMode");n(-1)},ph:function(a,b,c,d){GLctx.renderbufferStorage(a,b,c,d)},oh:function(){q("missing function: emscripten_glRenderbufferStorageMultisample");n(-1)},nh:function(){q("missing function: emscripten_glResetHistogram");n(-1)},mh:function(){q("missing function: emscripten_glResetMinmax");n(-1)},
lh:function(){q("missing function: emscripten_glRotated");n(-1)},kh:function(){q("missing function: emscripten_glRotatef");n(-1)},jh:function(a,b){GLctx.sampleCoverage(a,!!b)},ih:function(){q("missing function: emscripten_glScaled");n(-1)},hh:function(){q("missing function: emscripten_glScalef");n(-1)},gh:function(a,b,c,d){GLctx.scissor(a,b,c,d)},fh:function(){q("missing function: emscripten_glSecondaryColor3b");n(-1)},eh:function(){q("missing function: emscripten_glSecondaryColor3bv");n(-1)},dh:function(){q("missing function: emscripten_glSecondaryColor3d");
n(-1)},ch:function(){q("missing function: emscripten_glSecondaryColor3dv");n(-1)},bh:function(){q("missing function: emscripten_glSecondaryColor3f");n(-1)},ah:function(){q("missing function: emscripten_glSecondaryColor3fv");n(-1)},$g:function(){q("missing function: emscripten_glSecondaryColor3i");n(-1)},_g:function(){q("missing function: emscripten_glSecondaryColor3iv");n(-1)},Zg:function(){q("missing function: emscripten_glSecondaryColor3s");n(-1)},Yg:function(){q("missing function: emscripten_glSecondaryColor3sv");
n(-1)},Xg:function(){q("missing function: emscripten_glSecondaryColor3ub");n(-1)},Wg:function(){q("missing function: emscripten_glSecondaryColor3ubv");n(-1)},Vg:function(){q("missing function: emscripten_glSecondaryColor3ui");n(-1)},Ug:function(){q("missing function: emscripten_glSecondaryColor3uiv");n(-1)},Tg:function(){q("missing function: emscripten_glSecondaryColor3us");n(-1)},Sg:function(){q("missing function: emscripten_glSecondaryColor3usv");n(-1)},Rg:function(){q("missing function: emscripten_glSecondaryColorPointer");
n(-1)},Qg:function(){q("missing function: emscripten_glSelectBuffer");n(-1)},Pg:function(){q("missing function: emscripten_glSeparableFilter2D");n(-1)},Og:function(){q("missing function: emscripten_glShadeModel");n(-1)},Ng:function(){V(1280)},Mg:_emscripten_glShaderSource,Lg:function(a,b,c){GLctx.stencilFunc(a,b,c)},Kg:function(a,b,c,d){GLctx.stencilFuncSeparate(a,b,c,d)},Jg:function(a){GLctx.stencilMask(a)},Ig:function(a,b){GLctx.stencilMaskSeparate(a,b)},Hg:function(a,b,c){GLctx.stencilOp(a,b,c)},
Gg:function(a,b,c,d){GLctx.stencilOpSeparate(a,b,c,d)},Fg:function(){q("missing function: emscripten_glTexBuffer");n(-1)},Eg:function(){q("missing function: emscripten_glTexCoord1d");n(-1)},Dg:function(){q("missing function: emscripten_glTexCoord1dv");n(-1)},Cg:function(){q("missing function: emscripten_glTexCoord1f");n(-1)},Bg:function(){q("missing function: emscripten_glTexCoord1fv");n(-1)},Ag:function(){q("missing function: emscripten_glTexCoord1i");n(-1)},zg:function(){q("missing function: emscripten_glTexCoord1iv");
n(-1)},yg:function(){q("missing function: emscripten_glTexCoord1s");n(-1)},xg:function(){q("missing function: emscripten_glTexCoord1sv");n(-1)},wg:function(){q("missing function: emscripten_glTexCoord2d");n(-1)},vg:function(){q("missing function: emscripten_glTexCoord2dv");n(-1)},ug:function(){q("missing function: emscripten_glTexCoord2f");n(-1)},rg:function(){q("missing function: emscripten_glTexCoord2fv");n(-1)},qg:function(){q("missing function: emscripten_glTexCoord2i");n(-1)},pg:function(){q("missing function: emscripten_glTexCoord2iv");
n(-1)},og:function(){q("missing function: emscripten_glTexCoord2s");n(-1)},ng:function(){q("missing function: emscripten_glTexCoord2sv");n(-1)},mg:function(){q("missing function: emscripten_glTexCoord3d");n(-1)},lg:function(){q("missing function: emscripten_glTexCoord3dv");n(-1)},kg:function(){q("missing function: emscripten_glTexCoord3f");n(-1)},jg:function(){q("missing function: emscripten_glTexCoord3fv");n(-1)},ig:function(){q("missing function: emscripten_glTexCoord3i");n(-1)},hg:function(){q("missing function: emscripten_glTexCoord3iv");
n(-1)},gg:function(){q("missing function: emscripten_glTexCoord3s");n(-1)},fg:function(){q("missing function: emscripten_glTexCoord3sv");n(-1)},eg:function(){q("missing function: emscripten_glTexCoord4d");n(-1)},dg:function(){q("missing function: emscripten_glTexCoord4dv");n(-1)},cg:function(){q("missing function: emscripten_glTexCoord4f");n(-1)},bg:function(){q("missing function: emscripten_glTexCoord4fv");n(-1)},ag:function(){q("missing function: emscripten_glTexCoord4i");n(-1)},$f:function(){q("missing function: emscripten_glTexCoord4iv");
n(-1)},_f:function(){q("missing function: emscripten_glTexCoord4s");n(-1)},Zf:function(){q("missing function: emscripten_glTexCoord4sv");n(-1)},Yf:function(){q("missing function: emscripten_glTexCoordPointer");n(-1)},Xf:_emscripten_glTexEnvf,Wf:_emscripten_glTexEnvfv,Vf:_emscripten_glTexEnvi,Uf:function(){q("missing function: emscripten_glTexEnviv");n(-1)},Tf:function(){q("missing function: emscripten_glTexGend");n(-1)},Sf:function(){q("missing function: emscripten_glTexGendv");n(-1)},Rf:function(){q("missing function: emscripten_glTexGenf");
n(-1)},Qf:function(){q("missing function: emscripten_glTexGenfv");n(-1)},Pf:function(){q("missing function: emscripten_glTexGeni");n(-1)},Of:function(){q("missing function: emscripten_glTexGeniv");n(-1)},Nf:function(){q("missing function: emscripten_glTexImage1D");n(-1)},Mf:function(a,b,c,d,e,k,l,m,p){var r=null;p&&(r=Ne(m,l,d,e,p));GLctx.texImage2D(a,b,c,d,e,k,l,m,r)},Lf:function(){q("missing function: emscripten_glTexImage3D");n(-1)},Kf:function(){q("missing function: emscripten_glTexParameterIiv");
n(-1)},Jf:function(){q("missing function: emscripten_glTexParameterIuiv");n(-1)},If:function(a,b,c){GLctx.texParameterf(a,b,c)},Hf:function(a,b,c){GLctx.texParameterf(a,b,D[c>>2])},Gf:function(a,b,c){GLctx.texParameteri(a,b,c)},Ef:function(a,b,c){GLctx.texParameteri(a,b,t[c>>2])},Df:function(){q("missing function: emscripten_glTexStorage2D");n(-1)},Cf:function(){q("missing function: emscripten_glTexStorage3D");n(-1)},Bf:function(){q("missing function: emscripten_glTexSubImage1D");n(-1)},Af:function(a,
b,c,d,e,k,l,m,p){var r=null;p&&(r=Ne(m,l,e,k,p));GLctx.texSubImage2D(a,b,c,d,e,k,l,m,r)},zf:function(){q("missing function: emscripten_glTexSubImage3D");n(-1)},yf:function(){q("missing function: emscripten_glTransformFeedbackVaryings");n(-1)},xf:function(){q("missing function: emscripten_glTranslated");n(-1)},wf:function(){q("missing function: emscripten_glTranslatef");n(-1)},vf:function(a,b){GLctx.uniform1f(T[a],b)},tf:function(a,b,c){if(256>=b){var d=X[b-1];for(var e=0;e<b;++e)d[e]=D[c+4*e>>2]}else d=
D.subarray(c>>2,c+4*b>>2);GLctx.uniform1fv(T[a],d)},sf:function(a,b){GLctx.uniform1i(T[a],b)},rf:function(a,b,c){GLctx.uniform1iv(T[a],t.subarray(c>>2,c+4*b>>2))},qf:function(){q("missing function: emscripten_glUniform1ui");n(-1)},pf:function(){q("missing function: emscripten_glUniform1uiv");n(-1)},of:function(a,b,c){GLctx.uniform2f(T[a],b,c)},nf:function(a,b,c){if(256>=2*b){var d=X[2*b-1];for(var e=0;e<2*b;e+=2)d[e]=D[c+4*e>>2],d[e+1]=D[c+(4*e+4)>>2]}else d=D.subarray(c>>2,c+8*b>>2);GLctx.uniform2fv(T[a],
d)},mf:function(a,b,c){GLctx.uniform2i(T[a],b,c)},lf:function(a,b,c){GLctx.uniform2iv(T[a],t.subarray(c>>2,c+8*b>>2))},kf:function(){q("missing function: emscripten_glUniform2ui");n(-1)},hf:function(){q("missing function: emscripten_glUniform2uiv");n(-1)},gf:function(a,b,c,d){GLctx.uniform3f(T[a],b,c,d)},ff:function(a,b,c){if(256>=3*b){var d=X[3*b-1];for(var e=0;e<3*b;e+=3)d[e]=D[c+4*e>>2],d[e+1]=D[c+(4*e+4)>>2],d[e+2]=D[c+(4*e+8)>>2]}else d=D.subarray(c>>2,c+12*b>>2);GLctx.uniform3fv(T[a],d)},ef:function(a,
b,c,d){GLctx.uniform3i(T[a],b,c,d)},df:function(a,b,c){GLctx.uniform3iv(T[a],t.subarray(c>>2,c+12*b>>2))},cf:function(){q("missing function: emscripten_glUniform3ui");n(-1)},bf:function(){q("missing function: emscripten_glUniform3uiv");n(-1)},af:function(a,b,c,d,e){GLctx.uniform4f(T[a],b,c,d,e)},$e:function(a,b,c){if(256>=4*b){var d=X[4*b-1];for(var e=0;e<4*b;e+=4)d[e]=D[c+4*e>>2],d[e+1]=D[c+(4*e+4)>>2],d[e+2]=D[c+(4*e+8)>>2],d[e+3]=D[c+(4*e+12)>>2]}else d=D.subarray(c>>2,c+16*b>>2);GLctx.uniform4fv(T[a],
d)},_e:function(a,b,c,d,e){GLctx.uniform4i(T[a],b,c,d,e)},Ze:function(a,b,c){GLctx.uniform4iv(T[a],t.subarray(c>>2,c+16*b>>2))},Ye:function(){q("missing function: emscripten_glUniform4ui");n(-1)},Xe:function(){q("missing function: emscripten_glUniform4uiv");n(-1)},We:function(){q("missing function: emscripten_glUniformBlockBinding");n(-1)},Ve:function(a,b,c,d){if(256>=4*b){var e=X[4*b-1];for(var k=0;k<4*b;k+=4)e[k]=D[d+4*k>>2],e[k+1]=D[d+(4*k+4)>>2],e[k+2]=D[d+(4*k+8)>>2],e[k+3]=D[d+(4*k+12)>>2]}else e=
D.subarray(d>>2,d+16*b>>2);GLctx.uniformMatrix2fv(T[a],!!c,e)},Ue:function(){q("missing function: emscripten_glUniformMatrix2x3fv");n(-1)},Te:function(){q("missing function: emscripten_glUniformMatrix2x4fv");n(-1)},Se:function(a,b,c,d){if(256>=9*b){var e=X[9*b-1];for(var k=0;k<9*b;k+=9)e[k]=D[d+4*k>>2],e[k+1]=D[d+(4*k+4)>>2],e[k+2]=D[d+(4*k+8)>>2],e[k+3]=D[d+(4*k+12)>>2],e[k+4]=D[d+(4*k+16)>>2],e[k+5]=D[d+(4*k+20)>>2],e[k+6]=D[d+(4*k+24)>>2],e[k+7]=D[d+(4*k+28)>>2],e[k+8]=D[d+(4*k+32)>>2]}else e=
D.subarray(d>>2,d+36*b>>2);GLctx.uniformMatrix3fv(T[a],!!c,e)},Re:function(){q("missing function: emscripten_glUniformMatrix3x2fv");n(-1)},Qe:function(){q("missing function: emscripten_glUniformMatrix3x4fv");n(-1)},Pe:function(a,b,c,d){if(256>=16*b){var e=X[16*b-1];for(var k=0;k<16*b;k+=16)e[k]=D[d+4*k>>2],e[k+1]=D[d+(4*k+4)>>2],e[k+2]=D[d+(4*k+8)>>2],e[k+3]=D[d+(4*k+12)>>2],e[k+4]=D[d+(4*k+16)>>2],e[k+5]=D[d+(4*k+20)>>2],e[k+6]=D[d+(4*k+24)>>2],e[k+7]=D[d+(4*k+28)>>2],e[k+8]=D[d+(4*k+32)>>2],e[k+
9]=D[d+(4*k+36)>>2],e[k+10]=D[d+(4*k+40)>>2],e[k+11]=D[d+(4*k+44)>>2],e[k+12]=D[d+(4*k+48)>>2],e[k+13]=D[d+(4*k+52)>>2],e[k+14]=D[d+(4*k+56)>>2],e[k+15]=D[d+(4*k+60)>>2]}else e=D.subarray(d>>2,d+64*b>>2);GLctx.uniformMatrix4fv(T[a],!!c,e)},Oe:function(){q("missing function: emscripten_glUniformMatrix4x2fv");n(-1)},Ne:function(){q("missing function: emscripten_glUniformMatrix4x3fv");n(-1)},Me:function(){q("missing function: emscripten_glUnmapBuffer");n(-1)},Le:_emscripten_glUseProgram,Ke:function(){q("missing function: emscripten_glUseProgramObjectARB");
n(-1)},Je:function(a){GLctx.validateProgram(Q[a])},Ie:function(){q("missing function: emscripten_glVertex2d");n(-1)},He:function(){q("missing function: emscripten_glVertex2dv");n(-1)},Ge:function(){q("missing function: emscripten_glVertex2f");n(-1)},Ee:function(){q("missing function: emscripten_glVertex2fv");n(-1)},De:function(){q("missing function: emscripten_glVertex2i");n(-1)},Ce:function(){q("missing function: emscripten_glVertex2iv");n(-1)},Be:function(){q("missing function: emscripten_glVertex2s");
n(-1)},Ae:function(){q("missing function: emscripten_glVertex2sv");n(-1)},ze:function(){q("missing function: emscripten_glVertex3d");n(-1)},ye:function(){q("missing function: emscripten_glVertex3dv");n(-1)},xe:function(){q("missing function: emscripten_glVertex3f");n(-1)},we:function(){q("missing function: emscripten_glVertex3fv");n(-1)},ve:function(){q("missing function: emscripten_glVertex3i");n(-1)},ue:function(){q("missing function: emscripten_glVertex3iv");n(-1)},te:function(){q("missing function: emscripten_glVertex3s");
n(-1)},se:function(){q("missing function: emscripten_glVertex3sv");n(-1)},re:function(){q("missing function: emscripten_glVertex4d");n(-1)},qe:function(){q("missing function: emscripten_glVertex4dv");n(-1)},pe:function(){q("missing function: emscripten_glVertex4f");n(-1)},oe:function(){q("missing function: emscripten_glVertex4fv");n(-1)},ne:function(){q("missing function: emscripten_glVertex4i");n(-1)},me:function(){q("missing function: emscripten_glVertex4iv");n(-1)},le:function(){q("missing function: emscripten_glVertex4s");
n(-1)},ke:function(){q("missing function: emscripten_glVertex4sv");n(-1)},je:function(){q("missing function: emscripten_glVertexAttrib1d");n(-1)},ie:function(){q("missing function: emscripten_glVertexAttrib1dv");n(-1)},he:function(a,b){GLctx.vertexAttrib1f(a,b)},ge:function(a,b){GLctx.vertexAttrib1f(a,D[b>>2])},fe:function(){q("missing function: emscripten_glVertexAttrib1s");n(-1)},ee:function(){q("missing function: emscripten_glVertexAttrib1sv");n(-1)},de:function(){q("missing function: emscripten_glVertexAttrib2d");
n(-1)},ce:function(){q("missing function: emscripten_glVertexAttrib2dv");n(-1)},be:function(a,b,c){GLctx.vertexAttrib2f(a,b,c)},ae:function(a,b){GLctx.vertexAttrib2f(a,D[b>>2],D[b+4>>2])},$d:function(){q("missing function: emscripten_glVertexAttrib2s");n(-1)},_d:function(){q("missing function: emscripten_glVertexAttrib2sv");n(-1)},Zd:function(){q("missing function: emscripten_glVertexAttrib3d");n(-1)},Yd:function(){q("missing function: emscripten_glVertexAttrib3dv");n(-1)},Xd:function(a,b,c,d){GLctx.vertexAttrib3f(a,
b,c,d)},Wd:function(a,b){GLctx.vertexAttrib3f(a,D[b>>2],D[b+4>>2],D[b+8>>2])},Vd:function(){q("missing function: emscripten_glVertexAttrib3s");n(-1)},Ud:function(){q("missing function: emscripten_glVertexAttrib3sv");n(-1)},Td:function(){q("missing function: emscripten_glVertexAttrib4Nbv");n(-1)},Rd:function(){q("missing function: emscripten_glVertexAttrib4Niv");n(-1)},Qd:function(){q("missing function: emscripten_glVertexAttrib4Nsv");n(-1)},Pd:function(){q("missing function: emscripten_glVertexAttrib4Nub");
n(-1)},Od:function(){q("missing function: emscripten_glVertexAttrib4Nubv");n(-1)},Nd:function(){q("missing function: emscripten_glVertexAttrib4Nuiv");n(-1)},Md:function(){q("missing function: emscripten_glVertexAttrib4Nusv");n(-1)},Ld:function(){q("missing function: emscripten_glVertexAttrib4bv");n(-1)},Kd:function(){q("missing function: emscripten_glVertexAttrib4d");n(-1)},Jd:function(){q("missing function: emscripten_glVertexAttrib4dv");n(-1)},Id:function(a,b,c,d,e){GLctx.vertexAttrib4f(a,b,c,d,
e)},Gd:function(a,b){GLctx.vertexAttrib4f(a,D[b>>2],D[b+4>>2],D[b+8>>2],D[b+12>>2])},Fd:function(){q("missing function: emscripten_glVertexAttrib4iv");n(-1)},Ed:function(){q("missing function: emscripten_glVertexAttrib4s");n(-1)},Dd:function(){q("missing function: emscripten_glVertexAttrib4sv");n(-1)},Cd:function(){q("missing function: emscripten_glVertexAttrib4ubv");n(-1)},Bd:function(){q("missing function: emscripten_glVertexAttrib4uiv");n(-1)},Ad:function(){q("missing function: emscripten_glVertexAttrib4usv");
n(-1)},zd:function(a,b){GLctx.vertexAttribDivisor(a,b)},yd:function(){q("missing function: emscripten_glVertexAttribI1i");n(-1)},xd:function(){q("missing function: emscripten_glVertexAttribI1iv");n(-1)},vd:function(){q("missing function: emscripten_glVertexAttribI1ui");n(-1)},ud:function(){q("missing function: emscripten_glVertexAttribI1uiv");n(-1)},td:function(){q("missing function: emscripten_glVertexAttribI2i");n(-1)},sd:function(){q("missing function: emscripten_glVertexAttribI2iv");n(-1)},rd:function(){q("missing function: emscripten_glVertexAttribI2ui");
n(-1)},qd:function(){q("missing function: emscripten_glVertexAttribI2uiv");n(-1)},pd:function(){q("missing function: emscripten_glVertexAttribI3i");n(-1)},od:function(){q("missing function: emscripten_glVertexAttribI3iv");n(-1)},nd:function(){q("missing function: emscripten_glVertexAttribI3ui");n(-1)},md:function(){q("missing function: emscripten_glVertexAttribI3uiv");n(-1)},kd:function(){q("missing function: emscripten_glVertexAttribI4bv");n(-1)},jd:function(){q("missing function: emscripten_glVertexAttribI4i");
n(-1)},id:function(){q("missing function: emscripten_glVertexAttribI4iv");n(-1)},hd:function(){q("missing function: emscripten_glVertexAttribI4sv");n(-1)},gd:function(){q("missing function: emscripten_glVertexAttribI4ubv");n(-1)},fd:function(){q("missing function: emscripten_glVertexAttribI4ui");n(-1)},ed:function(){q("missing function: emscripten_glVertexAttribI4uiv");n(-1)},dd:function(){q("missing function: emscripten_glVertexAttribI4usv");n(-1)},cd:function(){q("missing function: emscripten_glVertexAttribIPointer");
n(-1)},bd:_emscripten_glVertexAttribPointer,$c:function(){throw"Legacy GL function (glVertexPointer) called. If you want legacy GL emulation, you need to compile with -s LEGACY_GL_EMULATION=1 to enable legacy GL emulation.";},_c:function(a,b,c,d){GLctx.viewport(a,b,c,d)},Zc:function(){q("missing function: emscripten_glWindowPos2d");n(-1)},Yc:function(){q("missing function: emscripten_glWindowPos2dv");n(-1)},Xc:function(){q("missing function: emscripten_glWindowPos2f");n(-1)},Wc:function(){q("missing function: emscripten_glWindowPos2fv");
n(-1)},Vc:function(){q("missing function: emscripten_glWindowPos2i");n(-1)},Uc:function(){q("missing function: emscripten_glWindowPos2iv");n(-1)},Tc:function(){q("missing function: emscripten_glWindowPos2s");n(-1)},Sc:function(){q("missing function: emscripten_glWindowPos2sv");n(-1)},Qc:function(){q("missing function: emscripten_glWindowPos3d");n(-1)},Pc:function(){q("missing function: emscripten_glWindowPos3dv");n(-1)},Oc:function(){q("missing function: emscripten_glWindowPos3f");n(-1)},Nc:function(){q("missing function: emscripten_glWindowPos3fv");
n(-1)},Mc:function(){q("missing function: emscripten_glWindowPos3i");n(-1)},Lc:function(){q("missing function: emscripten_glWindowPos3iv");n(-1)},Kc:function(){q("missing function: emscripten_glWindowPos3s");n(-1)},Jc:function(){q("missing function: emscripten_glWindowPos3sv");n(-1)},Ic:function(a,b){Oe(a,b)},Hc:function(a,b,c){h.set(h.subarray(b,b+c),a);return a},Fc:function(){return Se.apply(null,arguments)},ba:function(a){return ge(a)?0:-5},aa:function(a){if(!f.noExitRuntime&&(za=!0,Pa=mf,Va(Za),
f.onExit))f.onExit(a);f.quit(a,new na(a))},I:Te,Ec:function(a){GLctx.activeTexture(a)},Dc:function(a,b){GLctx.attachShader(Q[a],U[b])},Cc:function(a,b,c){c=y(c);GLctx.bindAttribLocation(Q[a],b,c)},Bc:function(a,b){GLctx.bindBuffer(a,b?ve[b]:null)},Ac:function(a,b){GLctx.bindFramebuffer(a,b?we[b]:null)},zc:function(a,b){GLctx.bindRenderbuffer(a,b?R[b]:null)},xc:function(a,b){GLctx.bindTexture(a,b?S[b]:null)},wc:function(a,b,c,d){GLctx.blendColor(a,b,c,d)},vc:function(a){GLctx.blendEquation(a)},uc:function(a,
b){GLctx.blendFunc(a,b)},tc:function(a,b,c,d){c?GLctx.bufferData(a,h.subarray(c,c+b),d):GLctx.bufferData(a,b,d)},sc:function(a,b,c,d){GLctx.bufferSubData(a,b,h.subarray(d,d+c))},rc:function(a){return GLctx.checkFramebufferStatus(a)},$:function(a){GLctx.clear(a)},_:function(a,b,c,d){GLctx.clearColor(a,b,c,d)},Z:function(a){GLctx.clearStencil(a)},pc:function(a,b,c,d){GLctx.colorMask(!!a,!!b,!!c,!!d)},oc:function(a){GLctx.compileShader(U[a])},nc:function(a,b,c,d,e,k,l,m){GLctx.compressedTexImage2D(a,
b,c,d,e,k,m?h.subarray(m,m+l):null)},mc:function(a,b,c,d,e,k,l,m,p){GLctx.compressedTexSubImage2D(a,b,c,d,e,k,l,p?h.subarray(p,p+m):null)},lc:function(a,b,c,d,e,k,l,m){GLctx.copyTexSubImage2D(a,b,c,d,e,k,l,m)},kc:function(){var a=W(Q),b=GLctx.createProgram();b.name=a;Q[a]=b;return a},jc:function(a){var b=W(U);U[b]=GLctx.createShader(a);return b},ic:function(a){GLctx.cullFace(a)},hc:function(a,b){for(var c=0;c<a;c++){var d=t[b+4*c>>2],e=ve[d];e&&(GLctx.deleteBuffer(e),e.name=0,ve[d]=null,d==Ie&&(Ie=
0),d==Je&&(Je=0))}},gc:function(a,b){for(var c=0;c<a;++c){var d=t[b+4*c>>2],e=we[d];e&&(GLctx.deleteFramebuffer(e),e.name=0,we[d]=null)}},ec:function(a){if(a){var b=Q[a];b?(GLctx.deleteProgram(b),b.name=0,Q[a]=null,ze[a]=null):V(1281)}},dc:function(a,b){for(var c=0;c<a;c++){var d=t[b+4*c>>2],e=R[d];e&&(GLctx.deleteRenderbuffer(e),e.name=0,R[d]=null)}},cc:function(a){if(a){var b=U[a];b?(GLctx.deleteShader(b),U[a]=null):V(1281)}},bc:function(a,b){for(var c=0;c<a;c++){var d=t[b+4*c>>2],e=S[d];e&&(GLctx.deleteTexture(e),
e.name=0,S[d]=null)}},ac:function(a){GLctx.depthMask(!!a)},$b:function(a){GLctx.disable(a)},_b:function(a){GLctx.disableVertexAttribArray(a)},Zb:_glDrawArrays,Yb:_glDrawElements,Xb:function(a){GLctx.enable(a)},Vb:function(a){GLctx.enableVertexAttribArray(a)},Ub:function(){GLctx.finish()},Tb:function(){GLctx.flush()},Sb:function(a,b,c,d){GLctx.framebufferRenderbuffer(a,b,c,R[d])},Rb:function(a,b,c,d,e){GLctx.framebufferTexture2D(a,b,c,S[d],e)},Qb:function(a){GLctx.frontFace(a)},Pb:function(a,b){for(var c=
0;c<a;c++){var d=GLctx.createBuffer();if(!d){for(V(1282);c<a;)t[b+4*c++>>2]=0;break}var e=W(ve);d.name=e;ve[e]=d;t[b+4*c>>2]=e}},Ob:function(a,b){for(var c=0;c<a;++c){var d=GLctx.createFramebuffer();if(!d){for(V(1282);c<a;)t[b+4*c++>>2]=0;break}var e=W(we);d.name=e;we[e]=d;t[b+4*c>>2]=e}},Nb:function(a,b){for(var c=0;c<a;c++){var d=GLctx.createRenderbuffer();if(!d){for(V(1282);c<a;)t[b+4*c++>>2]=0;break}var e=W(R);d.name=e;R[e]=d;t[b+4*c>>2]=e}},Mb:function(a,b){for(var c=0;c<a;c++){var d=GLctx.createTexture();
if(!d){for(V(1282);c<a;)t[b+4*c++>>2]=0;break}var e=W(S);d.name=e;S[e]=d;t[b+4*c>>2]=e}},Kb:function(a){GLctx.generateMipmap(a)},Jb:function(a,b,c){c?t[c>>2]=GLctx.getBufferParameter(a,b):V(1281)},Ib:function(){if(ue){var a=ue;ue=0;return a}return GLctx.getError()},Hb:function(a,b,c,d){a=GLctx.getFramebufferAttachmentParameter(a,b,c);if(a instanceof WebGLRenderbuffer||a instanceof WebGLTexture)a=a.name|0;t[d>>2]=a},Y:function(a,b){Ke(a,b,"Integer")},Gb:function(a,b,c,d){a=GLctx.getProgramInfoLog(Q[a]);
null===a&&(a="(unknown error)");0<b&&d?(b=g(a,h,d,b),c&&(t[c>>2]=b)):c&&(t[c>>2]=0)},Fb:function(a,b,c){if(c)if(a>=te)V(1281);else{var d=ze[a];if(d)if(35716==b)a=GLctx.getProgramInfoLog(Q[a]),null===a&&(a="(unknown error)"),t[c>>2]=a.length+1;else if(35719==b)t[c>>2]=d.Zt;else if(35722==b){if(-1==d.ot){a=Q[a];var e=GLctx.getProgramParameter(a,GLctx.ACTIVE_ATTRIBUTES);for(b=d.ot=0;b<e;++b)d.ot=Math.max(d.ot,GLctx.getActiveAttrib(a,b).name.length+1)}t[c>>2]=d.ot}else if(35381==b){if(-1==d.pt)for(a=
Q[a],e=GLctx.getProgramParameter(a,GLctx.Qu),b=d.pt=0;b<e;++b){var k=GLctx.dv(a,b);d.pt=Math.max(d.pt,k.length+1)}t[c>>2]=d.pt}else t[c>>2]=GLctx.getProgramParameter(Q[a],b);else V(1282)}else V(1281)},Eb:function(a,b,c){c?t[c>>2]=GLctx.getRenderbufferParameter(a,b):V(1281)},Db:function(a,b,c,d){a=GLctx.getShaderInfoLog(U[a]);null===a&&(a="(unknown error)");0<b&&d?(b=g(a,h,d,b),c&&(t[c>>2]=b)):c&&(t[c>>2]=0)},Cb:function(a,b,c,d){a=GLctx.getShaderPrecisionFormat(a,b);t[c>>2]=a.rangeMin;t[c+4>>2]=a.rangeMax;
t[d>>2]=a.precision},Ab:function(a,b,c){c?35716==b?(a=GLctx.getShaderInfoLog(U[a]),null===a&&(a="(unknown error)"),t[c>>2]=a.length+1):35720==b?(a=GLctx.getShaderSource(U[a]),t[c>>2]=null===a||0==a.length?0:a.length+1):t[c>>2]=GLctx.getShaderParameter(U[a],b):V(1281)},zb:function(a){if(Ae[a])return Ae[a];switch(a){case 7939:var b=GLctx.getSupportedExtensions();for(var c=[],d=0;d<b.length;++d)c.push(b[d]),c.push("GL_"+b[d]);b=w(H(c.join(" ")));break;case 7936:case 7937:case 37445:case 37446:(b=GLctx.getParameter(a))||
V(1280);b=w(H(b));break;case 7938:b=w(H("OpenGL ES 2.0 ("+GLctx.getParameter(GLctx.VERSION)+")"));break;case 35724:b=GLctx.getParameter(GLctx.SHADING_LANGUAGE_VERSION);c=b.match(/^WebGL GLSL ES ([0-9]\.[0-9][0-9]?)(?:$| .*)/);null!==c&&(3==c[1].length&&(c[1]+="0"),b="OpenGL ES GLSL ES "+c[1]+" ("+b+")");b=w(H(b));break;default:return V(1280),0}return Ae[a]=b},yb:function(a,b){b=y(b);var c=0;if(-1!==b.indexOf("]",b.length-1)){var d=b.lastIndexOf("["),e=b.slice(d+1,-1);if(0<e.length&&(c=parseInt(e),
0>c))return-1;b=b.slice(0,d)}a=ze[a];return a?(b=a.wu[b])&&c<b[0]?b[1]+c:-1:-1},xb:function(a){return(a=S[a])?GLctx.isTexture(a):0},wb:function(a){GLctx.lineWidth(a)},vb:function(a){GLctx.linkProgram(Q[a]);ze[a]=null;He(a)},ub:function(a,b){3333==a||3317==a&&(Ce=b);GLctx.pixelStorei(a,b)},tb:function(a,b,c,d,e,k,l){(l=Ne(k,e,c,d,l))?GLctx.readPixels(a,b,c,d,e,k,l):V(1280)},sb:function(a,b,c,d){GLctx.renderbufferStorage(a,b,c,d)},rb:function(a,b,c,d){GLctx.scissor(a,b,c,d)},pb:function(a,b,c,d){b=
Ee(b,c,d);GLctx.shaderSource(U[a],b)},ob:function(a,b,c){GLctx.stencilFunc(a,b,c)},nb:function(a,b,c,d){GLctx.stencilFuncSeparate(a,b,c,d)},mb:function(a){GLctx.stencilMask(a)},lb:function(a,b){GLctx.stencilMaskSeparate(a,b)},kb:function(a,b,c){GLctx.stencilOp(a,b,c)},jb:function(a,b,c,d){GLctx.stencilOpSeparate(a,b,c,d)},ib:function(a,b,c,d,e,k,l,m,p){var r=null;p&&(r=Ne(m,l,d,e,p));GLctx.texImage2D(a,b,c,d,e,k,l,m,r)},hb:function(a,b,c){GLctx.texParameterf(a,b,c)},gb:function(a,b,c){GLctx.texParameterf(a,
b,D[c>>2])},eb:function(a,b,c){GLctx.texParameteri(a,b,c)},db:function(a,b,c){GLctx.texParameteri(a,b,t[c>>2])},cb:function(a,b,c,d,e,k,l,m,p){var r=null;p&&(r=Ne(m,l,e,k,p));GLctx.texSubImage2D(a,b,c,d,e,k,l,m,r)},bb:function(a,b){GLctx.uniform1f(T[a],b)},ab:function(a,b,c){if(256>=b){var d=X[b-1];for(var e=0;e<b;++e)d[e]=D[c+4*e>>2]}else d=D.subarray(c>>2,c+4*b>>2);GLctx.uniform1fv(T[a],d)},$a:function(a,b){GLctx.uniform1i(T[a],b)},_a:function(a,b,c){GLctx.uniform1iv(T[a],t.subarray(c>>2,c+4*b>>
2))},Za:function(a,b,c){GLctx.uniform2f(T[a],b,c)},Ya:function(a,b,c){if(256>=2*b){var d=X[2*b-1];for(var e=0;e<2*b;e+=2)d[e]=D[c+4*e>>2],d[e+1]=D[c+(4*e+4)>>2]}else d=D.subarray(c>>2,c+8*b>>2);GLctx.uniform2fv(T[a],d)},Xa:function(a,b,c){GLctx.uniform2i(T[a],b,c)},Va:function(a,b,c){GLctx.uniform2iv(T[a],t.subarray(c>>2,c+8*b>>2))},Ua:function(a,b,c,d){GLctx.uniform3f(T[a],b,c,d)},Ta:function(a,b,c){if(256>=3*b){var d=X[3*b-1];for(var e=0;e<3*b;e+=3)d[e]=D[c+4*e>>2],d[e+1]=D[c+(4*e+4)>>2],d[e+2]=
D[c+(4*e+8)>>2]}else d=D.subarray(c>>2,c+12*b>>2);GLctx.uniform3fv(T[a],d)},Sa:function(a,b,c,d){GLctx.uniform3i(T[a],b,c,d)},Ra:function(a,b,c){GLctx.uniform3iv(T[a],t.subarray(c>>2,c+12*b>>2))},Qa:function(a,b,c,d,e){GLctx.uniform4f(T[a],b,c,d,e)},Pa:function(a,b,c){if(256>=4*b){var d=X[4*b-1];for(var e=0;e<4*b;e+=4)d[e]=D[c+4*e>>2],d[e+1]=D[c+(4*e+4)>>2],d[e+2]=D[c+(4*e+8)>>2],d[e+3]=D[c+(4*e+12)>>2]}else d=D.subarray(c>>2,c+16*b>>2);GLctx.uniform4fv(T[a],d)},Oa:function(a,b,c,d,e){GLctx.uniform4i(T[a],
b,c,d,e)},Na:function(a,b,c){GLctx.uniform4iv(T[a],t.subarray(c>>2,c+16*b>>2))},Ma:function(a,b,c,d){if(256>=4*b){var e=X[4*b-1];for(var k=0;k<4*b;k+=4)e[k]=D[d+4*k>>2],e[k+1]=D[d+(4*k+4)>>2],e[k+2]=D[d+(4*k+8)>>2],e[k+3]=D[d+(4*k+12)>>2]}else e=D.subarray(d>>2,d+16*b>>2);GLctx.uniformMatrix2fv(T[a],!!c,e)},Ka:function(a,b,c,d){if(256>=9*b){var e=X[9*b-1];for(var k=0;k<9*b;k+=9)e[k]=D[d+4*k>>2],e[k+1]=D[d+(4*k+4)>>2],e[k+2]=D[d+(4*k+8)>>2],e[k+3]=D[d+(4*k+12)>>2],e[k+4]=D[d+(4*k+16)>>2],e[k+5]=D[d+
(4*k+20)>>2],e[k+6]=D[d+(4*k+24)>>2],e[k+7]=D[d+(4*k+28)>>2],e[k+8]=D[d+(4*k+32)>>2]}else e=D.subarray(d>>2,d+36*b>>2);GLctx.uniformMatrix3fv(T[a],!!c,e)},Ja:function(a,b,c,d){if(256>=16*b){var e=X[16*b-1];for(var k=0;k<16*b;k+=16)e[k]=D[d+4*k>>2],e[k+1]=D[d+(4*k+4)>>2],e[k+2]=D[d+(4*k+8)>>2],e[k+3]=D[d+(4*k+12)>>2],e[k+4]=D[d+(4*k+16)>>2],e[k+5]=D[d+(4*k+20)>>2],e[k+6]=D[d+(4*k+24)>>2],e[k+7]=D[d+(4*k+28)>>2],e[k+8]=D[d+(4*k+32)>>2],e[k+9]=D[d+(4*k+36)>>2],e[k+10]=D[d+(4*k+40)>>2],e[k+11]=D[d+(4*
k+44)>>2],e[k+12]=D[d+(4*k+48)>>2],e[k+13]=D[d+(4*k+52)>>2],e[k+14]=D[d+(4*k+56)>>2],e[k+15]=D[d+(4*k+60)>>2]}else e=D.subarray(d>>2,d+64*b>>2);GLctx.uniformMatrix4fv(T[a],!!c,e)},Ia:function(a){GLctx.useProgram(a?Q[a]:null)},Ha:function(a,b){GLctx.vertexAttrib1f(a,b)},Ga:function(a,b){GLctx.vertexAttrib2f(a,D[b>>2],D[b+4>>2])},Fa:function(a,b){GLctx.vertexAttrib3f(a,D[b>>2],D[b+4>>2],D[b+8>>2])},Ea:function(a,b){GLctx.vertexAttrib4f(a,D[b>>2],D[b+4>>2],D[b+8>>2],D[b+12>>2])},Da:function(a,b,c,d,
e,k){GLctx.vertexAttribPointer(a,b,c,!!d,e,k)},Ca:function(a,b,c,d){GLctx.viewport(a,b,c,d)},X:function(a){return Math.pow(2,a)},C:function(a){return Math.log(a)/Math.LN2},B:function(a){var b=Ue.gt[a];Ue.gt.splice(a,1);Z(b)},A:Ue,Aa:function(){n("trap!")},i:gb,l:gb,e:Oe,za:function(){return 0},ya:function(a){return Ve[a]||0},xa:function(a){if(0==a)return F.dt;t[a>>2]=We;Ve[We]=0;We++;return 0},va:Xe,ua:function(a,b){if(!(a in Ve))return F.dt;Ve[a]=b;return 0},ta:function(){},W:function(){},sa:function(){},
V:function(){},ra:function(a,b,c,d){return cf(a,b,c,d)},a:sa,b:Pa};var nf=f.asm(f.Vu,f.Wu,buffer);f.asm=nf;var mb=f.__GLOBAL__sub_I_bind_cpp=function(){return f.asm.eq.apply(null,arguments)},lb=f.__GLOBAL__sub_I_canvaskit_bindings_cpp=function(){return f.asm.fq.apply(null,arguments)};f.__Z11ApplyPathOpR6SkPathRKS_8SkPathOp=function(){return f.asm.gq.apply(null,arguments)};f.__Z11ToSVGStringRK6SkPath=function(){return f.asm.hq.apply(null,arguments)};
f.__Z13ApplySimplifyR6SkPath=function(){return f.asm.iq.apply(null,arguments)};f.__Z14MakePathFromOpRK6SkPathS1_8SkPathOp=function(){return f.asm.jq.apply(null,arguments)};f.__Z16MakePathFromCmdsmi=function(){return f.asm.kq.apply(null,arguments)};f.__Z21MakePathFromSVGStringNSt3__212basic_stringIcNS_11char_traitsIcEENS_9allocatorIcEEEE=function(){return f.asm.lq.apply(null,arguments)};f.__Z6EqualsRK6SkPathS1_=function(){return f.asm.mq.apply(null,arguments)};
f.__Z6ToCmdsRK6SkPath=function(){return f.asm.nq.apply(null,arguments)};f.__Z8CopyPathRK6SkPath=function(){return f.asm.oq.apply(null,arguments)};var hf=f.__ZSt18uncaught_exceptionv=function(){return f.asm.pq.apply(null,arguments)},nb=f.___emscripten_environ_constructor=function(){return f.asm.qq.apply(null,arguments)};f.___errno_location=function(){return f.asm.rq.apply(null,arguments)};
var kd=f.___getTypeName=function(){return f.asm.sq.apply(null,arguments)},lf=f._emscripten_GetProcAddress=function(){return f.asm.tq.apply(null,arguments)},Sa=f._emscripten_replace_memory=function(){return f.asm._emscripten_replace_memory.apply(null,arguments)},ld=f._free=function(){return f.asm.uq.apply(null,arguments)},Aa=f._malloc=function(){return f.asm.vq.apply(null,arguments)},jf=f._memalign=function(){return f.asm.wq.apply(null,arguments)},kf=f._memset=function(){return f.asm.xq.apply(null,
arguments)};f.setThrew=function(){return f.asm.Rs.apply(null,arguments)};f.stackAlloc=function(){return f.asm.Ss.apply(null,arguments)};var Z=f.stackRestore=function(){return f.asm.Ts.apply(null,arguments)},Y=f.stackSave=function(){return f.asm.Us.apply(null,arguments)};f.dynCall_di=function(){return f.asm.yq.apply(null,arguments)};f.dynCall_fi=function(){return f.asm.zq.apply(null,arguments)};f.dynCall_fiff=function(){return f.asm.Aq.apply(null,arguments)};
f.dynCall_fii=function(){return f.asm.Bq.apply(null,arguments)};f.dynCall_fiii=function(){return f.asm.Cq.apply(null,arguments)};f.dynCall_i=function(){return f.asm.Dq.apply(null,arguments)};f.dynCall_ii=function(){return f.asm.Eq.apply(null,arguments)};f.dynCall_iidi=function(){return f.asm.Fq.apply(null,arguments)};f.dynCall_iif=function(){return f.asm.Gq.apply(null,arguments)};f.dynCall_iiff=function(){return f.asm.Hq.apply(null,arguments)};
f.dynCall_iifff=function(){return f.asm.Iq.apply(null,arguments)};f.dynCall_iiffi=function(){return f.asm.Jq.apply(null,arguments)};f.dynCall_iiffii=function(){return f.asm.Kq.apply(null,arguments)};f.dynCall_iii=function(){return f.asm.Lq.apply(null,arguments)};f.dynCall_iiif=function(){return f.asm.Mq.apply(null,arguments)};f.dynCall_iiiff=function(){return f.asm.Nq.apply(null,arguments)};f.dynCall_iiifff=function(){return f.asm.Oq.apply(null,arguments)};
f.dynCall_iiiffi=function(){return f.asm.Pq.apply(null,arguments)};f.dynCall_iiiffii=function(){return f.asm.Qq.apply(null,arguments)};f.dynCall_iiifi=function(){return f.asm.Rq.apply(null,arguments)};f.dynCall_iiififiiiii=function(){return f.asm.Sq.apply(null,arguments)};f.dynCall_iiififiiiiii=function(){return f.asm.Tq.apply(null,arguments)};f.dynCall_iiifiiiii=function(){return f.asm.Uq.apply(null,arguments)};f.dynCall_iiifiiiiii=function(){return f.asm.Vq.apply(null,arguments)};
f.dynCall_iiii=function(){return f.asm.Wq.apply(null,arguments)};f.dynCall_iiiif=function(){return f.asm.Xq.apply(null,arguments)};f.dynCall_iiiii=function(){return f.asm.Yq.apply(null,arguments)};f.dynCall_iiiiid=function(){return f.asm.Zq.apply(null,arguments)};f.dynCall_iiiiii=function(){return f.asm._q.apply(null,arguments)};f.dynCall_iiiiiid=function(){return f.asm.$q.apply(null,arguments)};f.dynCall_iiiiiii=function(){return f.asm.ar.apply(null,arguments)};
f.dynCall_iiiiiiii=function(){return f.asm.br.apply(null,arguments)};f.dynCall_iiiiiiiii=function(){return f.asm.cr.apply(null,arguments)};f.dynCall_iiiiiiiiii=function(){return f.asm.dr.apply(null,arguments)};f.dynCall_iiiiiiiiiii=function(){return f.asm.er.apply(null,arguments)};f.dynCall_iiiiij=function(){return f.asm.fr.apply(null,arguments)};f.dynCall_iiiij=function(){return f.asm.gr.apply(null,arguments)};f.dynCall_iiij=function(){return f.asm.hr.apply(null,arguments)};
f.dynCall_iijj=function(){return f.asm.ir.apply(null,arguments)};f.dynCall_ji=function(){return f.asm.jr.apply(null,arguments)};f.dynCall_jii=function(){return f.asm.kr.apply(null,arguments)};f.dynCall_jiii=function(){return f.asm.lr.apply(null,arguments)};f.dynCall_jiiiijiiiii=function(){return f.asm.mr.apply(null,arguments)};f.dynCall_v=function(){return f.asm.nr.apply(null,arguments)};f.dynCall_vd=function(){return f.asm.or.apply(null,arguments)};
f.dynCall_vdd=function(){return f.asm.pr.apply(null,arguments)};f.dynCall_vddd=function(){return f.asm.qr.apply(null,arguments)};f.dynCall_vdddd=function(){return f.asm.rr.apply(null,arguments)};f.dynCall_vdddddd=function(){return f.asm.sr.apply(null,arguments)};f.dynCall_vf=function(){return f.asm.tr.apply(null,arguments)};f.dynCall_vff=function(){return f.asm.ur.apply(null,arguments)};f.dynCall_vfff=function(){return f.asm.vr.apply(null,arguments)};
f.dynCall_vffff=function(){return f.asm.wr.apply(null,arguments)};f.dynCall_vfi=function(){return f.asm.xr.apply(null,arguments)};f.dynCall_vi=function(){return f.asm.yr.apply(null,arguments)};f.dynCall_vid=function(){return f.asm.zr.apply(null,arguments)};f.dynCall_vidd=function(){return f.asm.Ar.apply(null,arguments)};f.dynCall_viddd=function(){return f.asm.Br.apply(null,arguments)};f.dynCall_vidddd=function(){return f.asm.Cr.apply(null,arguments)};
f.dynCall_viddi=function(){return f.asm.Dr.apply(null,arguments)};f.dynCall_viddidd=function(){return f.asm.Er.apply(null,arguments)};f.dynCall_viddiiddiii=function(){return f.asm.Fr.apply(null,arguments)};f.dynCall_viddiii=function(){return f.asm.Gr.apply(null,arguments)};f.dynCall_vif=function(){return f.asm.Hr.apply(null,arguments)};f.dynCall_viff=function(){return f.asm.Ir.apply(null,arguments)};f.dynCall_vifff=function(){return f.asm.Jr.apply(null,arguments)};
f.dynCall_viffff=function(){return f.asm.Kr.apply(null,arguments)};f.dynCall_vifffff=function(){return f.asm.Lr.apply(null,arguments)};f.dynCall_viffffff=function(){return f.asm.Mr.apply(null,arguments)};f.dynCall_vifffffffff=function(){return f.asm.Nr.apply(null,arguments)};f.dynCall_viffffi=function(){return f.asm.Or.apply(null,arguments)};f.dynCall_viffffii=function(){return f.asm.Pr.apply(null,arguments)};f.dynCall_viffi=function(){return f.asm.Qr.apply(null,arguments)};
f.dynCall_viffiff=function(){return f.asm.Rr.apply(null,arguments)};f.dynCall_viffiiffiii=function(){return f.asm.Sr.apply(null,arguments)};f.dynCall_viffiii=function(){return f.asm.Tr.apply(null,arguments)};f.dynCall_vifii=function(){return f.asm.Ur.apply(null,arguments)};f.dynCall_vii=function(){return f.asm.Vr.apply(null,arguments)};f.dynCall_viid=function(){return f.asm.Wr.apply(null,arguments)};f.dynCall_viidddd=function(){return f.asm.Xr.apply(null,arguments)};
f.dynCall_viif=function(){return f.asm.Yr.apply(null,arguments)};f.dynCall_viifd=function(){return f.asm.Zr.apply(null,arguments)};f.dynCall_viiff=function(){return f.asm._r.apply(null,arguments)};f.dynCall_viifff=function(){return f.asm.$r.apply(null,arguments)};f.dynCall_viiffff=function(){return f.asm.as.apply(null,arguments)};f.dynCall_viifffff=function(){return f.asm.bs.apply(null,arguments)};f.dynCall_viiffffff=function(){return f.asm.cs.apply(null,arguments)};
f.dynCall_viifffffffff=function(){return f.asm.ds.apply(null,arguments)};f.dynCall_viifffffffffi=function(){return f.asm.es.apply(null,arguments)};f.dynCall_viiffffi=function(){return f.asm.fs.apply(null,arguments)};f.dynCall_viiffffii=function(){return f.asm.gs.apply(null,arguments)};f.dynCall_viiffi=function(){return f.asm.hs.apply(null,arguments)};f.dynCall_viiffii=function(){return f.asm.is.apply(null,arguments)};f.dynCall_viifi=function(){return f.asm.js.apply(null,arguments)};
f.dynCall_viififiiiii=function(){return f.asm.ks.apply(null,arguments)};f.dynCall_viififiiiiii=function(){return f.asm.ls.apply(null,arguments)};f.dynCall_viifiiiii=function(){return f.asm.ms.apply(null,arguments)};f.dynCall_viifiiiiii=function(){return f.asm.ns.apply(null,arguments)};f.dynCall_viii=function(){return f.asm.os.apply(null,arguments)};f.dynCall_viiif=function(){return f.asm.ps.apply(null,arguments)};f.dynCall_viiiff=function(){return f.asm.qs.apply(null,arguments)};
f.dynCall_viiifffffffffi=function(){return f.asm.rs.apply(null,arguments)};f.dynCall_viiiffi=function(){return f.asm.ss.apply(null,arguments)};f.dynCall_viiiffii=function(){return f.asm.ts.apply(null,arguments)};f.dynCall_viiii=function(){return f.asm.us.apply(null,arguments)};f.dynCall_viiiiffi=function(){return f.asm.vs.apply(null,arguments)};f.dynCall_viiiiffii=function(){return f.asm.ws.apply(null,arguments)};f.dynCall_viiiifiii=function(){return f.asm.xs.apply(null,arguments)};
f.dynCall_viiiii=function(){return f.asm.ys.apply(null,arguments)};f.dynCall_viiiiiffii=function(){return f.asm.zs.apply(null,arguments)};f.dynCall_viiiiifi=function(){return f.asm.As.apply(null,arguments)};f.dynCall_viiiiifiii=function(){return f.asm.Bs.apply(null,arguments)};f.dynCall_viiiiii=function(){return f.asm.Cs.apply(null,arguments)};f.dynCall_viiiiiii=function(){return f.asm.Ds.apply(null,arguments)};f.dynCall_viiiiiiifi=function(){return f.asm.Es.apply(null,arguments)};
f.dynCall_viiiiiiii=function(){return f.asm.Fs.apply(null,arguments)};f.dynCall_viiiiiiiii=function(){return f.asm.Gs.apply(null,arguments)};f.dynCall_viiiiiiiiii=function(){return f.asm.Hs.apply(null,arguments)};f.dynCall_viiiiiiiiiii=function(){return f.asm.Is.apply(null,arguments)};f.dynCall_viiiiiiiiiiiiiii=function(){return f.asm.Js.apply(null,arguments)};f.dynCall_viiiiij=function(){return f.asm.Ks.apply(null,arguments)};f.dynCall_viiiij=function(){return f.asm.Ls.apply(null,arguments)};
f.dynCall_viiij=function(){return f.asm.Ms.apply(null,arguments)};f.dynCall_viij=function(){return f.asm.Ns.apply(null,arguments)};f.dynCall_viijii=function(){return f.asm.Os.apply(null,arguments)};f.dynCall_vij=function(){return f.asm.Ps.apply(null,arguments)};f.dynCall_vji=function(){return f.asm.Qs.apply(null,arguments)};f.asm=nf;f.then=function(a){if(f.calledRun)a(f);else{var b=f.onRuntimeInitialized;f.onRuntimeInitialized=function(){b&&b();a(f)}}return f};
function na(a){this.name="ExitStatus";this.message="Program terminated with exit("+a+")";this.status=a}na.prototype=Error();na.prototype.constructor=na;var mf;jb=function of(){f.calledRun||pf();f.calledRun||(jb=of)};
function pf(){function a(){if(!f.calledRun&&(f.calledRun=!0,!za)){ab||(ab=!0,Va(Xa));Va(Ya);if(f.onRuntimeInitialized)f.onRuntimeInitialized();if(f.postRun)for("function"==typeof f.postRun&&(f.postRun=[f.postRun]);f.postRun.length;)$a.unshift(f.postRun.shift());Va($a)}}if(!(0<hb)){if(f.preRun)for("function"==typeof f.preRun&&(f.preRun=[f.preRun]);f.preRun.length;)bb();Va(Wa);0<hb||f.calledRun||(f.setStatus?(f.setStatus("Running..."),setTimeout(function(){setTimeout(function(){f.setStatus("")},1);
a()},1)):a())}}f.run=pf;function n(a){if(f.onAbort)f.onAbort(a);void 0!==a?(oa(a),q(a),a=JSON.stringify(a)):a="";za=!0;throw"abort("+a+"). Build with -s ASSERTIONS=1 for more info.";}f.abort=n;if(f.preInit)for("function"==typeof f.preInit&&(f.preInit=[f.preInit]);0<f.preInit.length;)f.preInit.pop()();f.noExitRuntime=!0;pf();f.ready=function(){return new Promise(function(a,b){f.onAbort=b;ab?a(f):$a.unshift(function(){a(f)})})};delete f.then;


  return CanvasKitInit;
}
);
})();
if (typeof exports === 'object' && typeof module === 'object')
    module.exports = CanvasKitInit;
  else if (typeof define === 'function' && define['amd'])
    define([], function() { return CanvasKitInit; });
  else if (typeof exports === 'object')
    exports["CanvasKitInit"] = CanvasKitInit;
  