import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './Dropdown.css';
import svgLib from '../../../functionLib/svglib';

const Dropdown = (props) => {
    const contentWrapper = React.createRef();
    const refName = React.createRef();

    let contentHeight;

    const {
        options = [],
        name,
        onChange,
        defaultValue,
        className,
        activeClassName,
        arrowColor,
        activeArrowColor,
        value,
        acuikit,
        outline,
        zIndex,
        onNameClick,
        style,
        arrowStyle,
        color,
    } = props;

    const [height, setHeight] = useState('0px');
    const [isActive, setisActive] = useState(false);
    const [activeValue, setActiveValue] = useState(options.length ? options[0].name : '');

    useEffect(() => {
        contentHeight = contentWrapper.current.getBoundingClientRect().height;
        if (contentHeight !== 0) {
            setHeight(`${contentHeight}px`);
        } else {
            setHeight('auto');
        }
    }, [isActive]);

    const onToggleActive = () => {
        setisActive(!isActive);
    };

    const onSelect = (selectValue) => {
        setisActive(false);
        const selectedItem = options.find((item) => item.value === selectValue);
        setActiveValue(selectedItem.name);
        onChange(selectValue);
    };

    useEffect(() => {
        if (value) {
            const selectedItem = options.find((item) => item.value === value);

            setActiveValue(selectedItem.name);
        }
    }, []);

    return (
        <div
            style={{
                ...style,
                zIndex,
                backgroundColor: color ? 'rgb(230, 241, 255)' : '#006cff',
            }}
            onMouseLeave={() => setisActive(false)}
            className={`select_main ${acuikit ? 'dropDown_acuikit' : ''} ${className} ${
                isActive ? `${activeClassName} dropDown_acuikit_active` : ''
            }`}
        >
            <div className="dropdown_header" ref={refName}>
                <div className="select_main_name" onClick={name !== 'Add data' ? onNameClick : onToggleActive}>
                    <span style={{ color: color ? '#006cff' : 'white' }}>{name || activeValue}</span>
                </div>

                {options.length ? (
                    <div
                        style={{
                            ...arrowStyle,
                        }}
                        className="arrow_wrapper"
                        onClick={onToggleActive}
                    >
                        <div className={`dropDown_arrow_down ${isActive ? 'select_arrow_down_rotete' : ''}`}>
                            {svgLib.chevronDown(arrowColor || 'white')}
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="select_main_content" style={{ height: `${isActive ? height : '0px'}` }}>
                <div className="select_main_conteiner" ref={contentWrapper}>
                    {options.length
                        ? options.map((item) => (
                              <div className="select_main_option" onClick={() => onSelect(item.value)} key={item.value}>
                                  {item.content}
                              </div>
                          ))
                        : null}
                </div>
            </div>
        </div>
    );
};

Dropdown.propTypes = {
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    name: PropTypes.string,
    onChange: PropTypes.func,
    arrowColor: PropTypes.string,
    activeArrowColor: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    acuikit: PropTypes.bool,
    zIndex: PropTypes.number,
    onNameClick: PropTypes.func,
    color: PropTypes.bool,
};

Dropdown.defaultProps = {
    name: '',
    onChange: () => {},
    arrowColor: '',
    activeArrowColor: '',
    value: '',
    className: '',
    activeClassName: '',
    acuikit: false,
    zIndex: 20,
    onNameClick: () => {},
};

export default Dropdown;
