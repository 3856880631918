import svgLib from '../../functionLib/svglib';
import { useAppSelector } from '../../redux/hooks';

export const notificationStyles = {
    error: {
        color: '#FF6F61',
        icon: svgLib.errorTwo('white', { height: '20px', width: '20px' }),
    },
    warning: {
        color: '#FFBB00',
        icon: svgLib.information('white', { height: '20px', width: '20px' }),
    },
    info: {
        color: '#79CC66',
        icon: svgLib.check('white', { height: '20px', width: '20px' }),
    },
};

export const useNotificationsState = () => {
    const n11sState = useAppSelector((state) => state.notifications);
    const style = n11sState.type && notificationStyles[n11sState.type];
    const { fontSize, message } = shortenMessage(n11sState.message);
    return { ...n11sState, style, message, fontSize };
};
const shortenMessage = (message: string | undefined) => {
    if (!message) {
        return { message, fontSize: 16 };
    }
    let fontSize: number;
    if (message.length > 600) {
        fontSize = 10;
        message = `${message.substring(0, 600)}...`;
    } else if (message.length > 106) {
        fontSize = 10;
    } else if (message.length > 46) {
        fontSize = 12;
    } else {
        fontSize = 14;
    }
    return { message, fontSize };
};
