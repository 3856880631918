const woocommerce = (options = {}) => {
    const { GATEWAY_URL, DEFAULT_API_ERROR } = options

    /**
     * Connects an agent to the Woocommerce store
     * @param {String} agentID - Id of the agent to connect to Woocommerce store
     * @param {String} store - Woocommerce store to connect to
     * @returns {Promise<String>} An address of the authorization to redirect by 
     */
    const connect = async (agentID, store) => {
        if (!agentID) throw new Error('Please specify the id of the agent to connect to Woocommerce')
        if (!store) throw new Error('Please specify store address to connect')

        const url = new URL(`${GATEWAY_URL}/api/wooConnect`)

        url.searchParams.append('agentID', agentID)
        url.searchParams.append('store', store)
        url.searchParams.append('returnUrl', encodeURI(new URL(`/v2/bots/bot/${agentID}/settings/integrations`, window.location.origin)))

        const options = {
            mode: 'cors',
            credentials: 'include',
            method: 'GET'
        }

        try {
            const response = await fetch(url, options),
                statusCode = response.status,
                body = await response.json()

if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }

            if (!response.ok) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body })
            if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

            return body.redirect
        } catch (error) {
            throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
        }
    }

    return ({
        connect
    })
}

export default woocommerce;