import { useEffect, useMemo } from 'react';
import { useSounds } from '../../../../../hooks/useSounds';
import { UserSessionWithAgentSession } from '../../../../../types/userSession';

export const useLastQueuedSessionId = (sessions: UserSessionWithAgentSession[]) => {
    const sounds = useSounds();
    const lastQueuedSessionId = useMemo(() => findLastQueuedSessionId(sessions), [sessions]);
    useEffect(() => {
        if (lastQueuedSessionId) {
            sounds.playNewChat();
        }
    }, [lastQueuedSessionId]);
    return { lastQueuedSessionId };
};

const findLastQueuedSessionId = (sessions: UserSessionWithAgentSession[]): string | undefined => {
    const lastSessionId = sessions
        .filter((item) => item.session.status === 'queued')
        .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))[sessions.length - 1]?._id;
    return lastSessionId;
};
