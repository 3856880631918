import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerType } from '../../types/customer';
import { CustomerRole } from '../../types/customerRole';

export type CustomerState = {
    customer: CustomerType | null;
    customerRoles: CustomerRole[];
};

const initCustomerState: CustomerState = {
    customer: null,
    customerRoles: [],
};

const customerSlice = createSlice({
    name: 'customer',
    initialState: initCustomerState,
    reducers: {
        setCustomer: (state, action: PayloadAction<CustomerType>) => ({ ...state, customer: action.payload }),
        setCustomerRoles: (state, action: PayloadAction<CustomerRole[]>) => ({
            ...state,
            customerRoles: action.payload,
        }),
    },
});

export const customerActions = customerSlice.actions;
export const customerReducer = customerSlice.reducer;
