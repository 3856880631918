import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { isMobile } from 'react-device-detect';
import App from './App';
import * as serviceWorker from './serviceWorker';
import MobileComponent from './components/mainComponents/MobileComponent/MobileComponent';

function isAllowedForMobile(url: string) {
    if (!url.includes('/skills')) {
        return false;
    } if (isMobile) {
        return false;
    }
    return false;
}


ReactDOM.render(isAllowedForMobile(window.location.href) ? <MobileComponent /> : <App />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister();
