import React, { ChangeEventHandler, FocusEventHandler, MouseEventHandler } from 'react';
import './ToggleComponent.css';

export type ToggleProps = {
    checked?: boolean;
    disabled?: boolean;
    id?: string;
    name?: string;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onClick?: MouseEventHandler<HTMLInputElement>;
    onFocus?: FocusEventHandler<HTMLInputElement>;
};

const ToggleComponent: React.FC<ToggleProps> = ({
    checked,
    disabled,
    id,
    name,
    onBlur,
    onChange,
    onClick,
    onFocus,
}) => (
    <label className="ToggleComponent_main">
        <input
            type="checkbox"
            className="ToggleComponent_main_checkbox"
            checked={checked}
            disabled={disabled}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onClick={onClick}
            onFocus={onFocus}
        />
        <span style={{ cursor: disabled ? 'not-allowed' : 'pointer' }} className="ToggleComponent_main_span" />
    </label>
);
export default ToggleComponent;
