import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LivechatAgentWithCustomerMetadata } from '../../api/models/livechat.types';
import { LivechatData } from '../../types/livachat';

export type LivechatState = {
    data: LivechatData | null;
    agents: LivechatAgentWithCustomerMetadata[];
};

const initLiveChat: LivechatState = {
    data: null,
    agents: [],
};

const livechatSlice = createSlice({
    name: 'livechat',
    initialState: initLiveChat as LivechatState,
    reducers: {
        setLivechatData: (state, action: PayloadAction<LivechatData>) => ({ ...state, data: action.payload }),
        setLivechatAgents: (state, action: PayloadAction<LivechatAgentWithCustomerMetadata[]>) => ({
            ...state,
            agents: action.payload,
        }),
    },
});

export const livechatActions = livechatSlice.actions;
export const livechatReducer = livechatSlice.reducer;
