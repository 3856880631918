import utils from '../utils'

const templates = (options = {}) => {
    const { GATEWAY_URL, DEFAULT_API_ERROR } = options

    /**
     * Gets template by id
     * @param {String} Id - Id of the template to take
     * @param {Object, String} fields - Projection of the fields to return
     * @returns {Promise} taken template
     */
    const getById = async (templateID, fields) => {
        if (!templateID) throw new Error('Please specify template id')

        const url = new URL(`${GATEWAY_URL}/api/templates/${templateID}`)

        if (fields) {
            if (typeof fields === 'string')
                url.searchParams.append('fields', fields)
            else
                url.searchParams.append('fields', utils._parseFieldsObjectToString(fields))
        }

        const options = {
            headers: {
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            credentials: 'include',
            method: 'GET'
        }

        try {
            const response = await fetch(url, options),
                statusCode = response.status,
                body = await response.json()

if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }

            if (!response.ok) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body })
            if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

            return body
        } catch (error) {
            throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
        }
    }

    /**
     * Gets list of templates
     * @param {Object} settings - Settings to get templates by (sort, limit, skip, etc.)
     * @param {Object, String} fields - Projection of the fields to return
     * @returns {Promise<Array>} list of templates by settings
     */
    const get = async (settings = {}, fields) => {
        const url = new URL(`${GATEWAY_URL}/api/templates`)

        Object.keys(settings).forEach(key => url.searchParams.append(key, settings[key]))

        if (fields) {
            if (typeof fields === 'string')
                url.searchParams.append('fields', fields)
            else
                url.searchParams.append('fields', utils._parseFieldsObjectToString(fields))
        }

        const options = {
            headers: {
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            credentials: 'include',
            method: 'GET'
        }

        try {
            const response = await fetch(url, options),
                statusCode = response.status,
                body = await response.json()

if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }

            if (!response.ok) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body })
            if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

            return body
        } catch (error) {
            throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
        }
    }



    return ({
        getById,
        get
    })
}

export default templates;