import { LivechatAgentWithCustomerMetadata } from '../../api/models/livechat.types';
import { useAppDispatch, useAppSelector } from '../hooks';
import { livechatActions } from './livechat.slice';

export const useLivechatData = () => useAppSelector((state) => state.livechat.data);
export const useUnsafeLivechatData = () => useAppSelector((state) => state.livechat.data!);

export const useLivechatAgents = () => useAppSelector((state) => state.livechat.agents);
export const useLivechatAgentsState = () => {
    const appDispatch = useAppDispatch();
    const livechatAgents = useLivechatAgents();

    const setSelectedSessionStatus = (agents: LivechatAgentWithCustomerMetadata[]) =>
        appDispatch(livechatActions.setLivechatAgents(agents));
    return [livechatAgents, setSelectedSessionStatus] as const;
};
