const dialogFlow = (options = {}) => {
    const { GATEWAY_URL, DEFAULT_API_ERROR } = options

    /**
     * Gets list of the DialogFlow agents for specified bot
     * @param {String} agentID - Id of the bot to get list of the DialogFlow agents for
     * @returns {Promise} list of DialogFlow agents
     */
    const getAgents = async (agentID) => {
        if (!agentID) throw new Error('Please specify id of the bot to get DialogFlow agents list for')

        const url = new URL(`${GATEWAY_URL}/api/${agentID}/integrations/dialogflow/agents`)

        const options = {
            mode: 'cors',
            credentials: 'include',
            method: 'GET'
        }

        try {
            const response = await fetch(url, options),
                statusCode = response.status,
                body = await response.json()

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }

            if (!response.ok) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body })
            if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

            return body
        } catch (error) {
            throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
        }
    }

    /**
     * Updates Dialog Flow intents for an agent
     * @param {String} agentID - Id of the agent to update DialogFlow intents for
     * @returns {Promise} agent with updated Dialog Flow intents
     */
    const updateIntents = async (agentID) => {
        if (!agentID) throw new Error('Please specify id of the bot to get DialogFlow intents list for')

        const url = new URL(`${GATEWAY_URL}/api/${agentID}/integrations/dialogflow/intents`)

        const options = {
            mode: 'cors',
            credentials: 'include',
            method: 'PUT'
        }

        try {
            const response = await fetch(url, options),
                statusCode = response.status,
                body = await response.json()

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }

            if (!response.ok) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body })
            if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

            return body
        } catch (error) {
            throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
        }
    }

    /**
     * Removes store data from the Dialog flow agent
     * @param {String} agentID - Id of the agent to clear DialogFlow store data for
     * @returns {Promise<String>} action result as a message
     */
    const clearStoreData = async (agentID) => {
        if (!agentID) throw new Error('Please specify id of the bot to clear Dialog Flow from the store data')

        const url = new URL(`${GATEWAY_URL}/api/clearDialogFlowStoreData`)

        const options = {
            headers: {
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ agentID })
        }

        try {
            const response = await fetch(url, options),
                statusCode = response.status,
                body = await response.json()

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }

            if (!response.ok) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body })
            if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

            return (body || {}).message
        } catch (error) {
            throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
        }
    }

    /**
     * Fills Dialog Flow agent with data from the store
     * @param {String} agentID - Id of the agent to fill Dialog Flow for
     * @param {Object} settings - Settings to fill Dialog Flow with store data by
     * @returns {Promise<String>} message with result of fullfillment
     */
    const fillWithStoreData = async (agentID, settings) => {
        if (!agentID) throw new Error('Please specify id of the bot to fill Dialog Flow with the store data')

        const url = new URL(`${GATEWAY_URL}/api/fillDialogFlow`)

        const options = {
            headers: {
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ agentID, settings })
        }

        try {
            const response = await fetch(url, options),
                statusCode = response.status,
                body = await response.json()

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }

            if (!response.ok) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body })
            if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

            return (body || {}).message
        } catch (error) {
            throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
        }
    }

    return ({
        getAgents,
        updateIntents,
        clearStoreData,
        fillWithStoreData
    })
}

export default dialogFlow;