import { useEffect, useRef } from 'react';
import { useConfig } from '../../../../../api/config';
import { useApi } from '../../../../../functionLib/hooks';
import { useAppBot } from '../../../../../redux/bot/bot.selectors';
import { useLivechatData } from '../../../../../redux/livechat/livechat.selectors';
import {
    useQueuedSessionsState,
    useSelectedSessionStatus,
    useUserSessionsState
} from '../../../../../redux/sessions/sessions.selectors';
import { getUserSessions } from './useSessionsList.api';

const POLLING_INTERVAL = 3 * 1000; // 3 seconds

export const useGlobalSessionsList = () => {
    const config = useConfig();
    const api = useApi();
    const { botId: selectedBotId } = useAppBot();
    const selectedSessionStatus = useSelectedSessionStatus();
    const liveChatData = useLivechatData();

    const [sessionsList, setSessionsList] = useUserSessionsState();
    const timer = useRef<NodeJS.Timer>();

    useEffect(() => {
        if (liveChatData && liveChatData.agentId && selectedBotId) {
            getUserSessions(api, liveChatData, selectedBotId, selectedSessionStatus).then(setSessionsList);
            if (config.DISABLE_BACKGROUND_QUEUE_CHECK) {
                console.warn(
                    '[useGlobalSessionsList] DISABLE_BACKGROUND_QUEUE_CHECK is set. skipping background queue check'
                );
            } else {
                timer.current = setInterval(
                    () =>
                        getUserSessions(api, liveChatData, selectedBotId, selectedSessionStatus).then(setSessionsList),
                    POLLING_INTERVAL
                );
            }
            return () => timer.current && clearInterval(timer.current);
        }
    }, [selectedBotId, liveChatData, selectedSessionStatus]);

    return { sessionsList };
};

export const useGlobalQueuedSessionsList = () => {
    const config = useConfig();
    const api = useApi();
    const { botId: selectedBotId } = useAppBot();
    const liveChatData = useLivechatData();

    const [queuedSessionsList, setQueuedSessionsList] = useQueuedSessionsState();
    const timer = useRef<NodeJS.Timer>();

    useEffect(() => {
        if (liveChatData && liveChatData.agentId && selectedBotId) {
            getUserSessions(api, liveChatData, selectedBotId, 'queued').then(setQueuedSessionsList);
            if (config.DISABLE_BACKGROUND_QUEUE_CHECK) {
                console.warn(
                    '[useGlobalQueuedSessionsList] DISABLE_BACKGROUND_QUEUE_CHECK is set. skipping background queue check'
                );
            } else {
                timer.current = setInterval(
                    () => getUserSessions(api, liveChatData, selectedBotId, 'queued').then(setQueuedSessionsList),
                    POLLING_INTERVAL
                );
            }
            return () => timer.current && clearInterval(timer.current);
        }
    }, [selectedBotId, liveChatData]);

    return { queuedSessionsList };
};
