const integrations = (options = {}) => {
    const { GATEWAY_URL, DEFAULT_API_ERROR } = options

    /**
     * Removes webhooks of the specified integration
     * @param {String} agentID - Id of the agent to remove webhooks for
     * @param {('shopify'|'woocommerce')} integration - An integration to remove webhooks for
     * @returns {Promise} an error if occurred while removing webhooks
     */
    const removeWebhooks = async (agentID, integration) => {
        if (!agentID) throw new Error('Please specify the id of the agent to remove webhooks for')
        if (!integration) throw new Error('Please specify integration to remove webhooks for')

        const url = new URL(`${GATEWAY_URL}/api/removeWebhooks`)

        url.searchParams.append('agentID', agentID)
        url.searchParams.append('integration', integration)

        const options = {
            mode: 'cors',
            credentials: 'include',
            method: 'GET'
        }

        try {
            const response = await fetch(url, options),
                statusCode = response.status,
                body = await response.json()

if (body && body.error && typeof body.error === 'string') body.error = { message: body.error }

            if (!response.ok) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body })
            if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error })

            return body
        } catch (error) {
            throw (error.statusCode ? error : { message: DEFAULT_API_ERROR })
        }
    }

    return ({
        removeWebhooks
    })
}

export default integrations;