const facebook = (options = {}) => {
    const { GATEWAY_URL, DEFAULT_API_ERROR, ARCHITECT_URL } = options;

    /**
     * Connects an agent to the Facebook channel
     * @returns {Promise} customer connected to Facebook channel
     */
    const connect = async (agentId, fbConnectionData) => {
        // const url = new URL(`${GATEWAY_URL}/api/channels/facebook/connection`);
        const url = new URL(`${ARCHITECT_URL}/v1/facebook/connection?agentId=${agentId}`);

        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
            // mode: 'no-cors',
            // credentials: 'include',
            method: 'POST',
            body: JSON.stringify(fbConnectionData),
        };

        try {
            const response = await fetch(url, options);
            const statusCode = response.status;
            const body = await response.json();
            // console.log(response, body);

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body.data;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const getFBLLToken = async (agentId, fbConnectionData) => {
        const url = new URL(`${GATEWAY_URL}/api/channels/facebook/getLLToken`);

        const options = {
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ agentId, fbConnectionData }),
        };

        try {
            const response = await fetch(url, options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body.data;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const getPages = async (agentId) => {
        const url = new URL(`${ARCHITECT_URL}/v1/facebook/page?agentId=${agentId}`);

        const fetchOptions = {
            method: 'GET',
            json: true,
        };

        try {
            const response = await fetch(url, fetchOptions);
            const statusCode = response.status;
            const body = await response.json();
            console.log('getPages', body);

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    /**
     * Diconnects Facebook channel for customer
     * @returns {Promise} customer disconnected from Facebook channel
     */
    const disconnect = async (agentId) => {
        // const url = new URL(`${GATEWAY_URL}/api/channels/facebook/connection`);
        const url = new URL(`${ARCHITECT_URL}/v1/facebook/connection?agentId=${agentId}`);

        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'DELETE',
        };

        try {
            const response = await fetch(url, options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body.data;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Refreshes Facebook connection for the customer
     * @param {Object} customer Customer object with Facebook connection enabled to refresh
     * @returns {Promise} customer with refreshed Facebook connection
     */
    const refresh = async (customer = {}) => {
        if (!customer.messengers || !customer.messengers.facebook)
            throw new Error('Please specify correct customer data to update Facebook connection');

        const url = new URL(`${GATEWAY_URL}/api/refreshFB`);

        const options = {
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ customer }),
        };

        try {
            const response = await fetch(url, options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body.data;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Connects agent to specified Facebook page
     * @param {Object} agent - An agent to connect Facebook page to
     * @param {Object} customer - Customer of the agent
     * @param {String} pageID - Id of the Facebook page to connect
     * @returns {Promise} data with updated agent and customer after connection
     */
    const connectPage = async (agentId, pageId) => {
        if (!pageId) throw new Error('Please specify Facebook page id to connect agent to');

        const url = new URL(`${ARCHITECT_URL}/v1/facebook/page?agentId=${agentId}`);

        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({ pageId }),
        };

        try {
            const response = await fetch(url, options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    /**
     * Disconnects agent to specified Facebook page
     * @param {Object} agent - An agent to disconnect Facebook page to
     * @param {Object} customer - Customer of the agent
     * @param {String} pageID - Id of the Facebook page to disconnect
     * @returns {Promise} data with updated agent and customer after disconnection
     */
    const disconnectPage = async (agentId) => {
        if (!agentId) throw new Error('Please specify agent id');

        const url = new URL(`${ARCHITECT_URL}/v1/facebook/page?agentId=${agentId}`);

        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'DELETE',
        };

        try {
            const response = await fetch(url, options);
            const statusCode = response.status;
            const body = await response.json();

            if (statusCode === 200) return { message: 'Page successfully disconnected' };
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Disconnects agent to specified Facebook page
     * @param {Object} agent - An agent to disconnect Facebook page to
     * @param {Object} customer - Customer of the agent
     * @param {String} pageID - Id of the Facebook page to disconnect
     * @returns {Promise} data with updated agent and customer after disconnection
     */

    return {
        connect,
        disconnect,
        refresh,
        connectPage,
        disconnectPage,
        getFBLLToken,
        getPages,
    };
};

export default facebook;
