import React from 'react';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import ContentTable from './ContentTable';
import { TableContentProvider } from './GlobalStateContext';
import HeaderTable from './HeaderTable';
import './TableComponent.css';
import useTableEngine from './TableEngine';
import { TableColumn } from './types';

export type TableComponentProps = {
    onScroll?: React.UIEventHandler<HTMLDivElement>;
    onSort?: (value) => void;
    getSelectedRow?: any;

    columns: TableColumn[];
    data: any[];
    keyExtractor?: (item: any) => string;

    className?: string;
    initialSorting?: string;
    disableOverflow?: boolean;
    fetching?: boolean;
    loading?: boolean;
    maxHeight?: string;
    minHeight?: string;
    rowMinHeight?: string;
    showCheckbox?: boolean;
    style?: React.CSSProperties;
};

const TableComponent: React.FC<TableComponentProps> = ({
    columns,
    data,
    showCheckbox = false,
    getSelectedRow = () => {},
    maxHeight = '',
    minHeight = '',
    rowMinHeight,
    disableOverflow = false,
    className,
    loading = false,
    onScroll = () => {},
    onSort,
    initialSorting = '',
    fetching = false,
    style,
    keyExtractor,
}) => {
    const newColumns = columns.filter((element) => Object.keys(element).length);
    const tableEngine = useTableEngine({ newColumns, data, showCheckbox, keyExtractor });
    return (
        <TableContentProvider value={tableEngine}>
            <div className={`TableComponent_main ${className || ''}`}>
                <HeaderTable initialSorting={initialSorting} getSelectedRow={getSelectedRow} />
                <ContentTable
                    style={style}
                    rowMinHeight={rowMinHeight}
                    onSort={onSort}
                    loading={loading}
                    onScroll={onScroll}
                    maxHeight={maxHeight}
                    minHeight={minHeight}
                    disableOverflow={disableOverflow}
                />
                {loading && (
                    <div className="tableLoading">
                        <LoadingComponent />
                    </div>
                )}
                {!loading && !data.length && (
                    <div className="tableNoData">
                        <div></div>
                    </div>
                )}
                {fetching && (
                    <div className="tableFetching">
                        <h2 className="animate">Fetching new data</h2>
                    </div>
                )}
            </div>
        </TableContentProvider>
    );
};

export default TableComponent;
