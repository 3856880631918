import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationType } from './notification.types';

export type NotificationsState =
    | {
          showNotification: false;
          showCloseButton: boolean;
          message?: string;
          type?: NotificationType;
      }
    | {
          showNotification: true;
          showCloseButton: boolean;
          message: string;
          type: NotificationType;
      };

export const initialNotificationsState: NotificationsState = {
    showNotification: false,
    showCloseButton: false,
};

type ShowNotificationPayload = {
    message: string;
    type: NotificationType;

    showCloseButton: boolean;
};

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: initialNotificationsState as NotificationsState,
    reducers: {
        showNotification: (_, action: PayloadAction<ShowNotificationPayload>) => {
            return { showNotification: true, ...action.payload };
        },
        closeNotification: (state) => {
            return { ...state, showNotification: false };
        },
    },
});

export const notificationActions = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
