import React, { ChangeEventHandler } from 'react';
import './TitledInputTextComponent.css';

export type TitledInputTextProps = {
    title?: string;
    className?: string;
    style?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    onChange: ChangeEventHandler<HTMLInputElement>
} & React.HTMLProps<HTMLInputElement>;

const TitledInputTextComponent: React.FC<TitledInputTextProps> = ({
    title = '',
    className = '',
    style = {},
    inputStyle = {},
    ...inputProps
}) => (
    <div className={`titled_input_wrapper ${className}`} style={style}>
        <span className="titled_input_title">{title}</span>
        <div className="titled_input_content_wrapper">
            {inputProps.maxLength && (
                <span className="titled_input_symLength">
                    {inputProps.maxLength - (inputProps.value as string)?.length}
                </span>
            )}
            {inputProps.type !== 'textarea' ? (
                <input className="titled_input_input" type="text" {...inputProps} style={inputStyle} />
            ) : (
                <textarea className="titled_input_textarea ac_scroll" {...(inputProps as any)} style={inputStyle} />
            )}
        </div>
    </div>
);
export default TitledInputTextComponent;
