import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AgentsListItem } from '../../api/models/agents.types';
import { BotAgentType } from '../../types/agent';

export type BotState = {
    botList: AgentsListItem[];
    botId: string | null;
    botAgent: BotAgentType | null;
};

const botInitialState: BotState = {
    botList: [],
    botId: null,
    botAgent: null,
};

export const botSlice = createSlice({
    name: 'bot',
    initialState: botInitialState,
    reducers: {
        setBotAgent: (state, action: PayloadAction<BotAgentType | null>) => ({ ...state, botAgent: action.payload }),
        setBotId: (state, action: PayloadAction<string>) => ({ ...state, botId: action.payload }),
        setBotList: (state, action: PayloadAction<AgentsListItem[]>) => ({ ...state, botList: action.payload }),
    },
});

export const botActions = botSlice.actions;
export const botReducer = botSlice.reducer;
