/* eslint-disable react/no-array-index-key */
import React, { FocusEventHandler, forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import useEventListener, { useStateCallBack } from '../../../functionLib/hooks';
import svgLib from '../../../functionLib/svglib';
import './Select.css';

export type SelectOption<V = unknown> = {
    content: React.ReactElement | string;
    value: V;
    name?: string;
    hidden?: boolean;
};

export type SelectProps<T extends SelectOption<unknown>> = {
    activeClassName?: string;
    acuikit?: boolean;
    arrowColor?;
    cb?;
    className?: string;
    disabled?: boolean;
    header?;
    locked?: boolean;
    name?: string;
    nameChange?;
    offAnim?;
    onChange?: (value: T['value']) => void;
    onClickClose?: boolean;
    onClose?: () => void;
    onOpen?: () => void;
    options: T[];
    sortBy?;
    title?: string;
    value?: string;
    initialItem?: T;
    id?: string;
    style?: React.CSSProperties;
    textStyle?: React.CSSProperties;

    onFocus?: FocusEventHandler<HTMLDivElement>;
    onBlur?: FocusEventHandler<HTMLDivElement>;
};

export type SelectRef = {
    openSelect: () => void;
};

const FRefInputComp = <T extends SelectOption>(
    {
        options = [],
        name,
        onChange,
        sortBy,
        title = '',
        className,
        activeClassName,
        arrowColor,
        value,
        acuikit,
        offAnim,
        onClickClose = true,
        header,
        nameChange,
        cb,
        disabled = false,
        onOpen = () => { },
        onClose = () => { },
        locked = false,
        onBlur,
        onFocus,
        style,
        textStyle,
        initialItem,
    }: SelectProps<T>,
    ref: Ref<SelectRef>
) => {
    const contentWrapper = React.createRef<HTMLDivElement>();
    const refName = React.createRef<HTMLDivElement>();
    const select = useRef<HTMLDivElement>(null);

    let contentHeight: number;

    const [height, setHeight] = useState('0px');
    const [isActive, setIsActive] = useState(false);
    const [activeValue, setActiveValue] = useStateCallBack(initialItem?.name ?? options[0]?.name, cb);
    const [isClose, setIsClose] = useState(true);
    const [isTop, setIsTop] = useState(false);

    useImperativeHandle(ref, () => ({
        openSelect() {
            setIsActive(true);
        },
    }));
    const closeSelect = () => {
        if (isClose) {
            onClose();
            setIsActive(false);
        }
    };

    useEffect(() => {
        if (isActive) onOpen();
    }, [isActive]);

    useEffect(() => {
        if (
            select.current!.getBoundingClientRect().bottom + contentWrapper.current!.getBoundingClientRect().height >
            window.innerHeight
        ) {
            setIsTop(true);
        } else {
            setIsTop(false);
        }
    }, [isActive]);

    useEventListener('click', closeSelect);
    useEffect(() => {
        contentHeight = contentWrapper.current!.getBoundingClientRect().height;

        if (contentHeight !== 0) {
            setHeight(`${contentHeight}px`);
        } else {
            setHeight('auto');
        }
    }, [options.length]);

    const onToggleActive = () => setIsActive(!isActive);
    const onSelect = (selectValue: T['value']) => {
        if (onClickClose) setIsActive(false);
        const selectedItem = options.find((item) => item.value === selectValue)!;
        setActiveValue(selectedItem.name);
        onChange?.(selectValue);
    };

    useEffect(() => {
        if (value !== undefined) {
            const selectedItem = options.find((item) => item.value === value);
            if (selectedItem) setActiveValue(selectedItem.name);
        }
    }, [options, value]);
    const onMouseEnter = () => setIsClose(false);
    const onMouseLeave = () => setIsClose(true);
    useEffect(() => setIsClose(false), []);

    useEffect(() => {
        if (value && nameChange) {
            const selectedItem = options.find((item) => item.value === value)!;

            setActiveValue(selectedItem.name);
        }
    }, [options.find((item) => item.value === value)]);

    const contentStyle = {
        height: `${isActive ? height : '0px'}`,
        top: isTop ? 'auto' : '39px',
        bottom: isTop ? '37px' : 'auto',
    };

    const filteredOptions = options
        .sort((a, b) => {
            if (sortBy) {
                const x = a[sortBy].toLowerCase();
                const y = b[sortBy].toLowerCase();
                if (x > y) return 1;
                if (y > x) return -1;
            }
            return 0;
        })
        .filter((item) => !item.hidden);
    return (
        <div
            ref={select}
            style={{ zIndex: isActive ? '7' : '6', ...style }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onFocus={onFocus}
            onBlur={onBlur}
            className={`select_main ${locked ? 'select_ac_locked' : ''} ${acuikit ? 'select_acuikit' : ''} ${disabled ? 'select_main_disabled' : ''
                } ${className} ${isActive ? `${activeClassName} select_acuikit_active${isTop ? 'isTop' : ''}` : ''}`}
        >
            <span className="titled_select_title">{title}</span>
            <div className="select_main_name" onClick={onToggleActive} ref={refName}>
                <span style={textStyle}>{name || activeValue}</span>
                <div className={`select_arrow_down ${isActive ? 'select_arrow_down_rotete' : ''}`}>
                    {svgLib.chevronDown(arrowColor || '#1377ff')}
                </div>
            </div>
            <div className="select_main_content" style={{ ...contentStyle }}>
                <div
                    className={`select_main_conteiner${isTop ? 'isTop' : ''}`}
                    style={offAnim ? { display: isActive ? 'block' : 'none' } : {}}
                    ref={contentWrapper}
                >
                    {header || null}
                    <div className="selectScroll">
                        {filteredOptions.map((item, i) => (
                            <div
                                className={`select_main_option ${value === item.value ? 'select_main_option_active' : ''
                                    }`}
                                onClick={() => onSelect(item.value)}
                                key={i}
                            >
                                {item.content}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

// NOTE(m-nny): some black magic to mage forwardRef generic
// https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
const UnknownSelect = forwardRef(FRefInputComp);
export const GenericSelect = <T extends SelectOption>({ ref, ...rest }: SelectProps<T> & { ref?: Ref<SelectRef> }) => (
    <UnknownSelect {...rest} ref={ref} />
);

export default GenericSelect;
