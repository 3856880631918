import { deleteCookie } from '../../functionLib/cookieFuncs';
import { CustomerType } from '../../types/customer';
import { ApiConfig } from '../config';
import utils from '../utils';

const customers = (options: ApiConfig) => {
    const { GATEWAY_URL, DEFAULT_API_ERROR } = options;
    /**
     * Gets customer info
     * @param {Object, String} fields - Projection of the fields to return
     * @returns {Promise} authorized customer info
     */
    const get = async (fields?: string | {}): Promise<CustomerType> => {
        const url = new URL(`${GATEWAY_URL}/api/customers`);

        if (fields) {
            if (typeof fields === 'string') url.searchParams.append('fields', fields);
            else {
                url.searchParams.append('fields', utils._parseFieldsObjectToString(fields));
            }
        }

        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'GET',
            redirect: 'follow',
            credentials: 'include',
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const checkCustomerProBots = async (customerID) => {
        const url = new URL(`${GATEWAY_URL}/api/checkCustomerProBots?customerID=${customerID}`);

        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'GET',
            redirect: 'follow',
            credentials: 'include',
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') {
                body.error = { message: body.error };
            }

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) {
                throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };
            }

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Updating customer with specified id
     * @param {Object} customer - Data to update ("_id" field is required)
     * @returns {Promise} Updated customer
     */
    const save = async (customer: any = {}) => {
        if (!customer._id) throw new Error('Please specify "_id" field');

        const url = new URL(`${GATEWAY_URL}/api/saveCustomer`);

        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(customer),
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Approves password validity for the customer
     * @param {String} password - Password to check for validity for the customer
     * @returns {Promise} error if occurred while checking password
     */
    const checkPassword = async (password) => {
        if (!password) throw new Error('Please specify a valid password to check');

        const url = new URL(`${GATEWAY_URL}/api/password/check`);

        url.searchParams.append('password', password);

        const options: RequestInit = {
            method: 'GET',
            credentials: 'include',
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Sets password for the customer
     * @param {String} password - Password to set for the customer
     * @returns {Promise} error if occurred while setting password for the customer
     */
    const setPassword = async (password) => {
        if (!password) throw new Error('Please specify a valid password to set');

        const url = new URL(`${GATEWAY_URL}/api/password/create`);

        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({ password }),
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const logOut = async () => {
        const url = new URL(`${GATEWAY_URL}/login/exit`);

        try {
            (window as any).FB &&
                (window as any).FB.getLoginStatus((response) => {
                    if (response.status === 'connected') {
                        (window as any).FB.logout((resp) => {});
                    }
                });
            const options: RequestInit = {
                headers: { 'Content-Type': 'application/json' },
                method: 'GET',
                credentials: 'include',
            };
            const response = await fetch(url.toString(), options);
            const json = await response.json();
            if (json.status === 'success') {
                if (json.loggedOut) {
                    deleteCookie('sessionID');
                    window.location = '/login' as any;
                }
            }
        } catch (err) {
            window.notification.error(err.message);
        }
    };

    const getCustomerIDByEmail = async (email) => {
        const url = new URL(`${GATEWAY_URL}/getCustomerIDByEmail?email=${email}`);

        const fetchOptions: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'GET',
            credentials: 'include',
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            console.log(response);
            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    return {
        get,
        save,
        checkPassword,
        setPassword,
        logOut,
        getCustomerIDByEmail,
        checkCustomerProBots,
    };
};

export default customers;
