import React from 'react';
import logoHeader from './logo.png';
import './MobileComponent.css';
import noMobile from './noMobile.svg';

const MobileComponent: React.FC = () => (
    <div className="mobile-ver-wraper">
        <div className="mobile-headr">
            <img src={logoHeader} />
        </div>
        <div className="mobile-content">
            <div className="mobile-content-img-wraper">
                <img src={noMobile} />
            </div>
            <p>
                Due to high amount of visual elements Activechat Visual Chatbot builder is best experienced on
                laptop/desktop devices. Mobile screens are too small, sorry.
            </p>
        </div>
    </div>
);
export default MobileComponent;
