import { ApiConfig } from '../config';

const additional = (options: ApiConfig) => {
    const { GATEWAY_URL, DEFAULT_API_ERROR } = options;

    /**
     * Sends an email from the Ask ActiveChat account
     * @param {String} sendTo - an email to send email for
     * @param {String} emailTemplateName - template of email to send
     * @param {Object} data - data to fullfill template with
     * @param {String} subject - email subject
     */
    const sendEmail = async (sendTo, emailTemplateName, data, subject) => {
        if (!sendTo || !emailTemplateName || !subject) throw new Error('Incorrect params while sending email');

        const url = new URL(`${GATEWAY_URL}/api/sendEmail`);

        const options: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                sendTo,
                emailTemplateName,
                data,
                subject,
            }),
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const uploadImageToGS = async (
        fileToUpload: File | Blob,
        agentID: string,
        imageName: string,
        imageID: string,
        isAgentImage: boolean,
        isCustomerImage: boolean
    ): Promise<{ url: string }> => {
        if (!fileToUpload || !fileToUpload.size) {
            throw new Error('Incorrect params while uploading image');
        }

        if (fileToUpload.size > Number(4e6)) {
            throw new Error('File is too big. (bigger than 4 MB)');
        }

        const formData = new FormData();
        formData.append('filetoupload', fileToUpload);
        formData.append('agentID', agentID);
        isAgentImage && formData.append('agentImage', `${isAgentImage}`);
        isCustomerImage && formData.append('customerImage', `${isCustomerImage}`);
        imageName && formData.append('imageName', imageName);
        imageID && formData.append('imageID', imageID);

        const url = new URL(`${GATEWAY_URL}/api/fileUpload`);

        const options: RequestInit = {
            mode: 'cors',
            credentials: 'include',
            method: 'POST',
            body: formData,
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Add domain to Facebook whitelist
     * @param {Sring} domain - Domain to add
     * @param {String} agentID - Agent id to add domain for
     */
    const addDomainToFbWhiteList = async (domain, agentID) => {
        if (!domain) throw new Error('Please specify domain which to add');

        if (!agentID) throw new Error('Please specify agent id');

        const url = new URL(`${GATEWAY_URL}/login/addDomainToFbWhiteList`);

        const options: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ domain, agentID }),
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Validates file by type and url
     * @param {String} agentID - Id of the agent
     * @param {String} type - Type of the file to validate
     * @param {String} urlToValidate - Url of the file to validate
     * @returns {Promise} result of the validation
     */
    const fileTypeValidation = async (agentID, type, urlToValidate) => {
        if (!agentID) throw new Error('Please specify agent id');

        if (!type) throw new Error('Please specify type');

        if (!urlToValidate) throw new Error('Please specify url');

        const url = new URL(`${GATEWAY_URL}/api/fileTypeValidation`);

        const options: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ agentID, type, url: urlToValidate }),
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    /**
     * Sends event to scesific user
     * @param {String} agentID - Id of the agent
     * @param {String} userID - ID of the user
     * @param {String} event - event name
     * @returns {Promise} result of the validation
     */
    const sendEvent = async (agentID, userID, event) => {
        if (!agentID) throw new Error('Please specify agent id');

        if (!userID) throw new Error('Please specify userID');

        if (!event) throw new Error('Please specify event');

        const url = new URL(`${GATEWAY_URL}/api/sendEvent`);

        const options: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ agentID, userID, event }),
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    const uploadFileToGS = async (botId, imageId, fileToUpload) => {
        if (fileToUpload.size > Number(4e6)) {
            throw new Error('File is too big. (bigger than 4 MB)');
        }

        const formData = new FormData();
        formData.append('filetoupload', fileToUpload);
        formData.append('botId', botId);
        formData.append('imageId', imageId);

        const url = new URL(`${GATEWAY_URL}/api/files/upload`);

        const options: RequestInit = {
            mode: 'cors',
            method: 'POST',
            body: formData,
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;

            const body = await response.json();

            console.log(body);

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: 'DEFAULT_API_ERROR',
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: 'DEFAULT_API_ERROR', ...body.error };

            return body;
        } catch (error) {
            console.log(error);

            throw error.statusCode ? error : { message: 'DEFAULT_API_ERROR' };
        }
    };

    const uploadFileToGSKnowledge = async (botId, fileToUpload) => {
        if (fileToUpload?.size > Number(4e6)) {
            throw new Error('File is too big. (bigger than 4 MB)');
        }

        const formData = new FormData();
        formData.append('file', fileToUpload);
        formData.append('botId', botId);

        const url = new URL(`${GATEWAY_URL}/api/files-knowledge/upload`);

        const options: RequestInit = {
            mode: 'cors',
            method: 'POST',
            body: formData,
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;

            const body = await response.json();

            console.log(body);

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: 'DEFAULT_API_ERROR',
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: 'DEFAULT_API_ERROR', ...body.error };

            return body;
        } catch (error) {
            console.log(error);

            throw error.statusCode ? error : { message: 'DEFAULT_API_ERROR' };
        }
    };

    const removeFileFromGSKnowledge = async (botId, name) => {
        const url = new URL(`${GATEWAY_URL}/api/files-knowledge/upload?botId=${botId}&name=${name}`);

        const options: RequestInit = {
            mode: 'cors',
            method: 'DELETE',
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;

            const body = await response.json();

            console.log(body);

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: 'DEFAULT_API_ERROR',
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: 'DEFAULT_API_ERROR', ...body.error };

            return body;
        } catch (error) {
            console.log(error);

            throw error.statusCode ? error : { message: 'DEFAULT_API_ERROR' };
        }
    };

    const checkSiteOnReachable = async (address) => {
        const url = address.includes('http') ? address : 'https://' + address;
        try {
            await fetch(url.toString(), { mode: 'no-cors', method: 'HEAD' });
            return true;
        } catch (error) {
            return false;
        }
    };

    return {
        sendEvent,
        sendEmail,
        uploadImageToGS,
        addDomainToFbWhiteList,
        fileTypeValidation,
        uploadFileToGS,
        uploadFileToGSKnowledge,
        removeFileFromGSKnowledge,
        checkSiteOnReachable,
    };
};

export default additional;
