import React from 'react';
import AppHeaderComponent from '../../../UIComponents/AppHeaderComponent/AppHeaderComponent';
import AccountEditor from './AccountEditor';
import UI from './styled';

const AccountComponentMain = (props) => (
    <UI.AccountComponentMain>
        <AppHeaderComponent text="My account" setShowSidebar={props['setShowSidebar']} />
        <AccountEditor />
    </UI.AccountComponentMain>
);
export default AccountComponentMain;
