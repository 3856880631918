import type React from 'react';
import { AppAction, appReducer, AppState, initialAppState } from './modules/app';
import { initialUIState, UIAction, uiReducer, UIState } from './modules/ui';

export type GlobalState = {
    ui: UIState;
    app: AppState;
};
export const initGlobalState: GlobalState = {
    app: initialAppState,
    ui: initialUIState,
};

export type GlobalAction = UIAction | AppAction;
export type GlobalDispatch = React.Dispatch<GlobalAction>;
export const globalReducer: React.Reducer<GlobalState, GlobalAction> = (state, action) => ({
    app: appReducer(state.app, action as any),
    ui: uiReducer(state.ui, action as any),
});
