import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './AppNavProgressBarComponent.css';
import styled from 'styled-components';

const AppNavProgressBarComponentMain = styled.div`
       display: flex;
       align-items: center;
       justify-content: center;
       flex-direction: column;
    `;
const AppNavProgressBarComponent = (props) => {
    const {
        linkTo, name, className, onClick, max, current,
    } = props;


    const [currentState] = useState({
        perCurrent: current * 100 / max,
    });

    const [ro, setOt] = useState('180deg');
    const [rt, setRt] = useState('180deg');

    useEffect(() => {
        setOt(`rotate(${currentState.perCurrent - 50 < 0 ? 180 : (currentState.perCurrent - 50) * 100 / 50 * 180 / 100 + 180}deg)`);
        setRt(`rotate(${currentState.perCurrent - 50 >= 0 ? 360 : currentState.perCurrent * 100 / 50 * 180 / 100 + 180}deg)`);
    }, []);

    const content = (
        <AppNavProgressBarComponentMain className={className} onClick={onClick}>
            <div className="AppNavProgressBar_wrapper">
                <div className="AppNavProgressBar_current">
                    {current}
                </div>
                <div className="AppNavProgressBar_left">
                    <div style={{ transform: ro }} />
                </div>
                <div className="AppNavProgressBar_right">
                    <div style={{ transform: rt }} />
                </div>
            </div>
            <div className="AppNavProgressBar_test_wrapper">
                <span>
                    {name}
                </span>
            </div>
        </AppNavProgressBarComponentMain>
    );


    return (
        linkTo ? (
            <NavLink to={linkTo} style={{ textDecoration: 'none' }}>
                {content}
            </NavLink>
        )
            : content

    );
};
AppNavProgressBarComponent.propTypes = {
    linkTo: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    max: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
};

AppNavProgressBarComponent.defaultProps = {
    linkTo: '',
    name: '',
    className: '',
    onClick: () => { },
};
export default AppNavProgressBarComponent;
