const googleCalendar = (options = {}) => {
  const { GATEWAY_URL, DEFAULT_API_ERROR } = options;

  /**
   * Gets Google Calendar list by specified agent id
   * @param {String} agentID Id of the agent to get Google Calendar list for
   * @returns {Promise<[Object]>} array of tables taken from Google Calendar
   */
  const list = async agentID => {
    if (!agentID)
      throw new Error(
        "Please specify the id of the agent to get Google Calendar list"
      );

    const url = new URL(`${GATEWAY_URL}/api/getGoogleCalendarList`);

    const options = {
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify({ agentID })
    };

    try {
      const response = await fetch(url, options),
        statusCode = response.status,
        body = await response.json();
      if (body && body.error && typeof body.error === "string")
        body.error = { message: body.error };

      if (!response.ok)
        throw {
          statusCode,
          message: DEFAULT_API_ERROR,
          ...body.error,
          rawBodyTaken: body
        };
      if (body && body.error)
        throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

      return body.calendarList;
    } catch (error) {
      throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
    }
  };

  return {
    list
  };
};

export default googleCalendar;
