import React, { useEffect, useState } from 'react';
import { uiActions } from '../../../engine/actions';
import { useAppState } from '../../../engine/globalStateContext';
import { SidebarSettings } from '../../../engine/modules/ui.types';
import svgLib from '../../../functionLib/svglib';
import './SidebarComponent.css';

const SidebarComponent = () => {
    const [, dispatch] = useAppState();

    const [isShow, setIsShow] = useState(false);
    const [settings, setSettings] = useState<SidebarSettings>({
        name: 'Settings',
        content: <div>Hi</div>,
        width: '380px',
    });

    const show = (settingsSidebar?: SidebarSettings) => {
        setIsShow(true);
        setSettings(settingsSidebar || settings);
    };

    const close = () => {
        setIsShow(false);
        setSettings({} as any);
    };

    useEffect(() => {
        dispatch(uiActions.setSidebar({ show, close, isShow }));
    }, []);

    return (
        <div
            className="PopupComponent_main"
            style={{
                width: isShow ? settings.width : '0px',
                maxWidth: isShow ? settings.maxWidth : '0px',
                minWidth: isShow ? settings.minWidth : '0px',
            }}
        >
            <div className="PopupComponent_main_header">
                <div className="PopupComponent_main_header_logo">
                    {svgLib.settings('#ffffff', { height: '20px', width: '20px' })}
                </div>
                <div className="PopupComponent_main_header_name">
                    <span>{settings.name}</span>
                </div>
                <div className="PopupComponent_main_header_close">
                    <div className="PopupComponent_main_header_close_button" onClick={() => close()}>
                        {svgLib.cross('#ffffff', { height: '15px', width: '15px' })}
                    </div>
                </div>
            </div>
            <div data-show={isShow} className="PopupComponent_main_content">
                {settings.content}
            </div>
        </div>
    );
};

export default SidebarComponent;
