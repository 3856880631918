import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SessionStatus } from '../../types/livachat';
import { UserSessionWithAgentSession } from '../../types/userSession';

export type SessionsState = {
    selectedSessionStatus: SessionStatus;
    userSessions: UserSessionWithAgentSession[];
    queuedSessions: UserSessionWithAgentSession[];
};

const initialSessionsState: SessionsState = {
    selectedSessionStatus: 'active',
    queuedSessions: [],
    userSessions: [],
};

const sessionsSlice = createSlice({
    name: 'sessions',
    initialState: initialSessionsState,
    reducers: {
        setUserSessions: (state, action: PayloadAction<UserSessionWithAgentSession[]>) => ({
            ...state,
            userSessions: action.payload,
        }),
        setQueuedSessions: (state, action: PayloadAction<UserSessionWithAgentSession[]>) => ({
            ...state,
            queuedSessions: action.payload,
        }),
        setSelectedSessionStatus: (state, action: PayloadAction<SessionStatus>) => ({
            ...state,
            selectedSessionStatus: action.payload,
        }),
    },
});

export const sessionsActions = sessionsSlice.actions;
export const sessionsReducer = sessionsSlice.reducer;
