import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LivechatAgentWithCustomerMetadata } from '../../api/models/livechat.types';

export type AgentsState = {
    agentsWithMetadata: LivechatAgentWithCustomerMetadata[];
};

const agentsInitialState: AgentsState = {
    agentsWithMetadata: [],
};

const agentsSlice = createSlice({
    name: 'agents',
    initialState: agentsInitialState,
    reducers: {
        setAgentsWithMetadata: (_, action: PayloadAction<LivechatAgentWithCustomerMetadata[]>) => ({
            agentsWithMetadata: action.payload,
        }),
    },
});

export const agentsActions = agentsSlice.actions;
export const agentsReducer = agentsSlice.reducer;
