import { configureStore } from '@reduxjs/toolkit';
import { agentsReducer } from './agents/agents.slice';
import { botReducer } from './bot/bot.slice';
import { customerReducer } from './customer/customer.slice';
import { livechatReducer } from './livechat/livechat.slice';
import { notificationsReducer } from './notification/notification.slice';
import { sessionsReducer } from './sessions/sessions.slice';
import { usersReducer } from './users/users.slice';

export const store = configureStore({
    reducer: {
        notifications: notificationsReducer,
        bot: botReducer,
        sessions: sessionsReducer,
        customer: customerReducer,
        livechat: livechatReducer,
        users: usersReducer,
        agents: agentsReducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
