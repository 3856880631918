import React from 'react';
import { Step0 } from './Step0';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { StateSetter } from './types';

export type BodyProps = {
    currentStep: number;
    setCopyBot: StateSetter<boolean>;
    setStep: StateSetter<number>;
    setLoading: StateSetter<boolean>;
    setNewBotId: StateSetter<string>;
    copyBot: boolean;
    newBotId: string | undefined;
};
export const Body: React.FC<BodyProps> = ({
    currentStep,
    setCopyBot,
    setStep,
    setLoading,
    setNewBotId,
    copyBot,
    newBotId,
}) => {
    if (currentStep === 0) {
        return (
            <div className="new_bot_body">
                <Step0
                    currentStep={currentStep}
                    copyBot={copyBot}
                    setCopyBot={setCopyBot}
                    setStep={setStep}
                />
            </div>
        );
    }
    if (currentStep === 1) {
        return (
            <div className="new_bot_body">
                <Step1
                    currentStep={currentStep}
                    copyBot={copyBot}
                    setNewBotId={setNewBotId}
                    setStep={setStep}
                    setLoading={setLoading}
                />
            </div>
        );
    }
    if (currentStep === 2) {
        return (
            <div className="new_bot_body">
                <Step2 newBotId={newBotId!} />
            </div>
        );
    }
    return null;
};
