import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import TitledInput from '../../../../../UIComponents/TitledInputTextComponent/TitledInputTextComponent';
import UI from './styled';
import './styled.css';

const EmailEditor = ({ setInputValue, inputValue, checkNewData, popupError }) => {
    const [message, setMessage] = useState({ data: 'Invalid email', name: '', borderColor: '' });

    const onSetShow = () => {
        setMessage({
            ...message,
            name: '',
        });
    };

    const createMessage = (messageText, name) => {
        setMessage({
            ...message,
            data: messageText,
            name,
        });
    };

    useEffect(() => {
        if (popupError) createMessage('Invalid email format', 'name-email');
    }, [popupError]);

    return (
        <UI.EmailEditor>
            <TitledInput
                title="Email"
                value={inputValue.email}
                notification={{ ...message }}
                type="email"
                className="account-email-input"
                placeholder="example@mail.com"
                name="name-email"
                // setShow={onSetShow}
                onChange={(e) => {
                    setInputValue({ ...inputValue, email: e.target.value });
                    checkNewData();
                }}
                readOnly
            />
        </UI.EmailEditor>
    );
};

EmailEditor.propTypes = {
    checkNewData: PropTypes.func,
    setInputValue: PropTypes.func,
    inputValue: PropTypes.object,
    popupError: PropTypes.bool,
};

EmailEditor.defaultProps = {
    checkNewData: () => {},
    setInputValue: () => {},
    inputValue: {},
    popupError: false,
};

export default EmailEditor;
