const whatsapp = (options = {}) => {
    const { DEFAULT_API_ERROR, ARCHITECT_URL } = options;

    /**
     * Connects an agent to the Facebook channel
     * @returns {Promise} customer connected to Facebook channel
     */
    const connect = async (agentId, waConnectionData) => {
        const url = new URL(`${ARCHITECT_URL}/v1/whatsapp/connection?agentId=${agentId}`);

        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(waConnectionData),
        };

        try {
            const response = await fetch(url, options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body.data;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Disconnect Whatsapp channel for customer
     * @returns {Promise} customer disconnected from Facebook channel
     */
    const disconnect = async (agentId) => {
        // const url = new URL(`${GATEWAY_URL}/api/channels/facebook/connection`);
        const url = new URL(`${ARCHITECT_URL}/v1/whatsapp/connection?agentId=${agentId}`);

        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'DELETE',
        };

        try {
            const response = await fetch(url, options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body.data;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    return {
        connect,
        disconnect,
    };
};

export default whatsapp;
