import PropTypes from 'prop-types';
import React from 'react';
import ButtonComponent from '../../../../../UIComponents/ButtonComponent/ButtonComponent';
import './index.css';
import UI from './styled';

const SaveOrChangePassword = ({ onClick, onClickSave, className, locked, loading }) => (
    <UI.SaveOrChangePassword>
        <UI.ChangePasswordButton onClick={onClick}>Change password</UI.ChangePasswordButton>

        <ButtonComponent
            onClick={onClickSave}
            className={className}
            locked={locked}
            colorLoader="white"
            loading={loading}
            acuikit
        >
            {locked ? 'Changes saved' : 'Save changes'}
        </ButtonComponent>
    </UI.SaveOrChangePassword>
);

SaveOrChangePassword.propTypes = {
    onClick: PropTypes.func,
    onClickSave: PropTypes.func,
    className: PropTypes.string,
    locked: PropTypes.bool,
    loading: PropTypes.bool,
};

SaveOrChangePassword.defaultProps = {
    onClick: () => {},
    onClickSave: () => {},
    className: 'accountButton',
    locked: false,
    loading: false,
};

export default SaveOrChangePassword;
