import _ from 'lodash';
import React from 'react';
import { ResizeImageData } from '../../components/UIComponents/types/imageData';

export type SidebarSettings = {
    name: string;
    content: React.ReactElement;
    width?: string;
    minWidth?: string;
    maxWidth?: string;
};
export type Sidebar = {
    show: (settings?: SidebarSettings) => void;
    close: () => void;
    isShow: boolean;
};
export const dummpySidebar: Sidebar = {
    show: _.noop,
    close: _.noop,
    isShow: false,
};
export type PopUpSettings = {
    content: React.ReactElement;
};
export type PopUp = {
    show: (settings?: PopUpSettings) => void;
    close: () => void;
    crop: (src: string, cb: (data: ResizeImageData) => void, ratio?: number) => void;
};

export const dummpyPopUp: PopUp = {
    show: _.noop,
    close: _.noop,
    crop: _.noop,
};
