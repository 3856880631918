import { SessionStatus } from '../../types/livachat';
import { UserSessionWithAgentSession } from '../../types/userSession';
import { useAppDispatch, useAppSelector } from '../hooks';
import { sessionsActions } from './sessions.slice';

export const useUserSessions = () => useAppSelector((state) => state.sessions.userSessions);
export const useQueuedSessions = () => useAppSelector((state) => state.sessions.queuedSessions);
export const useSelectedSessionStatus = () => useAppSelector((state) => state.sessions.selectedSessionStatus);

export const useSelectedSessionStatusState = () => {
    const appDispatch = useAppDispatch();
    const selectedSessionStatus = useSelectedSessionStatus();

    const setSelectedSessionStatus = (status: SessionStatus) =>
        appDispatch(sessionsActions.setSelectedSessionStatus(status));
    return [selectedSessionStatus, setSelectedSessionStatus] as const;
};

export const useUserSessionsState = () => {
    const appDispatch = useAppDispatch();
    const userSessions = useUserSessions();

    const setUserSessions = (userSessions: UserSessionWithAgentSession[]) =>
        appDispatch(sessionsActions.setUserSessions(userSessions));

    return [userSessions, setUserSessions] as const;
};

export const useQueuedSessionsState = () => {
    const appDispatch = useAppDispatch();
    const queuedSessions = useQueuedSessions();

    const setQueuedSessions = (queuedSessions: UserSessionWithAgentSession[]) =>
        appDispatch(sessionsActions.setQueuedSessions(queuedSessions));

    return [queuedSessions, setQueuedSessions] as const;
};
