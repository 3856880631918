import React, { } from 'react';
import './BotsStatsComponent.css';
import PropTypes from 'prop-types';
import settings from './img/settings.png';


const BotsStatsComponent = (props) => {
    const { content, name, onClickSettings } = props;
    return (
        <div className="BotsStatsComponent_main">
            <div className="BotsStatsComponent_header">
                <div className="BotsStatsComponent_header_name">
                    <span>
                        {name}
                    </span>
                </div>
                <div className="BotsStatsComponent_header_settings">
                    <div className="BotsStatsComponent_header_settings_button" onClick={onClickSettings}>
                        <img src={settings} alt="" />
                    </div>
                </div>
            </div>
            <div className="BotsStatsComponent_content">
                {content}
            </div>
        </div>
    );
};

BotsStatsComponent.propTypes = {
    content: PropTypes.element,
    name: PropTypes.string,
    onClickSettings: PropTypes.func,
};

BotsStatsComponent.defaultProps = {
    content: null,
    name: '',
    onClickSettings: () => {},
};

export default BotsStatsComponent;
