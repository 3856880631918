import { useEffect } from 'react';
import { useSounds } from '../../../../../hooks/useSounds';
import { UserSessionWithAgentSession } from '../../../../../types/userSession';

export const useUnreadMessagesCount = (sessions: UserSessionWithAgentSession[]) => {
    const sounds = useSounds();
    const unreadMessagesCount = getUnreadMessagesCount(sessions);

    useEffect(() => {
        if (unreadMessagesCount) {
            sounds.playNewMsg();
        }
    }, [unreadMessagesCount]);

    return { unreadMessagesCount };
};

const getUnreadMessagesCount = (sessions: UserSessionWithAgentSession[]) =>
    sessions.reduce(
        (unreadMessagesCount, item) => unreadMessagesCount + (item.session.details?.userWaitingMessagesCount ?? 0),
        0
    );
