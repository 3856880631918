import React from 'react';
import svgLib from '../../../../functionLib/svglib';
import ButtonComponent from '../../../UIComponents/ButtonComponent/ButtonComponent';
import { StateSetter } from './types';

export type Step0Props = {
    currentStep: number;
    copyBot?: boolean;
    setCopyBot: StateSetter<boolean>;
    setStep: StateSetter<number>;
};
export const Step0: React.FC<Step0Props> = ({
    currentStep = 0,
    copyBot = false,
    setCopyBot = () => {},
    setStep = () => {},
}) => (
    <>
        <div className="new_bot_body_step_0">
            <div
                className={`new_bot_body_card ${!copyBot ? 'new_bot_body_card_selected' : ''}`}
                onClick={() => setCopyBot(false)}
            >
                <div className="new_bot_body_card_icon">{svgLib.new_bot_scratch('')}</div>
                <div className="new_bot_body_card_desc">
                    <span>Start new</span>
                </div>
            </div>
            <div
                className={`new_bot_body_card ${copyBot ? 'new_bot_body_card_selected' : ''}`}
                onClick={() => setCopyBot(true)}
            >
                <div className="new_bot_body_card_icon">{svgLib.new_bot_copy('')}</div>
                <div className="new_bot_body_card_desc">
                    <span>Copy existing bot</span>
                </div>
            </div>
        </div>
        <div className="new_bot_footer">
            <ButtonComponent
                className="new_bot_footer_button"
                acuikit
                locked={currentStep === 0}
                onClick={() => setStep((pre) => Math.max(0, pre - 1))}
            >
                Back
            </ButtonComponent>
            <ButtonComponent
                className="new_bot_footer_button"
                acuikit
                onClick={() => setStep((pre) => Math.min(2, pre + 1))}
            >
                Next
            </ButtonComponent>
        </div>
    </>
);
