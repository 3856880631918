import React from 'react';
import svgLib from '../../../functionLib/svglib';
import { injectNotificationsIntoWindows, useNotificationsActions } from '../../../hooks/notifications/actions';
import { useNotificationsState } from '../../../hooks/notifications/state';
import './NotificationComponent.css';

const NotificationComponent: React.FC = () => {
    injectNotificationsIntoWindows();
    const { close } = useNotificationsActions();
    const n11sState = useNotificationsState();
    const { fontSize, style, showCloseButton, message } = n11sState;

    return (
        <div
            className={`pNotificationComponent_fixed ${
                n11sState.showNotification ? 'pNotificationComponent_fixed_show' : null
            }`}
        >
            <div className="pNotificationComponent_main" style={{ background: style?.color }}>
                <div className="pNotificationComponent_image">{style?.icon}</div>

                <div className="pNotificationComponent_container">
                    <div className="pNotificationComponent_message" style={{ fontSize }}>
                        {message}
                    </div>
                </div>

                {showCloseButton && (
                    <div className="pNotificationComponent_header">
                        <div className="pNotificationComponent_close_button" onClick={close}>
                            {svgLib.cross('white', { height: '10px', width: '10px' })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NotificationComponent;
