import React, { useEffect } from 'react';
import svgLib from '../../../functionLib/svglib';
import CheckboxComponent from '../CheckboxComponent/CheckboxComponent';
import { useTableContent } from './GlobalStateContext';

type HeaderTableProps = {
    getSelectedRow: (value) => void;
    initialSorting: string;
};

const HeaderTable: React.FC<HeaderTableProps> = (props) => {
    const context = useTableContent();
    const { initialSorting } = props;
    const { data, selectedRow, sort, showCheckbox, columns, selectedAll } = context;
    const { value } = sort;

    useEffect(() => {
        props.getSelectedRow(context.selectedRow?.value);
    }, [selectedRow?.value]);

    useEffect(() => {
        context.selectedRow?.set([]);
    }, [data.length]);

    const sortState = value;

    const setSort = (item) => {
        const sortedBy = item.sortBy || item.field;
        context.sort.set({
            by: sortedBy,
            // direction: sortedBy === sortState.by ? !sortState.direction : true,
            direction: !sortState.direction,
        });
    };

    useEffect(() => {
        if (initialSorting) {
            setSort({ sortBy: initialSorting });
        }
    }, []);

    const selectAll = () => {
        if (!context.selectedAll.value) {
            context.selectedAll.set(true);
            context.selectedRow.set(context.data);
        } else {
            context.selectedAll.set(false);
            context.selectedRow.set([]);
        }
    };

    useEffect(() => {
        if (context.selectedRow.value.length && context.selectedRow.value.length === context.data.length) {
            context.selectedAll.set(true);
        } else {
            context.selectedAll.set(false);
        }
    }, [selectedRow]);

    return (
        <div className="HeaderTableStyle">
            {showCheckbox && (
                <div className="table_checkbox_wrapper">
                    <CheckboxComponent checked={selectedAll.value} onChange={selectAll} className="tablecheckbox" />
                </div>
            )}
            {showCheckbox && selectedRow.value.length > 0 ? (
                <div className="table_checkbox_counter"> {selectedRow.value.length}</div>
            ) : null}
            {columns.map((item, index) => (
                <div
                    className={`TdTable ${item.className ? item.className : ''}`}
                    style={{ cursor: item.disableSort ? 'default' : 'pointer' }}
                    onClick={item.disableSort ? () => {} : () => setSort(item)}
                    key={index}
                >
                    {item.CustomHeader ? item.CustomHeader(context.data) : item.Header}
                    {(item.sortBy || item.field) === context.sort.value.by ? (
                        <div
                            style={{ transform: !context.sort.value.direction ? 'rotate(180deg)' : 'rotate(0deg)' }}
                            className="arrow_sort"
                        >
                            {svgLib.arrow('#000000', { height: '15px', width: '15px' })}
                        </div>
                    ) : null}
                </div>
            ))}
        </div>
    );
};

export default HeaderTable;
