/* eslint-disable indent */
import styled from 'styled-components';

const AvatarEditor = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

const AvatarImageInput = styled.input`
    opacity: 0;
    width: 64px;
    height: 64px;
    border-radius: 50px;
    margin: 0px 10px 0px 5px;
    outline: none;
    cursor: pointer;
    z-index: 0;
`;

const AvatarEditorLabel = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
`;
const AvatarEditorInputBox = styled.div`
    display: flex;
    flex-direction: column;
`;

const AvatarEditorText = styled.p`
    color: rgba(0, 0, 0, 0.38);
    font-family: var(--main-font);
    font-size: 12px;
    margin: 0px 0px 0px 5px;
`;

const AvatarName = styled.span`
    font-size: 12px;
    font-family: var(--main-font);
    margin: 0px 0px 10px 0px;
`;

const CropOrRemoveContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 0px 0px 10px;
    margin: 0px 5px 0px 0px;
`;

const CropImageContainer = styled.label`
    display: flex;
    align-items: center;
    margin: 0px 22px 0px 0px;
    cursor: pointer;
`;

const CropImage = styled.img`
    height: 20px;
    margin: 0px 12px 0px 0px;
`;

const RemoveImageContainer = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const RemoveImage = styled.img``;

export type BoxProps = { border: string };
const Box = styled.div<BoxProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({ border }) => border} dashed rgba(0, 0, 0, 0.1);
    margin: 0px 20px 0px 0px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
`;

const UploadedImage = styled.img`
    height: 100%;
    position: absolute;
`;

export default {
    AvatarEditor,
    AvatarImageInput,
    AvatarEditorLabel,
    AvatarEditorText,
    AvatarEditorInputBox,
    AvatarName,
    CropOrRemoveContainer,
    CropImageContainer,
    RemoveImageContainer,
    CropImage,
    RemoveImage,
    Box,
    UploadedImage,
};
