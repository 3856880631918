import React from "react";
import "./AnswerCardComponent.css";
const AnswerCardComponent = ({
  title = "",
  imgSrc = "",
  description = "",
  answerValue = "",
  onClick = () => {},
  selected = false,
  cardType = "large"
}) => {
  const cardTypes = {
    large: (
      <div
        answervalue={answerValue}
        className={`signup_quiz_sequence_selector_answer_card ${
          selected === answerValue
            ? "signup_quiz_sequence_selector_answer_card_selected"
            : ""
        }`}
        onClick={onClick}
      >
        <div className='signup_quiz_sequence_selector_answer_card_title_wrapper'>
          <span
            className={`signup_quiz_sequence_selector_answer_card_title ${
              selected === answerValue
                ? "signup_quiz_sequence_selector_answer_card_title_selected"
                : ""
            }`}
          >
            {title}
          </span>
        </div>
        <div className='signup_quiz_sequence_selector_answer_card_image_wrapper'>
          <img src={imgSrc} />
        </div>
        <div className='signup_quiz_sequence_selector_answer_card_description_wrapper'>
          <span
            className={`signup_quiz_sequence_selector_answer_card_description ${
              selected === answerValue
                ? "signup_quiz_sequence_selector_answer_card_description_selected"
                : ""
            }`}
          >
            {description}
          </span>
        </div>
      </div>
    ),
    small: (
      <div
        answervalue={answerValue}
        className={`signup_quiz_sequence_selector_answer_card_small ${
          selected === answerValue
            ? "signup_quiz_sequence_selector_answer_card_small_selected"
            : ""
        }`}
        onClick={onClick}
      >
        <div className='signup_quiz_sequence_selector_answer_card_image_wrapper'>
          <img src={imgSrc} />
        </div>
        <div className='signup_quiz_sequence_selector_answer_card_description_wrapper'>
          <span
            className={`signup_quiz_sequence_selector_answer_card_description ${
              selected === answerValue
                ? "signup_quiz_sequence_selector_answer_card_description_selected"
                : ""
            }`}
          >
            {description}
          </span>
        </div>
      </div>
    )
  };
  return cardTypes[cardType];
};

export default AnswerCardComponent;
