import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CrossGrey from './img/CrossGrey.png';

export const InformPopup = ({
    show,
    setShow,
    customTop,
    display,
    className,
    offZIndex,
    view,
    color,
    title,
    description,
}) => {
    const contentWrapper = React.createRef();
    const viewWrapper = React.createRef();

    const [height, setHeight] = useState('0px');
    const [width, setWidth] = useState('0px');
    const [top, setTop] = useState('0px');
    useEffect(() => {
        if (contentWrapper && contentWrapper.current && contentWrapper.current.getBoundingClientRect().height !== 0) {
            setHeight(`${contentWrapper.current.getBoundingClientRect().height + 55}px`);
            setWidth(`${contentWrapper.current.getBoundingClientRect().width + 30}px`);
        } else {
            setHeight('auto');
            setWidth('auto');
        }
        if (customTop.length) {
            setTop(`${customTop[0]}px`);
        } else {
            setTop(`${viewWrapper && viewWrapper.current && viewWrapper.current.getBoundingClientRect().height / 2}px`);
        }
    }, []);

    useEffect(() => {
        if (contentWrapper && contentWrapper.current && contentWrapper.current.getBoundingClientRect().height !== 0) {
            setHeight(`${contentWrapper.current.getBoundingClientRect().height + 55}px`);
            setWidth(`${contentWrapper.current.getBoundingClientRect().width + 30}px`);
        } else {
            setHeight('auto');
            setWidth('auto');
        }
    }, [show]);

    let style;
    switch (display) {
        case 'top':
            style = {
                overflow: 'hidden',
                position: 'absolute',
                height: show ? height : '0px',
                width: show ? width : '0px',
                transition: 'width 0.3s, height 0.3s',
                left: '50%',
                transform: 'translateX(-50%)',
                bottom: `${customTop[0]}px`,
                zIndex: '3423432432',
            };
            break;

        default:
            style = {
                overflow: 'hidden',
                position: 'absolute',
                height: show ? height : '0px',
                width: show ? width : '0px',
                transition: 'width 0.3s, height 0.3s',
                left: '50%',
                transform: 'translateX(-50%)',
                top: customTop[0] ? `${customTop[0]}px` : '39px',
                zIndex: '3423432432',
            };
            break;
    }

    return (
        <div className={`InformComponent_main ${className || ''}`}>
            <div className="InformComponent_main_container" style={{ zIndex: offZIndex ? 'auto' : '20' }}>
                <div className="InformPopup_main_container" ref={viewWrapper}>
                    {view}
                </div>
                <div id="style" style={{ ...style }}>
                    <div
                        className="InformComponent_main_message_container"
                        style={
                            customTop && {
                                top: customTop[1] ? `${customTop[1]}px` : `${16}px`,
                                border: color ? `1px solid ${color}` : 'none',
                            }
                        }
                        ref={contentWrapper}
                    >
                        <div className="InformComponent_main_message_content">
                            <div
                                className="InformComponent_main_message_sqwer_wrapper"
                                style={
                                    display === 'top'
                                        ? { bottom: '-10px', top: 'auto' }
                                        : { top: '-10px ', bottom: 'auto' }
                                }
                            >
                                <div
                                    className="InformComponent_main_message_sqwer"
                                    style={{ border: color ? `1px solid ${color}` : 'none' }}
                                />
                                <div
                                    className="InformComponent_main_message_hide"
                                    style={{ top: display === 'top' ? '-13px' : '10px' }}
                                />
                            </div>
                            <div className="InformComponent_main_message_header">
                                <div className="InformComponent_main_message_header_td">
                                    <div className="InformComponent_main_message_header_title">{title}</div>
                                </div>
                                <div className="InformComponent_main_message_header_td InformComponent_main_message_header_end">
                                    <div onClick={() => setShow()} className="InformComponent_main_message_closebutton">
                                        <img src={CrossGrey} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="InformComponent_main_message_contant_wrapper"
                                style={{ color: color || null }}
                            >
                                {description}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

InformPopup.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    customTop: PropTypes.arrayOf(PropTypes.any),
    display: PropTypes.string,
    className: PropTypes.string,
    offZIndex: PropTypes.bool,
    view: PropTypes.any.isRequired,
    color: PropTypes.string,
    title: PropTypes.any,
    description: PropTypes.any,
};

InformPopup.defaultProps = {
    show: false,
    setShow: () => {},
    customTop: [],
    display: '',
    className: '',
    offZIndex: false,
    title: null,
    description: null,
    color: '',
};
