import React, { useState, useEffect } from "react";
import "./BuildYourFirstBot.css";
import { Link, useHistory } from "react-router-dom";
import uuid from "uuid";
import moment from "moment";
import Select from "../../../UIComponents/Select/Select";
import { secondaryLangList, mainLangList } from "./botsLanguageArrays";
import ButtonComponent from "../../../UIComponents/ButtonComponent/ButtonComponent";
import Waiting from "../../../UIComponents/WaitingComponent/Waiting";
import API from "../../../../api";
import svgLib from "../../../../functionLib/svglib";

const api = API({ GATEWAY_URL: process.env.REACT_APP_GATEWAY_URL });

export const BuildYourFirstBot = () => {
  const [lang, setLang] = useState("en");
  const [step, setStep] = useState("lang");
  const [agentId, setAgentId] = useState("");
  const history = useHistory();

  const createNewAgentFromScratch = async () => {
    setStep("wait");
    try {
      const trial_end = moment().add("days", 14).unix() * 1000;
      const { data } = await api.agents.create({
        advancedSettings: {
          bot_language: lang,
        },
        trial_end,
        title: "My new bot",
        image:
          "https://storage.googleapis.com/activechat-200215.appspot.com/AC_symbol.png",
      });
      setAgentId(data._id);
      setStep("goTo");
    } catch (error) {
      setStep("lang");
      window.notification.error(error.message, 5);
    }
  };
  return (
    <div className="signup_quiz_component">
      {step === "lang" && (
        <div className="build_your_first_bot_wrapper">
          <div className="signup_quiz_sequence_selector_question_wrapper">
            <span className="build_your_first_bot_step_text">
              {/* Choose language for your virtual agent */}
              Create your first project
            </span>
          </div>

          <div className="new_bot_lang_desc" style={{ maxWidth: "340px" }}>
            <span>
              Skip this step If you've been invited to an existing project
            </span>
          </div>
          <div className="build_your_first_bot_lang">
            <Select
              title="Language"
              acuikit
              onChange={(value) => setLang(value)}
              value={lang || "en"}
              options={[...mainLangList, ...secondaryLangList].map((item) => ({
                content: (
                  <div className="group_bot_select_option">{item.native}</div>
                ),
                value: item.code,
                name: item.native,
              }))}
            />
          </div>
          <div className="new_bot_lang_desc">
            {/* {mainLangList.some((item) => item.code === lang) ? (
              <span>
                This language has full AI / NLP support with Intents, Insights
                and AI hints
              </span>
            ) : (
              <span>
                This language is partially supported. Natural language
                understanding (Intents) will work, but Insights and AI hints
                will not be available
              </span>
            )} */}
          </div>

          <ButtonComponent
            className="build_your_first_bot_button"
            acuikit
            onClick={() => createNewAgentFromScratch()}
          >
            Next
          </ButtonComponent>

          <ButtonComponent
            outline
            acuikit
            className="build_your_first_bot_button"
            onClick={() => {
              history.push("/bots");
            }}
          >
            Skip
          </ButtonComponent>
        </div>
      )}

      {step === "wait" && (
        <div className="build_your_first_bot_wrapper">
          <div className="build_your_first_bot_wait">
            <Waiting text="Working..." />
          </div>
        </div>
      )}
      {step === "goTo" && (
        <div className="build_your_first_bot_wrapper">
          <div className="signup_quiz_sequence_selector_question_wrapper">
            <span className="build_your_first_bot_step_text">
              Your agent is ready! What’s next?
            </span>
          </div>
          <div className="build_your_first_bot_goTo">
            {
              // Item 1
            }
            <div className="build_your_first_bot_goTo_item">
              <div className="build_your_first_bot_goTo_item_header">
                {svgLib.go_to_intents("")}
              </div>
              <div className="build_your_first_bot_goTo_item_desc">
                <p>
                  Build your natural language agent skills from scratch by
                  providing example phrases that your customers can use to
                  describe each of their questions, requests or issues
                </p>
              </div>
              <div className="build_your_first_bot_goTo_item_button">
                <Link
                  className="build_your_first_bot_button_wrapper"
                  to={`/bots/bot/${agentId}/automation/skills`}
                >
                  <div>
                    <span>Get started</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
