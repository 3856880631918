import { Api } from '../../../../../api';
import { LivechatData, SessionStatus } from '../../../../../types/livachat';
import { UserSessionWithAgentSession } from '../../../../../types/userSession';

const getAgentQueuedSessions = async (
    api: Api,
    botId: string,
    agentId: string,
    sessionStatus: SessionStatus,
    tags: string[]
): Promise<UserSessionWithAgentSession[]> =>
    Promise.allSettled([
        api.users.getSessions(botId, agentId, sessionStatus),
        api.users.getSessions(botId, null, sessionStatus, tags),
    ])
        .then((results) => {
            let users: UserSessionWithAgentSession[] = [];
            results.forEach((item) => {
                if (item.status === 'fulfilled') {
                    users = [...users, ...item.value.users];
                }
            });
            return users.filter((item, index) => users.findIndex((user) => user._id === item._id) === index);
        })
        .catch(() => []);

const getAgentSessions = async (
    api: Api,
    botId: string,
    agentId: string | null,
    sessionStatus: SessionStatus
): Promise<UserSessionWithAgentSession[]> =>
    api.users
        .getSessions(botId, agentId, sessionStatus)
        .then((res) => res.users)
        .catch(() => []);

export const getUserSessions = async (
    api: Api,
    liveChatData: LivechatData,
    selectedBotId: string,
    sessionStatus: SessionStatus
) => {
    if (liveChatData.livechat_admin) {
        return getAgentSessions(api, selectedBotId, null, sessionStatus);
    }
    if (liveChatData.liveChat && sessionStatus === 'queued') {
        return getAgentSessions(api, selectedBotId, null, sessionStatus);
    }
    if (liveChatData.liveChat && sessionStatus === 'queued') {
        return await getAgentSessions(api, selectedBotId, null, sessionStatus);
    }
    if (sessionStatus === 'queued') {
        return getAgentQueuedSessions(api, selectedBotId, liveChatData.agentId, sessionStatus, liveChatData.tags);
    }
    return getAgentSessions(api, selectedBotId, liveChatData.agentId, sessionStatus);
};
