/* eslint-disable */
import queryString from 'query-string';
import React from 'react';
import guid from '../../../guid';
import './ChatLandingComponent.css';

const values = queryString.parse(decodeURIComponent(window.location.search));

const CW_URL = 'https://chatwidget-dot-activechat-200215.appspot.com';
// const CW_URL = 'https://chatwidget-dev-dot-activechat-200215.appspot.com'

const userID = values.setUserID || getCookie('userID') || guid();
let agentID = '09e2049d-5e66-4e6a-b26e-cd73f2aa5bff';

const defaultAgentInfo = {
    title: 'ActiveChat',
    image: 'https://storage.googleapis.com/activechat-200215.appspot.com/113495580471143471434/5769f458-c302-4241-80c2-4bc6a41dfb15/image',
};

if (!userID) document.cookie = 'userID=' + userID + ' ;path=/';

class ChatLandingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: `agentID=${this.props.agentID || agentID}&userID=${userID}&title=${defaultAgentInfo.title}&image=${
                defaultAgentInfo.image
            }&anonymousID=${getCookie('anonymousID')}`,
        };
    }

    getAgentInfo = (agentID) => {
        return new Promise((resolve, reject) => {
            fetch(`/login/agentInfo?agentID=${agentID}`, {
                credentials: 'same-origin',
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`Status ${response.status}`);
                    }
                    return response.json();
                })
                .then((json) => {
                    if (json.error) return reject(json.error);
                    if (json.agent) return resolve(json.agent);

                    reject(new Error({ message: 'An unexpected error while taking agent info', response: json }));
                })
                .catch((e) => reject(e));
        });
    };

    checkBadResponse(values) {
        if (values.success === '0') {
            console.log('Bad response from woo auth!', values);

            fetch(`/login/sendEvent`, {
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'post',
                body: JSON.stringify({ event: 'woo_connect_fail', agentID: agentID, userID }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`Status ${response.status}`);
                    }
                    return response.json();
                })
                .then((json) => {
                    console.log('Event triggered', json);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }

    componentDidMount() {
        console.log(this.state, this.props.agentID);
        agentID =
            this.props.match && this.props.match.params && this.props.match.params.agentID
                ? this.props.match.params.agentID
                : this.props.agentID;
        this.getAgentInfo(this.props.agentID || agentID)
            .then((agent) => {
                let params = `agentID=${this.props.agentID || agentID}&userID=${userID}&title=${agent.title}&image=${
                    agent.image
                }&anonymousID=${getCookie('anonymousID')}&settings=${JSON.stringify(
                    agent.messengers.cis && agent.messengers.cis.settings
                        ? {
                              mainColor: agent.messengers.cis.settings.mainColor,
                              messageFontSize: agent.messengers.cis.settings.messageFontSize
                                  ? agent.messengers.cis.settings.messageFontSize
                                  : 14,
                              headerTextColor: agent.messengers.cis.settings.headerTextColor,
                          }
                        : { messageFontSize: 14 }
                )}`;
                this.setState({
                    params,
                });
            })
            .catch((error) => {
                console.error(error);
            });

        this.checkBadResponse(queryString.parse(decodeURIComponent(window.location.search)) || {});
    }

    render() {
        let { params } = this.state;
        console.log();
        return (
            // <div className={` ${this.props.className ? this.props.className : 'chatLandinWrapper'}`} >
            <iframe
                src={`${window._env_.CHATWIDGET_URL}/chat?${encodeURIComponent(
                    params + '&startPolling=true&disableCloseButton=true'
                )}`}
                className="ACCW-FRAME"
                id="AACW-CHAT"
                frameBorder="0"
            ></iframe>
            // </div>
        );
    }
}

export default ChatLandingComponent;
