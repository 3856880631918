import styled from 'styled-components';

const AccountEditor = styled.div`
  display: flex
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border: 1px solid black
  margin: 28px
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

const Loading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export default {
  AccountEditor,
  Loading,
};
