import { ApiConfig } from '../../config';

const chatbase = (options: ApiConfig) => {
    const { GATEWAY_URL, DEFAULT_API_ERROR } = options;

    /**
     * Diconnects Google Sheets integration from the specified agent
     * @param {String} agentID - Id of the agent to disconnect Google Sheets for
     * @returns {Promise} an error if occurred while disconnecting Google Sheets from the agent
     */
    const chatbaseConnect = async ({ agentID, token, customerID }) => {
        if (!agentID) throw new Error('Please specify the id of the agent to disconnect Google Sheets for');

        const url = new URL(`${GATEWAY_URL}/api/chatbase`);

        const options: RequestInit = {
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ agentID, customerID, token }),
        };

        try {
            const response = await fetch(url.toString(), options),
                statusCode = response.status,
                body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };
            if (!response.ok) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body };
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };
            return true;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const chatbaseDisconnect = async ({ agentID, customerID }) => {
        if (!agentID) throw new Error('Please specify the id of the agent to disconnect Google Sheets for');

        const url = new URL(`${GATEWAY_URL}/api/chatbase`);

        const options: RequestInit = {
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            method: 'DELETE',
            body: JSON.stringify({ agentID, customerID }),
        };

        try {
            const response = await fetch(url.toString(), options),
                statusCode = response.status,
                body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };
            if (!response.ok) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body };
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };
            return false;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    return {
        chatbaseConnect,
        chatbaseDisconnect,
    };
};

export default chatbase;
