import styled from 'styled-components';

const PopUp = styled.div`
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  background: #FFFFFF;
  min-width: 250px;
`;

const Background = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 999999999999;
  position: fixed;
`;

const PopUpHeader = styled.div`
  display: flex;
  height: 40px;
  padding-right: 20px;
  align-items: center;
  justify-content: flex-end;
`;

const PopUpText = styled.div`
  align-self: flex-start;
  font-family: var(--main-font);
  font-size: 14px;
`;

const Container = styled.div`
  padding: 40px;
  padding-top: 0px;
`;


const ClosePopUp = styled.div` 
  border: none;
  align-items: center;
  cursor: pointer;
`;

export default {
    PopUp,
    PopUpText,
    Background,
    ClosePopUp,
    PopUpHeader,
    Container,
};
