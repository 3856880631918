export const funCopy = (id) => {
    const copy = document.querySelector(`#${id}`);
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(copy);
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand('copy');

    selection.removeAllRanges();
    window.notification.info('Copied', 4);
};

export const copyTextToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = text;
    textArea.id = 'hidenTextAreaClipboard';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        window.notification.info('Copied', 3);
    } catch (err) {
        console.log('Oops, unable to copy');
        window.notification.error('Oops, unable to copy', 4);
    }

    document.body.removeChild(textArea);
};

export default funCopy;
