import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import './App.css';
import ErrorBoundary from './components/mainComponents/mainAppComponent/ErrorBoundary/ErrorBoundary';
import Root from './components/mainComponents/root/Root';
import NotificationComponent from './components/UIComponents/NotificationComponent/NotificationComponent';
import PopUpComponent from './components/UIComponents/PopUpComponent';
import { globalReducer, initGlobalState } from './engine/globalState';
import { GlobalStateProvider } from './engine/globalStateContext';
import { store } from './redux/store';

const App: React.FC = () => (
    <ErrorBoundary>
        <ReduxProvider store={store}>
            <GlobalStateProvider initialState={initGlobalState} reducer={globalReducer}>
                <Root />
                <NotificationComponent />
                <PopUpComponent />
            </GlobalStateProvider>
        </ReduxProvider>
    </ErrorBoundary>
);
export default App;
