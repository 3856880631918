const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) { byteString = atob(dataURI.split(',')[1]); } else { byteString = unescape(dataURI.split(',')[1]); }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
};

export const resizeImage = (src, type, imgWidth, imgHeight) => new Promise((resolve) => {
    const img = new Image();
    img.src = src;
    let resizedimg;
    img.onload = () => {
        const canvas = document.createElement('canvas');
        // var canvas = $("<canvas>", {"id":"testing"})[0];
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const MAX_WIDTH = imgWidth;
        const MAX_HEIGHT = imgHeight;
        let { width } = img;
        let { height } = img;

        if (width > height) {
            if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
            }
        } else if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
        }
        canvas.width = width;
        canvas.height = height;
        // console.log(width, height)

        ctx.drawImage(img, 0, 0, width, height);

        resizedimg = canvas.toDataURL(type);
        resolve(resizedimg);
    };
});

export default dataURItoBlob;
