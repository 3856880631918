/* eslint-disable no-prototype-builtins */
import guid from '../../../guid';
import { BotSchemaBlockType } from '../../../types/botSchema';

const uniqIDGen = (data: Record<string, BotSchemaBlockType>): string => {
    const newID = guid();
    if (data.hasOwnProperty(newID)) {
        return uniqIDGen(data);
    }
    return newID;
};
export type RawBlockDataModifierArgs = {
    rawJSON: BotSchemaBlockType;
    blocksList: Record<string, BotSchemaBlockType>;
    mouseCords: {
        x: number;
        y: number;
    };
    skill: string;
};
const RawBlockDataModifier = (obj: RawBlockDataModifierArgs): BotSchemaBlockType => {
    return {
        ...obj.rawJSON,
        id: uniqIDGen(obj.blocksList),
        cords: obj.mouseCords,
        skill: obj.skill,
    };
};

export default RawBlockDataModifier;
