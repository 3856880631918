import _ from 'lodash';
import io from 'socket.io-client';
import { Api } from '../api';
import { useConfig } from '../api/config';
import { LivechatAgent } from '../api/models/livechat.types';
import { CustomerRole } from '../types/customerRole';
import { LivechatData } from '../types/livachat';

// NOTE(m-nny): dirty, should find proper way
// eslint-disable-next-line react-hooks/rules-of-hooks
const config = useConfig();

const getAgent = async (id: string, api: Api) => {
    try {
        const result = await api.livechat.getAgentIdByRoleId(id);
        return result.agent;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const setLiveChatAgentOnline = async (agentId: string) => {
    try {
        const socket = io(`${config.LIVECHAT_SERVICE_URL}/api/v1/agents/setOnline?agentId=${agentId}`);
        socket.on('connect', () => {});
        socket.on('disconnect', () => {});
    } catch (error) {
    }
};

const getLiveChatData = async (
    api: Api,
    selectedBotId: string,
    roles: CustomerRole[]
): Promise<LivechatData | null> => {
    const permissionInThisBot = roles.find((role) => role.botId === selectedBotId);
    if (!permissionInThisBot) {
        return null;
    }
    const liveChatPermissionsData = {
        roleId: permissionInThisBot._id,

        people: _.intersection(permissionInThisBot.permissions, ['conversations', 'livechat_admin']).length > 0,
        livechat_admin: _.includes(permissionInThisBot.permissions, 'livechat_admin'),
        liveChat: _.includes(permissionInThisBot.permissions, 'livechat_agent'),
        edit_skills: _.includes(permissionInThisBot.permissions, 'edit_skills'),
    };
    let liveChatAgent: LivechatAgent | undefined;
    try {
        liveChatAgent = await getAgent(liveChatPermissionsData!.roleId, api);
        await setLiveChatAgentOnline(liveChatAgent._id);
    } catch (error) {
        console.log(error);
    }
    return {
        agentId: liveChatAgent?._id!,
        ...liveChatPermissionsData,
        tags: liveChatAgent?.tags || [],
    };
};

export default getLiveChatData;
